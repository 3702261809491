<template>
  <div class="password-checker">
    <div :class="['p-1 mt-1', cond.valid ? 'bg-green' : 'bg-gray']" :key="cond.string" v-for="(cond) in conditions">
      <icon :type="cond.valid ? 'check' : 'times'" /> {{ cond.valid }} {{ $t('pwdConditions.' + cond.string, cond.args ? cond.args : {}) }}
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Icon";
/**
 * Receives a password, checks it for complexity and displays error messages and checkboxes
 * **/
export default {
  name: "PasswordChecker",
  components: {
    Icon
  },
  props: {
    password: {type: String, default: ''}
  },
  data() {
    return {
      isValid: false,
      conditions: [{
          string: 'atLeastOneDigit',
          regex: /^(?=.*\d)(.*){12,64}$/,
          valid: false,
        },
        {
          string: 'atLeastOneUppercaseLetter',
          regex: /^(?=.*[A-Z])(.*){12,64}$/,
          valid: false,
        },
        {
          string: 'atLeastOneSpecialCharacter',
          args: {
            characters: '=;?!@#$%^&*-',
          },
          regex: /^[=;?!@#$%^&*-]*[a-zA-Z0-9]*[=;?!@#$%^&*-]{1,}[a-zA-Z0-9]*[=;?!@#$%^&*-]*$/,
          valid: false,
        },
        {
          string: 'atLeastCharacters',
          args: {
            min: 12,
            max: 64
          },
          regex: /[a-zA-Z\d=;?!@#$%^&*-_]{12,64}$/,
          valid: false,
        },
      ]
    }
  },
  watch: {
    password() {
      for(let i = 0; i < this.conditions.length; i++) {
        this.conditions[i].valid = this.checkComplexity(this.conditions[i].regex);
      }
      this.isValid = this.checkPasswordComplexity(this.password);
    },
  },
  methods: {
    checkComplexity(regex) {
      return regex.test(this.password);
    },
    /**
   * password must have:
   *  - one digit
   *  - one uppercase letter
   *  - one special character
   *  - length between 12 and 64
   *  @returns {object}
   *  {
   *      string: 'translationstring',
   *      args: 'translationargs'
   *  }
   */
    checkPasswordComplexity(password) {
        if(!/^(?=.*\d+)(?=.*[A-Z]+)(?=.*[^a-zA-Z0-9])(?=[=;?!@#$%^&*-]*[a-zA-Z0-9]*[=;?!@#$%^&*-]{1,}[a-zA-Z0-9]*[=;?!@#$%^&*-]*)[a-zA-Z\d=;?!@#$%^&*-_]{12,64}$/.test(password)) {
          this.$emit('status', false);
            if(!/^(?=.*\d)(.*){12,64}$/.test(password)) {
                return {string: 'errors.password.atLeastOneDigit'};
            } else if(!/^(?=.*[A-Z])(.*){12,64}$/.test(password)) {
              return {string: 'errors.password.atLeastOneUppercaseLetter'};
            } else if(!/^(?=.*[=;?!@#$%^&*-_])(.*){12,64}$/.test(password)) {
              return {string: 'errors.password.atLeastOneSpecialCharacter', args: {characters: this.characters}};
            } else if(!/[a-zA-Z\d=;?!@#$%^&*-_]{12,64}$/.test(password)) {
                return {string: 'errors.password.atLeastCharacters', args: {min: 12, max: 64}};
            }
        }
        this.$emit('status', true);
        return '';
    }
  }
}
</script>

<style>
  .password-checker {
    max-width: 350px;
  }
  .bg-green {
    background-color: #38884d;
  }
  .bg-gray {
    background-color: #777676;
  }
</style>