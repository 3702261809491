<template>
    <div class="container-fluid main-container">
        <div class="row sub-menu-panel">
          <navigation-panels-container
              id="library"
              :tab-selector="subMenuSelector"
              v-if="subMenuSelector"
          />
            <slot name="subMenu"/>
        </div>
        <div class="row">
            <div class="col ml-5">
                <slot name="buttonsLeft"/>
            </div>
            <div class="col"/>
            <div class="col mr-5 text-right create-new-button">
                <slot name="buttonsRight"/>
            </div>
        </div>
        <div class="row">
          <tabs slot="tabs"
                v-if="backButtonTabSelector"
                classes="col-12 col-md-2 col-lg-2 offset-md-1 backbutton"
                :tab-selector="backButtonTabSelector"
                :custom-tab-name="backButtonTabSelector"
                :first-inactive="true"
          />
          <tabs
              v-if="tabSelector"
              :id="id"
              :tab-selector="tabSelector"
              :type="type"
          />
            <slot name="tabs"/>
        </div>
        <div class="row main-panel">
            <slot name="filter"/>
            <portal-target name="filter" slim/>
            <div class="col mr-md-1 mr-xl-3 mt-3 overflow-auto">
                <div :class="['row no-gutters justify-content-between mb-3', hasTopBar ? 'top-bar' : '']">
                    <slot name="mainContentButtons"/>
                </div>
                <slot name="mainContent"/>
            </div>
        </div>
    </div>
</template>

<script>
    import Tabs from "@/components/navigation/Tabs";
    import NavigationPanelsContainer from "@/components/navigation/NavigationPanelsContainer";
    export default {
      name: 'MainTableLayout',
      components: {
        Tabs,
        NavigationPanelsContainer
      },
      props: {
        hasTopBar: {type: Boolean, default: true},
        /**
         * @tabSelector String the tabSelector (e.g. "appstabs", set in router.js)
         * @backButtonTabSelector String the same but for the backButton
         * if set, tabs will be displayed automatically
         */
        tabSelector: { type:String, default: ''},
        backButtonTabSelector: { type:String, default: ''},
        subMenuSelector: { type:String, default: ''},
        /**
         * @id String the id to use for the tabs if tabSelector is given
         */
        id: { type:String, default: ''},
        /**
         * @type String the type to use for the tabs if tabSelector is given
         */
        type: { type:String, default: ''},
      },
    }
</script>