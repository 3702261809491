<template lang="html">
    <section class="download">
      <div v-for="asset in assets"
            v-if="asset.key"
           :key="asset.id"
      >
      <p class="lighter smaller slim mb-1">{{ asset.version }}</p>
      <download-button
          classes="highlight single-line"
          :object-id="asset.id"
          :file-key="asset.key"
          :storage-u-r-l="storageURL"
          :username="username"
          :password="password"
          :file-key-only="true"
          :external-file-size="asset.size"
      ><icon class="left" type="windows" />{{ asset.label }} <span class="lighter" v-if="asset.info">{{ asset.info }}</span><p class="lighter smaller slim ml-4">{{ getReadableFileSize(asset.size) }} ({{ asset.key.split('.').pop() }})</p></download-button>
        </div>
    </section>
</template>

<script>
    import DownloadButton from "@/components/forms/DownloadButton";
    import {SpecialUuids} from "@/enum";
    import axios from "axios";
    import Icon from "./Icon";
    import filesize from "file-size";
    export default  {
        name: 'DownloadSection',
        components: {
            DownloadButton,
            Icon,
        },
        data() {
          return {
            uuids: SpecialUuids,
            assets: [],
            authToken: '',
            clientURL: '',
            storageURL: '',
            files: [],
            username:'public',
            password:'public',
          };
        },
        beforeMount() {
         this.setUpDownloadSection();

        },
        methods: {
          async setUpDownloadSection() {
            return fetch(process.env.VUE_APP_CONFIG)
                .then(r => r.json())
                .then(config => {
                  this.clientURL = config.sfx.project.webapp.apiHost + '/api/v2';
                  this.storageURL = config.sfx.project.webapp.storageHost + '/api/v2';
                })
                .then(() => {
                  this.getApplicationUuid('windows');
                  this.setNewestVersionsFromStorage();
                })
                .catch(e => {
                  console.log(e);
                })
          },
          getApplicationUuid(os) {
            this.assets.push({
              id: SpecialUuids[`VSTAGE_${os.toUpperCase()}`],
              key: '',
              os: os,
              version: '',
              size: '',
              label: this.$t('DOWNLOAD'),
              info: 'installer'
            });
            this.assets.push({
              id: SpecialUuids[`VSTAGE_${os.toUpperCase()}_NO_INSTALLER`],
              key: '',
              os: os,
              version: '',
              size: '',
              label: this.$t('DOWNLOAD'),
              info: 'NO INSTALLER'
            });
          },
          /**
           * */
          async setNewestVersionsFromStorage() {
            await this.assets.map(async asset => {
              const object = await this.getNewestVersionFromStorage(asset.id);
             if(object) {
                asset.key = object.key ? object.key : '';
                asset.version = object.metaData.attributes.version ? object.metaData.attributes.version : '';
                asset.size = object.size;
              }
            });
          },
          async getNewestVersionFromStorage(id) {
            return axios.get(`${this.storageURL}/assets/${id}/ls`,{auth: {
                username: this.username,
                password: this.password
              }}).then(response => {
              const arr = response.data;
              const filtered = arr.filter(file => {
                return !!file.metaData && !!file.metaData.attributes && !!file.metaData.attributes.version && file.contentType.includes('application/')
              });
              const sorted = filtered.sort((a, b) => {
                return this.compareVersion(b.metaData.attributes.version, a.metaData.attributes.version);
              });
              return sorted[0];
            })
          },
          getReadableFileSize(size) {
            return filesize(size, {fixed: 2, spacer: ' '}).human('si')
          },
          compareVersion(a, b) {
            const aArr = a.split('.');
            const bArr = b.split('.');
            if(Number(aArr[0]) !== Number(bArr[0])) {
              return Number(aArr[0]) - Number(bArr[0]);
            }
            else if(Number(aArr[1]) !== Number(bArr[1])) {
              return Number(aArr[1]) - Number(bArr[1]);
            }
            else {
              return Number(aArr[2]) - Number(bArr[2]);
            }
          }
        }
    }
</script>


<style lang="scss">
    .download {
      flex: 1;
      .content {
        height:auto !important;
      }
      &> div {
        margin-right:50%;
      }

    }
    .download .button {
        width:240px;
        margin-bottom:5px;
      .smaller {
        max-width: 130px;
        text-transform: none;
        &.lower {
          margin-bottom: 15px;
        }
      }
    }
</style>
