<template>
  <div class="d-flex">
    <div :key="key" v-for="(log, key) in logs">
      <div class="year-section p-2 mb-1 ml-1" :key="index2" v-for="(year, yearNumber, index2) in log">
        <div class="year">{{yearNumber}}</div>
        <div class="mb-1" :key="monthNumber" v-for="(month, monthNumber) in year">
          {{ new Intl.DateTimeFormat('en-US', {month: 'long'}).format(new Date(yearNumber, monthNumber + 1, 0)) }}: <span class="price">{{ getTotalPrice(month) }} CHF</span>
          <div v-if="showDetail" :key="entryIndex" v-for="(entry, entryIndex) in month">
            {{ entry.updatedAt}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {_} from "vue-underscore";
import DateMixin from "@/components/mixins/DateMixin.js";

export default {
  name: "LogSummary",
  props: {
    organizationId: {type: String, required:true},
    autoLoad: {type: Boolean, default: false},
  },
  mixins: [DateMixin],
  data() {
    return {
      logs: {},
      showDetail: false,
    };
  },
  beforeMount() {
    if(this.autoLoad) {
      this.loadLogs();
    }
  },
  methods: {
    async loadLogs() {
      const logs = await this.$store.dispatch('clientLoadVMLogs', {filter: 'organizationId eq ' + this.organizationId});
       this.logs = this.groupLogsByMonth(logs);
    },
    /***
     * Groups the vm log entries by month
     * */
    groupLogsByMonth(logs) {
      logs = _.groupBy(logs, ({updatedAt})=> new Date(updatedAt).getFullYear());
      logs = Object.keys(logs).map(key => {
        return {[key]: _.groupBy(logs[key], ({updatedAt})=> new Date(updatedAt).getMonth()) };
      })
      return logs;
    },
    /***
     * calculates the total price of given entries
     * */
    getTotalPrice(entries) {
      let totalPricePerMonth = 0;
      entries.map(entry => {
        if(entry.vmStoppedAt) {
          totalPricePerMonth += entry.clientPricePerMinute *  this.timeDifferenceFromTwoISOs(entry.vmStoppedAt, entry.vmStartedAt).minutes;
        }
      })
      return totalPricePerMonth;
    },

  }
}
</script>

<style lang="scss" scoped>
  .year {
    font-size: 1.3rem;
    font-weight: bold;
  }
  .year-section {
    color: #000;
    background-color: #efefef;
  }
</style>
