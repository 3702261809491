<template>
    <main-table-layout>
        <div slot="buttonsRight"/>
        <content-filter
                slot="filter"
                id="appList"
                @filter="filter"
                store-name="App"
                :type-filter-items="['app', 'webapp']"
        />
        <view-switcher
                slot="mainContentButtons"
                id="mainContent"
                :table="true"
                :thumbnails="true"
        />
        <pagination
                slot="mainContentButtons"
                :limit="paginationLimit"
                :total="$store.getters.getAppListOptions('appList', 'pagination_items')"
                id="projecttablepage"
                @setActive="loadPaginatedData"
                ref="pagination"
        />
        <Button
                permission="create"
                type="action-button"
                slot="mainContentButtons"
                link="/app/add" icon="plus">
            {{ $t('createNewApp') }}
        </Button>
      <Button
           slot="mainContent"
           @click="loadPaginatedData(0); $refs.pagination.setActive(0)"
           type="reload"
           icon="redo"
           class="icon-button"
       />

        <table-widget
                v-if="$store.getters.getViewSwitcher($route.name, 'mainContent') === 'table'"
                slot="mainContent"
                id="appsDefaultTable"
                :nav-by-clicking-row="true"
                :deletable="true"
                @deleteRow="deleteRow"
                :show-tags="true"
                :show-organization="true"
                :fields="tableFields"
                :data="$store.getters.getApps"
                :title-as-links="true"
                @sort="sort"
                @updateContent="updateContent"
                edit-permission="write"
                delete-permission="delete"
                resource="Project"
                :use-team-permission="true"
                :loading="loading"
        />

        <thumbnails
                v-if="$store.getters.getViewSwitcher($route.name, 'mainContent') === 'thumbnails'"
                slot="mainContent"
                id="appsDefaultTable"
                :deletable="true"
                @delete-row="deleteRow"
                :nav-by-clicking-row="true"
                :fields="thumbnailFields"
                :data="$store.getters.getApps"
                :title-as-links="true"
                @sort="sort"
                @updateContent="updateContent"
                edit-permission="write"
                delete-permission="delete"
                resource="Project"
                :use-team-permission="true"
                :loading="loading"
        />
    </main-table-layout>
</template>

<script>
    import Button from "../components/forms/Button";
    import TableWidget from "../components/widgets/TableWidget";
    import ContentFilter from "../components/widgets/filter/ContentFilter";
    import MainTableLayout from "../layouts/MainTableLayout";
    import ViewSwitcher from "../components/widgets/ViewSwitcher";
    import Thumbnails from "../components/widgets/ThumbnailView";
    import Pagination from "../components/Pagination";

    export default {
        name: 'Apps',
        components: {
            Button,
            TableWidget,
            ContentFilter,
            MainTableLayout,
            ViewSwitcher,
            Thumbnails,
            Pagination,
        },
        data() {
            return {
                loading: true,
                paginationLimit: 15,
                queryRelevantComponents: 2, //pagination and filter
                queryRelevantInits: 0,
                sorting: '-updatedAt',
                tableFields: [
                    {
                        name: '',
                        id:'',
                        type: 'image',
                    },
                    {
                        name: 'Name',
                        id:'name',
                        type: 'string',
                        sortable: true
                    },
                    {
                      name: 'Teams',
                      id: 'teams',
                      type: 'teams',
                    },
                    {
                        name: 'Type',
                        id:'type',
                        type: 'string',
                    },
                    {
                        name: 'Last Update',
                        id:'updatedAt',
                        type: 'date',
                        sortable: true
                    },
                    {
                        name: 'Status',
                        id:'state',
                        type: 'state',
                    },
                    {
                        name: 'Edit',
                        type: 'editButton'
                    },
                ],
                thumbnailFields: [
                    {
                        name: '',
                        id:'',
                        type: 'image',
                    },
                    {
                        name: 'Organization',
                        id:'organization',
                        type: 'organization',
                    },
                    {
                        name: 'Last Update',
                        id:'updatedAt',
                        type: 'date',
                        sortable: true
                    },
                    {
                        name: 'Name',
                        id:'name',
                        type: 'string',
                        sortable: true
                    },
                    {
                      name: 'Teams',
                      id: 'teams',
                      type: 'teams',
                    },
                    {
                        name: 'Tags',
                        id:'tags',
                        type: 'tags',
                    },
                    {
                        name: 'Status',
                        id:'state',
                        type: 'state',
                    },
                    {
                        name: 'Edit',
                        type: 'editButton'
                    },
                ],
            }
        },
        watch: {
            queryRelevantInits: function(newval) {
                if(newval === this.queryRelevantComponents) {
                    this.loadPaginatedData(0);
                }
            }
        },
        methods: {
            updateContent: function(args) {
                this.$store.dispatch('updateApp', args);
            },
            loadPaginatedData: function(offset, initial) {
              this.loading = true;
                let args = {
                    add: {
                        paging: {
                            appdefaultTable: true,
                        },
                        offset: {
                            appdefaultTable: offset,
                        },
                        limit: {
                            appdefaultTable: this.paginationLimit,
                        },
                        include: {
                            appdefaultTable: 'tags,teams',
                        },
                        sort: {
                          [this.listName]: this.sorting,
                        }
                    }
                };
                if(initial) {
                    this.queryRelevantInits++;
                    this.$store.dispatch('registerAppsQueryParams', args);
                }
                else {
                    this.$store.dispatch('loadApps', args).then(() => {
                      this.loading = false;
                    })
                }
            },
            deleteRow: function(id) {
                this.$store.dispatch('deleteApp', {
                    args: [id]
                });
            },
            filter: function(args, initial) {
                if(initial) {
                    this.queryRelevantInits++;
                    this.$store.dispatch('registerAppsQueryParams', args);
                }
                else {
                    this.$store.dispatch('loadApps', args).then(() => {
                       this.loading = false;
                    })
                }
            },
            setFilterParams: function(args) {
                this.$store.dispatch('submitQueryParams', {
                    storeType: 'apps',
                    listName: this.$route.params.id,
                    params: args,
                });
            },
            sort(args) {
              const {fieldname, direction} = args;
              const sortString = direction + fieldname;
              if(this.sorting !== sortString) {
                this.sorting = sortString;
              }
              this.loadPaginatedData(0);
            }
        }
    }
</script>
