<template>
    <tr class="meta-field">
        <td class="meta-field-label">
            {{ label }}
        </td>
        <td class="table-edit"
            v-if="!editing"
            @click.prevent="startEditing">
            <icon v-if="!editing" :type="'edit'" />
        </td>
        <td class="table-edit"
            v-if="editing"
            @click.prevent="cancelEditing">
            <icon v-if="editing" type="times" />
        </td>
        <td :key="field.id" v-if="!editing" class="meta-field-value">
          <div v-if="!this.$store.getters.isSuperAdmin && ['2fe0dcb3-b82f-46d6-b940-d7caeafb007a', '768c4690-8646-4e51-a9fe-2bcf606803e9'].includes(field.id)">
            <!--Placeholder for quickfix-->
          </div>
          <json-adder
              v-else-if="isJson && (getConfigField('widget') === 'JsonAdder')"
              :value="rawValue"
              :config="field.config"
              :editing="false"
          />
          <mat-color-adder
              v-else-if="isJson && (getConfigField('widget') === 'MatColorAdder')"
              :value="rawValue"
              :config="field.config"
              :editing="false"
          />
            <u-i-group
                    v-else-if="isJson && isUiOverlay"
                    :organization-id="$store.getters.getProjectOrganization($route.params.id)"
                    :id="$route.params.id"
                    @save="updateValue"
                    :value="renderValue"
            />
            <pre v-else-if="isJson">{{ renderValue }}</pre>
            <content-display
                    :list-name="field.id"
                    :type="field.config.type ? field.config.type : field.type"
                    :show-preview="field.type === 'asset'"
                    :value="renderValue"
                    v-else-if="isAsset"
                    :media-category="field.config && field.config.mediaCategory ? field.config.mediaCategory : null"
                    :teams="teams"
            />
            <content-display
                    v-else-if="isProject"
                    :value="renderValue"
                    :type="'project'"
                    :list-name="field.id"
                    :teams="teams"
            />
                <vector-display
                        v-else-if="isVector"
                        :value="renderValue"
                        :type="field.type"
                />
           <lifetime-setter
              v-else-if="field.config && field.config.unit === 'seconds' && isInteger"
              :value="rawValue"
              :editing="false"
              :hide-label="true"
            />

            <div v-else-if="isCheckbox">
                {{ renderValue }}
            </div>
            <div v-else-if="isDate">
                {{ dateFromISO(renderValue) }}
            </div>
            <div v-else-if="isDateTime">
                {{ dateTimeFromISO(renderValue) }}
            </div>
            <div v-else-if="isTime">
                {{ timeFromISO(renderValue) }}
            </div>
            <div v-else-if="isPassword">
                <span :key="x" v-for="x in renderValue.length">*</span>
            </div>
            <div v-else-if="isColor" style="display: flex; align-items: center;">
                <div class="color-square" :style="{backgroundColor: renderValue}" />
                <div style="padding-left: 15px">{{ renderValue }}</div>
            </div>
            <div v-else>
                {{ renderValue }}
            </div>
        </td>
        <td class="meta-field-value editing" v-else>
          <div v-if="!this.$store.getters.isSuperAdmin && ['2fe0dcb3-b82f-46d6-b940-d7caeafb007a', '768c4690-8646-4e51-a9fe-2bcf606803e9'].includes(field.id)">
            <!--Placeholder for quickfix-->
          </div>
            <mat-color-adder
                    v-else-if="isJson && (getConfigField('widget') === 'MatColorAdder')"
                    :value="rawValue"
                    @save="updateValue"
                    :config="field.config"
                />
            <json-adder
                v-else-if="isJson && (getConfigField('widget') === 'JsonAdder')"
                :value="rawValue"
                @save="updateValue"
                :config="field.config"
            />

            <text-widget
                    v-else-if="isText"
                    @save="updateValue"
                    :value="renderValue"
            />
            <color-picker
                    v-else-if="isColor"
                    :value="renderValue"
                    @save="updateValue"
            />
            <range-widget
                    v-else-if="isRange"
                    :value="rawValue"
                    :type="field.type"
                    @save="updateValue"
            />
            <uuid-widget v-else-if="isUuid"
                         @save="updateValue"
                         :value="renderValue"
            />
            <list-widget
                v-else-if="isList"
                @save="updateValue"
                :value="rawValue"
            />
            <i-p-widget
                    v-else-if="isIP"
                    @save="updateValue"
                    :value="renderValue"
            />
            <uri-widget v-else-if="isUri"
                        @save="updateValue"
                        :value="renderValue"
            />
            <email-widget
                    v-else-if="isEmail"
                    @save="updateValue"
                    :value="renderValue"
            />
            <boolean-widget
                    v-else-if="isCheckbox"
                    @save="updateValue"
                    :value="rawValue"
                    :label="field.key"
            />
            <vector-widget
                    v-else-if="isVector"
                    :value="rawValue"
                    :type="field.type"
                    @save="updateValue"
            />
            <date-picker-widget
                    v-else-if="isDateOrTime"
                    :value="renderValue"
                    :type="field.type"
                    @save="updateValue"
            />
            <content-list
                    v-else-if="isAsset"
                    :value="renderValue"
                    :type="field.config.type ? field.config.type : field.type"
                    :show-preview="field.type === 'asset'"
                    :list-name="field.id"
                    @save="updateValue"
                    :organization-id="organizationId"
                    :media-category="field.config && field.config.mediaCategory ? field.config.mediaCategory : null"
                    :teams="teams ? teams.map(item => {return item.id}) : []"
            />
            <content-list
                    v-else-if="isProject"
                    :value="renderValue"
                    :type="'project'"
                    :list-name="field.id"
                    @save="updateValue"
                    :organization-id="organizationId"
            />
             <lifetime-setter
              v-else-if="field.config && field.config.unit === 'seconds' && isInteger"
              :value="rawValue"
              :editing="true"
              :hide-label="true"
              @setValue="updateValue"
            />
            <number
                    v-else-if="isFloat"
                    @save="updateValue"
                    :value="renderValue"
                    :type="field.type"
            />
            <integer-widget
                    v-else-if="isInteger"
                    @save="updateValue"
                    :value="renderValue"
                    :type="field.type"
            />
            <codemirror
                    v-else-if="isJson"
                    :value="renderValue"
                    @save="updateValue"
            />

            <enum v-else-if="isEnum"
                  :value="renderValue"
                  :possible-values="renderConfig"
                  @save="updateValue"
            />
            <password-widget
                    v-else-if="isPassword"
                    :value="renderValue"
                    :possible-values="field.config"
                    @save="updateValue"
            />

        </td>
    </tr>
</template>

<script>
    import Icon from "../../Icon";
    import {MetaFieldTypes} from '../../../enum';
    import ContentList from "../editors/ContentList";
    import ContentDisplay from "../editors/ContentDisplay";
    import Enum from "../editors/Enum";
    import Number from "../editors/FloatWidget";
    import TextWidget from "../editors/TextWidget";
    import BooleanWidget from "../editors/BooleanEditor";
    import VectorWidget from "../editors/VectorWidget";
    import DateMixin from '@/components/mixins/DateMixin.js';
    import PasswordWidget from "../editors/PasswordWidget";
    import VectorDisplay from "../editors/VectorDisplay";
    import EmailWidget from "../editors/EmailWidget";
    import IPWidget from "../editors/IPWidget";
    import UriWidget from "../editors/UriWidget";
    import RangeWidget from "../editors/RangeWidget";
    import ListWidget from "../editors/ListWidget";
    import IntegerWidget from "../editors/IntegerWidget";
    import UuidWidget from "../editors/UuidWidget";
    import UIGroup from "../editors/UIGroup";
    import DatePickerWidget from "@/components/widgets/editors/DatePickerWidget";
    import ColorPicker from "@/components/widgets/editors/ColorPicker";
    import Codemirror from "@/components/widgets/editors/Codemirror";
    import JsonAdder from "@/components/widgets/editors/JsonAdder";
    import MatColorAdder from "@/components/widgets/editors/MatColorAdder";
    import LifetimeSetter from "@/components/LifetimeSetter";
    /**
     * Returns the raw value or '(empty)' if value
     * is undefined or null.
     *
     * @param {string} v - Value
     * @param dft - Default value
     * @returns {string}
     */
    const emptyOrValue = (v, dft) => {
        const d = dft || '(empty)';
        return v ? v : d;
    };

    /**
     *
     */
    export default {
        name: 'MetaField',
        components: {
            DatePickerWidget,
            ColorPicker,
            Icon,
            Codemirror,
            ContentList,
            ContentDisplay,
            Enum,
            Number,
            TextWidget,
            BooleanWidget,
            VectorWidget,
            PasswordWidget,
            VectorDisplay,
            EmailWidget,
            IPWidget,
            UriWidget,
            RangeWidget,
            ListWidget,
            UuidWidget,
            IntegerWidget,
            UIGroup,
            JsonAdder,
            MatColorAdder,
            LifetimeSetter
        },
        mixins: [DateMixin],
        props: {
            label: {type: String, required: true},
            value: {type: String, required: false, default: null},
            field: {type: Object, required: true},
            /**
            * The id of the target (e.g. of the Asset)
            * */
            targetId: {type: String, required: true},
            /**
            * The storeName to update the item (e.g. Project)
            * */
            storeName: {type: String, default: ''},
            /**
            * If required by the store (e.g. setting the metaValue of an Instance which needs id = projectId and cid = instanceId)
            * */
            cid: {type: String, required: false, default: null},
            organizationId: {type: String, required: true},
            teams: {type: Array, default: null},
            doEmitOnly: {type: Boolean, default: false}
        },
        data() {
            return {
                editing: false,
                updatedValue: [],
            }
        },
        computed: {
            isText() {
                return this.field.type === MetaFieldTypes.String || this.field.type === MetaFieldTypes.Text;
            },
            isIP() {
                return this.field.type === MetaFieldTypes.Ip || this.field.type === MetaFieldTypes.Ipv6;
            },
            isUri() {
                return this.field.type === MetaFieldTypes.Uri
            },
            isEmail() {
                return this.field.type === MetaFieldTypes.Email
            },
            isUuid() {
                return this.field.type === MetaFieldTypes.Uuid;
            },
            isDateOrTime() {
                return this.field.type === MetaFieldTypes.Date || this.field.type === MetaFieldTypes.Time || this.field.type === MetaFieldTypes.DateTime;
            },
            isDate() {
                return this.field.type === MetaFieldTypes.Date;
            },
            isTime() {
                return this.field.type === MetaFieldTypes.Time;
            },
            isDateTime() {
                return this.field.type === MetaFieldTypes.DateTime;
            },
            isUiOverlay() {
                return this.field.key === 'uiOverlay';
            },
            isJson() {
                return this.field.type === MetaFieldTypes.Json;
            },
            isAsset() {
                return this.field.type === MetaFieldTypes.Asset || this.field.type === MetaFieldTypes.DataSet;
            },
            isProject() {
                return this.field.type === MetaFieldTypes.Project;
            },
            isVector() {
                return this.field.type === MetaFieldTypes.Vector2 || this.field.type === MetaFieldTypes.Vector3 || this.field.type === MetaFieldTypes.Vector4 || this.field.type === MetaFieldTypes.xForm2 || this.field.type === MetaFieldTypes.xForm3;
            },
            isRange() {
                return this.field.type === MetaFieldTypes.Range;
            },
            isCheckbox() {
                return (this.field.type === MetaFieldTypes.Boolean);
            },
            isList() {
                return (this.field.type === MetaFieldTypes.List);
            },
            isColor() {
                return this.field.type === MetaFieldTypes.Color;
            },
            isEnum() {
                return this.field.type === MetaFieldTypes.Enum;
            },
            isPassword() {
                return this.field.type === MetaFieldTypes.Password;
            },
            isFloat() {
                return this.field.type === MetaFieldTypes.Float;
            },
            isInteger() {
                return this.field.type === MetaFieldTypes.Integer;
            },
            typeI18nKey() {
                let key = 'editors.type.' + this.field.type;
                if (this.field.type === MetaFieldTypes.Asset) {
                    if (this.field.config) {
                        key = key + '.' + this.field.config.type;
                    }
                }
                return key;
            },
            renderConfig() {
                if(typeof this.field.config === 'string') {
                    return JSON.parse(this.field.config);
                }
                else {
                    return this.field.config;
                }
            },
            /**
             * Render the current value according the type
             * @returns {string}
             */
            renderValue() {
                const v = this.getValue();
                const d = this.field.defaultValue;
                switch (this.field.type) {
                    case MetaFieldTypes.Integer:
                    case MetaFieldTypes.String : {
                        return emptyOrValue(v, d);
                    }
                    case MetaFieldTypes.Vector2 : {
                        return v;
                    }
                    case MetaFieldTypes.Vector3 : {
                        return v;
                    }
                    case MetaFieldTypes.Vector4 : {
                        return v;
                    }
                    case MetaFieldTypes.xForm2 : {
                        return v;
                    }
                    case MetaFieldTypes.xForm3 : {
                        return v;
                    }
                    case MetaFieldTypes.Boolean: {
                        if ( v === undefined) {
                            if (d === undefined) {
                                return false;
                            }
                        }
                        return v;
                    }
                    case MetaFieldTypes.Json: {
                        return emptyOrValue(v, d);
                    }
                    default:
                        return emptyOrValue(v, d);
                }
            },
            rawValue() {
                return this.getValue();
            },
        },
        beforeMount() {
            if(!this.value) {
                this.updatedValue = '';
            }
        },
        methods: {
            startEditing() {
                this.editing = true;
            },
            cancelEditing() {
                this.editing = false;
            },
            getConfigField(fieldName) {
              if(this.field.config) {
                return this.field.config[fieldName] ? this.field.config[fieldName] : null;
              }
            },
            updateValue(value) {
                let args = {
                    id: this.targetId,
                    values: [{metaFieldId: this.field.id, value: value}]
                };
                if(this.cid) {
                    args.cid = this.cid;
                }

                const updated = {
                   metaFieldId: this.field.id,
                   value: value,
                   cid: this.cid,
                 };

                if(this.doEmitOnly) {
                  this.$emit('updated', updated);
                } else {
                  this.$store.dispatch('set' + this.storeName + 'MetaValues', args).then(() => {
                    this.$emit('updated', updated)
                  });
                }
                this.setValue(value);
                this.editing = false;
            },
            setValue(value) {
                if (!this.updatedValue) {
                    this.updatedValue = value;
                }
                this.updatedValue = value
            },
            getValue() {
                if (this.hasUpdateValue()) {
                  return this.updatedValue;
                }

                if (this.hasValue()) {
                  return this.value;
                }
                
                if (this.hasDefault()) {
                    return this.field.default;
                }
                return null;
            },
            hasValue() {
                return this.value && this.value.length > 0;
            },
            hasUpdateValue() {
                return this.updatedValue && this.updatedValue.length > 0;
            },
            hasDefault() {
                return !!this.field.default;
            },
        },
    }
</script>

<style lang="scss" scoped>
    .meta-field {
        background-color: $meta-field-background-color;
    }

    pre {
        color: #fff;
    }

    .meta-field-label {
        font-size: 1em;
        margin-right: 10px;
        width: 20%;
        min-width: 100px;

        &.editing {
            background-color: $input-background-color;
        }
    }
    .meta-field-value {
      background-color: darken($meta-field-background-color, 10%);
      border: 1px solid darken($meta-field-background-color,15%);
    }

    td, th {
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
    }
    table tr {
      border: 1px solid darken($meta-field-background-color, 10%);
    }

    .table-edit {
        position: relative;
        height: 100%;
        cursor: pointer;
        width:40px;
        min-width:40px;
        border-right: 1px solid $input-background-color;

        .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            -webkit-transition: all 300ms ease;
            transition: all 300ms ease;
            opacity: 0.8;
        }

        &:hover {
            .icon {
                opacity: 1;
            }
        }
    }
</style>
