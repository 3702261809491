<template>
    <one-column-sidebar-left
      sub-menu-selector="librarypanel"
      back-button-tab-selector="sfxbackbutton"
      tab-selector="addsfxtab"
      :id="$route.params.id"
    >
        <div slot="mainContent">
          <form-wizard
              :current-slot="currentSlot"
              :number-of-slots="3"
          >
            <!--------------------------------- SLOT - 1---------------------------->
            <div slot="slot-1">
              <h1>{{ $t('createNewSfxdata') }}</h1>
            <organization-switcher
                @setOrganization="id => {organizationId = id}"
                @setTeam="id => this.teamId = id"
                @unsetTeam="teamId = ''"
                class="mb-2" />
            <input
                    type="text"
                    :class="['form-text mb-3 mt-3', $v.name.$dirty && $v.name.$error ? 'form-group--error' : '']"
                    :id="'name'"
                    v-model="name"
                    :placeholder="$t('enternamehere')"
                    @input="delayTouch($v.name)"
            >

            <div class="form-error" v-if="$v.name.$dirty && !$v.name.required && $v.name.$error">{{ $t('errors.required') }}</div>
            <div class="form-error" v-if="$v.name.$dirty && !$v.name.alphaNumSpace">{{ $t('errors.alphaNumSpaceOnly') }}</div>

            <div class="form-error" v-if="$v.name.$dirty && !$v.name.maxLength">{{ $t('errors.atMostCharacters', {num: 128}) }}</div>

            <div class="form-error" v-if="$v.name.$dirty && !$v.name.minLength">{{ $t('errors.atLeastCharacters', {num: 3}) }}</div>
            <div class="form-error" v-if="$v.name.$dirty && !$v.name.isUnique">{{ $t('errors.sfxdataNameAlreadyExists') }}</div>
            <div class="form-error" v-if="error">{{ $t(error) }}</div>
        <div>
            <div class="inline-edit-label">
                <b>{{ $t('description') }}</b>
            </div>

            <textarea
                    :class="['form-text mb-3', $v.description.$dirty && $v.description.$error ? 'form-group--error' : '']"
                    :id="'description'"
                    v-model="description"
                    :placeholder="$t('enterdeschere')"
                    @input="delayTouch($v.description)"
            />
            <div class="form-error" v-if="$v.description.$dirty && !$v.description.maxLength">{{ $t('errors.atMostCharacters', {num: 128}) }}</div>
            </div>
            <div class="row">
              <div class="col-12 mt-3 mb-3">
                <Button
                    v-if="!processing"
                    class="mb-3 mt-5"
                    :deactivated="!enableSaving"
                    @click="step1">
                  <icon v-if="enableSaving" type="save"/><icon v-else type="ban" /> {{ $t('SAVE') }}
                </Button>
                <loading-panel
                    v-if="processing"
                    class="col-12 col-md-6 mt-5"
                    message="pleaseWait"
                    :time-in-seconds="20"
                    color-scheme="bright"
                />
              </div>
              </div>
              </div>
            <div slot="slot-2">
              <h2>{{ name }}</h2>
              {{ projectId }}
              <simple-hierarchy
                  key="trallala1"
                  :project-id="projectId"
                  :team-id="teamId"
                  :organization-id="organizationId"
                  v-if="organizationId"
                  @tree="setTree"
              />
            </div>
            <div slot="slot-3">
              <h2>{{ name }} upload</h2>
              {{ projectId }}
            <simple-hierarchy
                key="trallala"
                :project-id="projectId"
                :team-id="teamId"
                :tree-data="instances"
                :organization-id="organizationId"
                v-if="organizationId"
                task="uploadFiles"
              />
              <Button
                    class="mb-3 mt-5"
                    @click="push">
                  <icon type="save"/> {{ $t('Go to object') }}
                </Button>
            </div>
          </form-wizard>
        </div>
    </one-column-sidebar-left>
</template>

<script>
    import Button from "../components/forms/Button";
    import OneColumnSidebarLeft from "../layouts/OneColumnSidebarLeft";
    import Icon from "../components/Icon";
    import { required, minLength, maxLength } from 'vuelidate/lib/validators';
    import {alphaNumSpace} from '@/customValidators';
    import OrganizationSwitcher from "../components/organizations/OrganizationSwitcher";
    import VuelidateMixin from "@/components/mixins/VuelidateMixin.js";
    import FormWizard from "@/components/forms/FormWizard";
    import SimpleHierarchy from "@/components/tree/SimpleHierarchy";
    import LoadingPanel from "@/components/LoadingPanel";
    export default {
        name: 'SFXAdd',
        components: {
            OrganizationSwitcher,
            Button,
            OneColumnSidebarLeft,
            Icon,
            FormWizard,
            SimpleHierarchy,
            LoadingPanel
        },
        mixins: [VuelidateMixin],
        data() {
            return {
                assetId: '',
                enableSaving: false,
                name: '',
                description: '',
                type: 'assembly',
                error: null,
                organizationId: null,
                teamId: null,
                processing: false,
                currentSlot: 1,
                projectId: '',
                instances: {},
            };
        },
        watch: {
            name() {
                this.error = null;
            },
            '$v.$invalid': function() {
                this.checkSaving();
            },
        },
        validations: {
            name: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(127),
                alphaNumSpace,
                async isUnique(value) {
                    // standalone validator ideally should not assume a field is required
                    if (value === '') {
                        return true;
                    }
                    let bool = true;
                    await this.$store.dispatch('checkIfSfxdataNameExists', {type: 'Asset', name: value}).then(data => {
                        bool = data.length === 0;
                    });
                    return bool;
                },
            },
            description: {
                maxLength: maxLength(1024),
            },
        },
        beforeMount() {
            if(this.$route.params.id) {
              this.loadAsset();
            }
        },
        methods: {
            loadAsset: async function() {
              this.$store.dispatch('clientLoadAsset', {
                   id: this.$route.params.id
                  }).then(data => {
                    this.projectId = data.sourceProjectId;
                    this.assetId = data.id;
                    this.organizationId = data.organizationId;
                    this.name = data.name;
                    this.setTree();
              })
            },
            step1: async function() {
              this.processing = true;
                if (this.enableSaving) {
                  let args = {
                    type: 'assembly',
                    name: this.name,
                    description: this.description,
                    organizationId: this.organizationId,
                  };
                  if(this.teamId) {
                    args.teams = [this.teamId];
                  }
                  this.$store.dispatch('clientCreateAsset', args).then(data => {
                    this.projectId = data.sourceProjectId;
                    this.assetId = data.id;
                    this.currentSlot++;
                  })
                }
            },
            checkSaving: function() {
              this.enableSaving = this.name && !this.$v.$invalid;
            },
            push() {
             this.$router.push(`/library/3d-data/assembly/${this.assetId}/general`);
            },
            setTree() {
                this.currentSlot++;
                this.loadInstances();
            },
            loadInstances() {
              this.$store.dispatch('clientLoadProjectInstances', {id: this.projectId, limit: 200, filter: 'level in 1 2 3'}).then(data => {
                this.instances = data;
              })
            }
        },
    }
</script>
