<template>
    <three-columns
        sub-menu-selector="librarypanel"
        back-button-tab-selector="sfxbackbutton"
        tab-selector="assemblytab"
        :id="$route.params.id"
    >
      <portal-target slot="leftContent" name="aboveTreeSection"/>
      <div v-if="hasAccess" class="row" slot="leftContent">
        <div class="col-12">
          <h2 class="box-title mb-2 too-long-text-break">
            {{ this.$store.getters.getSfxdataName($route.params.id) }}
          </h2>
        </div>
      </div>
        <lazy-loading-hierchy
                :show-meta="true"
                slot="leftContent"
                v-if="projectId && hasAccess"
                :project-id="projectId"
                :id="'sfxEditLinkedDataHierarchy'"
                :fields="[
                    {
                        name: 'Name',
                        id: 'name',
                        type: 'string',
                        sortable: true
                    },
                    {
                        name: 'Typ',
                        id: 'type',
                        type: 'string',
                    },
                ]"
                :selection-handler="'setAssemblyTreeSelected'"
                :open-handler="'setAssemblyTreeOpen'"
                :close-handler="'setAssemblyTreeClosed'"
        />
        <div v-if="$store.getters.getSfxdataPipelineStatus($route.params.id) === 'finished' && hasAccess" class="row" slot="fullContent">
          <div class="col-12 col-lg-6 export-profile-left">
            <info-panel
                class=""
                info-panel-header="ExportProfiles"
                :collapsible="true"
                :initially-collapsed="editFormShowing || startFormShowing"
                preview-id="subversionForm"
            >
              <div slot="info">
               <export-profile-list
                   v-if="$store.getters.getSfxdataOrganization($route.params.id)"
                   :organization-id="$store.getters.getSfxdataOrganization($route.params.id)"
                   :list-id="$route.params.id"
                   @create="currentlyAddingFilter = true; processingFilterItem = '';"
                   @edit="(id) => {currentlyEditingFilter = id;}"
                   @createInstance="(item) => {processingFilterItem = item; currentlyAddingFilter = false; forceReRenderKey++;}"
                   :key="forceReRenderKey"
               />
              </div>
            </info-panel>
          </div>
          <div v-if="hasAccess" class="col-12 col-lg-6 export-profile-right">
            <info-panel
                class=""
                info-panel-header="ExportedProfiles"
                :collapsible="true"
                :initially-collapsed="editFormShowing || startFormShowing"
                preview-id="subversionForm"
            >
              <div slot="info">
                <export-instance-list
                    v-if="projectId"
                    :project-id="projectId"
                    :list-id="$route.params.id + 'instances'"
                    :key="forceReRenderKey"
                    @started="processingFilterItem = ''; forceReRenderKey++;"
                    :root-node="rootNode"
                />
              </div>
            </info-panel>
          </div>
          <div v-if="hasAccess" class="col-12">
            <info-panel
                v-if="processingFilterItem"
                class="mt-3"
                info-panel-header="Preview"
                :collapsible="true"
                :initially-collapsed="false"
                preview-id="processForm"
            >
              <div slot="info">
                <filter-trigger-form
                    v-if="projectId && rootNode"
                    :filter-item="processingFilterItem"
                    @abort="processingFilterItem = ''"
                    @started="processingFilterItem = ''; forceReRenderKey++;"
                    :project-id="projectId"
                    :key="forceReRenderKey"
                    :root-node="rootNode"
                />
              </div>
            </info-panel>
            <info-panel
                v-if="$store.getters.getSfxdataOrganization($route.params.id) && editFormShowing"
                class="mt-3"
                info-panel-header="New"
                :collapsible="true"
                :initially-collapsed="false"
                preview-id="subversionForm"
            >
              <div slot="info">
                <filter-add-form
                    class="ml-2"
                    :organization-id="$store.getters.getSfxdataOrganization($route.params.id)"
                    :teams="$store.getters.getSfxdataTeams($route.params.id)"
                    :filter-id="currentlyEditingFilter ? currentlyEditingFilter : null"
                    @finished="finishEditing"
                    :key="forceReRenderKey"
                />
              </div>
            </info-panel>
          </div>
        </div>
        <div class="row" slot="fullContent" v-if="!hasAccess && !loading">
            <div class="col-12">
              {{ $t('NoExportProfileAccess')}}
            </div>
          </div>
    </three-columns>
</template>

<script>
    import LazyLoadingHierchy from "../components/tree/LazyLoadingHierchy";
    import ThreeColumns from "../layouts/ThreeColumns";
    import InfoPanel from "@/components/InfoPanel";
    import DateMixin from "@/components/mixins/DateMixin.js";
    import ExportInstanceList from "@/components/exportProfiles/ExportInstanceList";
    export default {
        name: "SFXLinkedData",
        components: {
            ExportProfileList: () => import("@/components/exportProfiles/ExportProfileList"),
            ThreeColumns,
            LazyLoadingHierchy,
            InfoPanel,
            FilterAddForm: () => import("@/sfxFilter/FilterAddForm"),
            FilterTriggerForm: () => import("@/sfxFilter/FilterTriggerForm"),
            ExportInstanceList,
        },
        mixins: [DateMixin],
        data() {
            return {
                loading: false,
                organizationId: '',
                metaSets: [],
                currentlyAddingFilter: false,
                metaSetName: '',
                projectId: '',
                currentlyEditingFilter: '',
                processingFilterItem: '',
                forceReRenderKey: 0,
                hasAccess: false,
                rootNode: '',
            };
        },
        computed: {
            editFormShowing() {
              return (!!this.currentlyEditingFilter || !!this.currentlyAddingFilter);
            },
            startFormShowing() {
              return !!this.processingFilterItem;
            }
        },
        beforeMount() {
            this.loading = true;
            this.id = this.$route.params.id;
            this.$store.dispatch('loadSfxdata', {
                id: this.id,
                addPipelineState: true,
                include: 'teams'
            }).then(async () => {
                this.projectId = this.$store.getters.getSfxdataSourceProjectId(this.id);
                this.organizationId = this.$store.getters.getSfxdataOrganization(this.id);
                this.hasAccess = this.$store.getters.isSuperAdmin ? true : await this.$store.dispatch('checkIfExportProfileFeature', {organizationId: this.organizationId});
                this.loading = false;
                this.setRootNode();
            }).catch(e => {
              if(e.status === 401) {
                this.$router.push('/access-denied');
              }
            });
        },
        methods: {
          setRootNode() {
            this.$store.dispatch('clientLoadProjectInstances', {
              id: this.projectId,
              filter: 'type in node model, level eq 1, parentId eq null'
            }).then(rootNode => {
              if(rootNode && rootNode[0] && rootNode[0].id) {
                this.rootNode = rootNode[0].id;
              }
            })
          },
          finishEditing() {
            this.currentlyEditingFilter = '';
            this.currentlyAddingFilter = false;
            this.forceReRenderKey++;
          },
          decreaseTreeItemSources() {
            this.$store.dispatch('decreaseAssemblyTreeSourceList', {
              id: this.$store.getters.getSfxdataListOptions(this.id, 'selected'),
              listName: this.projectId,
            });
          },
          increaseTreeItemSources() {
            this.$store.dispatch('increaseAssemblyTreeSourceList', {
              id: this.$store.getters.getSfxdataListOptions(this.id, 'selected'),
              listName: this.projectId,
            });
          },
        },
    }
</script>
<style lang="scss">
  // iPad landscape
@media only screen
    and (min-device-width: 1024px)
    and (max-device-width: 1024px)
    and (min-device-height: 768px)
    and (max-device-height: 768px)
    and (orientation: landscape)
    and (-webkit-min-device-pixel-ratio: 2) {
   .export-profile-right, .export-profile-left {
      flex: 0 0 100% !important;
      max-width: 100% !important;
  }
}
</style>