<template lang="html">
  <section class="user-form mb-5">
    <form id="user-form">
      <div v-if="error" class="form-error">{{ error }}</div>
      <Button @click="login">Login</Button><br />
      <!--<Button @click="$store.dispatch('clientGetSilentAccessToken')">aquire silent</Button>
      <Button @click="$store.dispatch('clientGetAllUserAccounts')">Get All Accounts</Button>
      <Button @click="$store.dispatch('checkLogin')">Check Login</Button>
      <Button @click="$store.dispatch('idpLogout')">Logout</Button>-->
    </form>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import Button from "@/components/forms/Button";
export default  {
  name: 'LoginFormAzb2cUserFlow',
  components: {
    Button
  },
  data() {
    return {
      username: null,
      password: null,
      error: null,
      loading: false,
      authUrl: '',
      code: '',
      user: '',
      accountId: '',
    }
  },
  computed: mapState({
    idpClientApi: state => state.auth.idpClientApi,
    authToken: state => state.users.authToken,
  }),
  methods: {
    async login() {
      if(this.authToken) {
        await this.$store.dispatch('idpCheckLogin');
      } else {
        await this.$store.dispatch('deleteAllCookies');
        window.localStorage.clear();
        window.sessionStorage.clear();
        await this.$store.dispatch('clientRedirectUser');
      }
    },
  }
}
</script>

<style lang="scss">
#user-form {
  width: 200px;
  .form-text {
    width: 100%;
    background-color:$inputcolor;
    &:-webkit-autofill,
    &:-internal-autofill-selected,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      background-color:$inputcolor !important;
      -webkit-box-shadow: 0 0 0 30px $inputcolor inset !important;
      color: #fff !important;
      outline:none;
      -webkit-text-fill-color: #fff !important;
    }
    &:focus {
      outline:none;
    }
  }
}
</style>