<template>
    <div class="form-item">
        <label v-if="label">{{ label }}</label>
        <textarea v-if="type === 'textarea'"
                  :id="id"
                  :ref="id"
                  :name="name"
                  :value="value"
                  :disabled="disabled"
                  :placeholder="placeholder"
                  :required="required"
                  :mask="mask"
                  class="form-textarea form-item"
                  @blur="$emit('blur', id, $event.target.value)"
                  @keyup="stoppedTyping"
                  @keyup.enter="$emit('getValue', id, $event.target.value)"
                  @keyup.esc="$emit('abort')"
        />

        <input v-if="type !== 'textarea'"
               :id="id"
               :name="name"
               :type="type"
               :value="value"
               :placeholder="placeholder"
               :required="required"
               :disabled="disabled"
               :mask="mask"
               :ref="id"
               :class="['form-text', error ? 'error' : '']"
               @blur="$emit('blur', id, $event.target.value)"
               @keyup="stoppedTyping"
               @keyup.enter="$emit('saveValue', id, $event.target.value, 'enter')"
               @keyup.esc="$emit('abort')"
        >
        <div v-if="desc">
            {{ desc }}
        </div>
        <div v-if="error">
            {{ error }}
        </div>
        <slot />
        <!--<ul class="form-errors" v-if="fieldErrors">
            <li v-for="(error, index) in fieldErrors" :key="index">{{ error }}</li>
        </ul>-->
        <!--<input  class="form-text" @keyup.enter="$emit('submit')" type="text" />-->
</div>
</template>

<script>

    import {_} from 'vue-underscore';

    export default {
        name: "TextInput",
        props: {
            type: {
                type: String,
                default: 'text',
                /*validator: function validator(value) {
                    return ['text', 'textarea', 'tel', 'number', 'email', 'password', 'url', 'date'].indexOf(value) !== -1;
                }*/
            },
            mask: { type: [Boolean, String], default: false },
            //saveMask: { type: [Boolean, String], default: false },
            label : {
                type: String, required: false, default: '',
            },
            id: {
                type:String,
                default: '',
            },
            name: {
                type: String,
                default: '',
            },
            placeholder: {
              type: String,
                default: '',
            },
            required: {
                type: Boolean,
            },
            disabled: {
                type: Boolean,
            },
            value: {type: [Number, String], default: null},
            hasKeyup: {
                type: Boolean,
            },
            desc: {type: String, default: ''},
            error: {type: String, default: ''},
            setref: {type: String, default: ''},
            debounceTime: {type: Number, default: 200},
        },
        data() {
            return {
                inputvalue: '',
            }
        },
        mounted(){
            this.$emit('init');
        },
        created() {
            this.stoppedTyping = _.debounce(this.typingAlert, this.debounceTime);
        },
        methods: {
            keyup: function(arg) {
                this.$emit('keyup', arg);
            },
            typingAlert: function (args) {
                this.$emit('typingAlert', {value: args.target.value, field: this.id});
            },
            clearValue: function() {
                this.value = '';
            },
        },
    }
</script>

<style lang="scss">
    .form-text, .form-textarea {
        margin-bottom: 15px;
        &.error {
            border: 1px solid red;
        }
    }
</style>
