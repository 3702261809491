<template>
    <ul id="sfx-user-navigation" class="row no-gutters justify-content-end">
        <navigation-tab
                class="user-special-link"
                :title="$store.getters.getCurrentUserName"
                :routename="'user_me'"
                :pid="$store.getters.getCurrentUserId">
          <preview
                    class="icon-preview"
                    v-if="$store.getters.getCurrentUserPreviewUri"
                    :preview-uri="$store.getters.getCurrentUserPreviewUri"
                    preview-id="usernavPreview"
                    :removable="false"
            />
        </navigation-tab>

        <language-switcher />
        <li class="nav-item logout-link">
            <a class="nav-link" @click.prevent="logout"><span>{{ $t('Logout') }}</span></a>
        </li>
    </ul>
</template>

<script>
    import NavigationTab from "./NavigationTab";
    import LanguageSwitcher from "./LanguageSwitcher";
    import Preview from "../preview/Preview";
    //import OrganizationSwitcher from "./OrganizationSwitcher";

    export default {
        name: 'UserNavigation',
        components: {
            NavigationTab,
            LanguageSwitcher,
            Preview,
            //OrganizationSwitcher,
        },
        methods: {
            logout: function () {
                this.$router.push('/');
                this.$store.dispatch('idpLogout');
            },
        },


    }
</script>


<style lang="scss">
    .logout-link {
        margin-right:-15px;
        a {
            color: #000 !important;
        }
        &:hover {
            a {
                color:#fff !important;
            }
        }
    }

    #sfx-user-navigation {
        margin-right:-15px;
        li {
            background-color: rgba(0,0,0,0.3);
            a {
                background-color:transparent;
                border-color:transparent;
                color:#fff;
                margin-right:0;
                span {
                    color:#fff;
                    display:inline-block;
                    padding-top: 10px;
                }
                &::after {
                    display:none;
                }
                &:hover, &.router-link-active {
                    background-color: rgba(0,0,0,0.3);
                    border-color: transparent !important;
                }
            }
        }
        .user-special-link {
            a {
                padding-left: 55px;
                height:$user-nav-height;
                span {
                    display:inline-block;
                    padding-top: 10px;
                }
            }
          .icon {
            font-size: 1.3em !important;
            margin-top: -5px;
          }
        }
        .icon-preview {
            display:inline-block;
            width: 35px;
            position:absolute;
            left: 8px;
            border-radius:50%;
            margin-right: 5px;
            top:50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            z-index:5;
            .square-image {
                border-radius:50%;
            }
        }
    }
</style>