<template>
    <div :class="['tile-container', 'rows-' + rows, makeCompactDisplay ? 'compact' : '', viewOnly ? 'viewOnly' : 'viewAndEdit', iconsOnly ? 'iconsOnly' : '']">
        <div @click="trigger(values[index], tile)" :key="index" v-for="(tile, index) in tiles" :class="['tile', activeTiles.includes(tile) || activeTiles.includes(values[index]) ? 'active' : '']">
            <div class="icon-container" v-if="icons"><icon size="1.4" :type="tile" /></div>
            <div v-if="!iconsOnly">{{ $t(tile) }}</div>
            <div class="p-3" v-else></div>
        </div>
    </div>
</template>

<script>
    import Icon from "../Icon";
    export default {
        name: "TileButton",
        components: {
            Icon
        },
        props: {
            tiles: {type: Array, required: true},
            values: {type: Array, required: true},
            /**
             * must be the tile-name, not the value
             * */
            initialActiveTiles: {type: Array, default: null},
            icons: {type: Boolean, default: false},
            rows: {type: Number, default: 2},
            isMultiSelect: {type: Boolean, default: false},
            makeCompactDisplay: {type: Boolean, default: false},
            /**
             * Whether or not to make a viewOnly view without clicking handlers
             * */
            viewOnly: {type: Boolean, default: false},
            iconsOnly: {type: Boolean, default: false},
        },
        data() {
            return {
                activeTiles: [],
            };
        },
        beforeMount() {
            if(this.initialActiveTiles) {
                this.activeTiles = JSON.parse(JSON.stringify(this.initialActiveTiles));
            }
        },
        methods: {
            clearActiveStatus: function() {
                this.activeTiles = [];
            },
          /**
           * @return {Object} with properties:
           *  value: the value of the tile
           *  button: the name of the tile
           *  operation {String} "add" | "remove"
           * */
            trigger: function(value, tile) {
                let operation = 'add';
                if(this.isMultiSelect) {
                    if(this.activeTiles.includes(tile) || this.activeTiles.includes(value)) {
                        operation = 'remove';
                        this.activeTiles.splice(this.activeTiles.indexOf(tile), 1);
                    }
                    else {
                        this.activeTiles.push(tile);
                    }
                }
                else {
                    this.activeTiles = [tile];
                }
                this.$emit('trigger', {value: value, button: tile, operation: operation});
            }
        }
    }
</script>

<style lang="scss" scoped>

    .tile-container {
        display:flex;
        width: 100%;
        flex-wrap: wrap;
        &.viewAndEdit {
          .tile {
            cursor:pointer;
            &.active, &:hover {
              background-color: $tile-button-background-active;
              -moz-box-shadow:    none;
              -webkit-box-shadow: none;
              box-shadow:         none;
              border: 1px solid $tile-active-border-color;
              div {
                opacity:1;
              }
            }
          }
        }
        .tile {
            margin-bottom:-1px;
            margin-right:-1px;
            flex: 0 0 50%;
            max-width: 50%;
            background-color: $tile-button-background;
            border: 1px solid $tile-border-color;
            -webkit-transition: all 300ms ease;
            transition: all 300ms ease;
            -moz-box-shadow:    inset 0 0 10px #222;
            -webkit-box-shadow: inset 0 0 10px #222;
            box-shadow:         inset 0 0 10px #222;
          cursor: not-allowed;
            div {
                opacity: 0.3;
            }
            &.active {
              background-color: $tile-button-background-active;
              -moz-box-shadow:    none;
              -webkit-box-shadow: none;
              box-shadow:         none;
              border: 1px solid $tile-active-border-color;
              div {
                opacity:1;
              }
            }
        }
        &.rows-3 {
            .tile {
                flex: 0 0 33%;
                max-width: 33%;
            }
        }
        &.rows-4 {
            .tile {
                flex: 0 0 25%;
                max-width: 25%;
            }
        }
        div {
            text-align: center;
            padding:5px 3px;
        }
        &.compact {
            .tile {
                position:relative;
                padding-left:32px;
            }
            .icon-container {
                position:absolute;
                left: 7px;
                top:50%;
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
            }
            div {
                text-align: left;
            }
        }
        &.iconsOnly {
          .icon-container {
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
          }
        }
    }
</style>