<template>
    <div class="container-fluid main-container">
        <div class="row sub-menu-panel">
          <navigation-panels-container
              id="library"
              :tab-selector="subMenuSelector"
              v-if="subMenuSelector"
          />
            <slot name="subMenu"/>
        </div>
        <div class="row">
            <div class="col ml-5">
                <slot name="buttonsLeft"/>
            </div>
            <div class="col"/>
            <div class="col mr-5 text-right create-new-button">
                <slot name="buttonsRight"/>
            </div>
        </div>
        <div class="row">
          <tabs slot="tabs"
                v-if="backButtonTabSelector"
                classes="col-12 col-md-2 col-lg-2 offset-md-1 backbutton"
                :tab-selector="backButtonTabSelector"
                :custom-tab-name="backButtonTabSelector"
                :first-inactive="true"
          />
          <tabs
              v-if="tabSelector"
              :id="id"
              :tab-selector="tabSelector"
              :type="type"
          />
            <slot name="tabs"/>
        </div>

        <div class="row content-panel">
            <div class="col-12 col-md-5 col-lg-5 col-xl-4">
                <div class="sub-sub-panel-background sticky-top" style="margin-left: -15px;">
                    <div class="row p-3 pt-4">
                        <div class="col-12 col-md-12">
                            <slot name="leftContent"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-7 col-lg-7 col-xl-8">
                <div class="sub-sub-panel-background" style="margin-right: -15px;">
                    <div class="row p-sm-4 p-lg-4">
                        <div class="col-12">
                          <slot name="fullContent"/>
                        </div>
                        <div class="col-12 col-lg-12 col-xl-5 col-xxl-5">
                            <slot name="middleContent"/>
                        </div>

                        <div class="col-lg-12 col-xl-7 col-xxl-7">
                            <slot name="rightContent"/>
                        </div>
                        <div class="col-12">
                          <slot name="fullContentAfter"/>
                        </div>
                    </div>
                </div>
            </div>
</div>
    </div>
</template>

<script>
import Tabs from "@/components/navigation/Tabs";
import NavigationPanelsContainer from "@/components/navigation/NavigationPanelsContainer";

export default {
  name: "ThreeColumns",
  components: {
    Tabs,
    NavigationPanelsContainer
  },
  props: {
    /**
     * @tabSelector String the tabSelector (e.g. "appstabs", set in router.js)
     * @backButtonTabSelector String the same but for the backButton
     * if set, tabs will be displayed automatically
     */
    tabSelector: { type:String, default: ''},
    backButtonTabSelector: { type:String, default: ''},
    subMenuSelector: { type:String, default: ''},
    /**
     * @id String the id to use for the tabs if tabSelector is given
     */
    id: { type:String, default: ''},
    /**
     * @type String the type to use for the tabs if tabSelector is given
     */
    type: { type:String, default: ''},
  },
}
</script>