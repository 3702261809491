<template>
    <div v-if="$store.getters.isSuperAdmin" class="state-display lighter darker">
      <icon :type="state" />
      <icon v-if="state === 'p'" type="clock" />
      <icon v-else-if="state === 'a'" type="eye" />
      <icon v-else-if="state === 'i'" type="ban" />
      <icon v-else-if="state === 'h'" type="eye-slash" />
      </div>
</template>

<script>
import Icon from "./Icon";
export default {
  name: "StateSetter",
  components: {
    Icon
  },
  props: {
    state: {type: String, required: true},
  }
}
</script>

<style lang="scss">
  .state-display {
    width: $input-default-height;
    height: $input-default-height;
    position:relative;
    .icon {
      position:absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%,-50%);
      transform: translate(-50%,-50%);
    }
    &.bright {
      background-color: rgba(255,255,255,0.5);
      color: #000;
    }
  }
</style>