<template>
    <one-column-sidebar-left
      :sub-menu-selector="$route.name !== 'user_me' ? 'adminpanel' : ''"
      :back-button-tab-selector="$route.name !== 'user_me' ? 'userbackbutton' : ''"
      :id="$route.params.id"
      :tab-selector="$route.name !== 'user_me' ? 'usertab' : ''"
    >
        <info-panel slot="sidebarLeft"
                    :id="$route.params.id"
                    :preview-uri="$store.getters.getUserPreviewUri($route.params.id)"
                    :image-editable="true"
                    upload-type="User"
                    :created="$store.getters.getUserCreationDate($route.params.id)"
                    :last-change="$store.getters.getUserChangeDate($route.params.id)"
                    :downloadable="true"
                    :removable="true"
                    preview-id="userEdit"
        />

        <div slot="mainContent">
            <user-edit-form
                    :user-id="$route.params.id"
            />
        </div>
    </one-column-sidebar-left>
</template>

<script>
    import { mapState } from 'vuex';
    import InfoPanel from "../components/InfoPanel";
    import OneColumnSidebarLeft from "../layouts/OneColumnSidebarLeft";
    import UserEditForm from "@/components/forms/UserEditForm";
    export default {
        name: 'UserEdit',
        components: {
            InfoPanel,
            OneColumnSidebarLeft,
            UserEditForm
        },
        data() {
            return {
                id: '',
                accessAllowed: false,
            };
        },
        computed: mapState({currentUserOrg: state => state.users.currentUserOrg,}),
        watch: {
          currentUserOrg() {
            this.checkPermission();
          }
        },
        mounted() {
            if(this.currentUserOrg) {
              this.checkPermission();
            }
        },
        methods: {
            async checkPermission() {
              if(this.$store.getters.getCurrentUserId !== this.$route.params.id) {
                this.accessAllowed = await this.$store.dispatch('checkTeamOrgPermission', {
                op: 'writeOrganization',
                opOnly: true,
                orgPermissionOnly: true,
                organizationId: this.currentUserOrg
              });
              if(this.accessAllowed) {
                this.id = this.$route.params.id;
              }
              else {
                await this.$router.push('/access-denied');
              }
              }
            }
        }
    }
</script>
