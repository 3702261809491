<template>
    <div :id="uuId + 'dragHandle'">dragHandle</div>
</template>

<script>
    /*
    if you use this mixin, the following things are needed:
    a div:
    <div :id="uuId + 'dragHandle'">dragHandle</div>

    afterDraggedMethod: function() {
        do the things after finished dragging here...
    }

    Further Documentation:
    https://interactjs.io/
    * */
    import interact from "interactjs";

    export default {
        name: "DraggableMixinJsVue",
        props: {
            uiId: {type: String, required: true}, //the unique identifier used for the div's id
            dragContraint: {type: String, required: true}, //the class of the parent keeping the dragged element from going everywhere
        },
        methods: {
            makeDraggable() {
                let $this = this;
                interact('#' + this.uiId + 'dragHandle').draggable({
                    inertia: false,
                    modifiers: [
                        interact.modifiers.restrictRect({
                            restriction: '.' + $this.dragContraint, //todo: make dynamic
                            endOnly: false,
                        }),
                    ],
                    listeners: {
                        start () {
                            $this.dragging = true;
                        },
                        move (event) {
                            let target = document.getElementById($this.uiId);
                            var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
                            var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

                            // translate the element
                            target.style.webkitTransform =
                                target.style.transform =
                                    'translate(' + x + 'px, ' + y + 'px)';

                            // update the posiion attributes
                            target.setAttribute('data-x', x);
                            target.setAttribute('data-y', y);
                        },
                        end (event) {
                            $this.afterDraggedMethod();
                            let target = event.target;
                            $this.dragging = false;
                            target.style.webkitTransform =
                                target.style.transform = 'none';
                        },
                    },

                });
            },
        }
    }
</script>