<template>
<info-panel previewId="simple">
  <div slot="info" class="simple-hierarchy">
  <div v-if="task === 'editHierarchy'" style="background-color: #666262; border: transparent" class="header p-3 mt-3">
    <h2><icon class="mr-2" style="position: static; float:left;" type="baby" size="3"/>Adding children</h2>
  </div>
    <div v-else style="background-color: #666262; border: transparent" class="header p-3 mt-3">
    <h2><icon class="mr-2" style="position: static; float:left;" type="kiwi-bird" size="3"/>Let's add some objects!</h2>
  </div>
    <div class="mt-5">
      <div :key="index + forceReRenderKey" v-for="(item, index) in tree">
        <simple-node v-if="task === 'editHierarchy'"
                     @addChild="addChild"
                     @updateItem="updateItem"
                     :item="item" />
        <simple-node-upload v-else-if="task === 'uploadFiles'" :item="item" />
      </div>
    </div>
    <Button v-if="task === 'editHierarchy'" @click="save" class="mt-3">save hierarchy</Button>
    <Button v-if="task === 'editHierarchy'" @click="skip" class="mt-3 ml-2">skip</Button>
    </div>
  </info-panel>
</template>

<script>
import Icon from "@/components/Icon";
import Button from "@/components/forms/Button";
import InfoPanel from "@/components/InfoPanel";
import {v4 as uuidv4} from "uuid";
import SimpleNode from "@/components/tree/SimpleNode";
import SimpleNodeUpload from "@/components/tree/SimpleNodeUpload";
export default {
  name: "SimpleHierarchy",
  components: {
    Button,
    Icon,
    InfoPanel,
    SimpleNode,
    SimpleNodeUpload
  },
  props: {
    projectId: {type: String, required: true},
    organizationId: {type: String, required: true},
    teamId: {type: String, default: ''},
    treeData: {default: null},
    // possible values:
    // uploadFiles | editHierarchy
    task: {type: String, default: 'editHierarchy'}
  },
  data() {
    return {
      root: '',
      list: {},
      tree: {},
      instances: [],
      editing: '',
      forceReRenderKey: 0
    }
  },
  watch: {
    treeData() {
       this.doTheTree();
    }
  },
  beforeMount() {
    this.doTheTree(true);
  },
  methods: {
    doTheTree(init = false) {
      if((!this.treeData || !Object.keys(this.treeData).length) && init) {
        const root = uuidv4();
        this.root = root;
        this.list[root] = {name: 'root', id: root, parentId: null, organizationId: this.organizationId};
        this.tree = this.list;
    } else {
      console.log('treeing')
      this.list = this.array_to_object(this.treeData);
      this.tree = this.list_to_tree(this.list);
    }
    },
    addChild(parentId) {
      const leaf = uuidv4();
      this.list[leaf] = {
        name: 'leaf',
        id: leaf,
        parentId: parentId
      };
      this.tree = this.list_to_tree(this.list);
      this.forceReRenderKey++;
    },
    updateItem(item) {
      this.list[item.id] = item;
      this.tree = this.list_to_tree(this.list);
      this.forceReRenderKey++;
    },
    async save() {
      this.instances = Object.values(this.list);
      this.$store.dispatch('clientCreateAsset', {
          organizationId: this.organizationId,
          teams: this.teamId ? [{id: this.teamId}] : [],
          type: 'node',
          name: this.projectId,
          externalId: this.projectId,
      }).then(async asset => {
        console.log('asset SAVED: ')
        console.log(asset);
        this.instances = this.instances.map(instance => {
          instance.assetId = asset.id;
          return instance;
        });
        let next;
        let newRootInstances = [{id: null}];
        // eslint-disable-next-line no-cond-assign
        while(next = this.getNext(Object.values(this.instances), newRootInstances)) {
          await this.createInstances(next);
          newRootInstances = next;
        }
      }).then(() => {
        this.$emit('tree', Object.values(this.tree));
      })
    },
    skip() {
      this.$emit('tree', Object.values(this.tree));
    },
    getNext (array, currentItems)  {
      const ids = currentItems.map(item => {return item.id});
      const next = array.filter(item => { return ids.includes(item.parentId)});
      console.log('found next')
      console.log(next)
      if(!next.length) {
          return false
      }
      return next;
    },
    async createInstances(instances) {
      return this.$store.dispatch('clientCreateProjectInstances', {
        id: this.projectId,
        args: instances
      }).then(() => {
        return this.$store.dispatch('clientLoadProjectInstances', {
          id: this.projectId,
        })
      }).then(data => {
        console.log('total now:')
        console.log(data)
      })
    },
    array_to_object(arr) {
      let obj = {};
      arr.map(item => {
        obj[item.id] = item;
      })
      return obj;
    },
    list_to_tree(list) {
      list = JSON.parse(JSON.stringify(list));
      let map = {};
      const roots = [];
      const keys = Object.keys(list);
      for (let i = 0; i < keys.length; i += 1) {
        let node = list[keys[i]];
        list[node.id].children = []; // initialize the children
      }

      for (let i = 0; i < keys.length; i += 1) {
        let node = list[keys[i]];
        if (node.parentId) {
          // if you have dangling branches check that map[node.parentId] exists
          if(map[node.parentId] !== null) {
            if(!list[node.parentId]) {list[node.parentId] = {children: [], name: 'undefined'}}
            list[node.parentId].children.push(node);
          }
        } else {
          roots.push(node);
        }
      }
      return roots;
    }
  }
}
</script>

<style lang="scss">
  .leaf {
    font-size: 1rem;
    font-weight:bold;
    background-color: #665f5f;
    border: 1px solid lighten(#665f5f, 10%);
    border-left: 6px solid #baa53c;
  }
</style>