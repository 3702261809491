<template>
    <main-table-layout
      sub-menu-selector="organizingpanel"
    >
        <div slot="buttonsRight"/>
        <content-filter
                slot="filter"
                @filter="filtering"
                :id="listName"
                store-name="Project"
                :type-filter-items="$store.getters.isSuperAdmin ? ['assembly', 'vstage', 'webapp', 'app', 'library', 'kit'] : []"
        />

            <view-switcher
                slot="mainContentButtons"
                id="mainContent"
                :table="true"
                :thumbnails="true"
            />
        <pagination
                slot="mainContentButtons"
                :limit="paginationLimit"
                :total="$store.getters.getProjectListOptions(listName, 'pagination_items')"
                :id="listName"
                @setActive="loadPaginatedData"
                ref="pagination"
        />
        <Button
            v-if="$store.getters.isSuperAdmin"
            permission="createProject"
            type="action-button" slot="mainContentButtons" link="/project/add" icon="plus">
                {{ $t('createNewProject') }}
        </Button>
<Button
           slot="mainContent"
           @click="loadPaginatedData(0); $refs.pagination.setActive(0)"
           type="reload"
           icon="redo"
           class="icon-button"
       />
            <table-widget
                    v-if="$store.getters.getViewSwitcher($route.name, 'mainContent') === 'table'"
                    slot="mainContent"
                    @sort="sort"
                    :id="'projectsDefaultTable'"
                    :nav-by-clicking-row="true"
                    :deletable="true"
                    @deleteRow="deleteRow"
                    :show-tags="true"
                    :show-organization="true"
                    :fields="tableFields"
                    :data="$store.getters.getProjectsByListName(listName)"
                    :title-as-links="true"
                    :key="forceReRenderKey"
                    edit-permission="write"
                    delete-permission="delete"
                    resource="Project"
                    :use-team-permission="true"
            />
            <thumbnails
                    v-if="$store.getters.getViewSwitcher($route.name, 'mainContent') === 'thumbnails'"
                    slot="mainContent"
                    @sort="sort"
                    :id="'projectsDefaultThumbnails'"
                    :deletable="true"
                    @deleteRow="deleteRow"
                    :nav-by-clicking-row="true"
                     edit-permission="write"
                    delete-permission="delete"
                    :fields="thumbnailFields"
                    :data="$store.getters.getProjectsByListName(listName)"
                    :title-as-links="true"
                    :key="forceReRenderKey"
                    resource="Project"
                    :use-team-permission="true"
            />
    </main-table-layout>
</template>

<script>
    import Button from "../components/forms/Button";
    import ContentFilter from "../components/widgets/filter/ContentFilter";
    import MainTableLayout from "../layouts/MainTableLayout";
    import ViewSwitcher from "../components/widgets/ViewSwitcher";
    import Thumbnails from "../components/widgets/ThumbnailView";
    import Pagination from "../components/Pagination";
    import { mapState } from 'vuex';
    import TableWidget from "@/components/widgets/TableWidget";
    export default {
        name: "Projects",
        components: {
            Button,
            TableWidget,
            ContentFilter,
            MainTableLayout,
            ViewSwitcher,
            Thumbnails,
            Pagination,
        },
        data() {
            return {
                filter: {},
                offset: 0,
                sorting: '-updatedAt',
                listName: 'adminProjectList',
                paginationLimit: 10,
                queryRelevantComponents: 2, //pagination and filter
                queryRelevantInits: 0,
                viewId: 'mainContent',
                forceReRenderKey: 0,
                tableFields: [
                    {
                        name: '',
                        id:'',
                        type: 'image',
                    },
                    {
                        name: 'Name',
                        id: 'name',
                        type: 'string',
                        sortable: true
                    },
                    {
                        name: 'Type',
                        id: 'type',
                        type: 'string',
                    },
                    {
                      name: 'Teams',
                      id: 'teams',
                      type: 'teams',
                    },
                    {
                        name: 'Last Update',
                        id: 'updatedAt',
                        type: 'date',
                        sortable: true
                    },
                    {
                        name: 'Edit',
                        type: 'editButton'
                    },
                ],
                thumbnailFields: [
                    {
                        name: '',
                        id:'',
                        type: 'image',
                    },
                    {
                        name: 'Organization',
                        id:'organization',
                        type: 'organization',
                        sortable: true
                    },
                    {
                        name: 'Last Update',
                        id: 'updatedAt',
                        type: 'date',
                        sortable: true
                    },
                    {
                        name: 'Name',
                        id: 'name',
                        type: 'string',
                        sortable: true
                    },
                  {
                    name: 'Teams',
                    id: 'teams',
                    type: 'teams',
                    sortable: true
                  },
                    {
                        name: 'Tags',
                        id:'tags',
                        type: 'tags',
                    },
                    {
                        name: 'Edit',
                        type: 'editButton'
                    },
                ],
            };
        },
        computed: mapState({pages: state => state.page.pages,}),
        watch: {
            queryRelevantInits: function(newval) {
                if(newval === this.queryRelevantComponents) {
                    this.loadPaginatedData(0, false);
                }
            }
        },
        methods: {
          loadData(initial = false) {
              this.loading = true;
              let args = {
                  listName: this.listName,
                 }
                  const paginationAddOn = {
                    paging: {
                      [this.listName]: true,
                    },
                    offset: {
                      [this.listName]: this.offset,
                    },
                    limit: {
                      [this.listName]: this.paginationLimit,
                    },
                    include: {
                      [this.listName]: 'teams,tags',
                    },
                    sort: {
                      [this.listName]: this.sorting,
                    }
                  };
                  args.add = this.filter && this.filter.add ? {...paginationAddOn, ...this.filter.add } : paginationAddOn;
                  if(this.filter && this.filter.remove) {
                    args.remove = this.filter.remove;
                  }

                if(initial) {
                    this.queryRelevantInits++;
                    this.$store.dispatch('registerProjectsQueryParams', args);
                }
                else {
                  this.$store.dispatch('loadProjects', args).then(() => {
                    this.loading = false;
                  });
                }
            },
            loadPaginatedData(offset, initial) {
              this.offset = offset;
              this.loadData(initial);
            },
            deleteRow: function(id) {
                this.$store.dispatch('deleteProject', {
                    args: [id]
                }).then(() => {
                  this.loadPaginatedData();
                })
            },
             filtering: function(args, initial) {
              this.filter = args;
              this.loadData(initial);
            },
            sort(args) {
              const {fieldname, direction} = args;
              const sortString = direction + fieldname;
              if(this.sorting !== sortString) {
                this.sorting = sortString;
              }
              this.loadData(false);
            }
        }
    }
</script>
