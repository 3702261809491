<template>
    <div class="">
        <edit-instance-form
                :id="editingId"
                :project-id="id"
                v-if="showEditForm"
                @abort="showEditForm = false"
                :store-name="storeName"
        />
        <table-widget
                class=""
                :id="'datasetsPerSfxdataTable'"
                :fields="fields"
                :data="$store.getters['get' + storeName + 'InstancesByListName'](listName)"
                :selected-rows="$store.getters['get' + storeName + 'InstancesByListName'](listName).selected"
                @selectCheckbox="selectCheckbox"
                @edit="edit"
        />
    </div>
</template>

<script>
    import TableWidget from "../../widgets/TableWidget";
    import EditInstanceForm from "../../forms/EditInstanceForm";

    export default {
        name: 'InstanceTable',
        components: {
            EditInstanceForm,
            TableWidget,
        },
        props: {
            id: {type: String, required:true,},
            connectedInstance: {type: String, default: null},
            storeName: {type: String, required: true},
            editable: {type: Boolean, default: false}
        },
        data() {
            return {
                listName: this.$route.params.id + 'Instances',
                showEditForm: false,
                editingId: null,
                titleEdit: false,
                descEdit: false,
                fields: [
                    {
                        name: 'Name',
                        id: 'name',
                        type: 'string',
                    },
                ],
            };
        },
        watch: {
            id: function() {
                this.loadInstances();
            },
            editable: function() {
                this.showHideEditField();
            }
        },
        beforeMount() {
            this.loadInstances();
            this.showHideEditField();
        },
        methods: {
            getNumberOfInstances: function() {
                let instances = Object.keys(this.$store.getters['get' + this.storeName + 'InstancesByListName'](this.id + 'Instances'));
                return instances.length;
            },
            edit: function(id) {
                this.showEditForm = true;
                this.editingId = id;
            },
            showHideEditField: function() {
                if(this.editable && this.fields.findIndex(item => {return item.id === 'editPopup'}) === -1) {
                    this.fields.push({
                        name: 'Edit',
                        id: 'editPopup',
                        type: 'editPopup',
                    });
                }
                else {
                    let index = this.fields.findIndex(item => {return item.id === 'editPopup'});
                    if(index !== -1) {
                        this.fields.splice(index, 1);
                    }
                }
            },
            selectCheckbox: function(id, operation){
                this.$emit('selectCheckbox', id, operation);
            },
            sort: function (args) {
                let params = {
                    listId: this.id,
                    id: this.id,
                    listName: this.listName,
                    [args.action]: {
                        sort: [args.direction + args.fieldname]
                    }
                }
                this.$store.dispatch('load' + this.storeName +'sInstances', params);
            },
            filter: function (args) {
                args.id = this.id;
                args.listName = this.listName;
                this.$store.dispatch('load' + this.storeName +'sInstances', args);
            },
            loadInstances: function(){
                if(this.id) {
                    this.$store.dispatch('load' + this.storeName +'Instances', {
                        id: this.id,
                        listName: this.listName,
                        keep: {
                            id: this.id,
                        },
                        add: {
                            include: {
                                [this.id + 'Instances']: 'asset',
                            },
                        },
                    });
                }
            },
        },
    }
</script>
