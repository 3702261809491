<template>
    <div class="row">
        <div class="col-12 col-md-8">
          <h1><icon class="mr-1" type="user" size="0.9" /> {{ $t('createNewUser') }}</h1>
        </div>

      <div class="col-12 col-md-8">
        <label class="mt-3">{{ $t('username') }}*</label>
        <input type="text"
               :class="['form-text', $v.username.$dirty && $v.firstName.$error ? 'form-group--error' : '']"
               v-model="username"
               @input="delayTouch($v.username)"
        >
        <div class="form-error" v-if="$v.username.$dirty && !$v.username.minLength">{{ $t('errors.atLeastCharacters', {num: 3}) }}</div>
        <div class="form-error" v-if="$v.username.$dirty && !$v.username.isUnique">{{ $t('errors.userNameAlreadyExists') }}</div>
      </div>

      <div class="w-100" />
      <div class="col-12 col-md-4">
         <label>{{ $t('firstName') }}*</label>
          <input type="text"
                 :class="['form-text', $v.firstName.$dirty && $v.firstName.$error ? 'form-group--error' : '']"
                 @input="delayTouch($v.firstName)"
                 v-model="firstName"
          >
          <div class="form-error" v-if="$v.firstName.$dirty && $v.firstName.$error">{{ $t('errors.atLeastCharacters', {num: 3}) }}</div>
      </div>
       <div class="col-12 col-md-4">
        <div class="w-100" />
          <label>{{ $t('lastName') }}*</label>
          <input type="text"
               :class="['form-text', $v.lastName.$dirty && $v.lastName.$error ? 'form-group--error' : '']"
               @input="delayTouch($v.lastName)"
               v-model="lastName"
          >
          <div class="form-error" v-if="$v.lastName.$dirty && $v.lastName.$error">{{ $t('errors.atLeastCharacters', {num: 3}) }}</div>
        </div>
        <div class="w-100" />
      <div class="col-12 col-md-8">
        <label>{{ $t('email') }}*</label>
        <input type="text"
               :class="['form-text', $v.email.$dirty && $v.email.$error ? 'form-group--error' : '']"
               @input="delayTouch($v.email)"
               v-model="email"
        >
        <div class="form-error" v-if="$v.email.$dirty && !$v.email.email">{{ $t('errors.mustBeAValidEmailAdress') }}</div>
        <div class="form-error" v-if="$v.email.$dirty && !$v.email.isUnique">{{ $t('errors.emailAddressAlreadyExists') }}</div>
      </div>
        <div class="w-100" />
      <div class="col-12 col-md-4">
        <label>{{ $t('password') }}*</label>
        <input :type="showPassword ? 'text' : 'password'"
               :class="['form-text mb-2', ($v.password.$dirty && $v.password.$error) || ($v.password2.$dirty && $v.password2.$error) ? 'form-group--error' : '']"
               v-model="password"
               @input="delayTouch($v.password)"
        >
        <input type="checkbox" v-model="showPassword" />{{ $t('showPassword') }}
        <password-checker
            :password="password"
            @status="state => setPwdState(state)"
        />
        </div>
        <div class="col-12 col-md-4">
          <label>{{ $t('repeatPassword') }}*</label>
          <input type="password"
                 :class="['form-text', $v.password2.$dirty && $v.password2.$error ? 'form-group--error' : '']"
                 v-model="password2"
                 @input="delayTouch($v.password2)"
          >
          <div class="form-error" v-if="$v.password2.$dirty && $v.password2.$error">{{ $t('errors.bothPasswordsMustBeTheSame') }}</div>
        </div>

         <div class="form-section col-12 col-md-8">
          <info-panel preview-id="something">
            <div slot="info">
              <h3>{{ $t('SelectOrganizations') }}</h3>
              <p>{{ $t('UserCreationHint') }}</p>
              <organization-tag
                            view-style="table"
                            @removeorg="(obj) => {removeOrg(obj)}"
                            @update="(orgId,item) => {updateOrg(orgId, item)}"
                            @setDefaultItem="(id) => {makeOrgDefault(id, {default: true})}"
                            :is-removable="!$store.getters.isSuperOrg(org.id)"
                            :is-editable="false"
                            v-for="org in selectedOrgs"
                            :key="org.id"
                            :org="org"
                            icon-left="user"
                            :is-default="defaultOrganizationId === org.id"
                            :show-app-panel="false"
                    />
        <universal-selector
            class="mt-2"
            @addItem="addOrg"
            :exclude-from-item-list="selectedOrgs"
            list-name="userAddOrgs"
            store-name="UserAdminOrganization"
            filter-attribute="displayName"
            tag-icon="building"
        />
              <div class="form-error" v-if="$v.selectedOrgs.$dirty && $v.selectedOrgs.$error">{{ $t('errors.required') }}</div>

            </div>
          </info-panel>

      </div>
        <div class="w-100" />
      <div class="col-12">
        <Button
                type="button"
                class="mb-3 mt-3"
                :deactivated="!enableSaving"
                @click="save"
        >
            <icon v-if="enableSaving" type="save"/><icon v-else type="ban" /> {{ $t('SAVE') }}
        </Button>
      </div>

    </div>
</template>

<script>
    import { required, minLength, email, sameAs } from 'vuelidate/lib/validators'
    import { mapState } from 'vuex';
    import Button from "./Button";
    import Icon from "../Icon";
    import VuelidateMixin from "@/components/mixins/VuelidateMixin.js";
    import UniversalSelector from "@/components/organizations/UniversalSelector";
    import {AdminData} from "@/enum";
    import OrganizationTag from "@/components/organizations/OrganizationTag";
    import InfoPanel from "@/components/InfoPanel";
    import PasswordChecker from "@/components/forms/PasswordChecker";
    export default {
        name: "AddUser",
        components: {
            Button,
            Icon,
            UniversalSelector,
            OrganizationTag,
            InfoPanel,
            PasswordChecker
        },
        mixins: [VuelidateMixin],
        data() {
            return {
                AdminData: AdminData,
                enableSaving: false,
                username: '',
                firstName: '',
                lastName: '',
                email: '',
                passwordError: '',
                password: '',
                password2: '',
                error: '',
                orgs: [],
                selectedOrgs: [],
                defaultOrganizationId: '',
                showPassword: false,
                passwordOkay: false,
            };
        },
        computed: mapState({usersTmp: state => state.users.usersTmp,}),
        watch: {
            '$v.$invalid': function() {
                this.enableSaving = !this.$v.$invalid;
                this.$emit('enableSaving', !this.$v.$invalid);
            },
            usersTmp(newValue) {
                if(newValue['last_inserted']) {
                    let id = newValue['last_inserted'];
                    this.$router.push('/admin/users/'+id+'/general');
                }
            },
        },
        validations: {
            username: {
                required,
                minLength: minLength(3),
                async isUnique(value) {
                    // standalone validator ideally should not assume a field is required
                    if (value === '') {
                        return true;
                    }
                    let bool = true;
                     await this.$store.dispatch('checkIfUserNameExists', {value: value}).then(data => {
                         bool = data.length === 0;
                        });
                     return bool;
                }
            },
            firstName: {
                required,
                minLength: minLength(3)
            },
            lastName: {
                required,
                minLength: minLength(3)
            },
            email: {
                required,
                email,
                async isUnique(value) {
                    if (value === '') {
                        return true;
                    }
                    let bool = true;
                    await this.$store.dispatch('checkIfUserEmailExists', value).then(data => {
                        bool = data.length === 0;
                    });
                    return bool;
                }
            },
            password: {
                required,
            },
            password2: {
                required,
                sameAsPassword: sameAs('password')
            },
            selectedOrgs: {
              required,
            },
            passwordOkay: {
              special() {
                return this.passwordOkay;
              }
            }
        },
        methods: {
            updateOrg(orgId, item) {
              console.log(item);
            },
            makeOrgDefault(orgId) {
              this.defaultOrganizationId = orgId;
            },
            addOrg(org, role = 'none') {
              this.selectedOrgs.push({id: org.id, displayName: this.$store.getters.getOrganizationName(org.id), role: role});
            },
            removeOrg(org) {
              this.selectedOrgs = this.selectedOrgs.filter(orgUser => orgUser.id !== org.id);
            },
          setPwdState(state) {
              this.passwordOkay = state;
          },
            save: function() {
                if(!this.$v.$anyError) {
                    if(!this.defaultOrganizationId) {
                      this.defaultOrganizationId = this.selectedOrgs[0].id;
                    }
                    let args = {
                        name: this.username,
                        firstName: this.firstName,
                        lastName: this.lastName,
                        email: this.email,
                        password: this.password,
                        organizationUsers: this.selectedOrgs.map(item => {
                          return {
                            organizationId: item.id,
                            role: 'none',
                            default: item.id === this.defaultOrganizationId
                          }
                        })
                    };
                    this.$store.dispatch('createUser', args);
                }
            },
        }
    }
</script>