<template>
    <main-table-layout :has-top-bar="false"
                       tab-selector="projecttab"
                       type="project"
                       back-button-tab-selector="projectbackbutton"
                       :id="$route.params.id"
    >
        <div slot="buttonsLeft"/>
        <div slot="buttonsRight">
            <Button
                    v-if="$route.query.wizard"
                    class="mb-3"
                    :link="'/project/' + this.$route.params.id + '/general'"
                    :deactivated="false"
            >
                <icon type="save"/> {{ $t('SAVE') }}
            </Button>
        </div>

        <h1 slot="mainContent">{{ $store.getters.getProjectName($route.params.id) }}</h1>

        <div slot="mainContent" class="mt-5">
            <project-linker
                :to-be-linked-types="['media', 'sfxdata', 'qrcodes', 'vforms']"
            />
        </div>
    </main-table-layout>
</template>

<script>
    import Button from "../components/forms/Button";
    import MainTableLayout from "../layouts/MainTableLayout";
    import ProjectLinker from "../components/connections/ProjectLinker";

    export default {
        name: "SFXEditMedia",
        components: {
            ProjectLinker,
            Button,
            MainTableLayout,
        },
        beforeMount() {
            this.$store.dispatch('loadProject', {id: this.$route.params.id}).catch(e => {
              if(e.status === 401) {
                this.$router.push('/access-denied');
              }
            });
        },
    }
</script>