<template>
    <div class="half-circle-spinner">
        <div class="circle circle-1"/>
        <div class="circle circle-2"/>
    </div>
</template>

<script>
    export default {
        name: "LoadingSpinner"
    }
</script>

<style scoped>

</style>