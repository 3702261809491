<template>
    <div>
        <template v-if="editing">
            <div v-for="(item, index) in rows" :key="index" class="mat-color-row">
                <div class="mat-color">
                    <div
                        :class="[
                            colorPickrId === index ? 'selected' : '',
                            'color-preview',
                            'editable',
                            colorPickrId === index ? 'hover' : '',
                        ]"
                        @click.self="
                            () => {
                                colorPickrId = index;
                            }
                        "
                        :style="{
                            margin: 'auto',
                            position: 'relative !important',
                            left: '-10px',
                            backgroundColor: item.color ? item.color : '#fff',
                        }"
                    >
                        <color-picker
                            v-if="colorPickrId === index"
                            ref="colorpickr"
                            slot="popupMainContent"
                            :value="item.color"
                            @save="
                                (color) => {
                                    colorPickrId = null;
                                    return updateColor(color, index);
                                }
                            "
                            @close="
                                () => {
                                    colorPickrId = null;
                                }
                            "
                            :popup-style="true"
                        />
                    </div>
                </div>
                <select v-model="item.material" class="custom-select material-select">
                    <option
                        class="form-text mb-2"
                        v-for="(matItem, matIndex) in materials"
                        :key="matIndex"
                        :value="matItem.id"
                    >
                        {{ matItem.displayName }}
                    </option>
                </select>

                <Button slot="buttonsRight" class="ml-3" @click="deleteRow(index)">
                    <icon type="" />{{ $t("Delete") }}
                </Button>
            </div>

            <div class="w-100" />
            <Button class="mr-2" @click="addRow">{{ $t("AddEntry") }}</Button>
            <Button @click="save">{{ $t("save") }}</Button>
            <div>{{ error }}</div>
        </template>
        <template v-else>
            <div :key="index" v-for="(row, index) in rows">
                <div style="display: flex; align-items: center">
                    <div class="w-50">
                        {{ materials.find((x) => x.id === row.material) ? materials.find((x) => x.id === row.material).displayName : 'N / A' }}
                    </div>
                    <div class="color-square" :style="{ backgroundColor: row.color }" />
                    <div class="pl-2" >{{ row.color ? row.color : 'N / A' }}</div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import Button from "@/components/forms/Button";
import ColorPicker from "@/components/widgets/editors/ColorPicker";

export default {
    name: "MatColorAdder",
    components: {
        Button,
        ColorPicker,
    },
    props: {
        value: { type: String, default: "" },
        config: { type: Object, required: true },
        editing: { type: Boolean, default: true },
    },
    data() {
        return {
            rows: [],
            error: "",
            materialListName: "MaterialList",
            materials: [],
            editColor: false,
            colorPickrId: null,
        };
    },
    beforeMount() {
        if (this.value && this.value.length) {
            try {
                this.rows = JSON.parse(this.value);
            } catch {
                this.error = "malformed json";
            }
        }

        this.loadMaterials();
    },
    methods: {
        loadMaterials() {
            let args = {
                listName: this.materialListName,
            };
            this.$store.dispatch("loadMaterials", args).then((data) => {
                for (let index = 0; index < data.length; index++) {
                    const element = data[index];
                    this.materials.push({ id: element.id, displayName: element.name });
                }
            });
        },
        updateColor(color, index) {
            this.rows[index].color = color;
        },
        deleteRow(index) {
            this.rows.splice(index, 1);
        },
        addRow() {
            this.rows.push({ material: "", color: "" });
        },
        save() {
            let validRows = [];
            this.rows.forEach(element => {
                if (element.material || element.color)
                {
                    validRows.push(element);
                }
            });
            this.$emit("save", JSON.stringify(validRows));
        },
    },
};
</script>
<style lang="scss">
.mat-color .color-pickr.popup-style {
    left: 35px;
}
</style>
<style lang="scss" scoped>
.mat-color {
    width: 55px;
}
.mat-color-row {
    display: inline-flex;
    margin-bottom: 10px;
    width: 100%;
}

.material-select {
    height: 40px;
    width: 100%;
    margin: auto 0;
}
</style>
