<template>
    <div class="container-fluid footer pt-3 pb-3">
        <div class="row">
            <div class="col-12 col-md-4">
                <h3>{{ $t('Info') }}</h3>
                <b>{{ $t('CC-Version') }}:</b> {{ this.$store.getters.appVersion }}<br />
              <router-link to="/gdpr" target="_blank">{{ $t('gdpr_link') }} <icon size="0.9" type="external-link-alt" /></router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import Icon from "@/components/Icon";
    export default {
        name: "PageFooter",
      components: {
          Icon
      }
    }
</script>