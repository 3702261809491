import Vue from 'vue'
import i18n from "../translation";

const state = {
    metaSetLists: {
        metaSetList: {},
    },
    metaSetListOptions: {},
    defaultQueryParams: {

    },
    queryParams: {
        metaSetList: {
            limit: {
                default: '50',
            },
        }
    },
    combinedQueryParams: {},
    /**
     * Used keys so far:
     * last_inserted
     * */
    metaSetTmp: {
    },
};
const getters = {
    getMetaSetTitle: (state) => (id, listName = 'metaSetList') => {
        return state.metaSetLists[listName][id] ? state.metaSetLists[listName][id].name : '';
    },
    getMetaSetDescription: (state) => (id, listName = 'metaSetList') => {
        return state.metaSetLists[listName][id] ? state.metaSetLists[listName][id].description : '';
    },
    getMetaSetMetaFields: (state) => (id, listName = 'metaSetList') => {
        return state.metaSetLists[listName][id] ? state.metaSetLists[listName][id].metaFields : [];
    },
    getMetaSetQueryParams: (state) => (listName) => {
        return state.combinedQueryParams[listName];
    },
    getMetaSetCreationDate: (state, rootGetters) => (id, listName = 'metaSetList') => {
        return state.metaSetLists[listName][id] ? rootGetters['convertDate'](state.metaSetLists[listName][id].createdAt) : '';
    },
    getMetaSetOrganization: (state) => (id, listName = 'metaSetList') => {
        return state.metaSetLists[listName][id] && state.metaSetLists[listName][id].organizationId ? state.metaSetLists[listName][id].organizationId : '';
    },
    getMetaSetChangeDate: (state, rootGetters) => (id, listName = 'metaSetList') => {
        return state.metaSetLists[listName][id] ? rootGetters['convertDate'](state.metaSetLists[listName][id].updatedAt) : '';
    },
    getMetaSetName: (state) => (id, listName = 'metaSetList') => {
        return state.metaSetLists[listName] && state.metaSetLists[listName][id] && state.metaSetLists[listName][id].name ? state.metaSetLists[listName][id].name : '';
    },
    getMetaSetOriginalQueryParams: function() {
        return state.queryParams;
    },
    getMetaSets() {
        return state.metaSetLists.metaSetList;
    },
    getMetaSetsByListName: (state) => (listName) => {
        return state.metaSetLists[listName] ? state.metaSetLists[listName] : {};
    },
    getMetaSetListOptions: (state) => (listName, option = '') => {
        let optionList = 'metaSetListOptions';
        if(option === 'preview') {
            return state[optionList][listName] && state[optionList][listName].previewPath ? Object.keys(state[optionList][listName].previewPath)[0] : '';
        }
        else if(option === 'fbxPreview') {
            return state[optionList][listName] && state[optionList][listName].fbxPreview ? Object.keys(state[optionList][listName].fbxPreview)[0] : '';
        }
        else if(option === 'pagination_items') {
            return state[optionList][listName] && state[optionList][listName].pagination_items ? parseInt(Object.keys(state[optionList][listName].pagination_items)[0]) : 0;
        }
        return state[optionList][listName] ? state[optionList][listName] : {};
    },
};
const mutations = {
    setMetaSetTmpMutation(state, data) {
        if(state.metaSetTmp[data.key]) {
            Vue.delete(state.metaSetTmp, data.key);
        }
        Vue.set(state.metaSetTmp, data.key, data.value);
    },
    loadMetaSetsMutation(state, args) {
        const data = args.data;
        const listName = args.listName ? args.listName :'metaSetList';
        Vue.set(state.metaSetLists, listName, {});
        for (var i = 0; i < data.length; i++) {
            let metaSet = data[i];
            Vue.set(state.metaSetLists[listName], metaSet.id, metaSet);
        }
    },
    loadMetaSetMutation(state, data) {
        let listName = 'metaSetList';
        if(data.listName) {
            listName = data.listName;
        }
        if(!state.metaSetLists[listName]) {
            Vue.set(state.metaSetLists, listName, {});
        }
        Vue.set(state.metaSetLists[listName], data.id, data);
    },
    updateMetaSetListOptionMutation(state, args) {
        let optionList = 'metaSetListOptions';

        if(!state[optionList][args.listName]) {
            Vue.set(state[optionList], args.listName, {});
        }

        if(args.override) {
            Vue.set(state[optionList][args.listName], args.optionKey, {});
        }

        //reset all other options
        if(args.optionKey === 'selected') {
            Vue.set(state[optionList][args.listName], args.optionKey, {});
        }

        if(!state[optionList][args.listName][args.optionKey]) {
            Vue.set(state[optionList][args.listName], args.optionKey, {});
        }
        if(state[optionList][args.listName][args.optionKey][args.value]) {
            Vue.delete(state[optionList][args.listName][args.optionKey], args.value);
        }
        else {
            Vue.set(state[optionList][args.listName][args.optionKey], args.value, args.value2);
        }
    },
};
const actions = {
    registerMetaSetsQueryParams: ({commit}, args) => {
        let listName = 'metaSetList';
        if(args.listName) {
            listName = args.listName;
        }
        if(Object.keys(args).length > 0) {
            commit('queryParamsSave', {storeType: 'metaset', listName: listName, params: args});
        }
    },
    loadMetaSets: async ({commit, dispatch, getters}, args = {}) => {
        let listName = args.listName ? args.listName : 'metaSetList';
        if(Object.keys(args).length > 0) {
            commit('queryParamsSave', {storeType: 'metaset', listName: listName, params: args});
        }
        commit('combineQueryParams', {storeType: 'metaset', listName: listName});
        dispatch('clientLoadMetaSets', getters.getMetaSetQueryParams(listName)).then(data => {

            for (let i = 0; i < data.length; i++) {
                data[i]['editLink'] = '/organizing/metaSets/' + data[i].id + '/general';
            }
            commit('loadMetaSetsMutation', {listName: listName, data: data});

            /*added pagination part*/
            if(getters.getMetaSetQueryParams(listName).paging === true && data.headers()) {
                let count = data.headers()['x-count'] ? parseInt(data.headers()['x-count']) : 0;
                commit('updateMetaSetListOptionMutation', {optionKey: 'pagination_items', value: count, listName: listName, override: true});
            }
        });
    },
    loadMetaSet: async ({commit, dispatch}, args = {}) => {
        return dispatch('clientLoadMetaSet', args).then(data => {
            if(args.listName) {
                data.listName = args.listName;
            }
            commit('loadMetaSetMutation', data);
            return data;
        });
    },
    createMetaSet: async ({commit, dispatch}, args) => {
        return dispatch('clientUpsertMetaSet', args)
            .then(data => {
                commit('loadMetaSetsMutation', {data: data});
                commit('setMetaSetTmpMutation', {key: 'last_inserted', value: data.id});
                dispatch('createNotification', {'text': i18n.t('events.metaSetCreated')});
                return data;
            });
    },
    updateMetaSet: async ({dispatch}, args) => {
        return dispatch('clientUpdateMetaSet', args).then(() => {
            dispatch('loadMetaSet', {id: args.id, listName: args.listName})
        })
    },

    deleteMetaSet: async ({dispatch}, args) => {
        return dispatch('clientDeleteMetaSet', args)
            .then(() => {
                dispatch('loadMetaSets', {id: args.id, listName: args.listName ? args.listName : 'metaSetList'});
                //commit('setMetaSetTmpMutation', {key: 'last_inserted', value: data.id});
                dispatch('createNotification', {'text': i18n.t('events.metaSetDeleted')});
            });
    },
    checkIfMetaSetNameExists: async ({dispatch, getters}, args) => {
        let value = args.value;
        let orgId = args.organizationId ? args.organizationId : getters.getCurrentUserOrg;
        return dispatch('clientLoadMetaSets', {
            filter: ['name eq ' + value + ', organizationId eq ' + orgId],
            fields: 'name,id',
        });
    },
    attachOrganizationMetaSets: async ({dispatch}, args = {}) => {
        //console.log(args);
        return dispatch('clientAddOrganizationMetaSet', args);
    },
    removeOrganizationMetaSets: async ({dispatch}, args = {}) => {
        return dispatch('clientRemoveOrganizationMetaSet', args);
    },
    attachProjectMetaSets: async ({dispatch}, args = {}) => {
        return dispatch('clientAddProjectMetaSet', args);
    },
    removeProjectMetaSets: async ({dispatch}, args = {}) => {
        return dispatch('clientRemoveProjectMetaSet', args);
    },
    attachAssetMetaSets: async ({dispatch}, args = {}) => {
        return dispatch('clientAddAssetMetaSet', args);
    },
    removeAssetMetaSets: async ({dispatch}, args = {}) => {
        return dispatch('clientRemoveAssetMetaSet', args);
    }

};
export default {
    state, getters, mutations, actions
}