<template>
    <two-column-layout
        sub-menu-selector="librarypanel"
        back-button-tab-selector="sfxbackbutton"
        tab-selector="assemblytab"
        :id="$route.params.id"
    >
        <template slot="mainContent">
            <div
                class="col-12 col-md-8"
                v-if="
                    $store.getters.getAssemblyPipelineStatus(this.$route.params.id) !==
                        'processing' && !versionStarted && !startingVersion /*&& canUpdateHierarchy*/
                "
            >
                <info-panel
                    info-panel-header="UpdateSfxData"
                    :collapsible="true"
                    :initially-collapsed="true"
                    preview-id="sfxImport"
                >
                    <div slot="info" class="col-12 col-md-8 mb-3 bg-beige p-3">
            <div v-if="hasNoVersions && !versionStarted && !startingVersion ">
              <div  class="col-12 col-md-8 form-error">
              {{ $t('makeAVersionFirst') }}
              </div>
              <Button class="mr-1" @click="createVersion">{{ $t('createNewType', {type: 'version'})}}</Button><br />
            </div>
            <loading-panel  :time-in-seconds="0"
            message="pleaseWait"
           v-else-if="startingVersion"/>

            <div v-else-if="versionStarted">
                <div  class="col-12 col-md-8 bg-dark p-3">
              {{ $t('versioningProcessStarted') }}
              </div>
            </div>
            <div v-else>
                {{ $t('Update3dHint') }}<br />
            <Button class="mt-3"  :link="'/library/3d-data/' + $route.params.id +'/update-cad'">{{ $t('Update3dData') }}</Button>
            </div>
             </div>
                </info-panel>

            </div>
            <div
                class="col-12 col-md-8"
                v-if="
                    $store.getters.getAssemblyPipelineStatus($route.params.id) ===
                        'finished' && canUpdateAssemblyMetaData
                "
            >
                <info-panel
                    info-panel-header="UpdateMetaData"
                    :collapsible="true"
                    :initially-collapsed="true"
                    preview-id="fileProcessing"
                >
                    <div class="row p-2" slot="info">
                        <div class="col-12 col-md-6"></div>
                        <div class="w-100" />
                        <upload-drop-zone
                            form-id="assemblyUpdate"
                            :class="[
                                !metaDataColumns ? 'col-12 col-md-8' : 'col-6 col-md-6',
                                'mt-3',
                            ]"
                            :image-editable="true"
                            :asset-id="$route.params.id"
                            asset-type="assembly"
                            target-type="Asset"
                            :auto-update-preview="false"
                            :accepted-file-types="
                                Object.values(metaDataFileFormats)
                                    .map((item) => {
                                        return '.' + item.format;
                                    })
                                    .join(' ')
                            "
                            :auto-upload="false"
                            @data="setData"
                            @fileReceived="fileReceived"
                            @filesInQueue="filesInQueue"
                            @queueEmpty="queueEmpty"
                            ref="metaDataUpload"
                            :removable="false"
                            :downloadable="false"
                            :is-loading="isLoading"
                        >
                            <div class="mt-3">
                                {{ $t("uploadMetaFile") }}
                            </div>
                        </upload-drop-zone>
                        <div class="col-12 col-md-8" v-if="metaDataColumns.length">
                            <label class="lighter mt-2">{{
                                $t("PostProcessingForMeta")
                            }}</label
                            ><br />
                            <select
                                class="custom-select dark"
                                v-model="vhubPreProcessors"
                            >
                                <option
                                    :key="key"
                                    v-for="(key, value) in vhubPreProcessorsOptions"
                                    :value="value"
                                >
                                    {{ $t(key.name) }}
                                </option>
                            </select>
                            <assembly-config
                                ref="AssemblyConfig"
                                :meta-data-columns="metaDataColumns"
                                :organization-id="
                                    $store.getters.getAssemblyOrganization(
                                        $route.params.id
                                    )
                                "
                                :teams="$store.getters.getAssemblyTeams($route.params.id)"
                                @setConfig="setMetaConfig"
                                permission="writeMetaSet"
                            />
                        </div>
                        <div
                            v-if="Object.keys(metaConfig).length"
                            class="col-12 col-md-6"
                        >
                            <label>{{ $t("Modus") }} </label
                            ><info-helper class="ml-1" text="MetaUpdateMode" />
                            <tile-button
                                ref="tiles"
                                :initial-active-tiles="metaSetUpdateModes[0]"
                                :tiles="metaSetUpdateModes"
                                :values="metaSetUpdateModes"
                                @trigger="selectMode"
                            />
                        </div>
                        <div
                            v-if="Object.keys(metaConfig).length"
                            class="form-section col-12"
                        >
                            <div class="form-item mt-3">
                                <input
                                    type="checkbox"
                                    id="sendMails"
                                    name="sendMails"
                                    v-model="sendMails"
                                />
                                <label class="ml-1" for="sendMails">{{
                                    $t("GetEmailNotification")
                                }}</label
                                ><info-helper
                                    class="ml-1"
                                    text="Emails werden nur beim Extract-Prozess versendet, nicht beim Reimport"
                                />
                            </div>
                            <label>{{ $t("email") }}</label>
                            <input
                                type="text"
                                :class="[
                                    'form-text w-100',
                                    $v.email.$dirty && $v.email.$error
                                        ? 'form-group--error'
                                        : '',
                                ]"
                                @input="delayTouch($v.email)"
                                v-model="email"
                            />
                            <div
                                class="form-error mb-2"
                                v-if="$v.email.$dirty && !$v.email.email"
                            >
                                {{ $t("errors.mustBeAValidEmailAdress") }}
                            </div>
                            <Button
                                :deactivated="
                                    !this.$v.email.email || partIdentifierColumnMissing
                                "
                                class="mt-3 icon-rotate-animation make-longer-animation"
                                @click="startMetaSetUpdate"
                                >{{ $t("XMLUpdateStart") }} <icon type="cube"
                            /></Button>
                        </div>
                    </div>
                </info-panel>
            </div>
            <div
                class="col-12 col-md-8 meta-field-value"
                v-if="
                    $store.getters.getAssemblyPipelineStatus($route.params.id) ===
                    'finished'
                "
            >
                <info-panel
                    info-panel-header="Apply Material Mapping"
                    :collapsible="true"
                    :initially-collapsed="true"
                >
                    <div slot="info" class="pl-3">
                        <apply-material-mapping
                            :assetId="$route.params.id"
                            v-if="
                                $store.getters.getAssemblyOrganization($route.params.id)
                            "
                        >
                        </apply-material-mapping>
                    </div>
                </info-panel>
            </div>
            <div
                class="col-12 col-md-8"
                v-if="
                    $store.getters.getAssemblyPipelineStatus($route.params.id) ===
                    'processing'
                "
            >
                {{ $t("AssemblyProcessing") }}
            </div>
            <div class="col-12 col-md-8" v-if="showMessage">
                {{ $t("UpdateProcessStarted") }}
            </div>
        </template>
    </two-column-layout>
</template>

<script>
import TwoColumnLayout from "../layouts/OneColumnSidebarLeft";
import InfoPanel from "@/components/InfoPanel";
import UploadDropZone from "@/components/files/UploadDropZone";
import { metaDataFileFormats, metaSetUpdateModes } from "@/enum";
import { decodeArrayBuffer } from "@/utils";
import { getXMLColumns } from "@/components/fileProcessors/xmlWindchillSearchRes";
import AssemblyConfig from "@/components/assemblies/MetaConfig";
import TileButton from "@/components/forms/TileButton";
import InfoHelper from "@/components/InfoHelper";
import { email } from "vuelidate/lib/validators";
import VuelidateMixin from "@/components/mixins/VuelidateMixin.js";
import Button from "../components/forms/Button";
import Icon from "../components/Icon";
import ApplyMaterialMapping from "@/components/assemblies/ApplyMaterialMapping";

export default {
    name: "SFXEdit",
    components: {
        TwoColumnLayout,
        AssemblyConfig,
        InfoPanel,
        UploadDropZone,
        TileButton,
        InfoHelper,
        Button,
        Icon,
        ApplyMaterialMapping,
    },
    mixins: [VuelidateMixin],
    data() {
        return {
            /**
             * the available file formats = processors for meta-files (currently Windchill Search Res only)
             * */
            metaDataFileFormats: metaDataFileFormats,
            vhubPreProcessorsOptions: metaDataFileFormats,

            /**
             * The preprocessor will be set as
             * */
            vhubPreProcessors: Object.keys(metaDataFileFormats)[0],
            /**
             * Sets the dropzone to loading-state while processing data
             * */
            isLoading: false,

            /**
             * the columns from the meta file (which is usually a table of some sort)
             * */
            metaDataColumns: [],

            /**
             * will be set by the meta-config component – is the content.pipeline.meta-Object
             * */
            metaConfig: {},
            /**
             * the mode to use for either overwriting/extending or reset the meta-data
             * */
            mode: "extend",
            metaSetUpdateModes: metaSetUpdateModes,

            email: this.$store.getters.getUserEmail(this.$store.getters.getCurrentUserId),
            sendMails: false,

            partIdentifierColumnMissing: true,

            content: {},
            canUpdateHierarchy: false,
            canUpdateAssemblyMetaData: false,
            teams: [],

            showMessage: false,
            hasNoVersions: true,
              versionStarted: false,
              startingVersion: false,
        };
    },
    validations: {
        email: {
            email,
        },
    },
    beforeMount() {
        this.loadSfx();
    },
    methods: {
        async createVersion() {
            this.startingVersion = true;
            await this.$store.dispatch('clientAssetToPipeline', {
            id: this.$route.params.id,
            type: 'createVersion',
            virtualMachine: null,
          }).then(() => {
            this.startingVersion = false;
            this.versionStarted = true;
          }).catch(() => {
            // todo: show error
          })
          },
          confirmUpdate() {
            this.$store.dispatch('clientFinishPipelineTask', {
                id: this.$route.params.id
            });
          },
          abortUpdate() {
            // todo:
            // delete task manager task
            // tell vHUB to delete the zip file
          },
        setVM(id) {
            console.log(this.vm);
            this.vm = id;
        },
        async setPermissions() {
            this.canUpdateHierarchy = await this.$store.dispatch(
                "checkTeamOrgPermission",
                {
                    teams: this.teams,
                    op: "updateSfxHierarchy",
                    organizationId: this.$store.getters.getAssemblyOrganization(
                        this.$route.params.id
                    ),
                }
            );
            this.canUpdateAssemblyMetaData = await this.$store.dispatch(
                "checkTeamOrgPermission",
                {
                    teams: this.teams,
                    op: "write",
                    organizationId: this.$store.getters.getAssemblyOrganization(
                        this.$route.params.id
                    ),
                }
            );
        },
        loadSfx: function () {
            this.$store
                .dispatch("loadAssembly", {
                    id: this.$route.params.id,
                    include: 'tags,metaSets,teams,versions',
                    addPipelineState: true,
                })
                .then((data) => {
                    this.setContent();
                    this.teams = data.teams ? data.teams : [];
                    this.hasNoVersions = data.versions.length === 0;
                })
                .catch((e) => {
                    if (e.status === 401) {
                        this.$router.push("/access-denied");
                    }
                });
        },
        setContent() {
            if (this.$store.getters.getAssemblyContent(this.$route.params.id)) {
                // this.content = this.$store.getters.getAssemblyContent(this.$route.params.id);
                this.content = { pipeline: { sendMails: true, email: "" } };
                if (this.content.pipeline.sendMails !== undefined) {
                    this.sendMails = this.content.pipeline.sendMails;
                } else {
                    this.sendMails = true;
                }
                if (this.content.pipeline.email) {
                    this.email = this.content.pipeline.email;
                }
            }
            this.setPermissions();
        },
        async setData(data) {
            const file = decodeArrayBuffer(data);
            this.metaDataColumns = await getXMLColumns(file);
            this.isLoading = false;
        },
        // todo: needed?
        filesInQueue: function () {
            this.isLoading = true;
        },
        queueEmpty: function () {
            this.metaConfig = {};
            this.metaDataColumns = [];
        },
        setMetaConfig(metaConfig) {
            this.metaConfig = metaConfig;
            this.partIdentifierColumnMissing = !this.metaConfig.partIdentifierColumn;
        },
        // 1 resume upload-dropzone upload which triggers 2
        startMetaSetUpdate() {
            this.$refs.metaDataUpload.resumeUpload();
        },
        // 2 when file is uploaded, start update of sfx content and
        // 3 start the pipeline task "dataprocessing"
        fileReceived: async function (assetId, fileName) {
            this.content.pipeline.files = [`${assetId}/${fileName}`];
            this.updateAssemblyContent(fileName).then(() => {
                this.startUpdate();
            });
        },
        async updateAssemblyContent(clientMetaFile) {
            if (this.$refs.AssemblyConfig) {
                await this.$refs.AssemblyConfig.save();
            }
            if (this.vhubPreProcessors && this.vhubPreProcessors !== "none") {
                this.content.pipeline.vhubPreProcessors = [];
                this.content.pipeline.vhubPreProcessors.push({
                    name: this.vhubPreProcessors,
                });
            }
            this.content.pipeline.sendMails = this.sendMails;
            this.content.pipeline.email = this.email;
            this.content.pipeline.lang = this.$i18n.locale ? this.$i18n.locale : "en";
            this.content.pipeline.meta = {
                updateMode: this.mode,
                clientMetaFile: clientMetaFile,
                ...this.metaConfig,
            };
            return await this.$store
                .dispatch("loadAssembly", { id: this.$route.params.id })
                .then(async () => {
                    await this.$store.dispatch("updateAssembly", {
                        id: this.$route.params.id,
                        content: JSON.stringify(this.content),
                    });
                });
        },
        startUpdate() {
            this.showMessage = true;
            this.$store.dispatch("clientAssetToPipeline", {
                id: this.$route.params.id,
                type: "dataprocessing",
            });
        },
        selectMode(params) {
            this.mode = params.value;
        },
    },
};
</script>

<style lang="scss" scoped>
.connection-delete {
    cursor: pointer;
}
</style>

