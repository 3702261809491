<template>
    <div>
        <popup
                @close="$emit('abort')">
            <div class="container-fluid" slot="popupMainContent">
                <div class="row">
                    <div class="col-12">
                        <h1>{{ $t('uiElement') }}</h1>
                    </div>
                    <div class="w-100"/>

                    <div class="col-12 col-md-7">
                        <div class="inline-edit-label">
                            <b>{{ $t('Name') }}</b>
                        </div>
                        <input class="form-text"
                               type="text"
                               ref="name"
                               @keydown.enter="save"
                               v-model="name"
                               @blur="$v.name.$touch"
                        >
                        <div class="form-error" v-if="!$v.name.required && $v.name.$error">
{{ $t('errors.required') }}
                        </div>
                        <div class="form-error" v-if="!$v.name.minLength">
{{ $t('errors.atLeastCharacters', {num: 3})
                            }}
                        </div>
                        <div class="form-section">
                            <div class="inline-edit-label">
                                <b class="mr-1">{{ $t('visibility') }}</b>
                            </div>
                            <div class="form-group">
                                <input id="checkbox" class="checkbox float-left mr-2" ref="checkbox" type="checkbox"
                                       v-model="isVisible">
                                <div class="ml-4 inline-edit-label" for="checkbox">{{ $t('isVisible') }}</div>
                            </div>
                            <div class="inline-edit-label">
                                <b>{{ $t('ActionName') }}</b>
                            </div>
                            <input class="form-text"
                                   type="text"
                                   ref="name"
                                   @keydown.enter="save"
                                   v-model="actionName"
                            >
                            <div class="inline-edit-label">
                                <b>{{ $t('ActionParameter') }}</b>
                            </div>
                            <input class="form-text"
                                   type="text"
                                   ref="name"
                                   @keydown.enter="save"
                                   v-model="actionParameter"
                            >
                        </div>
                        <div class="w-100"/>

                        <div class="inline-edit-label mt-2">
                            <b class="mr-1">{{ $t('picture') }}</b>
                            <div v-if="!mediaLinkEditing" class="edit-trigger"
                                 @click.prevent="() => {mediaLinkEditing = true}"/>
                        </div>

                        <div class="w-100">
                            <content-display
                                    class="w-100 mt-1 too-long-text-break"
                                    v-if="mediaLink"
                                    :list-name="id"
                                    type="media"
                                    :value="mediaLink"
                                    :show-preview="true"
                            />
                            <Button v-if="!mediaLink && !mediaLinkEditing" class="mt-2" @click="() => {mediaLinkEditing = true}">
                                {{ $t('addPicture') }} <icon type="image" />
                            </Button>
                            <Button v-if="mediaLink && !mediaLinkEditing" class="mt-2" @click="() => {mediaLinkEditing = true}">
                                {{ $t('changePicture') }} <icon type="edit" />
                            </Button>
                            <content-list
                                    v-if="mediaLinkEditing"
                                    :value="mediaLink"
                                    type="media"
                                    :list-name="id"
                                    @save="updateMediaLink"
                                    :organization-id="organizationId"
                                    :show-preview="true"
                                    :thumbnail-view="true"
                            />
                        </div>
                    </div>
                    <div class="col-12 col-md-4 mb-2 offset-1">
                        <div class="inline-edit-label">
                            <b>{{ $t('color') }}</b>
                        </div>
                        <div class="color-preview-container">
                            <div class="color-preview round large editable" :style="'background-color:' + color"
                                 @click.self="() => {editColor = true; }"/>
                        </div>
                        <color-picker
                                :show-save-button="false"
                                :value="color"
                                @changeColor="updateColor"
                        />
</div>
</div>
            </div>
            <div class="col-12 mt-5" slot="popupFooter">
                <Button
                        class="mb-3"
                        :deactivated="!enableSaving"
                        @click="save"
                >
                    <icon v-if="enableSaving" type="save"/>
                    <icon v-else type="ban"/>
                    {{ $t('SAVE') }}
                </Button>
            </div>
        </popup>
    </div>
</template>

<script>
    import Popup from "../Popup";
    import ColorPicker from "../widgets/editors/ColorPicker";
    import Icon from "../Icon";
    import Button from "./Button";
    import ContentList from "../widgets/editors/ContentList";
    import ContentDisplay from "../widgets/editors/ContentDisplay";
    import {required, minLength} from 'vuelidate/lib/validators';

    export default {
        name: "UIform",
        components: {
            Popup, ColorPicker, Icon, Button, ContentList, ContentDisplay,
        },
        props: {
            item: {type: Object, required: true},
            organizationId: {type: String, required: true},
            id: {type: String, required: true},
        },
        data() {
          return {
            enableSaving: false,
            editColor: false,
            mediaLinkEditing: false,
            color: '#ddd',
            mediaLink: null,
            isVisible: false,
            name: '',
            position: 0,
            actionName: '',
            actionParameter: ''
          };
        },
        watch: {
            name: function () {
                this.enableSaving = !this.$v.$anyError && this.name ? true : false;
            }
        },
        validations: {
            name: {
                required,
                minLength: minLength(3),
            },
        },
        beforeMount() {
            Object.keys(this.item).forEach(key => {
                if (this.item[key]) {
                    this[key] = this.item[key];
                }
            });
        },
        methods: {
            save() {
                this.$v.name.$touch();
                if (!this.$v.$anyError) {
                    let obj = {
                        color: this.color,
                        mediaLink: this.mediaLink,
                        isVisible: this.isVisible,
                        name: this.name,
                        position: this.position,
                        actionName: this.actionName,
                        actionParameter: this.actionParameter,
                    };
                    this.$emit('save', obj);
                }
            },
            updateColor(val) {
                this.color = val;
                this.editColor = false;
            },
            updateMediaLink(val) {
                this.mediaLink = val;
                this.mediaLinkEditing = false;
            }
        }
    }
</script>

<style scoped>
    .color-preview-container {
        width: 50px;
        height: 50px;
        position: relative;
        display: block;
    }
</style>