<template>
    <div :class="['text-center navpanel mr-1', arrowStyle ? 'arrowStyle' : '']">
        <router-link
                v-if="!disabled"
                class="nav-link"
                :to="{name: routename, params: {id: pid, ...props}}"
                :translate="translate">
<slot/>
            <icon v-if="icon" :type="icon" size="1.2" />
            <div class="title" v-if="translate">{{ $t("nav." + title) }}</div>
            <div class="title" v-else>{{ title }}</div>
        </router-link>
    </div>
</template>

<script>
    /**
     * Navigation Bar
     */
    import Icon from "../Icon";
    export default {
        name: 'NavigationItem',
        components: {
            Icon,
        },
        props: {
            routename : { type: String, required: true},
            pid : { type: String, default: null},
            translate : { type: Boolean },
            title: { type: String, required: true},
            active: { type: Boolean },
            icon: { type: String, default: null},
            disabled: {type: Boolean},
            addClasses: {type: Array, default: () => []},
            arrowStyle: {type: Boolean},
            activeClass: {type: String, default: 'active'},
            props: {type: Object, default: () => []},
        },
    }
</script>

<style lang="scss">
    .navpanel {
        z-index:1;
        .icon {
            font-size:1.3em;
            display:block;
            position:absolute;
            top:50%;
            left:50%;
            -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
            padding:0;
        }
        .nav-link {
            //padding: 15px;
            //background-color: #384858;
            background-color: $tab-item-background;
            display:block;
            padding:0;
            width: 40px;
            height:40px;
            position:relative;
            &:hover, &.router-link-active {
                color: #fff;
                background-color: $sub-panel-background-color;
            }
            &:hover {
                .title {
                    opacity: 1;
                }
            }
        }
        .title {
            background-color: $tab-item-background-active;
            position:absolute;
            top:-29px;
            width:100px;
            opacity:0;
            -webkit-transition: all 300ms ease;
            transition: all 300ms ease;
            padding:4px;
        }

    }
</style>