<template>
  <div class="row">
      <upload-drop-zone
          class="col-12 col-md-6"
          :asset-id="assetId"
          :image-editable="true"
          @fileReceived="fileReceived"
          @queueEmpty="$emit('queueEmpty')"
          @filesInQueue="$emit('filesInQueue')"
          :asset-type="assetType"
          target-type="Asset"
          form-id="uploadSfx"
          :downloadable="true"
          :accepted-file-types="'.fbx,.vab'"
          :max-file-size="1000000"
          :preview-uri="previewUri"
          :auto-upload="autoUpload"
          :ref="refId"
          :asset-name="assetName"
          :asset-description="assetDescription"
          :organization-id="organizationId"
          :team-id="teamId"
          :make-chunks="makeChunks"
      />
  </div>
</template>

<script>
import UploadDropZone from "@/components/files/UploadDropZone";
import {mapGetters} from "vuex";
export default {
  name: "FbxUpload",
  components: {
    UploadDropZone
  },
  props: {
    /**
     * @assetId Text the assetId
     */
    assetId: {type: String, default: null},
    /**
     * @organizationId Text the organizationId
     */
    organizationId: {type: String, default: null},
    teamId: {type: String, default: null},
    /**
     * @autoUpload Boolean Whether or not to upload the file right after drag and drop event
     */
    autoUpload: {type: Boolean, default: true},
    /**
     * If an asset needs to be created: type, name and description can be set
     * @assetType String e.g. media, helper, assembly etc.
     * @assetName String a name
     * @assetDescription String an optional description
     */
    assetType: {type: String, required:true},
    assetName: {type: String, default: ''},
    assetDescription: {type: String, default: ''},
    /**
     * @makeChunks Whether or not to upload the file in chunks
     * */
    makeChunks: {type: Boolean, default: false},
  },
  computed: {
    ...mapGetters(['getSfxdataPreviewUri'])
  },
  data() {
    return {
      refId: Date.now() + 'ref',
      enableSaving: false,
      title: '',
      previewUri: '',
      fileName: '',
    };
  },
  watch: {
    getSfxdataPreviewUri(newVal) {
      if(newVal.length) {
        this.setStatus();
      }
    }
  },
  beforeMount() {
    this.setStatus();
  },
  methods: {
    setStatus: function() {
      this.previewUri = this.getSfxdataPreviewUri(this.assetId);
      if(this.previewUri) {
        this.$emit('finished', false);
      }
    },
    fileReceived: function(assetId, fileName){
      this.fileName = fileName;
      this.enableSaving = true;
      this.$emit('finished',assetId , true,);
      this.previewUri = this.$store.getters.getSfxdataPreviewUri(this.assetId);
    },
    resumeUpload: function() {
      this.$refs[this.refId].resumeUpload();
    },
  }
}
</script>