<template>
  <div v-if="$store.getters.isSuperAdmin">
    <div class="lighter">{{ $t('State') }}</div>
    <select class="custom-select"
                v-model="selected">
            <option v-for="(key, item, index) in possibleStates"
                    :key="index"
                    :value="key">
                {{ item }}
            </option>
        </select>
  </div>
</template>

<script>
import {contentTypes, ProjectStates, AssetStates} from '@/enum';
export default {
  name: "StateSetter",
  props: {
    targetId: {type: String, required: true},
    storeName: {type: String, required: true},
    givenState: {type: String, default: 'a'},
  },
  data() {
    return {
      possibleStates: [],
      ProjectStates,
      AssetStates,
      selected: ''
    };
  },
  watch: {
    selected(val) {
      this.setState(val);
    },
    givenState(val) {
      this.selected = val;
    }
  },
  beforeMount() {
    if(this.givenState) {
      this.selected = this.givenState;
    }
    const type = contentTypes[this.storeName.toLowerCase()];
    this.possibleStates = this[`${type}States`];
  },
  methods: {
    setState(state) {
      if(this.givenState !== state) {
        this.$store.dispatch(`update${this.storeName}`, {
        id: this.targetId,
        state: state
        }).then(() => {
          this.$emit('setState', state);
        })
      }
    }
  }
}
</script>

<style scoped>

</style>