<template>
  <two-column-layout
      tab-selector="scenetab"
      type="scene"
      back-button-tab-selector="scenebackbutton"
      :id="$route.params.id"
      sub-menu-selector="librarypanel"
  >
    <div slot="buttonsLeft"/>

    <info-panel
        slot="sidebarLeft"
        :id="$route.params.id"
        upload-type="Asset"
        :created="$store.getters.getSceneCreationDate($route.params.id)"
        :last-change="$store.getters.getSceneChangeDate($route.params.id)"
        :downloadable="true"
        :removable="true"
        :image-editable="true"
        preview-id="sceneEdit"
        :organization="$store.getters.getSceneOrganization($route.params.id)"
        :preview-uri="$store.getters.getScenePreviewUri(this.$route.params.id)"
    />
    <team-attacher
        v-if="$store.getters.getSceneOrganization($route.params.id)"
      slot="sidebarLeft"
              class="mt-3"
              :id="$route.params.id"
              :teams="teams"
              store-name="Asset"
              :limit-by-organization-id="$store.getters.getSceneOrganization($route.params.id)"
              @added="loadAssetTeams"
              @removed="loadAssetTeams"
          />
    <info-panel
        slot="sidebarLeft"
        :id="$route.params.id"
        :tags="$store.getters.getSceneTags($route.params.id)"
        :taggable="true"
        preview-id="sceneEdit"
        @removeTag="removeTag"
        @addTag="addTag"
        :key="'infopanel2'"
        :limit-tags-to-organization="$store.getters.getSceneOrganization($route.params.id)"
    />

    <div slot="mainContent">
      <content-title-block
          v-if="$store.getters.getSceneTitle($route.params.id)"
          :value="$store.getters.getSceneTitle($route.params.id)"
          :id="$route.params.id"
          store-name="Scene"
          :organization-id="$store.getters.getSceneOrganization($route.params.id)"
      />
      <content-description-block
          :value="$store.getters.getSceneDescription(id)"
          :id="$route.params.id"
          store-name="Scene"
      />
      <meta-set-attacher
          :target-id="$route.params.id"
          store-name="Asset"
          @added="loadScene"
          @removed="loadScene"
          :given-meta-sets="$store.getters.getSceneMetaSets(id)"
      />
      <meta-panel
          :key="item.id"
          v-for="(item) in $store.getters.getSceneMetaSets(id)"
          :title="item.description"
          :fields="item.metaFields"
          icon-left="folder"
          store-name="Scene"
          :organization-id="$store.getters.getSceneOrganization(id)"
           @updated="loadScene"
      />
    </div>
  </two-column-layout>
</template>

<script>
import InfoPanel from "../components/InfoPanel";
import TwoColumnLayout from "../layouts/OneColumnSidebarLeft";
import MetaPanel from "../components/widgets/meta/MetaPanel";
import ContentTitleBlock from "../components/ContentTitleBlock";
import ContentDescriptionBlock from "../components/ContentDescriptionBlock";
import MetaSetAttacher from "@/components/widgets/meta/MetaSetAttacher";
import TeamAttacher from "@/components/organizations/TeamAttacher";

export default {
  name: 'SceneEdit',
  components: {
    MetaPanel,
    InfoPanel,
    TwoColumnLayout,
    ContentTitleBlock,
    ContentDescriptionBlock,
    MetaSetAttacher,
    TeamAttacher
  },
  data() {
    return {
      id: '',
      teams: []
    };
  },
  beforeMount() {
    this.loadScene();
    this.loadAssetTeams();
    this.id = this.$route.params.id;
  },
  methods: {
    loadAssetTeams() {
      this.$store.dispatch('clientLoadAssetTeams', {
        id: this.$route.params.id
      }).then(data => {
        this.teams = data;
      });
    },
    loadScene() {
      this.$store.dispatch('loadScene', {
        id: this.$route.params.id,
        include: 'metaSets,tags'
      }).catch(e => {
        if(e.status === 401) {
          this.$router.push('/access-denied');
        }
      });
    },
    removeTag: function(tag) {
      this.$store.dispatch('removeSceneTag', {
        id: this.$route.params.id,
        args: [tag.id],
      });
    },
    addTag: function(tag) {
      this.$store.dispatch('addSceneTag', {
        id: this.$route.params.id,
        args: [tag.id],
      });
    },
  },
}
</script>