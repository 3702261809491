<template>
    <main-table-layout
      subMenuSelector="adminpanel"
    >
        <div slot="buttonsRight"/>
        <content-filter
                slot="filter"
                @filter="filter"
                :id="'projectList'"
                store-name="VMLog"
                text-filtering-field-name=""
        />
            <view-switcher
                slot="mainContentButtons"
                :id="'mainContent'"
                :table="true"
                :thumbnails="true"
            />
        <pagination
                slot="mainContentButtons"
                :limit="paginationLimit"
                :total="$store.getters.getVMLogListOptions(listName, 'pagination_items')"
                :id="'projecttablepage'"
                @setActive="loadPaginatedData"
                ref="pagination"
        />
        <!--<Button
            v-if="$store.getters.isSuperAdmin"
            permission="createVMLog"
            type="action-button" slot="mainContentButtons" link="/project/add" icon="plus">
                {{ $t('createNewVMLog') }}
        </Button>-->
        <Button
           slot="mainContent"
           @click="loadPaginatedData(0); $refs.pagination.setActive(0)"
           type="reload"
           icon="redo"
           class="icon-button"
       />
        <log-viewer
            :key="forceReRenderKey"
            v-if="$store.getters.getVMLogsByListName(listName)"
            slot="mainContent"
            :data="$store.getters.getVMLogsByListName(listName)"
            @entryDeleted="deleteRow"
        />

    </main-table-layout>
</template>

<script>
    import Button from "../components/forms/Button";
    import ContentFilter from "../components/widgets/filter/ContentFilter";
    import MainTableLayout from "../layouts/MainTableLayout";
    import ViewSwitcher from "../components/widgets/ViewSwitcher";
    import Pagination from "../components/Pagination";
    import LogViewer from "@/components/LogViewer";
    export default {
        name: "VMLogs",
        components: {
            Button,
            ContentFilter,
            MainTableLayout,
            ViewSwitcher,
            Pagination,
            LogViewer
        },
        data() {
            return {
               listName: 'VMLogList',
                loading: true,
                paginationLimit: 55,
                queryRelevantComponents: 2, //pagination and filter
                queryRelevantInits: 0,
                viewId: 'mainContent',
                forceReRenderKey: 0,
            };
        },
        watch: {
            queryRelevantInits: function(newval) {
                if(newval === this.queryRelevantComponents) {
                    this.loadPaginatedData(0);
                }
            }
        },
        methods: {
            loadPaginatedData: function(offset, initial) {
              this.loading = true;
                let args = {
                    add: {
                        paging: {
                            [this.listName]: true,
                        },
                        offset: {
                            [this.listName]: offset,
                        },
                        limit: {
                            [this.listName]: this.paginationLimit,
                        },
                        include: {
                          [this.listName]: 'instance,logAsset'
                        },
                        sort: {
                          [this.listName]: '-createdAt'
                        },
                    },
                };
                if(initial) {
                    this.queryRelevantInits++;
                    this.$store.dispatch('registerVMLogQueryParams', args);
                }
                else {
                    this.$store.dispatch('loadVMLogs', args).then(() => {
                      this.loading = false;
                      this.forceReRenderKey++;
                    })
                }
            },
            deleteRow: function() {
              console.log('deleted')
                this.loadPaginatedData();
                this.forceReRenderKey++;
            },
            filter: function (args, initial) {
                if(initial) {
                    this.queryRelevantInits++;
                    this.$store.dispatch('registerVMLogQueryParams', args);
                }
                else {
                  this.loading = true;
                    this.$store.dispatch('loadVMLogs', args).then(() => {
                      this.loading = false;
                    })
                }
            },
            setFilterParams: function(args) {
                this.$store.dispatch('submitQueryParams', {
                    storeType: 'organization',
                    listName: this.$route.params.id,
                    params: args,
                });
            },

        }
    }
</script>
