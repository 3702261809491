<template>
    <div class="p-5 mt-5">
            <download-button
                loading-panel-classes="black"
                class="ml-3 mb-5 not-absolute"
                download-message=""
                object-id="98fc6056-88e0-4671-b3ff-d1a46d9d3cc4"
                file-key="saas_agb_en_us.pdf"
                username="public"
                password="public"
            ><icon type="file-pdf" />{{ $t('Download PDF') }}</download-button>
            <div class="w-100"></div>
            <div class="col-12 col-md-8" v-html="$t('saas_agb')"></div>
        </div>
</template>

<script>
    import DownloadButton from "@/components/forms/DownloadButton";
    import Icon from "@/components/Icon";
    export default {
        name: 'GDPR',
        components: {
          DownloadButton,
          Icon
        },
      methods: {
      logout() {
        this.$router.push('/');
        this.$store.dispatch('doLogout');
      },
      accept() {
        this.$store.dispatch('clientSaveUser', {
          id: this.$store.getters.getCurrentUserId,
          gdprAccepted: true
        }).then(() => {
          this.$store.dispatch('checkLogin');
        }).then(() => {
          this.$router.push('/project');
        })
      }
    }
    }
</script>