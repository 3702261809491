<template>
    <main-table-layout
        :has-top-bar="false"
        :tab-selector="$store.getters.getAppType($route.params.id) + 'tab'"
        :type="$store.getters.getAppType($route.params.id)"
        back-button-tab-selector="appbackbutton"
        :id="$route.params.id"
    >
        <div slot="buttonsRight">
            <Button
                    v-if="$route.query.wizard"
                    class="mb-3"
                    :link="'/app/' + this.$route.params.id + '/general'"
                    :deactivated="false"
            >
                <icon type="save"/> {{ $t('SAVE') }}
            </Button>
        </div>

        <h1 slot="mainContent"><icon class="mr-2" type="webapp" />{{ $store.getters.getAppName($route.params.id) }}</h1>

        <div slot="mainContent" class="mt-5">
            <project-linker
                :to-be-linked-types="['webapps']"
                :linking-limits="{'webapps': 1}"
            />
        </div>
    </main-table-layout>
</template>

<script>
    import Button from "../components/forms/Button";
    import MainTableLayout from "../layouts/MainTableLayout";
    import ProjectLinker from "@/components/connections/ProjectLinker";
    import Icon from "@/components/Icon";
    export default {
        name: "SFXEditMedia",
        components: {
            ProjectLinker,
            Button,
            MainTableLayout,
          Icon
        },
        beforeMount() {
            this.$store.dispatch('loadApp', {
                id: this.$route.params.id,
                include: 'organization,tags,metaSets'
            }).catch(e => {
              if(e.status === 401) {
                this.$router.push('/access-denied');
              }
            });
            this.id = this.$route.params.id;
        },
    }
</script>