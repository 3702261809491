<template>
    <ul :class="[
                level ? 'level-' + level : '',
                getOpenStatus(id) || level <= maxInitDepth ? 'open' : 'closed',
                id
            ]">
        <li><slot name="loadless" /></li>
      <li><slot name="loadingspinner" /></li>
        <li v-for="(item, key) in data"
            :key="key">
            <div :key="item.id + 'text'"
                    @click="setActiveItem(item.id, item.assetId, item.asset.type, item)"
                    :class="[`text too-long-text-break`, item.type === 'node' ? 'has-children' : '', $store.getters.getAssemblyListOptions($route.params.id).selected && item.id in $store.getters.getAssemblyListOptions($route.params.id).selected ? 'active' : '', getOpenStatus(item.id) || level === 1 ? 'open' : 'closed']"
                    :style="getOpenStatus(item.id) || level === 1 ? `z-index: ${level}` : 'z-index: 0'"
            >
                <!--PRE ICONS-->
                <icon v-if="getOpenStatus(item.id) || level === 1 || level === 2" type="folder-open" class="icon-left" />
                <icon v-else-if="item.type === 'node'" type="folder" class="icon-left" />
                <icon v-else type="cube" class="icon-left" />

                <div class="node-name inline-block ml-1">
                  <!--TEXT-->
                  {{ item.name }}

                  <!--POST ICONS-->
                  <div class="hover-icons">
                     <div class="icon-container mr-2" @click.stop="editItem(item.id)">
                      <icon class="lighter" type="cog" />
                    </div>
                      <div class="icon-container" v-if="item.type !== 'node'"><router-link :to="'/library/3d-data/model/' + item.asset.id + '/general'">
                        <icon class="lighter" type="external-link-alt" /></router-link></div>
                      <div class="icon-container" v-else-if="item.type === 'node'"><router-link :to="'/library/3d-data/instance/' + item.id + '/general'">
                        <icon class="lighter" type="external-link-alt" /></router-link></div>
                   </div>


                    <div class="lighter" v-if="item.type === 'node'">{{ parseInt(offset) ? parseInt(offset) : 0 }} - {{ (parseInt(offset) ? parseInt(offset) : 0) + Object.keys($store.getters.getAssemblyTreeLevel(item.id, projectId)).length }} / {{ $store.getters.getAssemblyListOptions(item.id, 'pagination_items') }}</div>

                  <div class="lazy-meta-box">
                    <div class="darker pl-2 pr-2 pt-1 pb-1" :key="metaSet.id" v-if="showMeta && ($store.getters.getProjectOrganization(item.projectId) === metaSet.organizationId || item.type !== 'node' || metaSet.organizationId === $store.getters.getSuperOrg)" v-for="metaSet in item.asset.metaSets"><p class="smaller">{{ metaSet.name }}</p></div>
                  </div>
                  <loading-spinner v-if="getLoadingItemStatus(item.id)" class="white" />
                    <span v-if="item.sources.length > 0">
                      <span class="linked-data"><icon type="paperclip" /> {{ item.sources.length }}</span>
                    </span>
                    <div
                        @click.stop="true ? toggleOpenClosed(item.id) : null" class="item-count"
                        v-if="item.type === 'node'">
                        <icon class="lighter" size="1.3" type="angle-right" />
                    </div>
                </div>
              <!--ASSET/INSTANCE EDITING-->
              <div v-if="isEditing || item.id === isEditing">
                <portal to="aboveTreeSection">
                  <model-edit-view-popup
                      :instance-id="isEditing"
                      :project-id="projectId"
                      @abort="() => {isEditing = null;}"
                      @updateName="updateItemName"
                  />
                </portal>

                <!--<div @click.stop="updateItemName(item.id)" class="mr-3 float-right editing-icon"><icon type="save" /></div>
                <div @click.stop="() => {isEditing = null; newName = null;}" class="mr-3 float-right editing-icon"><icon type="times" /></div>
                <input @keydown.enter="updateItemName(item.id)" class="form-text" v-model="newName" />-->
              </div>
            </div>
            <node
                    :data="$store.getters.getAssemblyTreeLevel(item.id, projectId)"
                    :fields="fields"
                    :level="level + 1"
                    :node-selection-handler="nodeSelectionHandler"
                    :node-open-handler="nodeOpenHandler"
                    :node-close-handler="nodeCloseHandler"
                    :id="item.id"
                    :project-id="projectId"
                    :max-init-depth="maxInitDepth"
                    :key="item.id"
                    :open-items="openItems"
                    :show-meta="showMeta"
            >
            <div slot="loadmore" class="text" v-if="item.type === 'node' && $store.getters.getAssemblyListOptions(item.id, 'pagination_items') > limit && getOpenStatus(item.id)"><div class="lighter">{{ $t('jumpTo') }}</div><input class="form-text" type="number" @keydown.enter.stop="loadOffset(item.id)" v-model="offset" ><div @click="loadMore(item.id)">load more<br > <icon type="angle-down" /></div></div>
              <div @click="loadLess(item.id)" slot="loadless" class="text" v-if="item.type === 'node' && offset > 0"><p><icon type="angle-up" /><br >load before </p></div>
              <div slot="loadingspinner" v-if="getLoadingItemStatus(item.id)" class="text-center pt-2 pb-2"><loading-spinner class="white" /></div>
            </node>
        </li>

        <li :class="[
                level ? 'level-' + level : ''
            ]">
<slot name="loadmore" />
</li>
    </ul>
</template>

<script>
    import Icon from "../Icon";
    import LoadingSpinner from "../LoadingSpinner";
    import ModelEditViewPopup from "@/components/tree/ModelEditViewPopup";

    export default {
        name: 'Node',
        components: {
            LoadingSpinner,
            Node,
            Icon,
            ModelEditViewPopup,
        },
        props: {
            fields: {type: Array, required: true,},
            data: {type: Array, default:null},
            level: {type: Number, default: 0},
            name: {type: String, default: ''},
            nodeSelectionHandler: {type: String, default: ''},
            nodeOpenHandler: {type: String, default: ''},
            nodeCloseHandler: {type: String, default: ''},
            projectId: {type: String, default: ''},
            id: {type: String, default: ''},
            previewPath: {type: String, default: null},
            maxInitDepth: {type: Number, default: 2},
            openItems: {type: Array, default: null},
            showMeta: {type: Boolean, default: false}
        },
        data() {
            return {
                showChildren: false,
                loadingData: false,
                loadingItem: null,
                newName: '',
                isEditing: null,
                offset: 0,
                limit: 200,
            };
        },
        watch: {
            projectId(newValue) {
                if (newValue) {
                    if ((this.level + 1) <= this.maxInitDepth && this.projectId) {
                        this.loadData();
                    }
                }
            },
        },
        beforeMount() {
          if(this.getOpenStatus(this.id)) {
            this.loadData(this.id, this.level);
          }
        },
        methods: {
            editItem(id) {
              //this.newName = name;
              this.isEditing = id;
            },
           updateItemName(id, name) {
              /*this.$store.dispatch('clientUpdateProjectInstance', {
                  id: this.projectId,
                  cid: id,
                  name: this.newName
                });*/
              let index = this.data.findIndex(item => {
                return item.id === id;
              });
              this.data[index].name = name;
              //this.isEditing = null;
              //this.newName = '';
            },
            getOpenStatus(id) {
                return this.openItems.length > 0 && this.openItems.includes(id);
            },
            getLoadingItemStatus(id) {
                return this.loadingItem && this.loadingItem.includes(id);
            },
            toggleOpenClosed: function (id) {
                this.$emit('click');
                if(this.getOpenStatus(id)) {
                    this.$store.dispatch(this.nodeCloseHandler, {
                        listName: this.$route.params.id,
                        id: id,
                    });
                    this.unloadData(id);
                }
                else {
                    this.loadingItem = id;
                    this.$store.dispatch(this.nodeOpenHandler, {
                        listName: this.$route.params.id,
                        id: id,
                    });
                    this.loadData(id);
                }
            },
            setActiveItem: function (id, assetId, type, asset) {
                this.$store.dispatch(this.nodeSelectionHandler, {
                    listName: this.$route.params.id,
                    id: id,
                    assetId: assetId,
                    type: type,
                    asset: asset,
                });
            },
          loadOffset(id) {
            this.unloadData(id);
            this.loadData(id, null, parseInt(this.offset));
          },
          loadMore(id) {
              this.offset = parseInt(this.offset) + this.limit;
              this.loadOffset(id);
          },
          loadLess(id) {
            if(this.offset > 0) {
              this.offset = parseInt(this.offset) - this.limit;
              if(this.offset < 0) {
                this.offset = 0;
              }
            }
            this.loadOffset(id);
          },
          getLoadingArgs(id = null, level = null, offset = null) {
            let parentId = id;
            if (!id) {
              parentId = this.id;
            }
            let args = {
              id: this.projectId,
              include: 'assetAndMetaSets,sources',
              limit: this.limit,
              level: this.level + 1,
              parentId: id,
              filter: 'level eq ' + [level ? level : this.level + 1] + ', type in model node, parentId eq ' + parentId,
            };
            if(offset) {
              args.offset = offset;
            }
            return args;
          },
          loadData(id = null, level = null, offset = null) {
                this.loadingData = true;
                this.loadingItem = id;
                let args = this.getLoadingArgs(id, level, offset);
                this.$store.dispatch('loadAssemblyTreeLevel', args).then(() => {
                    this.showChildren = true;
                    this.loadingData = false;
                    this.loadingItem = '';
                });
            },
            unloadData: function(id) {
                let parentId = id;
                if (!id) {
                    parentId = this.id;
                }
                this.$store.dispatch('unloadAssemblyTreeLevel', {
                    listName: this.projectId,
                    level: this.level + 1,
                    id: id,
                    parentId: parentId,
                }).then(() => {
                    this.showChildren = false;
                });
            },
        },
    }
</script>