<template>
  <one-column-sidebar-left
    back-button-tab-selector="qrcodebackbutton"
    sub-menu-selector="librarypanel"
  >
    <div slot="buttonsRight">
      <Button class="mb-3 mt-5" :deactivated="!enableSaving" @click="save">
        <icon v-if="enableSaving" type="save" />
        <icon v-else type="ban" />
        {{ $t("SAVE") }}
      </Button>
    </div>
    <add-qrcode-form ref="addqrcodeform" slot="mainContent" @enableSaving="canSave">
    </add-qrcode-form>
  </one-column-sidebar-left>
</template>

<script>
import Button from "../components/forms/Button";
import OneColumnSidebarLeft from "../layouts/OneColumnSidebarLeft";
import AddQrcodeForm from "../components/forms/AddQrcodeForm";

export default {
  name: "QrCodeAdd",
  components: {
    Button,
    OneColumnSidebarLeft,
    AddQrcodeForm,
  },
  data() {
    return {
      enableSaving: false,
    };
  },
  validations: {},
  watch: {},
  methods: {
    save: function () {
      this.$refs.addqrcodeform.save();
    },
    canSave: function (value) {
      this.enableSaving = value;
    },
  },
};
</script>
