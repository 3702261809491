<template>
    <div :class="['tag mb-1', viewStyle === 'table' ? 'd-flex w-100 long-tag' : '']" :style="'background-color: ' + org.color ">
        <div :class="['', viewStyle === 'table' ? 'col-3 p-2 mr-5' : 'organization-row pl-2 pr-2 mb-3']">
            <icon v-if="Number(org.visibility) !== 0" type="building" size="1.1" class="mr-2 float-left" />
            <icon v-else type="user" size="1.1" class="mr-2 float-left" />
            <div class="ml-4">
              <b>{{ org.displayName ? org.displayName : org.name }}</b><br />
              <div class="mt-3 mr-1 lighter">{{ $t('Role') }}:</div>
              <div class="w-100" v-if="isEditable && !lock"></div>
              <select class="custom-select" v-if="isEditable && !lock" @change="update('role')" v-model="role">
                <option :key="role" :value="role" v-for="(role, value) in possibleRoles">{{ value }}</option>
              </select>
              <span v-else>{{ role }}</span>
            </div>
        </div>
        <div v-if="isRemovable && !isDefault" class="icon-right" @click="$emit('removeorg', org)"><icon type="timesCircle" size="1" /></div>
        <div v-if="showAppPanel" :class="['align-self-end', viewStyle === 'table' ? 'col-4 p-2' : 'mt-3']">
            <label-with-edit-icon-and-content
                    label="appPanel URL"
                    :value="appPanel"
                    @edit="() => {appPanelEditing = true;}"
                    :make-placeholder-space="false"
            />
            <div v-if="appPanelEditing" :class="['', viewStyle === 'table' ? 'col-8 p-2 row' : 'mt-1']">
                <span class="lighter">{{ $t('manual') }}</span>
                <input
                        type="text"
                        :class="['form-text']"
                        v-model="appPanel"
                >
                <div class="mb-2">
                  <span class="lighter">{{ $t('select') }}</span>
                  <universal-selector
                    store-name="App"
                    list-name="apps"
                    @addItem="setAppPanel"
                    :limit-by-organization-id="org.id"
                    :after-query-filter="(item) => {
                      return item.type === 'webapp'
                    }"
                    />
                 </div>

                  <div class="edit-trigger mr-2" @click="() => { appPanelEditing = false }">
                    <icon :type="'times'"/>
                </div>
                <div class="edit-trigger" @click="update('appPanel'); appPanelEditing = false;">
                    <icon :type="'save'"/>
                </div>
            </div>
        </div>
        <div :class="['', viewStyle === 'table' ? 'align-self-end col-3 p-2' : 'mt-4']">
            <div v-if="isEditable && !lock">
                <div class="lighter">{{ $t('forms.default') }}:</div>
                <div class="custom-checkbox" @click="() => {this.default = !this.default; update('default')}">
                    <div :class="['custom-checkbox', isDefault ? 'active' : '' ]" ref="checkbox" type="checkbox"
                    >
<div class="inner"><icon type="check"/></div>
</div>
                </div>
            </div>
        </div>

        <div class="p-2" v-if="showDetails && isDefault">
            <b>{{ $t('forms.default') }}</b>
        </div>
    </div>
</template>

<script>
    import LabelWithEditIconAndContent from "../LabelWithEditIconAndContent";
    import Icon from "../Icon"
    import {UserRoles, AdminData} from '../../enum';
    import UniversalSelector from "@/components/organizations/UniversalSelector";

    export default {
        name: "Org",
        components: {
            Icon,
            LabelWithEditIconAndContent,
            UniversalSelector
        },
        props: {
            org : { type: Object, required: true},
            isRemovable : { type: Boolean, default: true},
            iconLeft: {type: String, default: "faHome"},
            isEditable : { type: Boolean, default: false},
            showDetails : { type: Boolean, default: true},
            isDefault: { type: Boolean, default: false},
            viewStyle: {type: String, default: "thumbnails"},
            showAppPanel: {type: Boolean, default: true},
            acceptNoneAsRole: {type: Boolean, default: true},
        },
        data() {
            if(this.showDetails) {
                return {
                    role:  '',
                    possibleRoles: {},
                    lock: false,
                    appPanel: '',
                    appPanelEditing: false,
                    default: false,
                };
            }
            return {
                lock: false,
                appPanel: '',
                appPanelEditing: false,
                default: false,
            };
        },
        beforeMount() {
            if(this.org.id === AdminData.vHubOrganizationId) {
                this.lock = true;
            }
            this.possibleRoles = JSON.parse(JSON.stringify(UserRoles));
            if(!this.acceptNoneAsRole) {
              delete this.possibleRoles['None'];
            }
            this.role = this.org.members && this.org.members[0] && this.org.members[0].relation ? this.org.members[0].relation.role : (this.org.role ? this.org.role : 'viewer');
            this.appPanel = this.org.members && this.org.members[0].relation && this.org.members[0].relation.appPanel ? this.org.members[0].relation.appPanel : '';
            this.default = this.org.members && this.org.members[0] && this.org.members[0].relation.default ? this.org.members[0].relation.default : false;
        },
        methods: {
            setAppPanel(item) {
              console.log(item);
              fetch(process.env.VUE_APP_CONFIG)
            .then(r => r.json())
            .then(async config => {
              const baseURL = config.sfx.project.webapp.appHost;
              this.appPanel = baseURL + '/' + item.slug;
              this.update('appPanel');
              this.appPanelEditing = false;
            });
            },
            update(field) {
                let member = {};
                member[field] = this[field];
                if(field === 'default') {
                    this.$emit('setDefaultItem', this.org.id);
                }
                else {
                    this.$emit('update', this.org.id, member);
                }

            }
        }
    }
</script>
<style lang="scss" scoped>
    .tag {
        background-color: $tag-background-color;
        display: inline-block;
        padding: 3px 35px 3px 8px;
        margin-right:8px;
        position:relative;
        cursor:pointer;
        word-break: break-all;
        .icon-right {
            position:absolute;
            right:10px;
            top: 15px;
            opacity:0.8;
            -webkit-transition: all 300ms ease;
            transition: all 300ms ease;
            cursor:pointer;
            &:hover {
                opacity: 1;
            }
        }
        .tag-details {
            background-color: darken($tag-background-color, 10%);
            padding:3px;
            width: 100%;
            margin-top:6px;
        }
    }
    .organization-row {
        width: 100%;
        background: rgba(0,0,0,0.3);
        padding: 1px;
    }
    .long-tag {
      margin-right:0;
    }
</style>