var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('two-column-layout',{attrs:{"sub-menu-selector":"librarypanel","back-button-tab-selector":"sfxbackbutton","tab-selector":"assemblytab","id":_vm.$route.params.id}},[_c('template',{slot:"mainContent"},[(
                _vm.$store.getters.getAssemblyPipelineStatus(this.$route.params.id) !==
                    'processing' && !_vm.versionStarted && !_vm.startingVersion /*&& canUpdateHierarchy*/
            )?_c('div',{staticClass:"col-12 col-md-8"},[_c('info-panel',{attrs:{"info-panel-header":"UpdateSfxData","collapsible":true,"initially-collapsed":true,"preview-id":"sfxImport"}},[_c('div',{staticClass:"col-12 col-md-8 mb-3 bg-beige p-3",attrs:{"slot":"info"},slot:"info"},[(_vm.hasNoVersions && !_vm.versionStarted && !_vm.startingVersion )?_c('div',[_c('div',{staticClass:"col-12 col-md-8 form-error"},[_vm._v(" "+_vm._s(_vm.$t('makeAVersionFirst'))+" ")]),_c('Button',{staticClass:"mr-1",on:{"click":_vm.createVersion}},[_vm._v(_vm._s(_vm.$t('createNewType', {type: 'version'})))]),_c('br')],1):(_vm.startingVersion)?_c('loading-panel',{attrs:{"time-in-seconds":0,"message":"pleaseWait"}}):(_vm.versionStarted)?_c('div',[_c('div',{staticClass:"col-12 col-md-8 bg-dark p-3"},[_vm._v(" "+_vm._s(_vm.$t('versioningProcessStarted'))+" ")])]):_c('div',[_vm._v(" "+_vm._s(_vm.$t('Update3dHint'))),_c('br'),_c('Button',{staticClass:"mt-3",attrs:{"link":'/library/3d-data/' + _vm.$route.params.id +'/update-cad'}},[_vm._v(_vm._s(_vm.$t('Update3dData')))])],1)],1)])],1):_vm._e(),(
                _vm.$store.getters.getAssemblyPipelineStatus(_vm.$route.params.id) ===
                    'finished' && _vm.canUpdateAssemblyMetaData
            )?_c('div',{staticClass:"col-12 col-md-8"},[_c('info-panel',{attrs:{"info-panel-header":"UpdateMetaData","collapsible":true,"initially-collapsed":true,"preview-id":"fileProcessing"}},[_c('div',{staticClass:"row p-2",attrs:{"slot":"info"},slot:"info"},[_c('div',{staticClass:"col-12 col-md-6"}),_c('div',{staticClass:"w-100"}),_c('upload-drop-zone',{ref:"metaDataUpload",class:[
                            !_vm.metaDataColumns ? 'col-12 col-md-8' : 'col-6 col-md-6',
                            'mt-3' ],attrs:{"form-id":"assemblyUpdate","image-editable":true,"asset-id":_vm.$route.params.id,"asset-type":"assembly","target-type":"Asset","auto-update-preview":false,"accepted-file-types":Object.values(_vm.metaDataFileFormats)
                                .map(function (item) {
                                    return '.' + item.format;
                                })
                                .join(' '),"auto-upload":false,"removable":false,"downloadable":false,"is-loading":_vm.isLoading},on:{"data":_vm.setData,"fileReceived":_vm.fileReceived,"filesInQueue":_vm.filesInQueue,"queueEmpty":_vm.queueEmpty}},[_c('div',{staticClass:"mt-3"},[_vm._v(" "+_vm._s(_vm.$t("uploadMetaFile"))+" ")])]),(_vm.metaDataColumns.length)?_c('div',{staticClass:"col-12 col-md-8"},[_c('label',{staticClass:"lighter mt-2"},[_vm._v(_vm._s(_vm.$t("PostProcessingForMeta")))]),_c('br'),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.vhubPreProcessors),expression:"vhubPreProcessors"}],staticClass:"custom-select dark",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.vhubPreProcessors=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.vhubPreProcessorsOptions),function(key,value){return _c('option',{key:key,domProps:{"value":value}},[_vm._v(" "+_vm._s(_vm.$t(key.name))+" ")])}),0),_c('assembly-config',{ref:"AssemblyConfig",attrs:{"meta-data-columns":_vm.metaDataColumns,"organization-id":_vm.$store.getters.getAssemblyOrganization(
                                    _vm.$route.params.id
                                ),"teams":_vm.$store.getters.getAssemblyTeams(_vm.$route.params.id),"permission":"writeMetaSet"},on:{"setConfig":_vm.setMetaConfig}})],1):_vm._e(),(Object.keys(_vm.metaConfig).length)?_c('div',{staticClass:"col-12 col-md-6"},[_c('label',[_vm._v(_vm._s(_vm.$t("Modus"))+" ")]),_c('info-helper',{staticClass:"ml-1",attrs:{"text":"MetaUpdateMode"}}),_c('tile-button',{ref:"tiles",attrs:{"initial-active-tiles":_vm.metaSetUpdateModes[0],"tiles":_vm.metaSetUpdateModes,"values":_vm.metaSetUpdateModes},on:{"trigger":_vm.selectMode}})],1):_vm._e(),(Object.keys(_vm.metaConfig).length)?_c('div',{staticClass:"form-section col-12"},[_c('div',{staticClass:"form-item mt-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sendMails),expression:"sendMails"}],attrs:{"type":"checkbox","id":"sendMails","name":"sendMails"},domProps:{"checked":Array.isArray(_vm.sendMails)?_vm._i(_vm.sendMails,null)>-1:(_vm.sendMails)},on:{"change":function($event){var $$a=_vm.sendMails,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.sendMails=$$a.concat([$$v]))}else{$$i>-1&&(_vm.sendMails=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.sendMails=$$c}}}}),_c('label',{staticClass:"ml-1",attrs:{"for":"sendMails"}},[_vm._v(_vm._s(_vm.$t("GetEmailNotification")))]),_c('info-helper',{staticClass:"ml-1",attrs:{"text":"Emails werden nur beim Extract-Prozess versendet, nicht beim Reimport"}})],1),_c('label',[_vm._v(_vm._s(_vm.$t("email")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],class:[
                                'form-text w-100',
                                _vm.$v.email.$dirty && _vm.$v.email.$error
                                    ? 'form-group--error'
                                    : '' ],attrs:{"type":"text"},domProps:{"value":(_vm.email)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.email=$event.target.value},function($event){return _vm.delayTouch(_vm.$v.email)}]}}),(_vm.$v.email.$dirty && !_vm.$v.email.email)?_c('div',{staticClass:"form-error mb-2"},[_vm._v(" "+_vm._s(_vm.$t("errors.mustBeAValidEmailAdress"))+" ")]):_vm._e(),_c('Button',{staticClass:"mt-3 icon-rotate-animation make-longer-animation",attrs:{"deactivated":!this.$v.email.email || _vm.partIdentifierColumnMissing},on:{"click":_vm.startMetaSetUpdate}},[_vm._v(_vm._s(_vm.$t("XMLUpdateStart"))+" "),_c('icon',{attrs:{"type":"cube"}})],1)],1):_vm._e()],1)])],1):_vm._e(),(
                _vm.$store.getters.getAssemblyPipelineStatus(_vm.$route.params.id) ===
                'finished'
            )?_c('div',{staticClass:"col-12 col-md-8 meta-field-value"},[_c('info-panel',{attrs:{"info-panel-header":"Apply Material Mapping","collapsible":true,"initially-collapsed":true}},[_c('div',{staticClass:"pl-3",attrs:{"slot":"info"},slot:"info"},[(
                            _vm.$store.getters.getAssemblyOrganization(_vm.$route.params.id)
                        )?_c('apply-material-mapping',{attrs:{"assetId":_vm.$route.params.id}}):_vm._e()],1)])],1):_vm._e(),(
                _vm.$store.getters.getAssemblyPipelineStatus(_vm.$route.params.id) ===
                'processing'
            )?_c('div',{staticClass:"col-12 col-md-8"},[_vm._v(" "+_vm._s(_vm.$t("AssemblyProcessing"))+" ")]):_vm._e(),(_vm.showMessage)?_c('div',{staticClass:"col-12 col-md-8"},[_vm._v(" "+_vm._s(_vm.$t("UpdateProcessStarted"))+" ")]):_vm._e()])],2)}
var staticRenderFns = []

export { render, staticRenderFns }