<template>
    <one-column-sidebar-left
        sub-menu-selector="librarypanel"
        back-button-tab-selector="sfxbackbutton"
        :tab-selector="$store.getters.getSfxdataType($route.params.id) + 'tab'"
        :id="$route.params.id"
    >
<info-panel slot="sidebarLeft"
            :id="$route.params.id"
            preview-id="sfxedit"
            upload-type="Asset"
            :created="$store.getters.getSfxdataCreationDate($route.params.id)"
            :last-change="$store.getters.getSfxdataChangeDate($route.params.id)"
            :organization="$store.getters.getSfxdataOrganization($route.params.id)"
            :image-editable="true"
            :preview-uri="$store.getters.getSfxdataPreviewUri($route.params.id)"

/>
        <info-panel
                slot="sidebarLeft"
                :id="$route.params.id"
                :tags="$store.getters.getSfxdataTags($route.params.id)"
                :taggable="true"
                @removeTag="removeTag"
                @addTag="addTag"
                :key="'infopanel2'"
                preview-id="sfxedit"
                :limit-tags-to-organization="$store.getters.getSfxdataOrganization($route.params.id)"
        />
      <team-attacher
          v-if="$store.getters.getSfxdataOrganization($route.params.id)"
          class="mt-3"
          slot="sidebarLeft"
          :id="$route.params.id"
          :teams="teams"
          storeName="Asset"
          :limit-by-organization-id="$store.getters.getSfxdataOrganization($route.params.id)"
          @added="loadAssetTeams"
          @removed="loadAssetTeams"
      />

        <div slot="mainContent">
            <content-title-block
                    v-if="$store.getters.getSfxdataName(id)"
                    :value="$store.getters.getSfxdataName(id)"
                    :id="$route.params.id"
                    store-name="Sfxdata"
                    :organization-id="$store.getters.getSfxdataOrganization($route.params.id)"
                    :icon="$store.getters.getSfxdataType($route.params.id)"
            />
            <content-description-block
                    :value="$store.getters.getSfxdataDescription(id)"
                    :id="$route.params.id"
                    store-name="Sfxdata"
            />
           <div v-if="$store.getters.isSuperAdmin" class="row">
            <state-setter
                v-if="$store.getters.getSfxdataState($route.params.id)"
              class="col-12 col-md-4 mb-2 mt-2"
              :target-id="$route.params.id"
              store-name="Sfxdata"
              :given-state="$store.getters.getSfxdataState($route.params.id)"
            />
          </div>

            <div class="row">
              <div class="col-12 col-md-6 no-gutters">
                <info-panel
                  :collapsible="true"
                  info-panel-header="files"
                  preview-id="fileview"
                >
                  <div slot="info">
                    <file-list
                  :target-id="$route.params.id"
                  store-name="Asset"
                  :file-types="['fbx', 'vab']"
                />
                  </div>
                </info-panel>
              </div>
              <div class="w-100"/>
            <div class="col-12 col-md-6 no-gutters">
              <info-panel
                  preview-id="metasetattacher"
                  :collapsible="true"
                  :initially-collapsed="true"
                  info-panel-header="sfx edit"
                  v-if="$store.getters.isSuperAdmin"
              >
                <meta-set-attacher
                    slot="info"
                    :target-id="$route.params.id"
                    store-name="Asset"
                    @added="loadSfx"
                    @removed="loadSfx"
                    :given-meta-sets="$store.getters.getSfxdataMetaSets(id)"
                />
              </info-panel>
            </div>
            <meta-panel
                class="col-12 col-md-6"
                type="sfxdata" :key="item.id" v-for="(item) in $store.getters.getSfxdataMetaSets(id)"
                        :title="item.description" :fields="item.metaFields" icon-left="folder"
                        store-name="Sfxdata"
                        :organization-id="$store.getters.getSfxdataOrganization(id)"
                 @updated="loadSfx"
            />
          </div>

    </div>
  </one-column-sidebar-left>
</template>

<script>
    import InfoPanel from "../components/InfoPanel";
    import OneColumnSidebarLeft from "../layouts/OneColumnSidebarLeft";
    import ContentTitleBlock from "../components/ContentTitleBlock";
    import ContentDescriptionBlock from "../components/ContentDescriptionBlock";
    import MetaPanel from "../components/widgets/meta/MetaPanel";
    import MetaSetAttacher from "@/components/widgets/meta/MetaSetAttacher";
    import TeamAttacher from "@/components/organizations/TeamAttacher";
    import StateSetter from "@/components/StateSetter";
    import FileList from "@/components/files/FileList";

    export default {
        name: 'SFXEdit',
        components: {
          InfoPanel,
          MetaPanel,
          OneColumnSidebarLeft,
          ContentTitleBlock,
          ContentDescriptionBlock,
          MetaSetAttacher,
          TeamAttacher,
          StateSetter,
          FileList
        },
        data() {
            return {
                id: '',
                teams: [],
            };
        },
        beforeMount() {
           this.loadSfx();
           this.loadAssetTeams();
            this.id = this.$route.params.id;
        },
        methods: {
          loadAssetTeams() {
              this.$store.dispatch('clientLoadAssetTeams', {
                id: this.$route.params.id
              }).then(data => {
                this.teams = data;
              });
          },
        loadSfx() {
              this.$store.dispatch('loadSfxdata', {
                id: this.$route.params.id,
                include: 'tags,metaSets'
              }).catch(e => {
                if(e.status === 401) {
                  this.$router.push('/access-denied');
                }
              });
            },
    removeTag: function(tag) {
      this.$store.dispatch('removeSfxdataTag', {
        id: this.$route.params.id,
        args: [tag.id],
      });
    },
    addTag: function(tag) {
      this.$store.dispatch('addSfxdataTag', {
        id: this.$route.params.id,
        args: [tag.id],
      });
    },
  },
}
</script>