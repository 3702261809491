<template>
    <div>
        <div class="inline-edit-label">
            {{ $t(label) }}<span v-if="mandatory">*</span>
            <div v-if="editable" class="edit-trigger ml-1" @click.prevent="$emit('edit')">
                <icon :type="'edit'"/>
            </div>
        </div>
        <div v-if="isPassword">
            <p>*****</p>
        </div>
        <div v-else-if="!value && makePlaceholderSpace"><p/></div>
        <div v-else-if="showValue">
            <p>{{ value ? value : '-' }}</p>
        </div>
    </div>
</template>

<script>
    import Icon from "./Icon";
    export default {
        name: "EditLabelWithContent",
        components: {
            Icon
        },
        props: {
            value: {default: ''},
            label: {type: String, required: true},
            isPassword: {type: Boolean, default: false},
            editable: {type: Boolean, default: true},
            makePlaceholderSpace: {type: Boolean, default: true},
            showValue: {type: Boolean, default: true},
            mandatory: {type: Boolean, deafult: false}
        },
    }
</script>