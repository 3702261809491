<template>
    <div>
        <div class="inline-edit-label mb-1">
            {{ $t('description') }}
            <div v-if="!descEdit && editable" class="edit-trigger" @click.prevent="editDesc">
                <icon :type="'edit'"/>
            </div>
        </div>
        <div class="mb-3" v-if="!descEdit">
            <p>{{ value }}</p>
        </div>
        <div v-else class="mb-3">
            <!--@keydown.enter="updateContent"-->
            <textarea ref="description" class="form-text"
                      @keydown.esc="cancelEditing"
                      v-model="updateValue"
                      @input="delayTouch($v.updateValue)"
            />
            <div class="form-error" v-if="$v.updateValue.$dirty && !$v.updateValue.maxLength">{{ $t('errors.atMostCharacters', {num: 128}) }}</div>
            <div class="edit-trigger mr-2" @click="cancelEditing">
                <icon :type="'times'"/>
            </div>
            <div v-if="enableSaving" class="edit-trigger" @click="updateContent">
                <icon :type="'save'"/>
            </div>
        </div>
    </div>
</template>

<script>
    import Icon from "./Icon";
    import {maxLength} from 'vuelidate/lib/validators';
    import VuelidateMixin from "@/components/mixins/VuelidateMixin.js";

    export default {
        name: "ContentTitleBlock",
        components: {
            Icon,
        },
        props: {
            value: {type: String, default: null},
            id: {type: String, required: true},
            storeName: {type: String, required: true},
            editable: {type: Boolean, default: true}
        },
        mixins: [VuelidateMixin],
        data() {
            return {
                descEdit: false,
                updateValue: null,
                enableSaving: true,
            };
        },
        validations: {
            updateValue: {
                maxLength: maxLength(1024),
            }
        },
        watch: {
            value(val) {
                if (val) {
                    this.updateValue = this.value;
                }
            },
            '$v.$invalid': function () {
                this.enableSaving = !this.$v.$invalid;
                this.$emit('enableSaving', !this.$v.$invalid);
            },
        },
        beforeMount() {
            this.updateValue = this.value;
        },
        methods: {
            editDesc: function () {
                this.descEdit = true;
            },
            cancelEditing: function () {
                this.descEdit = false;
                this.updateValue = this.value;
            },
            updateContent() {
                if (this.enableSaving) {
                    let args = {
                        id: this.id,
                        description: this.updateValue,
                    };
                    this.$store.dispatch('update' + this.storeName, args).then(() => {
                       this.$emit('updated');
                    });
                    this.descEdit = false;
                }
            }
        },
    }
</script>

<style scoped>

</style>