<template>
    <div>
        <div class="mb-3">
        {{ customMessage ? $t(customMessage) : $t('deletePromptQuestion') }}
          <slot/>
        </div>
        <div class="w-100"/>
        <Button @click="$emit('confirm')">{{ $t('yes') }}</Button><Button @click="$emit('abort')">{{ $t('abort') }}</Button>
    </div>
</template>

<script>
    import Button from "./Button";

    export default {
        name: "DeletePrompt",
        components: {
            Button,
        },
        props: {
            customMessage: {type: String, default: ''}
        }
    }
</script>

<style scoped>
    .button {
        margin-right: 15px;
    }
</style>