<template lang="html">
    <section class="user-form mb-5">
<form id="user-form">
<div v-if="error" class="form-error">{{ error }}</div>
  <label>Username</label>
  <div class="form-group form-item with-prepend-icon">
    <div class="input-prepend-icon no-border no-background" ><icon type="user" /></div>
      <input
          ref="username"
          class="form-text"
          type="text"
          label="username"
          v-model="username"
          name="username"
          :id="'username'"

      >
  </div>
  <div class="w-100" />
  <label>Password</label>
  <div class="form-group form-item with-prepend-icon">
    <div class="input-prepend-icon no-border no-background" ><icon type="lock" /></div>
                <input
                        ref="password"
                        class="form-text"
                        type="password"
                        label="password"
                        name="password"
                        :id="'password'"
                        @keydown.enter="doLoginForReal"
                >
  </div>
  <!--Do not remove login-button class, necessary for testing -->
    <Button class="highlight login-button" @click.native="doLoginForReal">{{ $t('SignIn') }}<loading-spinner class="white float-right ml-1" v-if="loading" /></Button>
    </form>
    </section>
</template>

<script>
    import Button from "@/components/forms/Button";
    import Icon from "@/components/Icon";
    import LoadingSpinner from "@/components/LoadingSpinner";

    export default  {
        name: 'LoginFormVHUB',
        components: {
            Button,
            Icon,
            LoadingSpinner
        },
        data() {
            return {
                username: null,
                password: null,
                error: null,
                loading: false,
            }
        },
        methods: {
            async doLoginForReal() {
              this.error = '';
              const username = this.$refs.username.value;
              const password = this.$refs.password.value;
              await this.$store.dispatch('idpLogin', {username, password})
                .catch(e => {
                  this.error = this.$t('errors.' + e.message);
                });
            }
        }
    }
</script>

<style lang="scss">
    #user-form {
        width: 200px;
        .form-text {
            width: 100%;
            background-color:$inputcolor;
          &:-webkit-autofill,
          &:-internal-autofill-selected,
          &:-webkit-autofill:hover,
          &:-webkit-autofill:focus,
          &:-webkit-autofill:active {
            background-color:$inputcolor !important;
            -webkit-box-shadow: 0 0 0 30px $inputcolor inset !important;
            color: #fff !important;
            outline:none;
            -webkit-text-fill-color: #fff !important;
          }
          &:focus {
            outline:none;
          }
        }
    }
</style>
