<template>
<div>
  <template v-if="editing">
    <input class="form-text" :key="index" v-for="(row, index) in rows" v-model="rows[index]" type="text" />
    <div class="w-100" />
    <Button class="mr-2" @click="addRow">{{ $t('AddEntry') }}</Button>
    <Button @click="save">{{ $t('save') }}</Button>
    <div>{{ error }}</div>
  </template>
  <template v-else>
    <div :key="index" v-for="(row, index) in rows">
      <span class="lighter">{{ index }}:</span> {{ row }}
    </div>
  </template>
</div>
</template>

<script>
import Button from "@/components/forms/Button";

export default {
  name: "JsonAdder",
  components: {
    Button
  },
  props: {
    value: {type: String, default: ''},
    config: {type: Object, required: true},
    editing: {type: Boolean, default: true}
  },
  data() {
    return {
      rows: [''],
      error: '',
    };
  },
  beforeMount() {
    if(this.value && this.value.length) {
      try {
        this.rows = JSON.parse(this.value);
      } catch {
        this.error = 'malformed json';
      }
    }
  },
  methods: {
    addRow() {
      this.rows.push('');
    },
    save() {
      this.$emit('save', JSON.stringify(this.rows));
    }
  }
}
</script>