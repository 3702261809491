<template>
  <two-column-layout
      :tab-selector="$store.getters.getAppType($route.params.id) + 'tab'"
      :type="$store.getters.getAppType($route.params.id)"
      back-button-tab-selector="appbackbutton"
      :id="$route.params.id"
  >
    <template slot="sidebarLeft">
      <info-panel slot="sidebarLeft"
                  :id="$route.params.id"
                  :preview-uri="$store.getters.getAppPreviewUri($route.params.id)"
                  :image-editable="true"
                  upload-type="Project"
                  :created="$store.getters.getAppCreationDate(id)"
                  :last-change="$store.getters.getAppChangeDate(id)"
                  :downloadable="true"
                  :key="1"
                  :organization="$store.getters.getAppOrganization($route.params.id)"
                  preview-id="appEdit"
      />
      <team-attacher
          v-if="$store.getters.getAppOrganization($route.params.id)"
          class="mt-3"
          slot="sidebarLeft"
          store-name="Project"
          :id="$route.params.id"
          :teams="teams"
          :limit-by-organization-id="$store.getters.getAppOrganization($route.params.id)"
          @added="loadProjectTeams"
          @removed="loadProjectTeams"
      />
      <info-panel
          slot="sidebarLeft"
          :id="$route.params.id"
          :tags="$store.getters.getAppTags($route.params.id)"
          :taggable="true"
          @removeTag="removeTag"
          @addTag="addTag"
          :key="2"
          preview-id="appEdit"
          :limit-tags-to-organization="$store.getters.getAppOrganization($route.params.id)"
      />
    </template>

    <div slot="mainContent">
      <content-title-block
          :value="this.$store.getters.getAppName($route.params.id)"
          :id="this.$route.params.id"
          store-name="App"
          :organization-id="$store.getters.getAppOrganization($route.params.id)"
          :icon="$store.getters.getAppType($route.params.id)"
      />
      <content-description-block
          :value="this.$store.getters.getAppDescription(id)"
          :id="this.$route.params.id"
          store-name="App"
      />
      <content-field-block
          :value="$store.getters.getAppSlug(id)"
          :id="$route.params.id"
          :trim="true"
          store-name="App"
          field-name="slug"
          label="slug"
          input-type="text"
      />

      <div class="row">
        <div class="col-12 col-lg-6 mt-3">
          <info-panel
              preview-id="metasetattacher"
              :collapsible="true"
              :initially-collapsed="true"
              info-panel-header="sfx edit"
              v-if="$store.getters.isSuperAdmin"
          >
            <meta-set-attacher
                :target-id="$route.params.id"
                store-name="Project"
                @added="loadApp"
                @removed="loadApp"
                slot="info"
                :given-meta-sets="$store.getters.getAppMetaSets(id)"
            />
          </info-panel>
        </div>
        <meta-panel
            class="col-12 col-lg-6 col-xl-11 col-xxl-8"
            type="app"
            :key="item.id"
            v-for="(item) in $store.getters.getAppMetaSets(id)"
            :title="item.description"
            :fields="item.metaFields"
            icon-left="folder"
            store-name="App"
            :organization-id="$store.getters.getAppOrganization($route.params.id)"
             @updated="loadApp"
        />
      </div>
      <Button v-if="$store.getters.isSuperAdmin && $store.getters.getAppType($route.params.id) === 'webapp'" @click="publishChanges">{{ $t('Publish changes') }}</Button>
    </div>
    <div slot="mainContentTopRight">
      {{ $t('STATUS') }}<br>
      <toggle-button :value="$store.getters.getAppStatus(id)"
                     color="#DA5E06"
                     :sync="true"
                     :labels="true"
                     @change="updateContent({
                                            id: $route.params.id,
                                            state: $event.value?'a':'i'
                                    })"/>
    </div>
  </two-column-layout>
</template>

<script>
import InfoPanel from "../components/InfoPanel";
import TwoColumnLayout from "../layouts/OneColumnSidebarLeft";
import ContentTitleBlock from "../components/ContentTitleBlock";
import ContentDescriptionBlock from "../components/ContentDescriptionBlock";
import ContentFieldBlock from "../components/ContentFieldBlock";
import MetaPanel from "../components/widgets/meta/MetaPanel";
import Button from "../components/forms/Button";
import MetaSetAttacher from "@/components/widgets/meta/MetaSetAttacher";
import TeamAttacher from "@/components/organizations/TeamAttacher";

export default {
  name: 'AppEdit',
  components: {
    ContentFieldBlock,
    MetaPanel,
    InfoPanel,
    TwoColumnLayout,
    ContentTitleBlock,
    ContentDescriptionBlock,
    Button,
    MetaSetAttacher,
    TeamAttacher,
  },
        data() {
            return {
                id: '',
                slug: '',
                teams: [],
            };
        },
        beforeMount() {
           this.loadApp();
            this.loadProjectTeams();
            this.id = this.$route.params.id;
        },
  methods: {
    loadProjectTeams() {
      this.$store.dispatch('clientLoadProjectTeams', {
        id: this.$route.params.id
      }).then(data => {
        this.teams = data;
      });
    },
    loadApp() {
      this.$store.dispatch('loadApp', {
        id: this.$route.params.id,
        include: 'organization,tags,metaSets'
      }).catch(e => {
        if(e.status === 401) {
          this.$router.push('/access-denied');
        }
      });
    },
    removeTag: function(tag) {
      this.$store.dispatch('removeAppTag', {
        id: this.$route.params.id,
        args: [tag.id],
      });
    },
    addTag: function(tag) {
      this.$store.dispatch('addAppTag', {
        id: this.$route.params.id,
        args: [tag.id],
      });
    },
    updateContent: function(args) {
      this.$store.dispatch('updateApp', args);
    },
    setEditing(fieldName) {
      this.fieldEditing = fieldName;
    },
    isEditing(fieldName) {
      return this.fieldEditing === fieldName;
    },
    publishChanges() {
      this.updateContent({
        id: this.$route.params.id,
        state: 'a'
      });
      return fetch(process.env.VUE_APP_CONFIG)
          .then(r => r.json())
          .then(config => {
            let url = `${config.sfx.project.webapp.appHost}/api/v2/apps/${this.$route.params.id}/publish`;
            const axios = require('axios');
            const authToken = this.$store.getters.authToken;

            return axios.post(url,  JSON.stringify({}), {
              headers: {
                'Authorization': 'Bearer ' + authToken,
                "Content-Type": "application/json"
              }}
            );
          });
    }
  },
}
</script>