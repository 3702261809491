<template>
  <one-column-sidebar-left
      :id="$route.params.id"
      :type="$route.params.type"
      :tab-selector="$route.params.type + 'tab'"
      :back-button-tab-selector="$route.params.type + 'backbutton'"
  >
    <template slot="mainContent">
      <file-browser
        :target-id="$route.params.id"
        :store-name="contentTypes[this.$route.params.type]"
      />
    </template>
  </one-column-sidebar-left>
</template>

<script>
import FileBrowser from "@/components/files/FileBrowser";
import OneColumnSidebarLeft from "@/layouts/OneColumnSidebarLeft";
import {contentTypes} from "@/enum";
export default {
  name: "FileBrowserView",
  components: {
    FileBrowser,
    OneColumnSidebarLeft
  },
  data() {
    return {
      contentTypes: contentTypes
    };
  },
  beforeMount() {
    //console.log(contentTypes[this.$route.params.type]);
  },
}
</script>

<style scoped>

</style>