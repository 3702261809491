<template>
    <div id="language-switch">
        <select class="custom-select"
                @change="setLanguageCookie"
                v-model="$i18n.locale">
            <option v-for="(lang, i) in langs"
                    :key="`Lang${i}`"
                    :value="lang">
{{ lang }}
            </option>
        </select>
    </div>
</template>

<script>
    export default {
        name: 'LanguageSwitcher',
        props: {
            username:
                {
                    type: String,
                    default: 'guest'
                },
            preSetLang: {type: String, default: null}
        },
        data () {
            return {
                langs: [
                    'en',
                    'de'
                ]
            }
        },
        beforeMount() {
            //checking for delicious cookies...
            const lang = this.$cookies.get('lang');
            if (lang && lang !== '') {
                this.$i18n.locale = lang;
            }
            if(this.preSetLang) {
              this.$i18n.locale = this.preSetLang;
            }
        },
        methods: {
            setLanguageCookie() {
                this.$cookies.set('lang', this.$i18n.locale);
            }
        },
    }
</script>

<style lang="scss" scoped>
    #language-switch {
        background-color: rgba(0,0,0,0.3);
        display:inline-block;
        .custom-select {
            cursor:pointer;
            background-color: transparent;
            color: #fff;
            height: $user-nav-height;
            padding-left:8px;
            &:focus {
                box-shadow:none;
            }
        }
    }
</style>
