<template>
    <main-table-layout
      subMenuSelector="adminpanel"
    >
        <div slot="buttonsRight"/>
        <div slot="mainContent">
          <div class="bg-dark p-2 mb-1 d-flex" :key="index + forceReRenderKey" v-for="(org, index) in organizations">
              <div class="bg-beige col-2">{{ org.name }}</div>
              <div class="year-section p-2">
                <storage-stats :organization-id="org.id" />
              </div>
              <div class="year-section p-2">
                <component-stats :organization-id="org.id" />
              </div>
              <log-summary :organization-id="org.id" :auto-load="true" />
           </div>
        </div>

    </main-table-layout>
</template>

<script>
   // import ContentFilter from "../components/widgets/filter/ContentFilter";
    import MainTableLayout from "../layouts/MainTableLayout";
    import DateMixin from "@/components/mixins/DateMixin.js";
    //import ViewSwitcher from "../components/widgets/ViewSwitcher";
    //import Pagination from "../components/Pagination";
    //import LogViewer from "@/components/LogViewer";
    import StorageStats from "@/components/StorageStats";
    import LogSummary from "@/components/LogSummary";
    import ComponentStats from "@/components/ComponentStats";
    export default {
        name: "VMLogs",
        components: {
            //ContentFilter,
            MainTableLayout,
            LogSummary,
            ComponentStats,
            //ViewSwitcher,
            //Pagination,
           // LogViewer,
           StorageStats
        },
        mixins: [DateMixin],
        data() {
            return {
               organizations: [],
               logs: {},
               projects: {},
               forceReRenderKey: 0,
               showDetail: false,
               storageUsage: {},
               loadingStorageUsage: false,
            };
        },
        beforeMount() {
          this.$store.dispatch('clientLoadOrganizations').then(async data => {
            this.organizations = data;
            this.forceReRenderKey++;
          });
        },
    }
</script>

<style lang="scss" scoped>
  .year {
    font-size: 1.3rem;
    font-weight: bold;
  }
  .year-section {
    color: #000;
    background-color: #efefef;
  }
</style>
