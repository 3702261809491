var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main-table-layout',{attrs:{"sub-menu-selector":"librarypanel"}},[_c('content-filter',{attrs:{"slot":"filter","store-name":"Materialmapper","id":"materialmapperList","type-filter-items":['tbl', 'obj']},on:{"filter":_vm.filter},slot:"filter"}),_c('view-switcher',{attrs:{"slot":"mainContentButtons","id":"mainContent","table":true,"thumbnails":true},slot:"mainContentButtons"}),_c('pagination',{ref:"pagination",attrs:{"slot":"mainContentButtons","limit":_vm.paginationLimit,"total":_vm.$store.getters.getDatasetListOptions(_vm.listName, 'pagination_items'),"id":"materialmappertablepage"},on:{"setActive":_vm.loadPaginatedData},slot:"mainContentButtons"}),_c('Button',{attrs:{"slot":"mainContentButtons","permission":"createDataSet","type":"action-button","link":"/library/materialmappers/add","icon":"plus"},slot:"mainContentButtons"},[_vm._v(" "+_vm._s(_vm.$t("createNewMaterialMapper"))+" ")]),_c('Button',{staticClass:"icon-button",attrs:{"slot":"mainContent","type":"reload","icon":"redo"},on:{"click":function($event){_vm.loadPaginatedData(0);
            _vm.$refs.pagination.setActive(0);}},slot:"mainContent"}),_c('div',{attrs:{"slot":"mainContent"},slot:"mainContent"},[(
                _vm.$store.getters.getViewSwitcher(this.$route.name, 'mainContent') ===
                'table'
            )?_c('table-widget',{attrs:{"id":"appsDefaultTable","fields":_vm.fields,"data":_vm.$store.getters.getMaterialmappersByListName(_vm.listName),"title-as-links":true,"nav-by-clicking-row":true,"deletable":true,"show-organization":true,"edit-permission":"writeDataSet","delete-permission":"deleteDataSet","use-team-permission":true,"loading":_vm.loading},on:{"sort":_vm.sort,"deleteRow":_vm.deleteRow}}):_vm._e(),(
                this.$store.getters.getViewSwitcher(
                    this.$route.name,
                    'mainContent'
                ) === 'thumbnails'
            )?_c('thumbnails',{attrs:{"id":"appsDefaultTable","fields":_vm.thumbnailFields,"data":_vm.$store.getters.getMaterialmappersByListName(_vm.listName),"title-as-links":true,"deletable":true,"nav-by-clicking-row":true,"edit-permission":"writeDataSet","delete-permission":"deleteDataSet","use-team-permission":true,"loading":_vm.loading},on:{"sort":_vm.sort,"deleteRow":_vm.deleteRow}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }