<template>
    <main-table-layout
        sub-menu-selector="librarypanel"
    >
        <div slot="buttonsRight"/>
        <content-filter
                slot="filter"
                id="sceneList"
                @filter="filter"
                store-name="Scene"
        />
        <view-switcher
                slot="mainContentButtons"
                :id="'mainContent'"
                :table="true"
                :thumbnails="true"
        />
        <pagination
                slot="mainContentButtons"
                :limit="paginationLimit"
                :total="$store.getters.getScenesListsOptions('sceneList', 'pagination_items')"
                :id="'scenetablepage'"
                @setActive="loadPaginatedData"
                ref="pagination"
        />
        <Button
            :permission="$store.getters.isSFXMember ? 'create' : 'createScene'"
                type="action-button" slot="mainContentButtons" link="/library/scenes/add" icon="plus">
              {{ $t('createNewType', {type: $t('type.scene')}) }}
        </Button>
      <Button
           slot="mainContent"
           @click="loadPaginatedData(0); $refs.pagination.setActive(0)"
           type="reload"
           icon="redo"
           class="icon-button"
       />
        <table-widget
                v-if="$store.getters.getViewSwitcher($route.name, 'mainContent') === 'table'"
                slot="mainContent"
                :id="listName"
                :nav-by-clicking-row="true"
                :deletable="true"
                @deleteRow="deleteRow"
                :show-tags="true"
                :show-organization="true"
                :edit-permission="$store.getters.isSFXMember ? 'write' : 'editScene'"
                :delete-permission="$store.getters.isSFXMember ? 'delete' : 'deleteScene'"
                :fields="tableFields"
                :data="$store.getters.getScenesByListName('sceneList')"
                :title-as-links="true"
                @sort="sort"
                 :loading="loading"
        />
        <thumbnails
                v-if="$store.getters.getViewSwitcher($route.name, 'mainContent') === 'thumbnails'"
                slot="mainContent"
                :id="listName"
                :deletable="true"
                :nav-by-clicking-row="true"
                edit-permission="editScene"
                delete-permission="deleteScene"
                :fields="thumbnailFields"
                :data="$store.getters.getScenesByListName('sceneList')"
                :title-as-links="true"
                @sort="sort"
                 :loading="loading"
        />
</main-table-layout>
</template>

<script>
    import Button from "../components/forms/Button";
    import TableWidget from "../components/widgets/TableWidget";
    import ContentFilter from "../components/widgets/filter/ContentFilter";
    import MainTableLayout from "../layouts/MainTableLayout";
    import ViewSwitcher from "../components/widgets/ViewSwitcher";
    import Thumbnails from "../components/widgets/ThumbnailView";
    import Pagination from "../components/Pagination";
    import FileTypeMixin from '@/components/files/FileTypeMixin.js';

    export default {
        name: "Scene",
        components: {
            Button,
            TableWidget,
            ContentFilter,
            MainTableLayout,
            ViewSwitcher,
            Thumbnails,
            Pagination,
        },
        mixins: [
            FileTypeMixin
        ],
        data() {
            return {
                loading: true,
                paginationLimit: 15,
                queryRelevantComponents: 2, //pagination and filter
                queryRelevantInits: 0,
                listName: 'scenedefaultTable',
                thumbnailFields: [

                    {
                        name: '',
                        id:'',
                        type: 'image',
                    },
                    {
                        name: 'Organization',
                        id:'organization',
                        type: 'organization',
                        sortable: true
                    },
                    {
                        name: 'Last Update',
                        id:'updatedAt',
                        type: 'date',
                        sortable: true
                    },
                    {
                        name: 'Name',
                        id:'name',
                        type: 'string',
                        sortable: true
                    },
                    {
                      name: 'Teams',
                      id: 'teams',
                      type: 'teams',
                    },

                    {
                        name: 'Tags',
                        id:'tags',
                        type: 'tags',
                    },
                    {
                        name: 'Edit',
                        type: 'editButton'
                    },
                ],
                tableFields: [
                    {
                        name: '',
                        id:'',
                        type: 'image',
                    },
                    {
                        name: 'Name',
                        id:'name',
                        type: 'string',
                        sortable: true
                    },
                    {
                      name: 'Teams',
                      id: 'teams',
                      type: 'teams',
                    },
                    {
                        name: 'Last Update',
                        id:'updatedAt',
                        type: 'date',
                        sortable: true
                    },
                    {
                        name: 'Edit',
                        type: 'editButton'
                    },
                ],
            }
        },
        watch: {
            queryRelevantInits: function(newval) {
                if(newval === this.queryRelevantComponents) {
                    this.loadPaginatedData(0);
                }
            }
        },
        methods: {
            loadPaginatedData: function(offset, initial) {
                this.loading = true;
                let args = {
                    add: {
                        paging: {
                            [this.listName]: true,
                        },
                        offset: {
                            [this.listName]: offset,
                        },
                        limit: {
                            [this.listName]: this.paginationLimit,
                        },
                        include: {
                            [this.listName]: 'tags',
                        },
                        sort: {
                          [this.listName]: this.sorting,
                        }
                    }
                };
                if(initial) {
                    this.queryRelevantInits++;
                    this.$store.dispatch('registerScenesQueryParams', args);
                }
                else {
                    this.$store.dispatch('loadScenes', args).then(() => {
                      this.loading = false;
                    })
                }
            },
            deleteRow: function(id) {
                this.$store.dispatch('deleteScene', {
                    args: [id]
                }).then(() => {
                  this.loadPaginatedData(0);
                });
            },
            filter: function(args, initial) {
                if(initial) {
                    this.queryRelevantInits++;
                    this.$store.dispatch('registerScenesQueryParams', args);
                }
                else {
                  this.loading = true;
                    this.$store.dispatch('loadScenes', args).then(() => {
                      this.loading = false;
                    })
                }
            },
            sort(args) {
              const {fieldname, direction} = args;
              const sortString = direction + fieldname;
              if(this.sorting !== sortString) {
                this.sorting = sortString;
              }
              this.loadPaginatedData(0);
            }
        }
    }
</script>
