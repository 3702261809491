import Vue from 'vue'
import i18n from "../translation";

const state = {
    organizationLists: {
        organizationList: {},
    },
    organizationListOptions: {},
    defaultQueryParams: {
        sort: {
            default: '-updatedAt',
        }
    },
    queryParams: {
        organizationList: {
            limit: {
                default: '50',
            },
            sort: {
                default: '-updatedAt',
            },
        }
    },
    combinedQueryParams: {},
    /**
     * Used keys so far:
     * last_inserted
     * */
    organizationTmp: {
    },
    globalOrganizations: {}
};
const getters = {
    getOrganizationQueryParams: (state) => (listName) => {
        return state.combinedQueryParams[listName];
    },
    getOrganizationListOptions: (state) => (listName, option = '') => {
        let optionList = 'organizationListOptions';
        if(option === 'preview') {
            return state[optionList][listName] && state[optionList][listName].previewPath ? Object.keys(state[optionList][listName].previewPath)[0] : '';
        }
        else if(option === 'fbxPreview') {
            return state[optionList][listName] && state[optionList][listName].fbxPreview ? Object.keys(state[optionList][listName].fbxPreview)[0] : '';
        }
        else if(option === 'pagination_items') {
            return state[optionList][listName] && state[optionList][listName].pagination_items ? parseInt(Object.keys(state[optionList][listName].pagination_items)[0]) : 0;
        }
        return state[optionList][listName] ? state[optionList][listName] : {};
    },
    getOrganizationName: (state) => (id) => {
        return state.globalOrganizations[id] && state.globalOrganizations[id].name ? state.globalOrganizations[id].name : '';
    },
    getOrganizationById: (state) => (id) => {
        return state.globalOrganizations[id] ? state.globalOrganizations[id] : {};
    },
    getOrganizationDisplayName: (state) => (id) => {
        return state.globalOrganizations[id] && state.globalOrganizations[id].displayName ? state.globalOrganizations[id].displayName : '';
    },
    getOrganizationEmail: (state) => (id, listName = 'organizationList') => {
        return state.organizationLists[listName] && state.organizationLists[listName][id] && state.organizationLists[listName][id].email ? state.organizationLists[listName][id].email : '';
    },
    getOrganizationBillingEmail: (state) => (id, listName = 'organizationList') => {
        return state.organizationLists[listName] && state.organizationLists[listName][id] && state.organizationLists[listName][id].billingEmail ? state.organizationLists[listName][id].billingEmail : '';
    },
    getOrganizationOwner: (state) => (id, listName = 'organizationList') => {
        return state.organizationLists[listName] && state.organizationLists[listName][id] && state.organizationLists[listName][id].ownerId ? state.organizationLists[listName][id].ownerId : '';
    },
    getOrganizationGroup: (state) => (id, listName = 'organizationList') => {
        return state.organizationLists[listName] && state.organizationLists[listName][id] && state.organizationLists[listName][id].groupRootId ? state.organizationLists[listName][id].groupRootId : '';
    },
    getOrganizationURL: (state) => (id, listName = 'organizationList') => {
        return state.organizationLists[listName] && state.organizationLists[listName][id] && state.organizationLists[listName][id].url ? state.organizationLists[listName][id].url : '';
    },
    getOrganizationAppPanel: (state) => (id, listName = 'organizationList') => {
        return state.organizationLists[listName] && state.organizationLists[listName][id] && state.organizationLists[listName][id].appPanel ? state.organizationLists[listName][id].appPanel : '';
    },
    getOrganizationVisibility: (state) => (id, listName = 'organizationList') => {
        return state.organizationLists[listName] && state.organizationLists[listName][id] && state.organizationLists[listName][id].visibility ? state.organizationLists[listName][id].visibility : '';
    },
    getOrganization: (state) => (idOrArray, listName = 'organizationList') => {
        if(Array.isArray(idOrArray)) {
            if(state.organizationLists[listName]) {
                return Object.values(listName === 'globalOrganizations' ? state.globalOrganizations : state.organizationLists[listName]).reduce((acc, item) => {
                    if(idOrArray.includes(item.id)) {
                        acc.push(item);
                    }
                    return acc;
                }, []);
            }
        }
        else {
            return state.organizationLists[listName] && state.organizationLists[listName][idOrArray] ? state.organizationLists[listName][idOrArray] : '';
        }
    },
    getVisibleOrganizations() {
        let orgs = state.organizationLists.organizationList;
        let filteredOrgs = Object.keys(orgs).filter(key => {
            return orgs[key].visibility > 0;
        }).reduce((obj, key) => {
            obj[key] = orgs[key];
            return obj;
        }, {});
        return filteredOrgs;
    },
    getOrganizationsByVisibility: (state, rootGetters) => (visibilities, listName = 'organizationList') => {
        let orgs = state.organizationLists[listName] ? state.organizationLists[listName] : [];
        let filteredOrgs = Object.keys(orgs).filter(key => {
            return visibilities.includes(orgs[key].visibility);
        }).reduce((obj, key) => {
            obj[key] = orgs[key];
            return obj;
        }, {});
        if(rootGetters.getCurrentUserPrivateOrg) {
            filteredOrgs[rootGetters.getCurrentUserPrivateOrg] = rootGetters.getOrganizationById(rootGetters.getCurrentUserPrivateOrg);
        }
        return filteredOrgs;
    },
    getVisibleOrganizationsByListName: (state) => (listName = 'organizationList') => {
        let orgs = state.organizationLists[listName] ? state.organizationLists[listName] : [];
        let filteredOrgs = Object.keys(orgs).filter(key => {
            return orgs[key].visibility > 0;
        }).reduce((obj, key) => {
            obj[key] = orgs[key];
            return obj;
        }, {});
        return filteredOrgs;
    },
    getOrganizationsByListName: (state) => (listName = 'organizationList') => {
        return state.organizationLists[listName] ? state.organizationLists[listName] : [];
    },
    getOrganizations() {
        if(state.globalOrganizations) {
            return state.globalOrganizations;
        }
        return state.organizationLists.organizationList;
    },
    getOrganizationTags: (state) => (id, listName = 'organizationList') => {
        return state.organizationLists[listName][id] && state.organizationLists[listName][id].tags ? state.organizationLists[listName][id].tags : [];
    },
    getOrganizationDescription: (state) => (id, listName = 'organizationList') => {
        return state.organizationLists[listName][id] && state.organizationLists[listName][id].description ? state.organizationLists[listName][id].description : '';
    },
    getOrganizationChangeDate: (state, rootGetters) => (id, listName = 'organizationList') => {
        return state.organizationLists[listName][id] && state.organizationLists[listName][id].updatedAt ? rootGetters['convertDate'](state.organizationLists[listName][id].createdAt) : '';
    },
    getOrganizationCreationDate: (state, rootGetters) => (id, listName = 'organizationList') => {
        return state.organizationLists[listName][id] && state.organizationLists[listName][id].createdAt ? rootGetters['convertDate'](state.organizationLists[listName][id].createdAt) : '';
    },
    getOrganizationPreviewUri: (state) => (id, listName = 'organizationList') => {
        return state.organizationLists[listName][id] && state.organizationLists[listName][id].previewUri ? state.organizationLists[listName][id].previewUri : '';
    },
    getOrganizationMetaSets: (state) => (id, listName = 'organizationList') => {
        return state.organizationLists[listName][id] && state.organizationLists[listName][id].metaSets ? state.organizationLists[listName][id].metaSets : {};
    },
};
const mutations = {
    setOrganizationTmpMutation(state, data) {
        if(state.organizationTmp[data.key]) {
            Vue.delete(state.organizationTmp, data.key);
        }
        Vue.set(state.organizationTmp, data.key, data.value);
    },
    loadOrganizationsMutation(state, data) {
        let listName = data.listName ? data.listName : 'organizationList';
        let orgs = data.orgs;
        Vue.set(state.organizationLists, listName, {});
        for (let i = 0; i < orgs.length; i++) {
            let organization = orgs[i];
            if(listName === 'globalOrganizations') {
                Vue.set(state.globalOrganizations, organization.id, organization);
            }
            else {
                Vue.set(state.organizationLists[listName], organization.id, organization);
            }
        }
    },
    loadOrganizationMutation(state, data) {
        let listName = 'organizationList';
        if(data.listName) {
            listName = data.listName;
        }
        if(!state.organizationLists[listName]) {
            Vue.set(state.organizationLists, listName, {});
        }
        Vue.set(state.organizationLists[listName], data.id, data);
    },
    updateOrganizationListOptionMutation(state, args) {
        let optionList = 'organizationListOptions';

        if(!state[optionList][args.listName]) {
            Vue.set(state[optionList], args.listName, {});
        }

        if(args.override) {
            Vue.set(state[optionList][args.listName], args.optionKey, {});
        }

        //reset all other options
        if(args.optionKey === 'selected') {
            Vue.set(state[optionList][args.listName], args.optionKey, {});
        }

        if(!state[optionList][args.listName][args.optionKey]) {
            Vue.set(state[optionList][args.listName], args.optionKey, {});
        }
        if(state[optionList][args.listName][args.optionKey][args.value]) {
            Vue.delete(state[optionList][args.listName][args.optionKey], args.value);
        }
        else {
            Vue.set(state[optionList][args.listName][args.optionKey], args.value, args.value2);
        }
    },
    clearGlobalOrgs: (state) => {
        state.globalOrganizations = {};
    }
};
const actions = {
    registerOrganizationsQueryParams: ({commit}, args) => {
        let listName = 'organizationList';
        if(args.listName) {
            listName = args.listName;
        }
        if(Object.keys(args).length > 0) {
            commit('queryParamsSave', {storeType: 'organization', listName: listName, params: args});
        }
    },
    loadOrganizations: async ({commit, dispatch, getters}, args = {}) => {
        let listName = args.listName ? args.listName : 'organizationList';
        if(Object.keys(args).length > 0) {
            commit('queryParamsSave', {storeType: 'organization', listName: listName, params: args});
        }
        commit('combineQueryParams', {storeType: 'organization', listName: listName});
        return dispatch('clientLoadOrganizations', getters.getOrganizationQueryParams(listName)).then(data => {

            for (let i = 0; i < data.length; i++) {
                data[i]['editLink'] = '/admin/organizations/' + data[i].id + '/general';
            }
            commit('loadOrganizationsMutation', {orgs: data, listName: listName});

            /*added pagination part*/
            if(getters.getOrganizationQueryParams(listName).paging === true && data.headers()) {
                let count = data.headers()['x-count'] ? parseInt(data.headers()['x-count']) : 0;
                commit('updateOrganizationListOptionMutation', {optionKey: 'pagination_items', value: count, listName: listName, override: true});
            }
            return data;
        });
    },
    loadOrganization: async ({commit, dispatch}, args = {}) => {
        let listName = args.listName ? args.listName : 'organizationList';
        return dispatch('clientLoadOrganization', args).then(data => {
            for (let i = 0; i < data.length; i++) {
                data[i]['editLink'] = '/organizations/' + data[i].id + '/general';
            }
            data.listName = listName;
            commit('loadOrganizationMutation', data);
            return data;
        });
    },
    loadUserAdminOrganizations: async ({commit, dispatch, getters}, args = {}) => {
        let listName = args.listName ? args.listName : 'organizationList';
        if(!getters.isSuperAdmin) {
             args.id = getters.getCurrentUserId;
            args.include = 'members';
        }
        if(Object.keys(args).length > 0) {
            commit('queryParamsSave', {storeType: 'organization', listName: listName, params: args});
        }
        commit('combineQueryParams', {storeType: 'organization', listName: listName});
        if(!getters.isSuperAdmin) {
            return dispatch('clientLoadUserOrganizations', getters.getOrganizationQueryParams(listName)).then(data => {
                for (let i = 0; i < data.length; i++) {
                    data[i]['editLink'] = '/organizations/' + data[i].id + '/general';
                }
                data.filter(item => {
                     return item.members[0].relation.role === 'admin';
                  })
                data.listName = listName;
                commit('loadOrganizationMutation', data);
                return data;
            });
        }
        return dispatch('clientLoadOrganizations', getters.getOrganizationQueryParams(listName)).then(data => {
                 data.filter(item => {
                 return item.visibility > 0;
              })
                data.listName = listName;
                commit('loadOrganizationMutation', data);
                return data;
        });

    },
    createOrganization: async ({commit, dispatch}, args) => {
        return dispatch('clientCreateOrganization', args)
            .then(data => {
                commit('loadOrganizationsMutation', {orgs: data});
                commit('setOrganizationTmpMutation', {key: 'last_inserted', value: data.id});
                dispatch('createNotification', {'text': i18n.t('events.organizationCreated')});
            });
    },
    updateOrganizationUser: async ({dispatch}, args) => {
        return dispatch('clientUpdateOrganizationUser', args)
            .then(() => {
                dispatch('loadUser', {
                    id: args.cid,
                    include: 'organizations'
                });
                dispatch('createNotification', {'text': i18n.t('events.organizationUpdated')});
            });
    },
    addOrganizationUser: async ({dispatch}, args) => {
        return dispatch('clientAddOrganizationMember', args)
            .then(() => {
                dispatch('loadUser', {
                    id: args.args[0].userId,
                    include: 'organizations'
                });
                dispatch('createNotification', {'text': i18n.t('events.organizationUpdated')});
            });
    },
    removeOrganizationUser: async ({dispatch}, args) => {
        return dispatch('clientRemoveOrganizationUser', args)
            .then(() => {
                dispatch('loadUser', {
                    id: args.args[0],
                    include: 'organizations'
                });
                dispatch('createNotification', {'text': i18n.t('events.organizationUpdated')});
            });
    },
    updateOrganization: async ({dispatch}, args) => {
        return dispatch('clientSaveOrganization', args)
            .then(() => {
                dispatch('loadOrganizations');
                dispatch('createNotification', {'text': i18n.t('events.organizationUpdated')});
            });
    },
    deleteOrganization: async ({dispatch}, args) => {
        return dispatch('clientDeleteOrganization', args)
            .then(() => {
                dispatch('loadOrganizations');
                //commit('setOrganizationTmpMutation', {key: 'last_inserted', value: data.id});
                dispatch('createNotification', {'text': i18n.t('events.organizationDeleted')});
            });
    },

    checkIfOrganizationNameExists: async ({dispatch}, args) => {
        let value = args.value;
        return dispatch('clientLoadOrganizations', {
            filter: ['name eq ' + value],
            fields: 'name,id',
        });
    },
    setOrganizationMetaValues: async ({dispatch}, args) => {
        //commit('updateProjectMutation', args);
        return dispatch('clientSaveOrganizationMetaSetValues', args).then(() => {
            dispatch('createNotification', {'text': i18n.t('events.organizationMetaValuesUpdated')});
        });
    },

};
export default {
    state, getters, mutations, actions
}
