<template>
    <div class="info-helper">
        <icon type="question-circle"/>
        <div class="tooltip" v-html="$t(text)"/>
    </div>
</template>

<script>
    import Icon from "./Icon";
    export default {
        name: "InfoHelper",
        components: {
            Icon,
        },
        props: {
            text: {type: String, required: true}
        }
    }
</script>

<style lang="scss" scoped>
    .info-helper {
        display:inline-block;
        padding-top:5px;
        position:relative;
        z-index:5;
        cursor:pointer;
        .tooltip {
            min-width: 370px;
            position:absolute;
            background-color: $info-helper-background-color;
            pointer-events:none;
            overflow:auto;
            padding:10px;
        }
        &:hover {
            .tooltip {
                opacity:1;
            }
        }
    }
</style>