import Vue from 'vue';
import i18n from "../translation";

const state = {
    /*
    * projectsLists contains all lists (e.g. if there is a box with the projects per organization, you would find it
    * in here with the id of the organization as the listname
    * */
    projectsLists: {
        projectList: {},
    },
    projectListOptions: {},
    defaultQueryParams: {
        limit: {
            default: '50',
        },
        filter: {
            default: 'type eq vstage',
        },
        sort: {
            default: '-updatedAt',
        },
        include: {
            default: 'metaSets'
        },
    },

    /*
    * The query params contain the different parameters of a query added by filters, table sorts and so on
    * the default filters always stay there
    *
    * */
    queryParams: {
        projectList: {
            limit: {
                default: '50',
            },
            filter: {
                default: 'type eq vstage',
            },
            sort: {},
            include: {
                default: 'metaSets'
            },
        },
    },
    /*
    * Combined query params are e.g. filter params which get combined from 'filter eq vstage' and 'filter eq coolman' to 'filter in vstage, coolman'
    * */
    combinedQueryParams: {
        projectList: {},
    },
    /**
     * Used keys so far:
     * last_inserted
     * */
    projectsTmp: {
    },
}

const getters = {
    getProjects() {
        return state.projectsLists.projectList;
    },
    getProjectsByListName: (state) => (listName) => {
        return state.projectsLists[listName] ? state.projectsLists[listName] : {};
    },
    getProjectById: (state) => (id, listName = 'projectList') => {
        return state.projectsLists[listName] && state.projectsLists[listName][id] ? state.projectsLists[listName][id] : null;
    },
    getProjectName: (state) => (id, listName = 'projectList') => {
        return state.projectsLists[listName][id] ? state.projectsLists[listName][id].name : '';
    },
    getProjectDescription: (state) => (id, listName = 'projectList') => {
        return state.projectsLists[listName][id] ? state.projectsLists[listName][id].description : '';
    },
    getProjectOrganization: (state) => (id, listName = 'projectList') => {
        return state.projectsLists[listName][id] && state.projectsLists[listName][id].organizationId ? state.projectsLists[listName][id].organizationId : '';
    },
    getProjectTags: (state) => (id, listName = 'projectList') => {
        return state.projectsLists[listName][id] && state.projectsLists[listName][id].tags ? state.projectsLists[listName][id].tags : [];
    },
    getProjectMetaSets: (state) => (id, listName = 'projectList') => {
        return state.projectsLists[listName][id] && state.projectsLists[listName][id].metaSets ? state.projectsLists[listName][id].metaSets : {};
    },
    getProjectCreationDate: (state, rootGetters) => (id, listName = 'projectList') => {
        return state.projectsLists[listName][id] ? rootGetters['convertDate'](state.projectsLists[listName][id].createdAt) : '';
    },
    getProjectChangeDate: (state, rootGetters) => (id, listName = 'projectList') => {
        return state.projectsLists[listName][id] ? rootGetters['convertDate'](state.projectsLists[listName][id].updatedAt) : '';
    },
    getProjectAssets: (state) => (id, listName = 'projectList') => {
        return state.projectsLists[listName][id] && state.projectsLists[listName][id].assets ? state.projectsLists[listName][id].assets : {};
    },
    getProjectState: (state) => (id, listName = 'projectList') => {
        return state.projectsLists[listName][id] && state.projectsLists[listName][id].state ? state.projectsLists[listName][id].state : 'n/a';
    },

    getProjectOriginalQueryParams: function() {
        return state.queryParams;
    },
    getProjectQueryParams: (state) => (listName = 'projectList') => {
        return state.combinedQueryParams[listName];
    },
    getProjectListOptions: (state) => (listName, option = '') => {
        let optionList = 'projectListOptions';
        if(option === 'preview') {
            return state[optionList][listName] && state[optionList][listName].previewPath ? Object.keys(state[optionList][listName].previewPath)[0] : '';
        }
        else if(option === 'fbxPreview') {
            return state[optionList][listName] && state[optionList][listName].fbxPreview ? Object.keys(state[optionList][listName].fbxPreview)[0] : '';
        }
        else if(option === 'pagination_items') {
            return state[optionList][listName] && state[optionList][listName].pagination_items ? parseInt(Object.keys(state[optionList][listName].pagination_items)[0]) : 0;
        }
        return state[optionList][listName] ? state[optionList][listName] : {};
    },
    getProjectPreviewUri: (state) => (id, listName = 'projectList') => {
        return state.projectsLists[listName][id] && state.projectsLists[listName][id].previewUri ? state.projectsLists[listName][id].previewUri : '';
    },
};

const mutations = {
    setProjectTmpMutation(state, data) {
        if(state.projectsTmp[data.key]) {
            Vue.delete(state.projectsTmp, data.key);
        }
        Vue.set(state.projectsTmp, data.key, data.value);
    },
    loadProjectsMutation(state, data) {
        let listName = 'projectList';
        if(data.listName) {
            listName = data.listName;
        }
        Vue.set(state.projectsLists, listName, {});
        for (var i = 0; i < data.length; i++) {
            let project = data[i];
            Vue.set(state.projectsLists[listName], project.id, project);
         }
    },
    loadProjectMutation(state, data) {
        let listName = 'projectList';
        if(data.listName) {
            listName = data.listName;
        }
        if(!state.projectsLists[listName]) {
            Vue.set(state.projectsLists, listName, {});
        }
        Vue.set(state.projectsLists[listName], data.id, data);
    },

    /**
     * expects a data object like data[key] = value
     * */
    updateProjectMutation(state, data) {
        let listName = data.listName ? data.listName : 'projectList';
        let id = data.id;
        //delete data.id;
        for (const key in data){
            const value = data[key];
            if(state.projectsLists[listName][id]) {
                if(!state.projectsLists[listName][id][key]) {
                    Vue.set(state.projectsLists[listName][id], key, {});
                }
                Vue.set(state.projectsLists[listName][id], key, value);
            }
        }
    },
    deleteProjectMutation(state, data) {
        let listName = data.listName ? data.listName : 'projectList';
        let id = data.id;
        if(state.projectsLists[listName] && state.projectsLists[listName][id]) {
            Vue.delete(state.projectsLists[listName], id);
            // state.dispatch('createNotification', {'text': i18n.t('events.projectDeleted')});
        }
    },
    appProjectTagsMutation(state, data) {
        let listName = data.listName ? data.listName : 'projectList';
        Vue.set(state.projectsLists[listName][data.id], 'tags', data.tags);
    },
    updateProjectListOptionMutation(state, args) {
        let optionList = 'projectListOptions';

        if(!state[optionList][args.listName]) {
            Vue.set(state[optionList], args.listName, {});
        }

        if(args.override) {
            Vue.set(state[optionList][args.listName], args.optionKey, {});
        }

        //reset all other options
        if(args.optionKey === 'selected') {
            Vue.set(state[optionList][args.listName], args.optionKey, {});
        }

        if(!state[optionList][args.listName][args.optionKey]) {
            Vue.set(state[optionList][args.listName], args.optionKey, {});
        }
        if(state[optionList][args.listName][args.optionKey][args.value]) {
            Vue.delete(state[optionList][args.listName][args.optionKey], args.value);
        }
        else {
            Vue.set(state[optionList][args.listName][args.optionKey], args.value, args.value2);
        }
    },
    markProjectsSelectedMutation(state, args){
        let listName = args.listName;
        let id = args.id;
        if(state.projectsLists[listName] && state.projectsLists[listName][id]) {
            Vue.set(state.projectsLists[listName][id], 'selected', true);
        }
    },
    markProjectsUnselectedMutation(state, args){
        let listName = args.listName;
        let id = args.id;
        if(state.projectsLists[listName] && state.projectsLists[listName][id]) {
            Vue.set(state.projectsLists[listName][id], 'selected', false);
        }
    },
}
const actions = {
    /*
    * Loads the projects and adds them to the store
    * args:
    *   listName: optional (default is projectList)
    *   to add a filter item to the query, do it with add (and use 'remove' to clear the query of this item)
    *   args.add = {filter: {
    *                   customId: 'name like %lalala%',
    *                   customId2: 'type eq media',
    *               }}
    * */
    registerProjectsQueryParams: ({commit}, args) => {
        let listName = 'projectList';
        if(args.listName) {
            listName = args.listName;
        }
        if(Object.keys(args).length > 0) {
            commit('queryParamsSave', {storeType: 'projects', listName: listName, params: args});
        }
    },
    loadProjects: async ({commit, dispatch, getters}, args = {}) => {
        let listName = 'projectList';
        if(args.listName) {
            listName = args.listName;
        }
        if(Object.keys(args).length > 0) {
            commit('queryParamsSave', {storeType: 'projects', listName: listName, params: args});
        }
        commit('combineQueryParams', {storeType: 'projects', listName: listName});
        return dispatch('clientLoadProjects', getters.getProjectQueryParams(listName)).then(data => {
            for (let i = 0; i < data.length; i++) {
                data[i]['editLink'] = '/project/' + data[i].id + '/general';
            }
            data.listName = listName;
            commit('loadProjectsMutation', data);

            /*added pagination part*/
            if(getters.getProjectQueryParams(listName).paging === true && data.headers()) {
                let count = data.headers()['x-count'] ? parseInt(data.headers()['x-count']) : 0;
                commit('updateProjectListOptionMutation', {optionKey: 'pagination_items', value: count, listName: listName, override: true});
            }
            return data;
        });
    },
    deleteProject: async ({commit, dispatch}, args = {}) => {
        let listName = 'projectList';
        if(args.listName) {
            listName = args.listName;
        }
        let queryArgs = args.args;
        dispatch('clientDeleteProjects', queryArgs).then(() => {
            commit('deleteProjectMutation', {listName: listName, id: queryArgs});
        });
    },

    createProject: async ({commit, dispatch}, args) => {
        return dispatch('clientCreateProject', args)
            .then(data => {
                commit('loadProjectMutation', data);
                commit('setProjectTmpMutation', {key: 'last_inserted', value: data.id});
                dispatch('createNotification', {'text': i18n.t('events.projectCreated')});
            });
    },
    addProjectTag: async ({commit, dispatch}, args) => {
        return dispatch('clientAddProjectTags', args).then(() => {
            let d = null;
            dispatch('clientLoadProjectTags', args).then(data => {
                commit('appProjectTagsMutation', {
                    id: args.id,
                    listName: args.listName ? args.listName : 'projectList',
                    tags: data,
                });
                d = data;
            });
            dispatch('createNotification', {'text': i18n.t('events.tagsAdded')});
            return d;
        });
    },
    removeProjectTag:  async ({commit, dispatch}, args) => {
        dispatch('clientRemoveProjectTags', args).then(() => {
            dispatch('clientLoadProjectTags', args).then(data => {
                commit('appProjectTagsMutation', {
                    id: args.id,
                    listName: args.listName ? args.listName : 'projectList',
                    tags: data,
                });
            });
            dispatch('createNotification', {'text': i18n.t('events.tagsRemoved')});
        });
    },

    /**
     * loads a single project
     */
    loadProject: async ({commit, dispatch}, args = {}) => {
            return dispatch('clientLoadProject', args).then(data => {
                if(args.listName) {
                    data.listName = args.listName;
                }
                commit('loadProjectMutation', data);
                return data;
            });
    },
    updateProject: async ({commit, dispatch}, args) => {
        commit('updateProjectMutation', args);
       return dispatch('clientSaveProject', args).then(() => {
            dispatch('createNotification', {'text': i18n.t('events.projectUpdated')});
        });
    },
    setProjectMetaValues: async ({dispatch}, args) => {
        //commit('updateProjectMutation', args);
        return dispatch('clientSaveProjectMetaSetValues', args).then(() => {
            dispatch('createNotification', {'text': i18n.t('events.projectMetaValuesUpdated')});
        });
    },
    setProjectListSelected: ({commit}, args) => {
        args.optionKey = 'selected';
        args.value = args.id;
        args.value2 = args.assetId;
        args.override = true;
        commit('updateProjectListOptionMutation', args);
    },
    markProjectsSelected: ({commit}, args) => {
        args.optionKey = 'selected';
        args.override = true;
        commit('markProjectsSelectedMutation', args);
    },
    markProjectsUnselected: ({commit}, args) => {
        args.optionKey = 'selected';
        args.override = true;
        commit('markProjectsUnselectedMutation', args);
    },

    checkIfProjectNameExists: async ({dispatch, getters}, args) => {
        let value = args.value;
        let orgId = args.organizationId ? args.organizationId : getters.getCurrentUserOrg;
        return dispatch('clientLoadProjects', {
            filter: ['name eq ' + value + ', organizationId eq ' + orgId],
            fields: 'name,id',
        });
    },
}
export default {
    state, getters, mutations, actions
}
