<template>
  <div>
    <label class="mt-3">{{ $t('selectVM') }}</label>
    <select class="custom-select dark" v-model="vm">
      <option v-for="(vm, index) in vms" :key="index" :value="vm.id">{{ vm.displayName }}<span v-if="showPrices">: {{ vm.clientPricePerMinute }} CHF/min</span></option>
    </select>
    <div v-if="!vms">{{ $t('noVMAvailable') }}</div>
  </div>
</template>

<script>
export default {
  name: "VmSelector",
  props: {
    showPrices: {type: Boolean, default: true}
  },
  data() {
    return {
      vms: [],
      vm: ''
    }
  },
  watch: {
    vm() {
      this.$emit('selected', this.vm);
    }
  },
  beforeMount() {
    this.loadVMs();
  },
  methods: {
    loadVMs() {
      this.$store.dispatch('loadVirtualMachines', {
        listName: Math.random().toString(36).substring(7),
        add: {
          filter: {
            vmSelector: 'state eq a'
          }
        }
      }).then(data => {
        if(data.length) {
          this.vms = data;
          this.vm = data[0].id;
        } else  {
          console.log('novm')
        }
      })
    },
  }
}
</script>