<template>
    <span class="icon" :style="getSize">
        <font-awesome-icon :icon="getIcon"/>
    </span>
</template>

<script>
    /**
     * Icon
     */
    import { library } from '@fortawesome/fontawesome-svg-core';
    import {
      faCodeBranch,
      faDollarSign,
      faExclamationTriangle,
      faPuzzlePiece,
      faSpinner,
      faGripVertical,
      faBatteryHalf,
      faWrench,
      faServer,
      faHdd,
      faChartPie,
      faThermometerHalf,
      faStopCircle,
      faPlayCircle,
      faDesktop,
      faCameraRetro,
      faGhost,
      faPaintRoller,
      faKiwiBird,
      faBabyCarriage,
      faChild,
      faBaby,
      faRobot,
      faTasks,
      faCopy,
      faDownload,
      faClock,
      faExternalLinkAlt,
      faRedo,
      faBoxOpen,
      faListUl,
      faFlask,
      faCog,
      faFileExport,
      faMinus,
      faExchangeAlt,
      faSitemap,
        faRecycle,
        faBiohazard,
        faQrcode,
        faQuestionCircle,
        faCubes,
        faMapMarkerAlt,
        faWindowMaximize,
        faBox,
        faThLarge,
        faMagic,
        faCommentAlt,
        faFont,
        faVolumeUp,
        faImage,
        faBroom,
        faFilePdf, faChartBar, faGlobeAmericas,  faPollH, faCloud, faTrashAlt, faBuilding, faAlignLeft, faCheck, faArrowsAlt, faHome, faLock, faVideo, faAngleUp, faTag, faFileDownload, faBan, faFileWord, faExclamation, faCamera, faFileUpload, faSave, faEllipsisV, faAngleDown, faDatabase, faBook, faFilm, faMobile, faTimes, faEyeSlash, faEye, faPencilAlt, faUnlink, faLink, faAngleLeft, faAngleRight, faPaperclip, faCube, faRss, faSignal, faPlus, faTable, faTh, faUserSecret, faFileCsv, faFile, faUserTag, faUser, faUsers, faSort, faTimesCircle, faSignInAlt, faFolderOpen, faFolder, faFilter } from '@fortawesome/free-solid-svg-icons';
    import { faEdit, } from '@fortawesome/free-regular-svg-icons'
    import {faWindows, faApple } from '@fortawesome/free-brands-svg-icons'

    const loadIcons = (icons) => icons.map(icon => [icon.prefix, icon.iconName]);
    const mergeIcons = (dest, src) => {
        for (const icon of src) {
            dest[icon[1]] = icon
        }
    };
    const icons = {
        timesCircle: ['fas', 'times-circle'],
        usertag: ['fas', 'user-tag'],
        team: ['fas users'],
        close: ['fas', 'times-circle'],
        login: ['fas', 'sign-in-alt'],
        check: ['fas', 'check'],
        sensor: ['fas', 'signal'],
        feed: ['fas', 'rss'],
        tbl: ['fas', 'file-csv'],
        obj: ['fas', 'file'],
        text: ['fas', 'align-left'],
        form: ['fa', 'poll-h'],
        report: ['fa', 'chart-bar'],
        image: ['fa', 'image'],
        audio: ['fa', 'volume-up'],
        font: ['fa', 'font'],
        message: ['fa', 'comment-alt'],
        model: ['fa', 'cube'],
        pdf: ['fa', 'file-pdf'],
        multipart: ['fa', 'magic'],
        app: ['fa', 'th-large'],
        webapp: ['fa', 'window-maximize'],
        vhelper: ['fa', 'map-marker-alt'],
        helper: ['fa', 'map-marker-alt'],
        assembly: ['fa', 'cubes'],
        cadConvert: ['fa', 'exchange-alt'],
        importHierarchy: ['fas', 'sitemap'],
        sort: ['fas', 'sort'],
        h: ['fa', 'eye-slash'],
        p: ['fa', 'clock'],
        a: ['fa', 'eye'],
        i: ['fa', 'ban'],
        m: ['fa', 'wrench']
    };
    const importedIcons = [
        faCodeBranch,
        faDollarSign,
        faExclamationTriangle,
        faPuzzlePiece,
        faSpinner,
        faGripVertical,
        faBatteryHalf,
        faWrench,
        faServer,
        faHdd,
        faChartPie,
        faThermometerHalf,
        faStopCircle,
        faPlayCircle,
        faDesktop,
        faGhost,
        faPaintRoller,
        faKiwiBird,
        faBabyCarriage,
        faChild,
        faBaby,
        faRobot,
        faTasks,
        faCameraRetro,
        faCopy,
        faDownload,
        faClock,
        faExternalLinkAlt,
        faRedo,
        faBoxOpen,
        faListUl,
        faFlask,
        faCog,
        faFileExport,
        faMinus,
        faExchangeAlt,
        faSitemap,
        faRecycle,
        faBiohazard,
        faQrcode,
        faQuestionCircle,
        faCubes,
        faMapMarkerAlt,
        faWindowMaximize,
        faBox,
        faThLarge,
        faMagic,
        faCommentAlt,
        faFont,
        faVolumeUp,
        faImage,
        faBroom,
        faFilePdf, faChartBar, faGlobeAmericas, faPollH, faCloud, faTrashAlt, faBuilding, faCheck, faAlignLeft, faArrowsAlt, faHome, faLock, faVideo, faAngleUp, faTag, faFileDownload, faBan, faFileWord, faExclamation,faCamera, faFileUpload, faSave, faEllipsisV, faAngleDown, faDatabase, faBook, faFilm, faMobile, faTimes, faEyeSlash, faEye, faPencilAlt, faUnlink, faLink, faAngleLeft, faAngleRight, faPaperclip, faCube, faRss, faSignal, faPlus, faTable, faTh, faUserSecret, faEdit, faFileCsv, faFile, faUserTag, faUser, faUsers, faSort, faTimesCircle, faSignInAlt, faFolderOpen, faFolder, faWindows, faApple, faFilter];
    mergeIcons(icons, loadIcons(importedIcons));
    Object.freeze(icons);
    library.add(importedIcons);

    export default {
        name: 'Icon',
        props: {
            /**
             * The icon type (face)
             * 
             * Following icons are available:
             *
             * file-word, exclamation, camera, file-upload, save, ellips-is-v, angle-down, database, book, 
             * film, mobile, times, eye-slash, eye, pencil-alt, unlink, link, angle-left, angle-right, 
             * paperclip, cube, rss, signal, plus, table, th, user-secret, file-csv, file, 
             * user-tag, user, users, sort, times-circle, sign-in-alt, folder-open, folder, 
             * filter, edit, windows, apple, usertag, team, close, login, check, sensor, feed,
             * obj, tbl
             *
             */
            type : { type: String, required: true/*,  validator: value => value in icons*/},
            /**
             * The icon size.
             *
             * @values small, normal, large or size in em
             */
            size : { type: String, default: 'small' }
        },
        data() {
            return { icons: icons }
        },
        computed: {
            getIcon: function() {
                return this.icons[this.type];
            },
            getSize: function() {
                switch (this.size) {
                    case 'large':
                        return 'font-size: 3.42em';
                    case 'normal':
                        return 'font-size: 1.7em';
                    case 'small':
                        return 'font-size: 1.14em';
                }
                if (this.size != null) {
                    return 'font-size: ' + this.size +'em';
                }
                return null;
            }
        }
    }
</script>

<docs>
Vue component example:

```jsx
    <div>
        Large icon
        <Icon type="filter" size="large"/>
    </div>
    <div>
        Normal icon
        <Icon type="filter" size="normal"/>
    </div>
    <div>
        Small icon
        <Icon type="filter" size="small"/>
    </div>
```
</docs>
