<template>
    <span/>
</template>

<script>
    export default {
        name: "ColorContrastMixinJsVue",
        methods: {
            getContrast: function (hexcolor){
                if(!hexcolor) {
                    return '';
                }
                // If a leading # is provided, remove it
                if (hexcolor.slice(0, 1) === '#') {
                    hexcolor = hexcolor.slice(1);
                }

                // If a three-character hexcode, make six-character
                if (hexcolor.length === 3) {
                    hexcolor = hexcolor.split('').map(function (hex) {
                        return hex + hex;
                    }).join('');
                }

                // Convert to RGB value
                var r = parseInt(hexcolor.substr(0,2),16);
                var g = parseInt(hexcolor.substr(2,2),16);
                var b = parseInt(hexcolor.substr(4,2),16);

                // Get YIQ ratio
                var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

                // Check contrast
                return (yiq >= 128) ? '#2b2a2a' : '#fff';
            }
        },
    }
</script>

<style scoped>

</style>