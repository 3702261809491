<template>
  <div>
    <popup
        @close="() => {showDeletePrompt = false;}"
        v-if="showDeletePrompt"
    >
      <delete-prompt slot="popupMainContent"
                     @abort="() => {showDeletePrompt = false; toBeDeletedInstance = null; toBeDeletedAsset = null;}"
                     @confirm="() => {showDeletePrompt = false; if(toBeDeletedInstance) {deleteInstance();}}"
      />
    </popup>
    <loading-panel :time-in-seconds="0" v-if="isLoading" />
    <div class="mb-3" v-else-if="!Object.keys(list).length && !list.length">
      {{ $t('nodata') }}
    </div>
    <div v-else class="mb-3 mt-2 export-profile-instance" :key="id" v-for="(item, id) in $store.getters.getFilterInstancesByListName(listId)">
       <div class="row">
         <div class="col-12 ml-0 pl-2 darker pt-2 pb-1 mb-2">
           <Button class="icon-button instance-delete-button float-right mr-2" @click="deleteInstance(item.id)"><icon type="trash-alt" /></Button>
           <state-display class="bright" :state="item.state" />
         </div>
         <div class="col-1 mt-1 text-right"><icon type="file-download" size="1.2" /></div>
         <div class="col">
            <div @click="editing = item.id; editName = item.name" class="float-right mr-3"><icon type="edit" /></div><p class="larger"><b>{{ item.name ? item.name : item.asset.name }}</b></p>
            <div v-if="editing === item.id">
              <input type="text" class="form-text col-8" v-model="editName" />
               <div class="edit-trigger mr-2" @click="cancelEditing">
                   <icon :type="'times'"/>
               </div>
               <div class="edit-trigger" @click="updateContent">
                  <icon :type="'save'"/>
               </div>
            </div>
            <p class="lighter smaller">{{ $t('Created') }}: {{ dateTimeFromISO(item.createdAt) }}</p>
            {{ item.description }}<br >

            <p class="smaller lighter">{{ item.id }}</p>
             <info-panel
                preview-id="something"
                class="mt-3 mb-1 mr-2 border-panel compact-header light-header smaller-title"
                info-panel-header="Details"
                :collapsible="true"
                :initially-collapsed="true">
                <div slot="info">
                  {{ $t('Filter') }}
                  <div :key="key" v-for="(value, key) in JSON.parse(item.content).filter">
                    {{ key }}: {{ value }}
                  </div>
                  <br >
                  {{ $t('axixMultiplier') }}: {{ JSON.parse(item.content).pipeline.axisMultiplier }}<br >
                  {{ $t('Mode') }}: {{ JSON.parse(item.content).pipeline.subVersion.mode }}
                  <div class="w-100" />
                  <vm-selector class="mb-3 col-12 col-md-6" @selected="setVM" />
                  <Button v-if="updatingInstance !== item.id && (item.content && JSON.parse(item.content) && JSON.parse(item.content).filterResults)" class="mt-2" @click="updateInstance(item.id, item.asset.id)">{{ $t('update') }}</Button>
                </div>
            </info-panel>
        </div>
         <div class="dark-section-right">
          <div class="text text-center">
            <div class="download export-instance-download" v-if="item.content && JSON.parse(item.content) && JSON.parse(item.content).filterResults">
              <icon type="download" size="1.2" />
              <download-button
                  loading-panel-classes="black"
                class="text-center w-100 not-absolute"
                download-message=""
                :div-instead-of-button="true"
                :show-stats="true"
                :object-id="item.id"
                :file-key="JSON.parse(item.content).filterResults && JSON.parse(item.content).filterResults[0].fileName ? JSON.parse(item.content).filterResults[0].fileName : 'lod0.fbx'"
            >{{ $t('FBX') }}</download-button>
            </div>
            <div v-else>
              <icon type="clock" size="1.3" /><br />
              <p class="smaller mt-1">{{ $t(item.pipelineStatus) }}</p>
            </div>
          </div>
          </div>
      </div>


    </div>
  </div>
</template>

<script>
import Button from "@/components/forms/Button";
// import ContentFilter from "@/components/widgets/filter/ContentFilter";
import Icon from "@/components/Icon";
import DateMixin from "@/components/mixins/DateMixin.js";
import InfoPanel from "@/components/InfoPanel";
import DownloadButton from "@/components/forms/DownloadButton";
import Popup from "@/components/Popup";
import DeletePrompt from "@/components/forms/DeletePrompt";
import LoadingPanel from "@/components/LoadingPanel";
import StateDisplay from "@/components/StateDisplay";
import vmSelector from "@/components/vmSelector";

export default {
  name: "ExportInstanceList",
  components: {
    Button,
    // ContentFilter,
    Icon,
    InfoPanel,
    DownloadButton,
    Popup,
    DeletePrompt,
    LoadingPanel,
    StateDisplay,
    vmSelector
  },
  mixins: [DateMixin],
  props: {
    projectId: {type: String, required: true},
    listId: {type: String, required: true},
    rootNode: {type: String, required: true}
  },
  data() {
    return {
      list: {},
      filter: {},
      toBeDeletedInstance: null,
      showDeletePrompt: false,
      isLoading: true,
      updatingInstance: '',
      editing: null,
      editName: '',
      enableSaving: true,
    }
  },
  watch: {
    projectId() {
      this.loadInstances();
    }
  },
  beforeMount() {
    this.loadInstances();
  },
  methods: {
    edit(id) {
      this.editing = id;
    },
    cancelEditing() {
      this.editing = null;
      this.editName = null
    },
    updateContent() {
      return this.$store.dispatch('clientUpdateProjectInstance', {
            id: this.projectId,
            cid: this.editing,
            name: this.editName
          }).then(() => {
            const index = this.list.findIndex(item => {return item.id === this.editing});
            this.list[index].name = this.editName;
            this.cancelEditing();
            this.$store.dispatch('createNotification', {'text': this.$t('events.instanceUpdated')});
          })
    },
    setVM(id) {
      this.vm = id;
    },
    updateInstance(instanceId, assetId) {
      this.updatingInstance = instanceId;
        this.$store.dispatch('clientLoadAsset', {id: assetId}).then(asset => {
          let content = JSON.parse(asset.content);
          if(this.rootNode) {
            const fileUri = `${this.rootNode}/lod0.fbx`;
            if(!content.pipeline.subVersion) {
              content.pipeline.subVersion = {};
            }
            content.pipeline.subVersion.rootNodeId = `${this.rootNode}`;
            content.pipeline.rootNodeFile = `lod0.fbx`;
            content.pipeline.files = [fileUri];
            content.pipeline.sendFilterMails = true;
            content.pipeline.email = content.pipeline.email = this.email ? this.email : this.$store.getters.getUserEmail(this.$store.getters.getCurrentUserId);
            content.pipeline.lang = this.$i18n.locale ? this.$i18n.locale : 'en'
        }
        else {
          this.error = this.$t('errors.rootNodeNotFound');
        }
          return this.$store.dispatch('clientUpdateProjectInstance', {
            id: this.projectId,
            cid: instanceId,
            content: JSON.stringify(content),
            state: 'p'
          })
      }).then(async () => {
        return this.$store.dispatch('clientInstanceToPipeline', {
            id: instanceId,
            type: 'filter',
            virtualMachine: this.vm,
          }).then(() => {
            this.$emit('started');
          });
      })
    },
    loadInstances() {
      if(this.projectId) {
        let args = {
          id: this.projectId,
          listName: this.listId,
          include: ['asset', 'project'],
          addPipelineState: true,
        };
        /*if(this.filter) {
          if (this.filter.add) { args.add = this.filter.add}
          if (this.filter.remove) { args.remove = this.filter.remove }
        }*/
        this.$store.dispatch('loadFilterInstances', args).then(data => {
          this.list = data;
          this.isLoading = false;
        }).catch(() => {
          this.isLoading = false;
        });
      }
    },
    deleteInstance(cid) {
      if(this.toBeDeletedInstance) {
        this.$store.dispatch('deleteFilterInstances',
            {
              id: this.projectId,
              args: [this.toBeDeletedInstance]
            }
        ).then(() => {
          this.forceReRenderKey++;
          this.showDeletePrompt = false;
          this.toBeDeletedInstance = null;
          this.loadInstances();
        });
      } else {
        this.toBeDeletedInstance = cid;
        this.showDeletePrompt = true;
      }
    },
    /*filtering(val) {
      this.filter = val;
      this.loadInstances();
    }*/
  }
}
</script>

<style lang="scss">
  $right-section-width: 110px;
  .export-profile-instance {
    background: #605E5B;
    padding: 15px;
    padding-top:0;
    padding-right: $right-section-width;
    position:relative;
    .info {
      word-wrap: break-word;
    }
  }
  .dark-section-right {
    background: #171615;
    height: 100%;
    position:absolute;
    right: 0;
    top:0;
    width: $right-section-width;
    text-align: center;
    padding:8px;
    .text {
      top: 50%;
      left: 50%;
      position:absolute;
      -webkit-transform: translate(-50%,-50%);
      transform: translate(-50%,-50%);
      width: 90%;
      text-align: center;
    }
  }
  .instance-delete-button {
  }

  .export-instance-download {
    cursor: pointer;
    &:hover {
      .icon {
       margin-bottom: -5px;
      }
    }
  }
</style>