<template>
  <div class="meta-set-attacher" v-if="$store.getters.isSuperAdmin || allowAccess">
    <popup
                @close="() => {showDeletePrompt = false;}"
                v-if="showDeletePrompt"
        >
            <delete-prompt slot="popupMainContent"
                           @abort="() => {showDeletePrompt = false;}"
                           @confirm="() => {removeMetaSet();}"
                           custom-message="RemoveMetaSetWarning"
            />
        </popup>
    <h3>{{ $t('AttachOrRemoveMetaSet') }}</h3>
    <select class="custom-select col-12 col-md-6" v-model="selected">
      <option v-if="(!attachedMetaSets || !attachedMetaSets.length) || !attachedMetaSets.filter(meta => meta.id === item.id).length" :key="index" v-for="(item, index) in $store.getters.getMetaSetsByListName(targetId)" :value="item.id">{{ item.description ? item.description : item.name }}</option>
    </select><br />
    <Button
        :deactivated="!selected"
        type="button"
        class="mb-3 mt-1 mr-2"
        @click="attachMetaSet"
    >
      <icon type="save"/> {{ $t('ADD') }}
    </Button>
    <div class="w-100" />
     <select class="custom-select col-12 col-md-6" v-model="unselected">
      <option v-if="(!attachedMetaSets || !attachedMetaSets.length) || attachedMetaSets.filter(meta => meta.id === item.id).length" :key="index" v-for="(item, index) in $store.getters.getMetaSetsByListName(targetId)" :value="item.id">{{ item.description ? item.description : item.name }}</option>
    </select><br />
    <Button
        :deactivated="!unselected"
        type="button"
        class="mb-3 mt-1"
        @click="removeMetaSet"
    >
      <icon type="save"/> {{ $t('REMOVE') }}
    </Button>
  </div>
</template>

<script>
import Button from "../../forms/Button"
import Icon from "../../Icon";
import DeletePrompt from "@/components/forms/DeletePrompt";
import Popup from "@/components/Popup";
export default {
  name: "MetaSetAttacher",
  components: {
    Button,
    Icon,
    DeletePrompt,
    Popup
  },
  props: {
    targetId: {type: String, required: true},
    storeName: {type: String, required: true},
    givenMetaSets: {default: () => {}},
    /**
     * Whether or not to show a warning on removing a metaSet
     * */
    showWarning: {type: Boolean, default: false},
    organizationId: {type: String, default: ''},
    allowAccess: {type: Boolean, default: false}
  },
  data() {
    return {
      selected: '',
      unselected: '',
      attachedMetaSets: [],
      showDeletePrompt: false
    };
  },
  watch: {
    givenMetaSets() {
      this.attachedMetaSets = this.givenMetaSets ? this.givenMetaSets : [];
    }
  },
  beforeMount() {
    this.attachedMetaSets = this.givenMetaSets;
    let args = {listName: this.targetId};
    if(this.organizationId) {args.add = {}; args.add.filter = {default: 'organizationId eq ' + this.organizationId}
    if(this.organizationId && this.$store.getters.isSuperAdmin) {args.add = {}; args.add.filter = {default: `organizationId in '${this.organizationId}' '${this.$store.getters.getSuperOrg}'`}}}
    args.add = { sort: { targetId : "+description" } };
    this.$store.dispatch('loadMetaSets', args);
  },
  methods: {
    attachMetaSet() {
      this.$store.dispatch(`attach${this.storeName}MetaSets`, {
        id: this.targetId,
        args: [this.selected]
      }).then(() => {
        this.$store.dispatch('createNotification', {'text': this.$t('MetaSetAttached')});
        this.$emit('added', this.selected);
      })
    },
    removeMetaSet() {
      if(this.showWarning && !this.showDeletePrompt) {
        this.showDeletePrompt = true;
      } else {
        this.$store.dispatch(`remove${this.storeName}MetaSets`, {
          id: this.targetId,
          args: [this.unselected]
        }).then(() => {
          this.$store.dispatch('createNotification', {'text': this.$t('MetaSetRemoved')});
          this.$emit('removed', this.unselected);
          this.showDeletePrompt = false;
        })
      }
    },
  }
}
</script>

<style lang="scss">
  .info-panel {
    .meta-set-attacher {
      .custom-select {
        background-color: $tag-background-dark;
        color: #fff;
      }
    }
  }
</style>