<template>
    <one-column-sidebar-left
      back-button-tab-selector="textbackbutton"
      sub-menu-selector="librarypanel"
    >
<div slot="buttonsRight">
            <Button
                    class="mb-3 mt-5"
                    :deactivated="!enableSaving"
                    @click="save">
                <icon v-if="enableSaving" type="save"/>
                <icon v-else type="ban"/>
                {{ $t('SAVE') }}
            </Button>
        </div>

        <div slot="mainContent">
            <h1><icon class="mr-2" type="align-left" size="0.9" />{{ $t('createNewText') }}</h1>
             <organization-switcher
                @setOrganization="id => {organizationId = id}"
                @setTeam="id => {teamId = id}"
                @unsetTeam="teamId = ''"
                class="mb-2" />
            <input
                    type="text"
                    :class="['form-text mb-3 mt-3', $v.name.$dirty && $v.name.$error ? 'form-group--error' : '']"
                    :id="'name'"
                    v-model="name"
                    :placeholder="$t('enternamehere')"
                    @input="delayTouch($v.name)"
            >

            <div class="form-error" v-if="$v.name.$dirty && !$v.name.required && $v.name.$error">{{ $t('errors.required') }}</div>
            <div class="form-error" v-if="$v.name.$dirty && !$v.name.alphaNumSpace">{{ $t('errors.alphaNumSpaceOnly') }}</div>

            <div class="form-error" v-if="$v.name.$dirty && !$v.name.maxLength">{{ $t('errors.atMostCharacters', {num: 128}) }}</div>

            <div class="form-error" v-if="$v.name.$dirty && !$v.name.minLength">{{ $t('errors.atLeastCharacters', {num: 3}) }}</div>
            <div class="form-error" v-if="$v.name.$dirty && !$v.name.isUnique">{{ $t('errors.textNameAlreadyExists') }}</div>

            <language-selector
                    class="mb-3 mt-3"
                    value="en"
                    store-name="Text"
                    :id="$route.params.id"
                    @update="updateLanguage"
            />

            <div class="inline-edit-label">
                <b>{{ $t('maintext') }}</b>
            </div>
            <div>
                 <textarea
                         :class="['form-text mb-3']"
                         :id="'content'"
                         v-model="content"
                         :placeholder="$t('entercontenthere')"
                 />
            </div>

            <div class="inline-edit-label">
                <b>{{ $t('description') }}</b>
            </div>
            <div>
                 <textarea
                         :class="['form-text', $v.description.$dirty && $v.description.$error ? 'form-group--error' : '']"
                         :id="'description'"
                         v-model="description"
                         :placeholder="$t('enterdeschere')"
                         @input="delayTouch($v.description)"
                 />
                <div class="form-error" v-if="$v.description.$dirty && !$v.description.maxLength">{{ $t('errors.atMostCharacters', {num: 128}) }}</div>
            </div>
        </div>
    </one-column-sidebar-left>
</template>

<script>
    import Button from "../components/forms/Button";
    import OneColumnSidebarLeft from "../layouts/OneColumnSidebarLeft";
    import Icon from "../components/Icon";
    import { required, minLength, maxLength } from 'vuelidate/lib/validators';
    import {alphaNumSpace} from '../customValidators';
    import OrganizationSwitcher from "../components/organizations/OrganizationSwitcher";
    import LanguageSelector from "../components/forms/LanguageSelector";
    import VuelidateMixin from "@/components/mixins/VuelidateMixin.js";

    export default {
        name: 'TextAdd',
        components: {
            OrganizationSwitcher,
            Button,
            OneColumnSidebarLeft,
            Icon,
            LanguageSelector,
        },
        mixins: [VuelidateMixin],
        data() {
            return {
                nameEdit: true,
                descEdit: true,
                id: '',
                enableSaving: false,
                name: '',
                description: '',
                content: '',
                locale: '',
                type: 'text',
                organizationId: '',
                teamId: ''
            };
        },
        validations: {
            name: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(127),
                alphaNumSpace,
                async isUnique(value) {
                    // standalone validator ideally should not assume a field is required
                    if (value === '') {
                        return true;
                    }
                    let bool = true;
                    await this.$store.dispatch('checkIfTextNameExists', {name: value, type: 'Asset', value: value, organizationId: this.organizationId,}).then(data => {
                        bool = data.length === 0;
                    });
                    return bool;
                },
            },
            description: {
                maxLength: maxLength(1024),
            },
        },
        watch: {
            '$v.$invalid': function() {
                this.enableSaving = !this.$v.$invalid;
                this.$emit('enableSaving', !this.$v.$invalid);
            },
        },
        methods: {
            updateLanguage: function(lang) {
                this.locale = lang;
            },
            save: async function() {
                if(this.enableSaving) {
                    let args = {
                        name: this.name,
                        slug: this.name,
                        description: this.description,
                        content: this.content,
                        type: this.type,
                        locale: this.locale ? this.locale : '*',
                    };
                    args.organizationId = this.organizationId;
                    if(this.teamId) {
                      args.teams = [this.teamId];
                    }
                    this.$store.dispatch("createText", args).then((data) => {
                        this.$router.push(`/library/texts/${data.id}/general`);
                    });
                }
            },
        },
    }
</script>
