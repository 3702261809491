export const urls = {
    create: {
        'Asset': 'organizations/%orgid/assets/%key',
        'User': 'assets/%key',
        'Team': 'organizations/%orgid/assets/%key',
        'Organization': 'organizations/%orgid/assets/%key',
        'Project': 'projects/%projectId/%key',
        'assemblyZipImport': 'organizations/%orgid/assets/%key',
        'CSVAsset': 'organizations/%orgid/assets/csv'
    },
    update: {
        'Asset': 'assets/%assetId/%key',
        'Project': 'projects/%projectId/%key',
        'assemblyZipImport': 'assets/%assetId/%key',
        'User': 'assets/%assetId/%key',
        'Team': 'assets/%assetId/%key',
        'Organization': 'assets/%assetId/%key',
        'Instance': 'projects/%projectId/instances/%instanceId'
    },
};
