<template>
  <one-column-sidebar-left
      sub-menu-selector="librarypanel"
      back-button-tab-selector="sfxbackbutton"
      tab-selector="assemblytab"
      :id="$route.params.id"
  >
    <info-panel slot="sidebarLeft"
                :id="$route.params.id"
                upload-type="Asset"
                :media-type="$store.getters.getAssemblyType(this.$route.params.id)"
                preview-id="sfxedit"
                :created="$store.getters.getAssemblyCreationDate(id)"
                :last-change="$store.getters.getAssemblyChangeDate(id)"
                :image-editable="true"
                :downloadable="true"
                :organization="$store.getters.getAssemblyOrganization($route.params.id)"
                :preview-uri="$store.getters.getAssemblyPreviewUri($route.params.id)"
    >
<div class="form-section" slot="info" v-if="$store.getters.isSuperAdmin">
  <div class="lighter uppercase">{{ $t('sourceProjectId') }}</div>{{ this.$store.getters.getAssemblySourceProjectId($route.params.id) }}<br />
   <div class="mt-2 mb-2"><span class="lighter"><icon type="folder" /> STORAGE</span><br /><span class="small">{{ `${$store.getters.getAssemblyOrganization($route.params.id)}/assets/${this.$route.params.id}` }}</span></div>
  <router-link class="mt-3 darker p-2" v-if="$store.getters.isSuperAdmin" :to="'/library/3d-data/add-asset-bundle/' + $route.params.id">Edit asset bundle <icon type="external-link" />></router-link>
</div>

</info-panel>
    <info-panel
        slot="sidebarLeft"
        :id="$route.params.id"
        :tags="$store.getters.getAssemblyTags($route.params.id)"
        :taggable="true"
        @removeTag="removeTag"
        @addTag="addTag"
        :key="'infopanel2'"
        preview-id="sfxedit"
        :limit-tags-to-organization="$store.getters.getAssemblyOrganization($route.params.id)"
    />
    <team-attacher
        class="mt-3"
        slot="sidebarLeft"
        store-name="Asset"
        :id="$route.params.id"
        :teams="teams"
        v-if="$store.getters.getAssemblyOrganization($route.params.id)"
        :limit-by-organization-id="$store.getters.getAssemblyOrganization($route.params.id)"
        @added="loadAssetTeams"
        @removed="loadAssetTeams"
    />
    <div slot="mainContent">
      <content-title-block
          v-if="$store.getters.getAssemblyName($route.params.id)"
          :value="$store.getters.getAssemblyName($route.params.id)"
          :id="$route.params.id"
          store-name="Assembly"
          :organization-id="$store.getters.getAssemblyOrganization($route.params.id)"
          icon="assembly"
          :editable="isNotLocked()"
      />
      <div v-if="!isNotLocked()" class="form-error">
        {{ $t('assemblyIsLocked')}}
      </div>
      <content-description-block
          :value="$store.getters.getAssemblyDescription($route.params.id)"
          :id="$route.params.id"
          store-name="Assembly"
          :editable="isNotLocked()"
      />
      <div v-if="$store.getters.isSuperAdmin" class="row">
        <state-setter
          v-if="isNotLocked()"
          class="col-12 col-md-4 mb-2 mt-2"
          :target-id="$route.params.id"
          store-name="Assembly"
          :given-state="$store.getters.getAssemblyState($route.params.id)"
        />
      </div>
      <info-panel v-if="$store.getters.getAssemblyContent($route.params.id) && $store.getters.getAssemblyContent($route.params.id).pipeline && $store.getters.getAssemblyContent($route.params.id).pipeline.files && $store.getters.getAssemblyContentHasPipelineFile($route.params.id)"
                  info-panel-header="importConfig"
                  :collapsible="true"
                  :initially-collapsed="pipelineStatus === 'finished' || !hasRootFile"
                  preview-id="fileProcessing"
      >
        <div class="row p-2" slot="info">
          <div class="form-section col-12 col-md-12 pl-4">
            <div class="row">
              <div v-if="$store.getters.getAssemblyContent($route.params.id) && $store.getters.getAssemblyContent($route.params.id).pipeline.pipeline" class="col-12 col-md-3 lighter">{{ $t('file') }}:</div>
              <div class="col-12 col-md-3 mt-2 lighter">{{ $t('File(s)') }}:</div>
              <div class="col-12 col-md-6 mt-2">
                <div :key="file" v-for="file in $store.getters.getAssemblyContent($route.params.id).pipeline.files">
                  {{ file }}
                </div>
              </div>
              <div class="w-100" />
              <template v-if="$store.getters.getAssemblyContent($route.params.id).pipeline.rootNodeFile"><div class="col-12 col-md-3 mt-2 lighter">{{ $t('rootFile') }}:</div><div class="col-12 col-md-6 mt-2">{{ $store.getters.getAssemblyContent($route.params.id).pipeline.rootNodeFile }}</div></template>
              <div class="w-100" />
              <template v-if="$store.getters.getAssemblyContentPipelineMeta($route.params.id).clientMetaFile"><div class="col-12 col-md-3 mt-2 lighter">{{ $t('metaFile') }}:</div><div class="col-12 col-md-7 mt-2">{{ $store.getters.getAssemblyContentPipelineMeta($route.params.id).clientMetaFile }}</div></template>
              <template v-if="$store.getters.getAssemblyContentPipelineMeta($route.params.id).partIdentifierColumn"><div class="col-12 col-md-3 mt-2 lighter">{{ $t('identifierColumn') }}:</div><div class="col-12 col-md-7 mt-2">{{ $store.getters.getAssemblyContentPipelineMeta($route.params.id).partIdentifierColumn }}</div></template>
              <template v-if="$store.getters.getAssemblyContentPipelineEmail($route.params.id)"><div class="col-12 col-md-3 mt-2 lighter">{{ $t('stepFileEmail') }}:</div><div class="col-12 col-md-7 mt-2">{{ $store.getters.getAssemblyContentPipelineEmail($route.params.id) }}</div></template>
              <template v-if="$store.getters.getAssemblyContentPipelineMeta($route.params.id).metaSet"><div class="col-12 col-md-3 mt-2 lighter">{{ $t('metaSetName') }}:</div><div class="col-12 col-md-7 mt-2">{{ $store.getters.getAssemblyContentPipelineMeta($route.params.id).metaSet.name.split('.').splice(2).join('.') }}</div></template>
            </div>
          </div>
          <div class="col-12 col-md-6 mt-4 pl-4">
            <h2>{{ $t('importSFX') }}</h2>
            <div class="mb-3" v-if="metaDataColumns.length && showPipelineForm() && $store.getters.getAssemblyContent($route.params.id).pipeline.vhubPostProcessors && hasRootFile">
              <assembly-config
                  ref="AssemblyConfig"
                  :meta-data-columns="metaDataColumns"
                  :organization-id="$store.getters.getAssemblyOrganization($route.params.id)"
                  :teams="teams"
                  @setConfig="setMetaConfig"
                  permission="writeMetaSet"
              />
            </div>
            <div v-if="showPipelineForm()"
            >
              <div class="form-item mt-3">
                <label>{{ $t('pivotPlacement') }}</label><info-helper class="ml-1" text="pivotComment" />
                <div class="darker p-1" v-if="oldContent && oldContent.pipeline">{{ $t('previousSelection') }}: {{ oldContent.pipeline.pivotPlacement }}</div>
                <select class="custom-select dark" v-model="pivotPlacement">
                  <option :key="key" :value="key" v-for="(key) in pivotPositions">{{ key }}</option>
                </select>
                </div>
              <div class="form-item mt-3">
                <label for="lod">{{ $t('LodLevels') }}</label><br />
                <div class="darker p-1" v-if="oldContent && oldContent.pipeline">{{ $t('previousSelection') }}: {{ oldContent.pipeline.lod }}</div>
                <input @input="delayTouch($v.lod)" class="form-text" min="0" max="5" type="number" id="lod" name="lod" v-model="lod" />
                <div class="form-error mb-2 mt-2" v-if="$v.lod.$dirty && !$v.lod.between">{{ $t('errors.mustBeValueBetween', {min: 1, max: 5}) }}</div>
              </div>
              <div v-if="$store.getters.isSFXMember || $store.getters.isSuperAdmin" class="form-item mt-3 darker p-2">
              <label for="lod">{{ $t('OutputFormats') }}</label><info-helper class="ml-1" text="outputFormatsHelp" /><br />
                <div :key="index" v-for="(item,index) in Object.keys(available3DOutputFormats)">
                   <input :disabled="item === 'fbx'"  type="checkbox" v-model="selectedOutputFormats[item]" />
                    {{ item }}
                </div>
              </div>
              <div class="w-100"/>
              <label>{{ $t('email') }}</label> <info-helper class="ml-1" text="stepEmailDisclaimer" />
              <div class="darker p-1" v-if="oldContent && oldContent.pipeline">{{ $t('previousSelection') }}: {{ oldContent.pipeline.email }}</div>
              <input type="text"
                     :class="['form-text w-100', $v.email.$dirty && $v.email.$error ? 'form-group--error' : '']"
                     @input="delayTouch($v.email)"
                     v-model="email"
              >
              <div class="form-error mb-2" v-if="$v.email.$dirty && !$v.email.email">{{ $t('errors.mustBeAValidEmailAdress') }}</div>

              <vm-selector :key="'upper'" @selected="setVM" :show-prices="false" />
              <div class="mt-3 mb-1 darker p-3"><h4>{{ $t('Disclaimer') }}</h4>{{ $t('stepFileProcessingDisclaimer') }}</div>
              <div class="form-error" v-if="error">{{ error }}</div>
              <Button :deactivated="!this.$v.email.email || partIdentifierColumnMissing" class="mt-3 icon-rotate-animation make-longer-animation" @click="initiatePiplineFileProcessing">{{ $t('importStart') }} <icon type="cube" /></Button>
            </div>

            <div class="mt-3 bg-bright p-3" v-if="['processing', 'finished'].includes(pipelineStatus) || isProcessing">
              <div v-if="(pipelineStatus === 'processing' || isProcessing) && !showLoading && hasRootFile">
                <div class="mb-2">{{ $t('yourFileIsGettingProcessed') }}</div>
              </div>
              <div v-else-if="pipelineStatus === 'finished' || !hasRootFile">{{ $t('yourFileIsFinishedProcessing') }}</div>
            </div>
            <loading-panel v-if="showLoading" message="pleaseWait" />
            <log-entry-progress class="mt-1" v-if="showProgress || ['processing', 'finished'].includes(pipelineStatus) || !hasRootFile" :id="$route.params.id" />
          </div>
          <div class="col-12 col-md-4 offset-md-1 mt-4" v-if="$store.getters.isSuperAdmin">
            <div class="row darker p-4">
              <div class="col-12">
                <h3>Advanced Section</h3>
              </div>
              <div class="col-12 col-md-4 lighter">{{ $t('stepFileStatus') }}:</div><div class="col-12 col-md-7 mt-2">{{ $t($store.getters.getAssemblyContent($route.params.id).pipeline.status) }}</div>
              <div class="col-12 col-md-4 lighter">{{ $t('priority') }}:</div><div class="col-12 col-md-7 mt-2">{{ $t($store.getters.getAssemblyContent($route.params.id).pipeline.priority) }}</div>

              <div class="col-12">
                 <div class="form-item mt-3">
                  <input type="checkbox" id="deleteExistingAssemblyParts" name="deleteExistingAssemblyParts" v-model="deleteExistingAssemblyParts" @change="updateSfxContent">
                  <label for="deleteExistingAssemblyParts">&nbsp;Vorher importierte Teile löschen </label><info-helper class="ml-1" text="Es werden sowohl die Dateien als auch die Assets und Instanzen gelöscht – nur verwenden, wenn man sicher ist" />
                </div>
                <div class="form-item mt-3">
                  <vm-selector :key="'lower'" @selected="setVM" :show-prices="false" />
                  <input type="checkbox" id="sendMails" name="sendMails" v-model="sendMails" @change="updateSfxContent">
                  <label for="sendMails">&nbsp;Emails an Kunden versenden</label><info-helper class="ml-1" text="Emails werden nur beim Extract-Prozess versendet, nicht beim Reimport" />
                </div>
                <div @click="hiddenSection = false">...</div>
                <div v-if="!hiddenSection">
                  <info-helper text="(Reextract bedeutet, mit demselben Step-File einen neuen Extract-Process zu starten – dabei wird jedoch ein neuer rootnode angelegt, d.h. falls schonmal ein Import stattgefunden hat, müsste man den bestehenden rootNode zuerst löschen.)"/>
                  <Button :deactivated="buttonsDisabled" class="mt-3 ml-3 mr-3"
                          @click="initiatePiplineFileProcessing">
                    {{ $t('reExtract') }} <icon type="biohazard"/>
                  </Button>

                  <info-helper text="(Reimport funktioniert nur, wenn der Tessilierungsprozess bereits durchgeführt un die Dateien immer noch auf der VM sind. Und wenn ein neuer Verarbeitungsprozess gestartet wird, werden die alten FBX-Dateien auf der VM gelöscht, also Achtung.)"/>

                  <Button :deactivated="buttonsDisabled" class="mt-3 ml-3 mr-2"
                          @click="reImport">
                    {{ $t('reImport') }} <icon type="recycle"/>
                  </Button>
                </div>
              </div>
              <!--<info-helper text="(Man kann erneut ein File hochladen & den extract Prozess erneut starten – dabei wird jedoch ein neuer rootnode angelegt, d.h. falls schonmal ein Import stattgefunden hat, müsste man  den bestehenden rootNode zuerst löschen.)" />-->


            </div>
          </div>
        </div>
      </info-panel>

      <div class="row">
        <div class="col-12 col-lg-6 no-gutters">
          <info-panel
              preview-id="metasetattacher"
              :collapsible="true"
              :initially-collapsed="true"
              info-panel-header="sfx edit"
              v-if="$store.getters.isSuperAdmin"
          >
            <meta-set-attacher
                slot="info"
                :target-id="$route.params.id"
                store-name="Asset"
                @added="loadSfx"
                @removed="loadSfx"
                :given-meta-sets="$store.getters.getAssemblyMetaSets($route.params.id)"
            />
          </info-panel>
        </div>
        <template v-if="Object.keys($store.getters.getAssemblyMetaSets($route.params.id)).length">
          <meta-panel
              class="col-12 col-lg-6"
              type="Assembly" :key="item.id" v-for="(item) in $store.getters.getAssemblyMetaSets($route.params.id)"
                      :title="item.description" :fields="item.metaFields" icon-left="folder"
                      store-name="Assembly"
                      :organization-id="$store.getters.getAssemblyOrganization($route.params.id)"
              @updated="loadSfx"
          />
        </template>
        <div v-if="$store.getters.isSuperAdmin" class="col-12">
          <info-panel
          preview-id="vmlogs"
          :collapsible="true"
          :initially-collapsed="true"
          info-panel-header="VM-Logs"
        >
          <log-viewer slot="info" @entryDeleted="loadSfx()" :data="$store.getters.getAssemblyVMLogs($route.params.id)" />
        </info-panel>
        </div>
        <meta-set-attacher
                      slot="info"
                      :target-id="$route.params.id"
                      store-name="Asset"
                      @added="loadSfx"
                      @removed="loadSfx"
                      :given-meta-sets="$store.getters.getAssemblyMetaSets($route.params.id)"
                  />
      </div>
    </div>
  </one-column-sidebar-left>
</template>

<script>
import InfoPanel from "../components/InfoPanel";
import OneColumnSidebarLeft from "../layouts/OneColumnSidebarLeft";
import ContentTitleBlock from "../components/ContentTitleBlock";
import ContentDescriptionBlock from "../components/ContentDescriptionBlock";
import MetaPanel from "../components/widgets/meta/MetaPanel";
import Button from "../components/forms/Button";
import { email, between } from 'vuelidate/lib/validators';
import InfoHelper from "../components/InfoHelper";
import Icon from "../components/Icon";
import { mapState } from 'vuex';
import VuelidateMixin from "@/components/mixins/VuelidateMixin.js";
import AssemblyConfig from "@/components/assemblies/MetaConfig";
import MetaSetAttacher from "@/components/widgets/meta/MetaSetAttacher";
import TeamAttacher from "@/components/organizations/TeamAttacher";
import StateSetter from "@/components/StateSetter";
import { PivotPositions, TaskManagerTaskTypes, available3DOutputFormats } from "@/enum";
import vmSelector from "@/components/vmSelector";
import LogViewer from "@/components/LogViewer";
import LogEntryProgress from "@/components/LogEntryProgress";
import LoadingPanel from "@/components/LoadingPanel";

export default {
  name: 'SFXEdit',
  components: {
    InfoPanel,
    MetaPanel,
    OneColumnSidebarLeft,
    ContentTitleBlock,
    ContentDescriptionBlock,
    Button,
    InfoHelper,
    Icon,
    AssemblyConfig,
    MetaSetAttacher,
    TeamAttacher,
    StateSetter,
    vmSelector,
    LogViewer,
    LogEntryProgress,
    LoadingPanel
  },
  mixins: [VuelidateMixin],
  data() {
    return {
      oldContent: null,
      showLoading: false,
      showProgress: false,
      lod: 2,
      hiddenSection: true,
      error: '',
      id: '',
      content: {},
      email: this.$store.getters.getUserEmail(),
      statusMessage: '',
      buttonsDisabled: false,
      sendMails: true,
      deleteExistingAssemblyParts: false,

      metaDataColumns: [],

      pipelineStatus: 'finished',
      isProcessing: false,
      pipelineTask: '',

      metaConfig: {},

      partIdentifierColumnMissing: true,

      teams: [],
      pivotPlacement: 'source',
      pivotPositions: PivotPositions,
      TaskManagerTaskTypes: TaskManagerTaskTypes,

      vm: '',
      hasRootFile: true,

      available3DOutputFormats: available3DOutputFormats,
      selectedOutputFormats: {"fbx": true}
    };
  },
  computed: mapState({
    AssemblyList: state => state.dynamicStore.AssemblyLists.AssemblyList,
  }),
  watch: {
    AssemblyList: {
      // This will let Vue know to look inside the array
      deep: true,

      // We have to move our method to a handler field
      handler() {
        if(this.$store.getters.getAssemblyContent(this.$route.params.id)) {
          this.content = this.$store.getters.getAssemblyContent(this.$route.params.id);
          if(this.content.pipeline) {
            this.sendMails = this.content.pipeline.sendMails !== undefined ? this.content.pipeline.sendMails : true;
            this.deleteExistingAssemblyParts = this.content.pipeline.deleteExistingAssemblyParts !== undefined ? this.content.pipeline.deleteExistingAssemblyParts : false;
            this.email = this.content.pipeline.email ? this.content.pipeline.email : this.$store.getters.getUserEmail();
            if(this.content.pipeline.pivotPlacement) {
              this.pivotPlacement = this.content.pipeline.pivotPlacement;
            }
            if(this.content.pipeline.rootNodeFile) {
              if(!(this.content.pipeline.files.length === 1 && this.content.pipeline.files[0].includes('.zip'))) {
                this.checkIfFileExists(this.content.pipeline.rootNodeFile);
              }
            }
          }
          if(this.$store.getters.getAssemblyContentPipelineMeta(this.$route.params.id).columns) {
            this.metaDataColumns = this.$store.getters.getAssemblyContentPipelineMeta(this.$route.params.id).columns;
          }
          else {
            this.partIdentifierColumnMissing = false;
          }
          if(this.$store.getters.getAssemblyPipelineStatus(this.$route.params.id)) {
            this.pipelineStatus = this.$store.getters.getAssemblyPipelineStatus(this.$route.params.id);
            this.pipelineTask = this.$store.getters.getAssemblyPipelineTask(this.$route.params.id);
            if(this.pipelineStatus === 'processing') {
              this.isProcessing = true;
              this.showProgress = true;
            }

          }
        }
      }
    },
  },
  validations: {
    email: {
      email,
    },
    lod: {
     between: between(0, 5)
    }
  },
  beforeMount() {
    this.loadAssetTeams();
    this.loadNewestVMLog();
    this.loadSfx();
  },
  methods: {
  isNotLocked() {
    return ![this.TaskManagerTaskTypes.VERSION_CREATE, this.TaskManagerTaskTypes.VERSION_DELETE, this.TaskManagerTaskTypes.VERSION_RESTORE].includes(this.pipelineTask)
  },
  loadNewestVMLog() {
    this.$store.dispatch('clientLoadVMLogs', {filter: `objectId eq ${this.$route.params.id}`, sort: '-createdAt', limit: 1})
      .then(data => {
        this.oldContent = data[0].config;
        const conf = this.oldContent.pipeline;
        this.pivotPlacement = conf.pivotPlacement;
        this.lod = conf.lod;
    })
  },
    setVM(id) {
      this.vm = id;
    },
    loadAssetTeams() {
      this.$store.dispatch('clientLoadAssetTeams', {
        id: this.$route.params.id
      }).then(data => {
        this.teams = data;
      });
    },
    loadSfx: function() {
      console.log('loadSfx')
      this.$store.dispatch('loadAssembly', {
        id: this.$route.params.id,
        include: 'tags,metaSets,teams,VMLogs',
        addPipelineState: true,
      }).then(async asset => {
        await this.checkAccess(asset);
      }).catch(e => {
        if(e.status === 401) {
          this.denyAccess();
        }
      });
      this.id = this.$route.params.id;
    },
    /**
    * Checks if the root file in the content field exists
    * if it does not, remove the edit form to start the pipeline
    * */
    async checkIfFileExists(fileName) {
        console.log(fileName);
        await this.$store.dispatch('clientGetAssetInfo', {id: this.$route.params.id, key: fileName}).then(res => {
          console.log(res);
        }).catch(() => {
          this.hasRootFile = false;
        })
    },
    async checkAccess(asset) {
       const hasAccess = await this.$store.dispatch('checkTeamOrgPermission', {
              op: 'writeMetaSet',
              teams: asset.teams,
              organizationId: asset.organizationId
            })
          if(!hasAccess) {
            this.denyAccess();
          }
    },
    denyAccess() {
      this.$router.push('/access-denied');
    },
    removeTag: function(tag) {
      this.$store.dispatch('removeAssemblyTag', {
        id: this.$route.params.id,
        args: [tag.id],
      });
    },
    addTag: function(tag) {
      this.$store.dispatch('addAssemblyTag', {
        id: this.$route.params.id,
        args: [tag.id],
      });
    },
    initiatePiplineFileProcessing: async function() {
      this.error = '';
      this.buttonsDisabled = true;
      if(this.email) {
        if(!this.$v.email.email) {
          return;
        }
      }
      if(this.content) {
        this.content.pipeline.status = 'cc: extract procedure initiated';
        this.content.pipeline.email = this.email ? this.email : this.$store.getters.getUserEmail(this.$store.getters.getCurrentUserId);
        this.content.pipeline.pivotPlacement = this.pivotPlacement;
        this.content.pipeline.lod = this.lod;
        this.content.pipeline.user = this.$store.getters.getCurrentUserId;
        if(this.$refs.AssemblyConfig) {
          await this.$refs.AssemblyConfig.save();
        }
        this.content.pipeline.outputFormats = Object.keys(this.selectedOutputFormats).filter(key => {return !!this.selectedOutputFormats[key]});

        if(this.metaConfig && Object.keys(this.metaConfig).length) {
         this.content.pipeline.meta = {clientMetaFile: this.content.pipeline.meta.clientMetaFile, ...this.metaConfig};
        }
        this.isProcessing = true;
        this.updateSfxContent().then(() => {
          return this.startFileTransfer();
        });
      }
    },
    async updateSfxContent() {
      this.content.pipeline.sendMails = this.sendMails;
      this.content.pipeline.deleteExistingAssemblyParts = this.deleteExistingAssemblyParts;
      return this.$store.dispatch('updateAssembly', {
        id: this.$route.params.id,
        content: JSON.stringify(this.content),
      });
    },
    resetStatus() {
      this.content.pipeline.status = 'initiallyUploaded';
      this.$store.dispatch('updateAssembly', {
        id: this.$route.params.id,
        content: JSON.stringify(this.content),
      });
      this.$store.dispatch('loadAssembly', {
        id: this.$route.params.id,
        include: 'tags,metaSets'
      });
    },
    async startFileTransfer() {
      this.buttonsDisabled = true;
      this.showLoading = true;
      await this.$store.dispatch('clientAssetToPipeline', {
        id: this.$route.params.id,
        type: 'extract',
        virtualMachine: this.vm,
      }).then(() => {
        this.loadSfx();
        this.showProgress = true;
        this.showLoading = false;
      }).catch(res => {
        this.error = res.response.errors.message;
        this.isProcessing = false;
      })
    },
    reImport() {
      this.buttonsDisabled = true;
      this.$store.dispatch('clientAssetToPipeline', {
        id: this.$route.params.id,
        type: 'reimport',
        virtualMachine: this.vm,
      }).catch(res => {
        this.error = res.response.errors.message;
        this.isProcessing = false;
      })
    },
    setMetaConfig(metaConfig) {
      this.metaConfig = metaConfig;
      this.partIdentifierColumnMissing = !this.metaConfig.partIdentifierColumn;
    },
    showPipelineForm() {
      return ['new', 'newUpdate'].includes(this.pipelineStatus) && !this.isProcessing && this.hasRootFile;
    }
  },
}
</script>