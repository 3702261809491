<template>
    <div class="orange-switch">
        <select class="custom-select"
                ref="orgswitch"
                @change="updateLanguage"
                v-model="updateValue">
            <option v-for="(langItem, langKey, index) in languages"
                    v-if="langItem.active"
                    :key="index"
                    :value="langKey">
                {{ langItem.name }}
            </option>
        </select>
    </div>
</template>

<script>
    import {Languages} from '@/enum';

    export default {
        name: "LanguageSelector",
        components: {
            // eslint-disable-next-line vue/no-unused-components
            Languages,
        },
        props: {
            value: { type: String, default: null},
        },
        data() {
            return {
                updateValue: '',
                languages: {},
            };
        },
        watch: {
            value: function() {
                this.updateValue = this.value;
            }
        },
        beforeMount() {
            this.updateValue = this.value;
            this.languages = Languages;
        },
        methods: {
            updateLanguage() {
                this.$emit('update', this.updateValue);
            },
        },
    }
</script>

<style scoped>

</style>