<template>
    <one-column-sidebar-left
        back-button-tab-selector="organizationbackbutton"
        tab-selector="organizationtab"
        sub-menu-selector="adminpanel"
        :id="$route.params.id"
        type="organization"
    >
        <info-panel slot="sidebarLeft"
                    :organization-id="$route.params.id"
                    :id="$route.params.id"
                    :preview-uri="$store.getters.getOrganizationPreviewUri(this.$route.params.id)"
                    :image-editable="true"
                    :created="$store.getters.getOrganizationCreationDate($route.params.id)"
                    :last-change="$store.getters.getOrganizationChangeDate($route.params.id)"
                    preview-id="organizationEdit"
                    upload-type="Organization"
                    :organization="$route.params.id"
        />
        <div slot="mainContent">
            <div slot="mainContent">
                <organization-edit-form
                        :organization-id="$route.params.id"
                />
            </div>
</div>
    </one-column-sidebar-left>
</template>

<script>
    import InfoPanel from "../components/InfoPanel";
    import OneColumnSidebarLeft from "../layouts/OneColumnSidebarLeft";
    import OrganizationEditForm from "@/components/forms/OrganizationEditForm";
    export default {
        name: 'OrganizationEdit',
        components: {
          InfoPanel,
          OneColumnSidebarLeft,
          OrganizationEditForm,
        },
        data() {
            return {
                id: '',
            };
        },
        beforeMount() {
            this.id = this.$route.params.id;
        },
        methods: {
            removeTag: function(tag) {
                this.$store.dispatch('removeOrganizationTag', {
                    id: this.$route.params.id,
                    args: [tag.id],
                });
            },
            addTag: function(tag) {
                this.$store.dispatch('addOrganizationTag', {
                    id: this.$route.params.id,
                    args: [tag.id],
                });
            },
            updateTags: function(tags) {
                this.$store.dispatch('updateOrganization', {
                    id: this.$route.params.id,
                    tags: tags,
                });
            },
        },
    }
</script>
