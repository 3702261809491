<template>
    <div>
        <content-title-block
                :value="$store.getters.getUserName(userId)"
                :id="$route.params.id"
                store-name="User"
                icon="user"
        />
        <div class="form-section">
            <label-with-edit-icon-and-content
                    label="firstName"
                    :value="firstName"
                    v-if="!isEditing('firstName')"
                    @edit="setEditing('firstName')"
                    :make-placeholder-space="false"
            />
            <div v-if="isEditing('firstName')">
                <input
                        type="text"
                        :class="['form-text', $v.firstName.$error ? 'form-group--error' : '']"
                        v-model="firstName"
                        @blur="$v.firstName.$touch"
                >
                <div class="edit-trigger mr-2" @click="cancelEditing()">
                    <icon :type="'times'"/>
                </div>
                <div class="edit-trigger" @click="updateUser('firstName')">
                    <icon :type="'save'"/>
                </div>
                <div class="form-error" v-if="!$v.firstName.minLength">
                  {{ $t('errors.atLeastCharacters', {num: 3}) }}
                </div>
            </div>
            <div class="w-100 mt-3"/>
            <label-with-edit-icon-and-content
                    label="lastName"
                    :value="lastName"
                    v-if="!isEditing('lastName')"
                    @edit="setEditing('lastName')"
                    :make-placeholder-space="false"
            />
            <div v-if="isEditing('lastName')">
                <input
                        type="text"
                        :class="['form-text', $v.lastName.$error ? 'form-group--error' : '']"
                        v-model="lastName"
                        @blur="$v.lastName.$touch">
                <div class="edit-trigger mr-2" @click="cancelEditing()">
                    <icon :type="'times'"/>
                </div>
                <div class="edit-trigger" @click="updateUser('lastName')">
                    <icon :type="'save'"/>
                </div>
                <div class="form-error" v-if="!$v.lastName.minLength">
                    {{ $t('errors.atLeastCharacters', {num: 3}) }}
                </div>
            </div>
            <div class="w-100 mt-3"/>
            <label-with-edit-icon-and-content
                    label="email"
                    :value="email"
                    v-if="!isEditing('email')"
                    @edit="setEditing('email')"
                    :make-placeholder-space="false"
            />
            <div v-if="isEditing('email')">
                <input
                        type="text"
                        :class="['form-text', $v.email.$error ? 'form-group--error' : '']"
                        v-model="email"
                        @blur="$v.email.$touch"
                >
                <div class="edit-trigger mr-2" @click="cancelEditing()">
                    <icon :type="'times'"/>
                </div>
                <div class="edit-trigger" @click="updateUser('email')">
                    <icon :type="'save'"/>
                </div>
                <div class="form-error" v-if="!$v.email.email">{{ $t('errors.mustBeAValidEmailAdress') }}</div>
                <div class="form-error" v-if="!$v.email.isUnique">{{ $t('errors.emailAddressAlreadyExists') }}</div>
            </div>

            <div class="w-100 mt-3"/>
            <div v-if="$store.getters.getIdentityProviderName === 'vhub' || true">
                <label-with-edit-icon-and-content
                    label="password"
                    :value="password"
                    :is-password="true"
                    v-if="!isEditing('password')"
                    @edit="setEditing('password')"
            />
            <div class="row darker p-2" v-if="isEditing('password')">
                <div class="col-11">
                    <label class="mt-3">{{ $t('oldPassword') }}*</label>
                    <input
                            :type="showPassword ? 'text' : 'password'"
                            :class="['form-text mb-1', $v.oldPassword.$error ? 'form-group--error' : '']"
                            v-model="oldPassword"
                            @blur="$v.oldPassword.$touch"
                    >
                </div>
                <div class="col-12 col-md-5">
                  <label class="mt-3">{{ $t('newPassword') }}*</label>
                  <input
                        :type="showPassword ? 'text' : 'password'"
                        :class="['form-text mb-1', $v.password.$error ? 'form-group--error' : '']"
                        v-model="password"
                        @blur="$v.password.$touch"
                  >
                  <input type="checkbox" v-model="showPassword" />{{ $t('showPassword') }}
                  <password-checker :password="password" />
                </div>
                <div class="col-12 col-md-5">
                <label class="mt-3">{{ $t('repeatPassword') }}*</label>
                <input type="password"
                       :class="['form-text mb-1', $v.password2.$error ? 'form-group--error' : '']"
                       v-model="password2"
                       @blur="$v.password2.$touch"
                >
                <div class="form-error" v-if="$v.password2.$error">{{ $t('errors.bothPasswordsMustBeTheSame') }}</div>
                </div>
                <div class="col-12 mt-3">
                  <div class="edit-trigger mr-2" @click="cancelEditing()">
                      <icon :type="'times'"/>
                  </div>
                  <div class="edit-trigger" @click="updateUser('password')">
                      <icon :type="'save'"/>
                  </div>
                </div>
            </div>
            </div>
        </div>
        <div class="form-section">
            <label-with-edit-icon-and-content
                    label="appPanel"
                    :value="appPanel"
                    v-if="!isEditing('appPanel')"
                    @edit="setEditing('appPanel')"
                    :make-placeholder-space="false"
            />
            <div v-if="isEditing('appPanel')">
                <input
                        type="text"
                        :class="['form-text', $v.email.$error ? 'form-group--error' : '']"
                        v-model="appPanel"
                        placeholder="trest"
                >
                <div class="edit-trigger mr-2" @click="cancelEditing()">
                    <icon :type="'times'"/>
                </div>
                <div class="edit-trigger" @click="updateUser('appPanel')">
                    <icon :type="'save'"/>
                </div>
            </div>
        </div>
        <div class="form-section">
          <info-panel
              :info-panel-header="$t('organizations')"
              :collapsible="true"
              :initially-collapsed="false"
              preview-id="none"
          >
            <div slot="info" class="container-fluid no-gutters" v-if="organizations">
                <div class="row">
                    <div class="w-100">
                        <view-switcher
                                class="mb-3 mt-3"
                                id="orgTag"
                                :table="true"
                                :thumbnails="true"
                        />
                    </div>
                    <organization-tag
                            :view-style="$store.getters.getViewSwitcher($route.name, 'orgTag')"
                            @removeorg="(obj) => {removeItemUser(obj,'Organization' )}"
                            @update="(orgId,item) => {updateItemUser(orgId, item, 'Organization')}"
                            @setDefaultItem="(id) => {updateItemUser(id, {default: true}, 'Organization')}"
                            :is-removable="(orgEditPermissions.includes(org.id) || $store.getters.isSuperAdmin) && !$store.getters.isSuperOrg(org.id)"
                            :is-editable="orgEditPermissions.includes(org.id) || $store.getters.isSuperAdmin"
                            :show-details="!orgEditPermissions.includes(org.id)"
                            v-for="org in organizations"
                            v-if="organizations.length"
                            :key="org.id"
                            :org="org"
                            icon-left="user"
                            :is-default="defaultOrganizationId === org.id"
                    />
                    <div v-if="!organizations">{{ $t('noOrgsAvailable') }}</div>
                  <universal-selector
                      class="mt-2"
                      v-if="editAllUserData || $store.getters.isSuperAdmin"
                      @addItem="addItemUser"
                      :exclude-from-item-list="organizations"
                      list-name="userOrgList"
                      store-name="Organization"
                      filter-attribute="displayName"
                      :tag-condition="{
                      fieldName: 'visibility',
                      '0': 'user',
                    }"
                  />
                </div>
            </div>
          </info-panel>

          <info-panel
              class="mt-5"
              :info-panel-header="org.displayName + ': ' + $t('teams')"
              :collapsible="true"
              :initially-collapsed="false"
              preview-id="noneNone"
              v-for="org in organizations"
              :key="org.id + 'teams'"
              v-if="organizations"
          >
          <div class="container-fluid no-gutters" slot="info">
            <div class="row">
              <div class="w-100">
                <view-switcher
                    class="mb-3 mt-3"
                    id="teamTag"
                    :table="true"
                    :thumbnails="true"
                />
              </div>
              <team-tag
                  :view-style="$store.getters.getViewSwitcher($route.name, 'teamTag')"
                  @removeteam="(obj) => {removeItemUser(obj,'Team' )}"
                  @update="(orgId,item) => {updateItemUser(orgId, item, 'Team')}"
                  @setDefaultItem="(id) => {updateItemUser(id, {default: true}, 'Team')}"
                  :is-removable="(teamEditPermissions.includes(team.id) || $store.getters.isSuperAdmin) && team.name !== $store.getters.getUserName(userId)"
                  :is-editable="teamEditPermissions.includes(team.id) || $store.getters.isSuperAdmin"
                  :show-details="!teamEditPermissions.includes(team.id) || $store.getters.isSuperAdmin"
                  v-for="team in teams[org.id]"
                  :key="team.id"
                  :team="team"
                  icon-left="users"
                  :is-default="team.default"
              />
             <div v-if="!teams">{{ $t('noOrgsAvailable') }}</div>
              <universal-selector
                  class="mt-2"
                  v-if="orgEditPermissions.includes(org.id)"
                  @addItem="(item) => {addItemUser(item, 'Team')}"
                  :exclude-from-item-list="teams[org.id]"
                  list-name="userTeamList"
                  store-name="Team"
                  filter-attribute="displayName"
                  :show-organization="true"
                  :limit-by-organization-id="org.id"
                  :key="forceReRenderKey"
                  tag-icon="users"
              />
            </div>
          </div>
          </info-panel>
          <div v-if="$store.getters.isSuperAdmin" class="row">
            <div class="col-12 col-md-4">
              <select @change="updateUser('blocked')" v-model="blocked" class="custom-select" >
                <option :value="true">blocked</option>
                <option :value="false">unblocked</option>
              </select>
          </div>
          </div>
        </div>
    </div>
</template>m

<script>
    import {required, minLength, email, sameAs} from 'vuelidate/lib/validators'
    import Icon from "../Icon";
    import LabelWithEditIconAndContent from "../LabelWithEditIconAndContent";
    import UniversalSelector from "../organizations/UniversalSelector";
    import OrganizationTag from "../organizations/OrganizationTag";
    import TeamTag from "@/components/organizations/TeamTag";
    import ContentTitleBlock from "../ContentTitleBlock";
    import ViewSwitcher from "../widgets/ViewSwitcher";
    import InfoPanel from "@/components/InfoPanel";
    import {AdminData} from "@/enum";
    import PasswordChecker from "@/components/forms/PasswordChecker";

    export default {
        name: "UserEditForm",
        components: {
          Icon,
          LabelWithEditIconAndContent,
          UniversalSelector,
          OrganizationTag,
          ContentTitleBlock,
          ViewSwitcher,
          InfoPanel,
          TeamTag,
          PasswordChecker
        },
        props: {
            userId: {type: String, default: null},
        },
        data() {
            return {
                blocked: 0,
                showPassword: false,
                AdminData: AdminData,
                appPanel: '',
                editAllUserData: false,
                orgEditPermissions: [],
                teamEditPermissions: [],
                fieldEditing: '',
                enableSaving: false,
                name: '',
                firstName: '',
                lastName: '',
                email: '',
                oldPassword: '',
                password: '',
                password2: '',
                error: '',
                role: '',
                organizations: [],
                teams: {},
                defaultOrganizationId: '',
                defaultTeamId: '',
                forceReRenderKey: 0
            };
        },
        mounted() {
            this.loadUser();
            this.loadUserOrganizations();
            this.loadUserTeams();
        },
        validations: {
            name: {
                required,
                minLength: minLength(3),
                async isUnique(value) {
                    // standalone validator ideally should not assume a field is required
                    if (value === '') {
                        return true;
                    }
                    let bool = true;
                    await this.$store.dispatch('checkIfUserNameExists', {name: value, type: 'User', value: value}).then(data => {
                        if (data.length > 0 && data[0]) {
                            if (data[0].id === this.userId) {
                                bool = true;
                            }
                        } else {
                            bool = data.length === 0;
                        }
                    });
                    return bool;
                }
            },
            firstName: {
                required,
                minLength: minLength(3)
            },
            lastName: {
                required,
                minLength: minLength(3)
            },
            email: {
                required,
                email,
                async isUnique(value) {
                    if (value === '') {
                        return true;
                    }
                    let bool = true;
                    await this.$store.dispatch('checkIfUserEmailExists', value).then(data => {
                        if (data.length > 0 && data[0]) {
                            if (data[0].id === this.userId) {
                                bool = true;
                            }
                        } else {
                            bool = data.length === 0;
                        }
                    });
                    return bool;
                }
            },
            oldPassword: {
              required
            },
            password: {
                required,
                minLength: minLength(6)
            },
            password2: {
                required,
                sameAsPassword: sameAs('password')
            },
        },
        methods: {
            loadUser() {
              let listName = 'userList';
              if(this.userId === this.$store.getters.getCurrentUserId) {
                  listName = 'globalUser';
              }
              this.$store.dispatch('loadUser', {
                id: this.userId,
                include: 'organizations,teams',
                listName: listName
              }).then(data => {
                delete data.teams;
                delete data.organizations;
                this.setData(data);
              });
            },
            loadUserTeams() {
              this.$store.dispatch('clientLoadUserTeams', {id: this.userId}).then(async data => {
                this.teams = {};
                data.map(team => {
                  if(!this.teams[team.organizationId]) {
                    this.teams[team.organizationId] = [];
                  }
                  this.teams[team.organizationId].push(team);
                })
                await this.setPermissionPerTeam(data);

                this.forceReRenderKey++;
              })
            },
            async setPermissionPerTeam(data) {
                for(let i = 0; i < data.length; i++) {
                    const hasTeamAccess = await this.$store.dispatch('checkTeamOrgPermission', {
                      op: 'writeTeam',
                      organizationId: null,
                      teams: [{id: data[i].id}]});
                    if(hasTeamAccess) {
                      this.teamEditPermissions.push(data[i].id);
                    }
                  }
            },
            loadUserOrganizations() {
              this.$store.dispatch('clientLoadUserOrganizations', {id: this.userId, include: ['requiredUser']}).then(async data => {
                this.organizations = data;

                const filtered = this.organizations.filter(item => {
                  return item.members && item.members[0] && item.members[0].relation.default;
                });
                if(filtered && filtered.length) {
                  this.defaultOrganizationId = filtered[0].id;
                }
                await this.setPermissionPerOrganization();
              })
            },
            async setPermissionPerOrganization() {
              for(let i = 0; i < this.organizations.length; i++) {
                  const hasOrgAccess = await this.$store.dispatch('checkTeamOrgPermission', {
                    op: 'writeOrganization',
                    organizationId: this.organizations[i].id});
                    if(hasOrgAccess) {
                      this.orgEditPermissions.push(this.organizations[i].id);
                    }
                }
            },
            setData(data) {
              const disattachedData = JSON.parse(JSON.stringify(data));
                Object.keys(disattachedData).map(key => {
                  this[key] = disattachedData[key];
                })
            },
            setEditing(fieldName) {
                this.fieldEditing = fieldName;
            },
            isEditing(fieldName) {
                return this.fieldEditing === fieldName;
            },
            cancelEditing: function () {
                this.fieldEditing = '';
                this.loadUser();
            },
            removeItemUser: function (obj, type = 'Organization') {
                this.$store.dispatch(`clientRemove${type}Member`, {
                        id: obj.id,
                        cid: this.id,
                        args: [this.userId],
                    }
                ).then(() => {
                  this.loadUser();
                  this.loadUserOrganizations();
                  this.loadUserTeams();
                });
            },
            addItemUser: function (org, type = 'Organization') {
                this.$store.dispatch(`clientAdd${type}Member`, {
                        id: org.id,
                        cid: this.id,
                        args: [{
                          userId: this.id,
                          role: type === 'Organization' && org.id !== this.AdminData.vHubOrganizationId ? 'none' : 'viewer',
                        }],
                    }
                ).then(() => {
                  this.loadUser();
                  this.loadUserOrganizations();
                  this.loadUserTeams();
                });
            },
            updateItemUser: function (organizationId, memberObject, type = 'Organization') {
              this.$store.dispatch(`clientUpdate${type}Member`, {
                    id: organizationId,
                    cid: this.userId,
                    ...memberObject
                  }
              ).then(() => {
                this[`loadUser${type}s`]();
                this.loadUser();
              });
            },
            updateUser: function (fieldName) {
                if (!this.$v.$anyError) {
                    let args = {
                        [fieldName]: this[fieldName],
                        id: this.userId
                    };
                    if(fieldName === 'password') {
                      args.oldPassword = this.oldPassword;
                    }
                    this.$store.dispatch('updateUser', args).then(data => {
                      this.setData(data);
                    });
                    this.cancelEditing();
                }
            },
        },
    }
</script>