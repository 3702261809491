<template>
    <main-table-layout
        sub-menu-selector="adminpanel"
    >
        <div slot="buttonsRight"/>
        <content-filter slot="filter"
                        id="teamList"
                        @filter="filtering"
                        store-name="Team"
        />
        <view-switcher
                slot="mainContentButtons"
                id="mainContent"
                :table="true"
                :thumbnails="true"/>
        <pagination
                slot="mainContentButtons"
                :limit="paginationLimit"
                :total="$store.getters.getTeamListOptions(listName, 'pagination_items')"
                id="teamtablepage"
                @setActive="loadPaginatedData"
                ref="pagination"
        />
        <Button
            permission="createTeam"
                type="action-button"
                slot="mainContentButtons"
                link="/admin/teams/add"
                icon="plus">
            {{ $t('createNewType', {type: 'team'}) }}
        </Button>

      <Button
           slot="mainContent"
           @click="loadPaginatedData(0); $refs.pagination.setActive(0)"
           type="reload"
           icon="redo"
           class="icon-button"
       />
        <table-widget
                v-if="$store.getters.getViewSwitcher($route.name, 'mainContent') === 'table'"
                slot="mainContent"
                id="appsDefaultTable"
                :fields="fields"
                :data="showPrivateTeams ? $store.getters.getTeamsByListName(listName) : $store.getters.getOwnOrPublicTeamsByListName($store.getters.getCurrentUserId, listName)"
                :title-as-links="true"
                :nav-by-clicking-row="true"
                :deletable="true"
                @deleteRow="deleteRow"
                :show-tags="true"
                :show-organization="true"
                edit-permission="writeTeam"
                delete-permission="deleteTeam"
                :loading="loading"
                @sort="sort"
        />
        <thumbnails
                v-if="$store.getters.getViewSwitcher($route.name, 'mainContent') === 'thumbnails'"
                slot="mainContent"
                id="appsDefaultTable"
                :fields="thumbnailFields"
                :data="showPrivateTeams ? $store.getters.getTeamsByListName(listName) : $store.getters.getOwnOrPublicTeamsByListName($store.getters.getCurrentUserId, listName)"
                :title-as-links="true"
                :deletable="true"
                @deleteRow="deleteRow"
                :nav-by-clicking-row="true"
                edit-permission="writeTeam"
                delete-permission="deleteTeam"
                :loading="loading"
                 @sort="sort"
        />
    </main-table-layout>
</template>

<script>
    import Button from "../components/forms/Button";
    import TableWidget from "../components/widgets/TableWidget";
    import ContentFilter from "../components/widgets/filter/ContentFilter";
    import MainTableLayout from "../layouts/MainTableLayout";
    import ViewSwitcher from "../components/widgets/ViewSwitcher";
    import Thumbnails from "../components/widgets/ThumbnailView";
    import Pagination from "../components/Pagination";

    export default {
        name: 'Teams',
        components: {
            Button,
            TableWidget,
            ContentFilter,
            MainTableLayout,
            ViewSwitcher,
            Thumbnails,
            Pagination,
        },
        data() {
            return {
                sorting: '-updatedAt',
                showPrivateTeams: false,
                loading: true,
                offset: 0,
                filter: {},
                listName: 'TeamList',
                paginationLimit: 15,
                queryRelevantComponents: 2, //pagination and filter
                queryRelevantInits: 0,
                fields: [{
                      name: '',
                      id: 'icon',
                      type: 'icon',
                      virtual: true,
                      fieldId: 'users'
                    },
                    {
                        name: 'Name',
                        id: 'displayName',
                        type: 'string',
                        sortable: true
                    },
                    {
                        name: 'visibility',
                        id: 'visibility',
                        type: 'teamvisibility',
                        sortable: true
                    },
                    {
                        name: 'Last Update',
                        id: 'updatedAt',
                        type: 'date',
                        sortable: true
                    },
                    {
                        name: 'Edit',
                        type: 'editButton'
                    },
                ],
                thumbnailFields: [{
                    name: '',
                    id: 'icon',
                    type: 'icon',
                    virtual: true,
                    fieldId: 'type'
                },
                    {
                        name: 'Organization',
                        id:'organization',
                        type: 'organization',
                        sortable: true
                    },
                    {
                        name: 'Last Update',
                        id: 'updatedAt',
                        type: 'date',
                        sortable: true
                    },
                    {
                        name: 'Name',
                        id: 'name',
                        type: 'string',
                        sortable: true
                    },
                    {
                        name: 'Tags',
                        id:'tags',
                        type: 'tags',
                    },
                    {
                        name: 'Edit',
                        type: 'editButton'
                    },
                ]
            }
        },
        watch: {
            queryRelevantInits: function(newval) {
                if(newval === this.queryRelevantComponents) {
                    this.loadPaginatedData(0, false);
                }
            }
        },
        beforeMount() {
          this.getViewPermission();
        },
        methods: {
            async getViewPermission() {
              this.showPrivateTeams = await this.$store.dispatch('checkTeamOrgPermission', {
                op: 'administerPrivateTeams',
                orgPermissionOnly: true,
                opOnly: true
              });
            },
            loadTeams(initial = false) {
              this.loading = true;
              let args =  {
                listName: this.listId,
              }
              if(this.filter) {
                if (this.filter.add) { args.add = this.filter.add}
                if (this.filter.remove) { args.remove = this.filter.remove }
              }
              const paginationAddOn = {
                limit: {
                  default: this.paginationLimit
                },
                offset: {
                  default: this.offset,
                },
                paging: {
                  default: true
                },
                sort: {
                  [this.listName]: this.sorting,
                }
              }
              args.add = this.filter.add ? {...paginationAddOn, ...this.filter.add } : paginationAddOn;
              if(!initial || this.queryRelevantInits >= this.queryRelevantComponents) {
                this.$store.dispatch('loadTeams', args).then(data => {
                  this.list = data;
                  this.loading = false;
                });
              } else {
                this.$store.dispatch('registerTeamQueryParams', {listName: this.listId, params: args});
              }
            },
            deleteRow: function (id) {
                this.$store.dispatch('deleteTeam', {
                    id: id
                }).then(() => {
                  this.loadPaginatedData(0, false);
                });
            },
            filtering(val, initial) {
              this.queryRelevantInits++;
              this.filter = val;
              this.loadTeams(initial);
            },
            loadPaginatedData(offset, initial) {
              this.queryRelevantInits++;
              this.offset = offset;
              this.loadTeams(initial);
            },
          sort(args) {
              const {fieldname, direction} = args;
              const sortString = direction + fieldname;
              if(this.sorting !== sortString) {
                this.sorting = sortString;
              }
              this.loadTeams(false);
            }
        }
    }
</script>
