<template>
    <div v-if="display" :class="[type, animatingClass]">
    <div @click="() => {if(!deactivated)$emit('click')}" :class="['form-submit-button', 'button', deactivated ? 'deactivated' : '']">
        <router-link v-if="link && !deactivated" :to="link">
             <!-- @slot Use this slot for button text -->
            <div class="content"><icon v-if="icon" :type="icon"/><slot/></div>
        </router-link>
        <div class="a" v-else>
            <div class="content"><icon v-if="icon" :type="icon"/><slot/></div>
        </div>
    </div>
    </div>
</template>

<script>
    /** 
     * vHub button
     */
    import Icon from '../Icon';

    export default {
        name: 'Button',
        components: {
            Icon
        },
        props: {
            /**
             * Router link
             */
            link : { type: String, default: null},
            /**
             * Icon
             */
            icon : { type: String, default: null},
            /**
             * Type of button
             * @value button, action-button
             */
            type: { type: String,  default: 'button'},
            /**
             * Button state
             */
            deactivated: { type: Boolean, default: false},
            permission: {type: String, default: ''},

            /**
             * Button state: use this class to turn on/off the animations
             * possible values:
             *  - turning
             */
            animatingClass: {type: String, default: ''},
        },
        data() {
          return {
            display: true,
          };
        },

        beforeMount() {
          if(this.permission) {
             this.display = false;
             this.checkPermission();
          }
        },
        methods: {
          async checkPermission() {
            this.display = await this.$store.dispatch('checkTeamOrgPermission', {
              op: this.permission,
              opOnly: true,
            })
          }
        }
    }
</script>

<style lang="scss">
    .action-button {
        clear:both;
        float:right;
        .button a {
            background-color: $highlight;
            color: #fff;
        }
    }
    .button {
        display:inline-block;
        position: relative;
        text-transform: uppercase;
        font-family: 'DIN Pro Medium', sans-serif;
        cursor:pointer;
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
        min-width:90px;
        .slim {
          font-family: 'DIN Light', sans-serif;
        }
        a, .a {
            display:block;
            text-align: left;
            background-color: $default-button-background;
            height: 100%;

            color: $default-button-font-color;
            -webkit-transition: all 300ms ease;
            transition: all 300ms ease;
            box-sizing: content-box;
            .content {
              position:relative;
              -webkit-transition: all 300ms ease;
              transition: all 300ms ease;
              padding: 10px 16px;
              height: $input-default-height;
              display:block;
              width: 100%;
              .icon {
                margin-top: -1px;
              }
            }
        }
        &.highlight a, &.highlight .a {
             background-color: $button-highlight-background;
         }
        &.brighter a, &.brighter .a {
          background-color: lighten($default-button-background, 20%);
        }
      &.alert {
        padding:0;
        a, .a {
          background-color: $alert;
        }
      }
        &.deactivated {
            a, .a {
                background-color: $deactivated-button-background !important;
                cursor: not-allowed;
            }
        }
        &:hover {
            a, .a {
                color: $default-button-font-color;
                background-color: $button-hover-background;
            }
        }

        .icon {
            margin-left:10px;
            float:right;
            font-size:1.3em;
            //margin-top:-0.00em;
            -webkit-transition: all 300ms ease;
            transition: all 300ms ease;
          &.left {
            float:left;
            margin-right: 10px;
            margin-left:0;
          }
        }
      &.make-longer-animation:hover {
        a, .a {
          padding-right:25px;
        }
      }
      &.icon-rotate-animation:hover {
        .icon {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
      &.icon-wiggle-animation:hover {
        .icon {
          -webkit-transform: rotate(-10deg);
          transform: rotate(-10deg);
        }
      }
      &.move-to-right-animation:hover {
        .icon {
          -webkit-transform: translateX(5px);
          transform: translateX(5px);
        }
      }
    }
    .info-panel {
        .button {
            &.deactivated {
                a, .a {
                    background-color: darken($deactivated-button-background, 5%) !important;
                }
            }
        }
    }
    .icon-button {
      min-width: auto;
      .button {
        min-width: auto;
        height: $input-default-height;
        width: $input-default-height;
        position:relative;
        .a {
          min-height: 2.3rem;
        }
        .icon {
          position:absolute;
          top:50%;
          left:50%;
          -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
          margin:0;
          padding:0;
        }
      }
    }
</style>

<docs>
Vue component examples:

A simple button
```jsx
    <Button>Click me!</Button>
```

A deactivated button
```jsx
    <Button :deactivated="true">Click me!</Button>
```

An action  button
```jsx
    <Button type="action-button">Click me!</Button>
```

A button with icon
```jsx
    <Button icon="save">Click me!</Button>
```

</docs>
