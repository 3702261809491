<template>
    <main-table-layout
      sub-menu-selector="librarypanel"
    >
        <div slot="buttonsRight"/>
        <content-filter slot="filter"
                        id="datasourceList"
                        @filter="filter"
                        store-name="DataSource"
                        :type-filter-items="['sensor', 'feed']"
        />
        <view-switcher
                slot="mainContentButtons"
                id="mainContent"
                :table="true"
                :thumbnails="true"/>
        <pagination
                slot="mainContentButtons"
                :limit="paginationLimit"
                :total="$store.getters.getDatasourceListOptions(listName, 'pagination_items')"
                id="datasourcetablepage"
                @setActive="loadPaginatedData"
                ref="pagination"
        />
        <Button
            permission="create"
                type="action-button"
                slot="mainContentButtons"
                link="/library/datasource/add"
                icon="plus">
            {{ $t('createNewDataSource') }}
        </Button>

      <Button
           slot="mainContent"
           @click="loadPaginatedData(0); $refs.pagination.setActive(0)"
           type="reload"
           icon="redo"
           class="icon-button"
       />
        <table-widget
                v-if="$store.getters.getViewSwitcher($route.name, 'mainContent') === 'table'"
                slot="mainContent"
                id="appsDefaultTable"
                :fields="fields"
                :data="$store.getters.getDatasourcesByListName(listName)"
                :title-as-links="true"
                @sort="sort"
                :nav-by-clicking-row="true"
                :deletable="true"
                @deleteRow="deleteRow"
                :show-tags="true"
                :show-organization="true"
                edit-permission="write"
                delete-permission="delete"
                :use-team-permission="true"
                :loading="loading"
        />
        <thumbnails
                v-if="$store.getters.getViewSwitcher($route.name, 'mainContent') === 'thumbnails'"
                slot="mainContent"
                id="appsDefaultTable"
                :fields="thumbnailFields"
                 :data="$store.getters.getDatasourcesByListName(listName)"
                :title-as-links="true"
                @sort="sort"
                :deletable="true"
                @deleteRow="deleteRow"
                :nav-by-clicking-row="true"
                edit-permission="write"
                delete-permission="delete"
                :use-team-permission="true"
                :loading="loading"
        />
    </main-table-layout>
</template>

<script>
    import Button from "../components/forms/Button";
    import TableWidget from "../components/widgets/TableWidget";
    import ContentFilter from "../components/widgets/filter/ContentFilter";
    import MainTableLayout from "../layouts/MainTableLayout";
    import ViewSwitcher from "../components/widgets/ViewSwitcher";
    import Thumbnails from "../components/widgets/ThumbnailView";
    import Pagination from "../components/Pagination";

    export default {
        name: 'DataSources',
        components: {
            Button,
            TableWidget,
            ContentFilter,
            MainTableLayout,
            ViewSwitcher,
            Thumbnails,
            Pagination,
        },
        data() {
            return {
                loading: true,
                listName: 'DatasourceList',
                paginationLimit: 15,
                queryRelevantComponents: 2, //pagination and filter
                queryRelevantInits: 0,
                sorting: '-updatedAt',
                fields: [{
                    name: '',
                    id: 'icon',
                    type: 'icon',
                    virtual: true,
                    fieldId: 'type'
                    },
                    {
                        name: 'Name',
                        id: 'name',
                        type: 'string',
                        sortable: true
                    },
                  {
                    name: 'Teams',
                    id: 'teams',
                    type: 'teams',
                    sortable: true
                  },
                    {
                        name: 'Type',
                        id: 'type',
                        type: 'string',
                        sortable: true
                    },
                    {
                        name: 'Last Update',
                        id: 'updatedAt',
                        type: 'date',
                        sortable: true
                    },
                    {
                        name: 'Edit',
                        type: 'editButton'
                    },
                ],
                thumbnailFields: [{
                    name: '',
                    id: 'icon',
                    type: 'icon',
                    virtual: true,
                    fieldId: 'type'
                },
                    {
                        name: 'Organization',
                        id:'organization',
                        type: 'organization',
                        sortable: true
                    },
                    {
                        name: 'Type',
                        id: 'type',
                        type: 'string',
                        sortable: true
                    },
                    {
                        name: 'Last Update',
                        id: 'updatedAt',
                        type: 'date',
                        sortable: true
                    },
                    {
                        name: 'Name',
                        id: 'name',
                        type: 'string',
                        sortable: true
                    },
                    {
                      name: 'Teams',
                      id: 'teams',
                      type: 'teams',
                      sortable: true
                    },
                    {
                        name: 'Tags',
                        id:'tags',
                        type: 'tags',
                    },
                    {
                        name: 'Edit',
                        type: 'editButton'
                    },
                ]
            }
        },
        watch: {
            queryRelevantInits: function(newval) {
                if(newval === this.queryRelevantComponents) {
                    this.loadPaginatedData(0);
                }
            }
        },
        methods: {
            loadPaginatedData: function (offset, initial) {
              this.loading = true;
                let args = {
                    add: {
                        paging: {
                            datasourcedefaultTable: true,
                        },
                        offset: {
                            datasourcedefaultTable: offset,
                        },
                        limit: {
                            datasourcedefaultTable: this.paginationLimit,
                        },
                        include: {
                            datasourcedefaultTable: 'tags,teams',
                        },
                        sort: {
                          [this.listName]: this.sorting,
                        }
                    }
                };
                if(initial) {
                    this.queryRelevantInits++;
                    this.$store.dispatch('registerDatasourceQueryParams', {params: args});
                }
                else {
                    this.$store.dispatch('loadDatasources', args).then(() => {
                    this.loading = false;
                  });
                }
            },
            deleteRow: function (id) {
                this.$store.dispatch('deleteDatasource', [id]).then(() => {
                  this.loadPaginatedData(0);
                })
            },
            filter: function(args, initial) {
                if(initial) {
                    this.queryRelevantInits++;
                    this.$store.dispatch('registerDatasourceQueryParams', {params: args});
                }
                else {
                    this.$store.dispatch('loadDatasources', args).then(() => {
                      this.loading = false;
                   });
                }
            },
            sort(args) {
              const {fieldname, direction} = args;
              const sortString = direction + fieldname;
              if(this.sorting !== sortString) {
                this.sorting = sortString;
              }
              this.loadPaginatedData(0);
            }
        }
    }
</script>
