<template>
    <div :id="uuId + 'dragHandle'">dragHandle</div>
</template>

<script>
    /*
    if you use this mixin, the following things are needed:
    a div:
    <div :id="uuId">dropzone</div>

    afterDroppedMethod: function() {
        do the things after finished dragging here...
    }

    Further Documentation:
    https://interactjs.io/
    * */
    import interact from "interactjs";

    export default {
        name: "DroppableMixin",
        props: {
            uiId: {type: String, required: true}, //the unique identifier used for the div's id
        },
        methods: {
            makeDroppable() {
                let $this = this;
                interact('#' + this.uiId).dropzone({
                    overlap: 0.40,
                    ondragenter: function () {
                        $this.dropping = true;
                    },
                    ondrop: function () {
                        $this.afterDroppedMethod();
                    },
                    ondragleave: function() {
                        $this.dropping = false;
                    },
                });
            },
        }
    }
</script>

