<template>
  <two-column-layout
      tab-selector="mediatab"
      type="media"
      back-button-tab-selector="mediabackbutton"
      :id="$route.params.id"
      sub-menu-selector="librarypanel"
  >
    <div slot="buttonsLeft"/>

    <info-panel
        slot="sidebarLeft"
        :id="$route.params.id"
        :upload-type="'Asset'"
        :created="$store.getters.getMediaCreationDate(id)"
        :last-change="$store.getters.getMediaChangeDate(id)"
        :downloadable="true"
        preview-id="mediaEdit"
        :organization="$store.getters.getMediaOrganization($route.params.id)"
    />
    <info-panel
        slot="sidebarLeft"
        :id="$route.params.id"
        :tags="$store.getters.getMediaTags($route.params.id)"
        :taggable="true"
        preview-id="mediaEdit"
        @removeTag="removeTag"
        @addTag="addTag"
        :key="'infopanel2'"
        :limit-tags-to-organization="$store.getters.getMediaOrganization($route.params.id)"
    />
    <team-attacher
        v-if="$store.getters.getMediaOrganization($route.params.id)"
        class="mt-3"
        slot="sidebarLeft"
        store-name="Asset"
        :id="$route.params.id"
        :teams="teams"
        :limit-by-organization-id="$store.getters.getMediaOrganization($route.params.id)"
        @added="loadAssetTeams"
        @removed="loadAssetTeams"
    />

        <div slot="mainContent">
          <content-title-block
                  v-if="$store.getters.getMediaName(id)"
            :value="$store.getters.getMediaName(id)"
            :id="$route.params.id"
                  store-name="Media"
                  :organization-id="$store.getters.getMediaOrganization($route.params.id)"
                  :icon="$store.getters.getMediaMediaCategoryByName($route.params.id)"

          />
          <content-description-block
                  :value="$store.getters.getMediaDescription(id)"
                  :id="$route.params.id"
                  store-name="Media"
          />

          <div class="row">
            <div class="col-12 col-md-6">
              <h2 class="mt-5">{{ $t('mediaEditFile') }}</h2>
              <p>{{ $t('uploadDisclaimer') }}</p>
              <div class="row">
                <upload-drop-zone
                    class="col-12 col-md-8 mb-5 mt-4"
                    :asset-id="$route.params.id"
                    :preview-uri="$store.getters.getMediaPreviewUri($route.params.id)"
                    target-type="Asset"
                    form-id="uploadMedia"
                    :downloadable="true"
                    :removable="true"
                    :make-chunks="true"
                />
              </div>
            </div>
            <div class="w-100"/>
            <div class="col-12 col-md-6 no-gutters">
              <info-panel
                  preview-id="metasetattacher"
                  :collapsible="true"
                  :initially-collapsed="true"
                  info-panel-header="sfx edit"
                  v-if="$store.getters.isSuperAdmin"
              >
                <meta-set-attacher
                    slot="info"
                    :target-id="$route.params.id"
                    store-name="Asset"
                    @added="loadMedia"
                    @removed="loadMedia"
                    :given-meta-sets="$store.getters.getMediaMetaSets(id)"
                />
              </info-panel>
            </div>
            <meta-panel
                class="col-12 col-md-6"
                :key="item.id"
                v-for="(item) in $store.getters.getMediaMetaSets(id)"
                :title="item.description"
                :fields="item.metaFields"
                icon-left="folder"
                store-name="Media"
                :organization-id="$store.getters.getMediaOrganization(id)"
                @updated="loadMedia"
            />
          </div>
    </div>
  </two-column-layout>
</template>

<script>
    import InfoPanel from "../components/InfoPanel";
    import TwoColumnLayout from "../layouts/OneColumnSidebarLeft";
    import MetaPanel from "../components/widgets/meta/MetaPanel";
    import ContentTitleBlock from "../components/ContentTitleBlock";
    import ContentDescriptionBlock from "../components/ContentDescriptionBlock";
    import UploadDropZone from "../components/files/UploadDropZone";
    import MetaSetAttacher from "@/components/widgets/meta/MetaSetAttacher";
    import TeamAttacher from "@/components/organizations/TeamAttacher";

export default {
  name: 'MediaEdit',
  components: {
        MetaPanel,
        InfoPanel,
        TwoColumnLayout,
        ContentTitleBlock,
        ContentDescriptionBlock,
        UploadDropZone,
        MetaSetAttacher,
        TeamAttacher
  },
        data() {
            return {
                id: '',
                teams: [],
            };
        },
        beforeMount() {
            this.loadMedia();
            this.id = this.$route.params.id;
            this.loadAssetTeams();
        },
  methods: {
    loadAssetTeams() {
      this.$store.dispatch('clientLoadAssetTeams', {
        id: this.$route.params.id
      }).then(data => {
        this.teams = data;
      });
    },
    loadMedia() {
      this.$store.dispatch('loadMedia', {
        id: this.$route.params.id,
        include: 'metaSets,tags'
      }).catch(e => {
        if(e.status === 401) {
          this.$router.push('/access-denied');
        }
      });
    },
    removeTag: function(tag) {
      this.$store.dispatch('removeMediaTag', {
        id: this.$route.params.id,
        args: [tag.id],
      });
    },
    addTag: function(tag) {
      this.$store.dispatch('addMediaTag', {
        id: this.$route.params.id,
        args: [tag.id],
      });
    },
  },
}
</script>