<template>
  <div>
    <div class="col-12 mt-4">
      <meta-set-attacher
        v-if="asset.id && instanceOrganizationId"
                :target-id="asset.id"
                store-name="Asset"
                @added="loadData"
                @removed="loadData"
                slot="info"
                :given-meta-sets="assetMetaSets"
                :show-warning="true"
                :organization-id="instanceOrganizationId"
                :allow-access="true"
            />
    </div>
    <info-panel
        v-if="asset"
        info-panel-header="globalValues"
        :collapsible="true"
        :initially-collapsed="true"
        preview-id="fileProcessing"
    >
      <div slot="info">
        <div class="col-12">
          <h2>{{ asset.name }}</h2>
          <p class="lighter">{{ $t('asset id') }}: {{ asset.id }}</p>
            <p v-if="asset.type !== 'node'" class="lighter">{{ $t('instanceDisclaimer') }}</p>
          </div>

          <div v-if="loading">
            <loading-spinner />
          </div>
          <div class="p-4" v-else-if="!assetMetaSets.length">
            {{ $t('nodata') }}
          </div>
          <div class="mt-3" :key="forceReRenderKey" v-else>

            <meta-panel :key="item.id" v-for="(item) in assetMetaSets"
			v-if="((asset.type === 'node' && item.organizationId === instanceOrganizationId) ||
			(asset.type !== 'node' && (item.organizationId === instanceOrganizationId || $store.getters.isSuperOrg(item.organizationId))))
			&& instanceMetaSets.filter(bitem=>{return bitem.id === item.id }).length"
                        :title="item.description ? item.description : item.name" :fields="item.metaFields" icon-left="folder"
                        store-name="Assembly"
                        :organization-id="asset.organizationId"
                        :target-id="asset.id"
                        @updated="loadAsset"
          />
        </div>
      </div>
    </info-panel>
    <info-panel
        v-if="asset"
        info-panel-header="localValues"
        :collapsible="true"
        :initially-collapsed="true"
        preview-id="fileProcessing"
    >
      <div slot="info">
          <div v-if="instance">
          <div class="d-flex">
            <h2>{{ instance.name }}</h2>
            <div v-if="!nameEditing" class="edit-trigger ml-2" @click.prevent="nameEditing = true">
              <icon :type="'edit'"/>
            </div>
          </div>
          <p class="lighter">{{ $t('instance id') }}: {{ instance.id }}</p>
          <div v-if="nameEditing">
            <input class="form-text" type="text" v-model="instanceName" >
            <div class="w-100"/>
            <div class="edit-trigger mr-2" @click="cancelEditing">
              <icon :type="'times'"/>
            </div>
            <div v-if="instanceName" class="edit-trigger" @click="updateName">
              <icon :type="'save'"/>
            </div>
          </div>
            <p class="lighter">{{ $t('localValuesDisclaimer') }}</p>
          <div class="mt-3" :key="forceReRenderKey + 500" v-if="instanceOrganizationId && instanceMetaSets.length">
            <meta-panel
                        :key="item.id"
                        v-for="(item) in instanceMetaSets"
                        :title="item.description ? item.description : item.name"
                        :fields="item.metaFields" icon-left="folder"
                        store-name="Instance"
                        :organization-id="instanceOrganizationId"
                        :cid="instance.id"
                        :target-id="projectId"
                         @updated="loadInstanceMetaValues"
                        v-if="asset.type !== 'node' || (item.organizationId === instanceOrganizationId || item.organizationId === $store.getters.getSuperOrg)"
            />
          </div>
          <div v-else-if="loading">
            <loading-spinner />
          </div>
          <div v-else>
            <info-panel
                v-if="asset"
                info-panel-header="MetaData"
                :collapsible="true"
                :initially-collapsed="false"
                preview-id="modeleditViewInstance"
            >
              <div slot="info">
                {{ $t('nodata') }}
              </div></info-panel>
          </div>
        </div>
      </div>
    </info-panel>

  </div>
</template>

<script>
import MetaPanel from "@/components/widgets/meta/MetaPanel";
import Icon from "@/components/Icon";
import InfoPanel from "@/components/InfoPanel";
import LoadingSpinner from "@/components/LoadingSpinner";
import MetaSetAttacher from "@/components/widgets/meta/MetaSetAttacher";
import {combineAssetAndInstanceMetaValues} from "@/utils";
export default {
  name: "ModelEditView",
  components: {
    MetaPanel,
    Icon,
    InfoPanel,
    LoadingSpinner,
    MetaSetAttacher
  },
  props: {
    instanceId: {type: String, required: true,},
    projectId: {type: String, required: true,},
    organizationId: {type: String, default: ''}
  },
  data() {
    return {
      loading: true,
      instance: {},
      instanceMetaValues: null,
      assetMetaSets: [],
      asset: {},
      instanceMetaSets: [],
      instanceOrganizationId: null,
      nameEditing: false,
      instanceName: '',
      forceReRenderKey: 0,
    };
  },
  beforeMount() {
    this.loadData();
  },
  methods: {
    async loadData() {
      await this.$store.dispatch('loadProject', {
        id: this.projectId
      }).then(project => {
        this.instanceOrganizationId = project.organizationId;
      }).then(async () => {
        return await this.loadProjectInstances();})
      .then(async () => {
        return await this.loadAsset();
      }).then(async () => {
        return await this.loadInstanceMetaValues();
      }).then(() => {
        this.loading = false;}).catch(e => {
        console.log(e);
        this.loading = false;
      })
    },
    async loadInstanceMetaValues() {
      this.instanceMetaValues = await this.$store.dispatch('getProjectInstanceMetaValues', {
          id: this.projectId,
          cid: this.instanceId,
        });
        this.makeMetaFieldsForInstance();
    },
    makeMetaFieldsForInstance() {
      this.instanceMetaSets = combineAssetAndInstanceMetaValues(this.assetMetaSets, this.instanceMetaValues);
    },
    async loadProjectInstances() {
      return await this.$store.dispatch('clientLoadProjectInstance', {
              id: this.projectId,
              cid: this.instanceId,
              include: ['assetAndMetaSetsBugfix', 'metaFields']
      }).then(instance => {
        this.instance = instance;
        this.instanceName = instance.name;
        return instance;
      });
    },
    async loadAsset() {
      return await this.$store.dispatch('clientLoadAsset', {
          id: this.instance.asset.id,
          include: 'metaSets'
        }).then(asset => {
          if(asset) {
            this.asset = asset;
            this.assetMetaSets = asset.metaSets;
          }
          this.forceReRenderKey++;
          return asset;
      });
    },
    updateName() {
      this.$store.dispatch('clientUpdateProjectInstance', {
        id: this.projectId,
        cid: this.instance.id,
        name: this.instanceName
      });
      this.instance.name = this.instanceName;
      this.nameEditing = false;
      this.$emit('updateName', this.instance.id, this.instance.name);
    },
    cancelEditing() {
      this.instanceName = this.instance.name;
      this.nameEditing = false;
    },

  },
}
</script>