<template>
    <div class="page-login container-fluid">
      <div v-if="showCookieNotification" class="cookie-notification p-3">
        <div class="close clickable" @click="showCookieNotification = false"><icon type="times" /></div>
        {{ $t('cookieNotification') }}<br />
        <Button class="mt-2" @click="showCookieNotification = false">{{ $t('gotIt') }}</Button>
      </div>
      <div class="background-image" v-bind:style="{ backgroundImage: 'url(' + image + ')' }"></div>
      <div class="row content full-height">
        <div class="col-12 col-md-7 col-lg-8 pl-5 pt-4 pb-4 pr-5 left-panel">
          <div class="row full-height">
            <div class="col-12">
              <logo class="login" />
              <h1 class="front mt-5" v-html="$t('frontSloganLeft')"></h1>
              <component :is="loginComponent" />
            </div>
          <div class="col align-self-end links-highlighted footer-links">
            <a href="https://vhub-documentation.azurewebsites.net/" target="_blank">{{ $t('Documentation') }} </a>
            <router-link to="/gdpr" target="_blank">| {{ $t('gdpr_link') }} <icon size="0.9" type="external-link-alt" /></router-link>

            <div class="float-right" v-html="$t('SideEffectsDisclaimer')"></div>
          </div>
          </div>
        </div>
        <div class="col-12 col-md-5 col-lg-4 pt-4 pl-5 pr-5 download-panel">
          <div class="row full-height">
            <div class="col-12">
              <logov-stage />
              <div class="h2 front mt-5">
                 <h2 class="front" v-html="$t('frontSloganRight')"></h2>
                <h2 class="front blue" v-html="$t(wordList[currentWord])"></h2>
              </div>
              <download-section class="mt-4 pb-4" />
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
    import Logo from '../assets/svgs/Logo.vue'
    import LogovStage from "@/assets/svgs/LogovStage";
    import DownloadSection from '../components/DownloadSection.vue';
    import Icon from "@/components/Icon";
    import Button from "@/components/forms/Button";
    import LoginFormVHUB from "@/components/identityProviders/LoginFormVHUB";
    import LoginFormAzB2cUserFlow from "@/components/identityProviders/LoginFormAzB2cUserFlow";

    export default {
        name: 'Login',
        components: {
            LoginFormAzB2cUserFlow,
            LoginFormVHUB,
            Logo,
            DownloadSection,
            LogovStage,
            Icon,
            Button,
        },
        data() {
          return {
            loginComponent: null,
            showCookieNotification: true,
            image: require('@/assets/images/bg-vhub-login.jpg'),
            wordList: [
                'presentations',
                'trainings',
                'manuals',
            ],
            currentWord: 0
          };
        },
        beforeMount() {
          // load the correct login form depending on the provider
          // the idp is set in the main.js file

        },
        mounted() {
          console.log('login mounted')
         const idp = this.$store.getters.getIdentityProviderName;
         console.log(idp);
         this.loginComponent = `LoginForm${idp}`

          this.changeWord();
        },
        methods: {
          changeWord() {
            const $this = this;
            setTimeout(function () {
              if($this.currentWord === $this.wordList.length - 1) {
                $this.currentWord = 0;
              }
              else {
                $this.currentWord++;
              }
              $this.changeWord();
            }, 2500);
          }
        }
    }
</script>

<style lang="scss" scoped>
    .cookie-notification {
      background-color: $highlight;
      width: 400px;
      height:140px;
      position:fixed;
      bottom:0;
      right:15px;
      z-index:100;
      .close {
        position:absolute;
        top: 5px;
        right:5px;
        z-index:4;
      }
    }
    h1.front {
      font-size: 3rem;
      font-family: 'DIN Pro Bold', sans-serif;
      margin-bottom:2rem;
    }
    h2.front {
      font-size: 2rem;
      font-family: 'DIN Pro Bold', sans-serif;
      text-transform: none;
      margin: 0;
    }
    h2.blue {
      color: $cornblue;
    }
    .left-panel {
      height: 100vh;
    }
    .download-panel {
      background-color: $downloadPanelColor;
      min-height: 100vh;
    }
    .background-image {
      width: 100vw;
      height:100vh;
      position:fixed;
      top:0;
      left:0;
      z-index:0;
      background-size: cover;
    }
    content {
      z-index:1;
      position:relative;
    }
    .filler {
      flex-grow: 1;
    }

    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 575.98px) {
    }

    // Small devices (landscape phones, less than 768px)
    @media (max-width: 767.98px) {

    }
    @media (max-height: 700px) and (min-width: 770px) {

    }

    /* iPad pro Landscape */
    @media only screen
    and (min-device-width: 1366px)
    and (max-device-width: 1366px)
    and (min-device-height: 1024px)
    and (max-device-height: 1024px)
    and (orientation: landscape)
    and (-webkit-min-device-pixel-ratio: 2) {
      h1.front {
        position:absolute;
        top:260px;
        }
        .h2.front {
          position:absolute;
          top:280px;
        }
        .user-form {
          position:fixed;
          top:540px;
        }
        .left-panel {
          position:relative;
          z-index:1;
          flex: 0 0 58.3333333333% !important;
          max-width: 58.3333333333% !important;
        }
        .download-panel{
           position:relative;
            z-index:0;
            flex: 0 0 41.6666666667% !important;
            max-width: 41.6666666667% !important;
          .download {
            position:absolute;
            top:470px;
          }
        }
    }

    //tablets portrait-mode
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait), screen and (max-width: 300px) {
        h1.front {
          position:absolute;
          top:280px;
        }
        .h2.front {
          position:absolute;
          top:280px;
        }
        .user-form {
          position:fixed;
          top:580px;
        }
        .left-panel {
          position:relative;
          z-index:1;
          flex: 0 0 58.3333333333% !important;
          max-width: 58.3333333333% !important;
        }
        .download-panel{
           position:relative;
            z-index:0;
            flex: 0 0 41.6666666667% !important;
            max-width: 41.6666666667% !important;
          .download {
            position:absolute;
            top:470px;
          }
        }
        .footer-links {
          position: fixed;
          bottom: 35px;
          width: 50%;
          * {
            float: none !important;
          }
        }
    }

    // Medium devices (tablets, less than 992px)
    @media (max-width: 991.98px) {
        h1.front {
          font-size: 2rem;
          position:absolute;
          top:250px;
        }
        .h2.front {
          h2 {
            font-size: 1.8rem;
          }
          position:absolute;
          top:250px;
        }
        .user-form {
          position:fixed;
          top:470px;
        }
        .left-panel {
          position:relative;
          z-index:1;
        }
        .download-panel{
           position:relative;
            z-index:0;
          .download {
            position:absolute;
            top:430px;
          }
        }
        .footer-links {
          position: fixed;
          bottom: 35px;
          width: 50%;
          * {
            float: none !important;
          }
        }
    }

    // Large devices (desktops, less than 1200px)
    @media (max-width: 1199.98px) {
    }

    // ultra large
    @media (min-width: 1699.98px) {
      .vstagelogo {
        max-width: 460px;
      }
      .logosvg {
        max-width: 400px;
      }
      h1.front {
        font-size: 3.5rem;
        margin-bottom:5vh;
      }
      h2.front {
        font-size: 2.5rem;
      }
      .download-panel, .left-panel {
        padding: 5vw !important;
      }
      .footer-links {
        font-size: 1.3rem;
        font-family: 'DIN Pro Bold', sans-serif;
      }
    }
</style>
