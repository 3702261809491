<template>
    <main-table-layout
        sub-menu-selector="organizingpanel"
    >
        <div slot="buttonsRight"/>
        <content-filter slot="filter"
                       @filter="filtering"
                        :id="listName"
                        store-name="MetaSet"
        />
        <view-switcher
                slot="mainContentButtons"
                :id="'mainContent'"
                :table="true"
                :thumbnails="false"
        />
        <pagination
                slot="mainContentButtons"
                :limit="paginationLimit"
                :total="$store.getters.getMetaSetListOptions(listName, 'pagination_items')"
                :id="listName"
                @setActive="loadPaginatedData"
                ref="pagination"
        />
        <Button
            permission="createMetaSet"
            type="action-button" slot="mainContentButtons" link="/organizing/metaSets/add" icon="plus">
            {{ $t('createnew') }}
        </Button>
      <Button
           slot="mainContent"
           @click="loadPaginatedData(0); $refs.pagination.setActive(0)"
           type="reload"
           icon="redo"
           class="icon-button"
       />
        <table-widget
                v-if="$store.getters.getViewSwitcher($route.name, 'mainContent') === 'table'"
                slot="mainContent"
                :id="'metaSetDefaultTable'"
                :nav-by-clicking-row="true"
                :deletable="true"
                @deleteRow="deleteRow"
                :show-tags="true"
                :show-organization="true"
                edit-permission="writeMetaSet"
                delete-permission="deleteMetaSet"
                :fields="tableFields"
                :data="$store.getters.getMetaSets"
                :title-as-links="true"
                @sort="sort"
                :loading="loading"
        />
        <thumbnails
                v-if="$store.getters.getViewSwitcher($route.name, 'mainContent') === 'thumbnails'"
                slot="mainContent"
                :id="'sfxdataDefaultThumbnails'"
                :deletable="true"
                :nav-by-clicking-row="true"
                edit-permission="writeMetaSet"
                delete-permission="deleteMetaSet"
                :fields="thumbnailFields"
                :data="$store.getters.getMetaSets"
                :title-as-links="true"
                @sort="sort"
                :loading="loading"
        />
    </main-table-layout>
</template>

<script>
    import Button from "../components/forms/Button";
    import TableWidget from "../components/widgets/TableWidget";
    import ContentFilter from "../components/widgets/filter/ContentFilter";
    import MainTableLayout from "../layouts/MainTableLayout";
    import ViewSwitcher from "../components/widgets/ViewSwitcher";
    import Thumbnails from "../components/widgets/ThumbnailView";

    import Pagination from "../components/Pagination";

    export default {
        name: "SFXData",
        components: {
            Button,
            TableWidget,
            ContentFilter,
            MainTableLayout,
            ViewSwitcher,
            Thumbnails,
            Pagination,
        },
        data() {
            return {
                loading: true,
                paginationLimit: 15,
                queryRelevantComponents: 2, //pagination and filter
                queryRelevantInits: 0,
                listName: 'metaSetList',
                filter: {},
                offset: 0,
                sorting: '-updatedAt',
                tableFields: [
                    {
                        name: '',
                        id:'',
                        type: 'image',
                    },
                    {
                        name: 'Name',
                        id:'name',
                        type: 'string',
                        sortable: true
                    },
                    {
                        name: 'Type',
                        id:'type',
                        type: 'string',
                        translate: true,
                        sortable: true
                    },
                    {
                        name: 'Last Update',
                        id:'updatedAt',
                        type: 'date',
                        sortable: true
                    },
                    {
                        name: 'Edit',
                        type: 'editButton'
                    },
                ],
                thumbnailFields: [
                    {
                        name: '',
                        id:'',
                        type: 'image',
                    },
                    {
                        name: 'Organization',
                        id:'organization',
                        type: 'organization',
                        sortable: true
                    },
                    {
                        name: 'Type',
                        id:'type',
                        type: 'string',
                        sortable: true
                    },
                    {
                        name: 'Last Update',
                        id:'updatedAt',
                        type: 'date',
                        sortable: true
                    },
                    {
                        name: 'Name',
                        id:'name',
                        type: 'string',
                        sortable: true
                    },
                    {
                        name: 'Tags',
                        id:'tags',
                        type: 'tags',
                    },
                    {
                        name: 'Edit',
                        type: 'editButton'
                    },
                ],
            }
        },
        watch: {
            queryRelevantInits: function(newval) {
                if(newval === this.queryRelevantComponents) {
                    this.loadPaginatedData(0);
                }
            }
        },
        methods: {
          loadPaginatedData(offset, initial) {
              this.offset = offset;
              this.loadData(initial);
            },
            loadData(initial = false) {
              this.loading = true;
              let args = {
                  listName: this.listName,
                 }
                  const paginationAddOn = {
                    paging: {
                      [this.listName]: true,
                    },
                    offset: {
                      [this.listName]: this.offset,
                    },
                    limit: {
                      [this.listName]: this.paginationLimit,
                    },
                    include: {
                      [this.listName]: 'teams',
                    },
                    sort: {
                      [this.listName]: this.sorting,
                    }
                  };
                  args.add = this.filter && this.filter.add ? {...paginationAddOn, ...this.filter.add } : paginationAddOn;
                  if(this.filter && this.filter.remove) {
                    args.remove = this.filter.remove;
                  }
                args.listName = this.listName;
                 if(initial) {
                    this.queryRelevantInits++;
                    this.$store.dispatch('registerMetaSetsQueryParams', /*{params: args, listName: this.listName}*/ args);
                }
                else {
                   this.loading = true;
                    this.$store.dispatch('loadMetaSets', /*{params: args, listName: this.listName}*/ args).then(() => {
                      this.loading = false;
                    })
                }
            },
            deleteRow: function (id) {
                this.$store.dispatch('deleteMetaSet', {
                    args: [id]
                });
            },
            filtering: function(args, initial) {
              this.filter = args;
              this.loadData(initial);
            },
            sort(args) {
              const {fieldname, direction} = args;
              const sortString = direction + fieldname;
              if(this.sorting !== sortString) {
                this.sorting = sortString;
              }
              this.loadData(false);
            }
        }
    }
</script>
