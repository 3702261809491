<template>
    <tr>
        <popup
                @close="() => {showDeletePrompt = false;}"
                v-if="showDeletePrompt"
        >
            <delete-prompt slot="popupMainContent"
                           @abort="() => {showDeletePrompt = false;}"
                           @confirm="() => {showDeletePrompt = false; deleteDataRowFinally();}"
            />
        </popup>
        <td class="data-row-delete" v-if="deletable" @click="deleteDataRow">
          <div class="row-lifetime">
            <icon type="battery-half" class="ml-2 mb-2" />
            <lifetime-setter class="hover p-2" :compact="true" :editable="false" :value="dataRow.lifetime" />
          </div>
            <Button type="icon-button" slot="buttonsRight"
            >
                <icon type="trash-alt"/>
            </Button>
        </td>
      <td class="data-row-delete" v-else>
        <!--empty placeholder because we have the selector top left-->
      </td>
        <td v-if="!hiddenFields.includes(columnIndex)" :key="property" v-for="(field, property, columnIndex) in fields">
            <transition name="toggle" mode="out-in">
                <div :key="'edit'" class=" ml-3" v-if="type === 'tbl' ? columnIndex === currentlyEditingRow.index : property === currentlyEditingRow.index">
                  <div v-if="field.type === 'timestamp'">
                      {{ $t('usePicker') }}
                      <date-picker-widget
                              class="mb-2"
                              v-if="field.type === 'timestamp'"
                              :value="currentlyEditingRow.value"
                              type="datetime"
                              @save="updateDataRow"
                      />
                  </div>
                  <div v-if="field.type === 'time'">
                      {{ $t('usePicker') }}
                      <date-picker-widget
                              class="mb-2"
                              v-if="field.type === 'time'"
                              :value="currentlyEditingRow.value"
                               type="time"
                              @save="updateDataRow"
                      />
                  </div>
                  {{ $t('manualEntry') }}
                  <input
                            class="form-text mb-2"
                            type="text"
                            :id="dataRow.id"
                            v-model="currentlyEditingRow.value"
                            :ref="'entry'"
                            @init="focusDataField"
                    >

                    <div class="edit-trigger mr-2" @click="finishEditingDataRow">
                        <icon :type="'times'"/>
                    </div>
                    <div class="edit-trigger" @click="saveDataRow">
                        <icon :type="'save'"/>
                    </div>
                </div>
            </transition>
            <div :key="'content'" class="table-filler" v-if="type === 'tbl' ? columnIndex !== currentlyEditingRow.index : property !==currentlyEditingRow.index"
                 @click="editDataRow(getIndexType === 'columnIndex' ? columnIndex : property)">
                        <div class="clickable withHoverBrightBackground pt-2 pb-2 pl-3 pr-4" v-if="field.type === 'timestamp' && dataRow.data[getIndexType === 'columnIndex' ? columnIndex : property]">
                            {{ dateTimeFromISO(dataRow.data[getIndexType === 'columnIndex' ? columnIndex : property]) }} <icon :type="'edit'"/>
                        </div>
                <div class="clickable withHoverBrightBackground pt-2 pb-2 pl-3 pr-4" v-else-if="dataRow.data[getIndexType === 'columnIndex' ? columnIndex : property]">
                            {{ dataRow.data[getIndexType === 'columnIndex' ? columnIndex : property] }} <icon :type="'edit'"/>
                        </div>
                <div v-else class="clickable withHoverBrightBackground pt-2 pb-2 pl-3 pr-4">
                    <span class="lighter">{{ $t('addData') }}</span> <icon :type="'plus'"/>
                </div>
            </div>
        </td>
    </tr>
</template>

<script>
    import DatePickerWidget from "../editors/DatePickerWidget";
    import DeletePrompt from "../../forms/DeletePrompt";
    import Popup from "../../Popup";
    import Icon from "../../Icon";
    import DateMixin from '@/components/mixins/DateMixin.js';
    import Button from "../../forms/Button";
    import LifetimeSetter from "@/components/LifetimeSetter";

    export default {
        name: "DatasetRowWidget",
        components: {
            DatePickerWidget,
            DeletePrompt,
            Popup,
            Icon,
            Button,
            LifetimeSetter
        },
        mixins: [
            DateMixin,
        ],
        props: {
            dataRow: {type: Object || Array, default: null},
            fields: {type: Object || Array, default: null},
            hiddenFields: {type: Array, required: true},
            datasetId: {type: String, required: true,},
            type: {type: String, required: true},
            deletable: {type: Boolean, default: false},
        },
        data() {
            return {
                currentlyEditingRow: {
                    id: '',
                    index: '',
                    value: '',
                },
                currentlyDeletingRow: '',
                showDeletePrompt: false,
                showMetaForm: false,
            };
        },
        computed: {
            getIndexType: function () {
                return this.type === 'tbl' ? 'columnIndex' : 'property';
            },
        },
        methods: {
            deleteDataRow: function(){
                this.showDeletePrompt = true;
            },
            deleteDataRowFinally: function() {
                this.$store.dispatch('deleteDatasetRow', {
                    id: this.datasetId,
                    args: [this.dataRow.id],
                    listName: this.datasetId,
                });
                this.$emit('updateDatasetRow', {id: this.dataRow.id, data: null})
            },
            editDataRow: function (index) {
                this.currentlyEditingRow.index = index;
                if(this.dataRow.data[index]) {
                    this.currentlyEditingRow.value = this.dataRow.data[index];
                }
            },
            focusDataField: function () {
                this.$refs.entry[0].$el.children[1].focus();
            },
            finishEditingDataRow: function () {
                this.currentlyEditingRow.index = '';
                this.currentlyEditingRow.value = '';
            },
            updateDataRow: function(val) {
                let value = val ? val : val.value;
                this.currentlyEditingRow.value = value;
            },
            saveDataRow: function () {
                let dataRowID = this.dataRow.id;
                //let value = val ? val : val.value;
                let value = this.currentlyEditingRow.value;
                let index = this.currentlyEditingRow.index;

                //make a deep copy
                let temp = JSON.parse(JSON.stringify(this.dataRow));
                if (!temp) {
                    temp = this.dataRow.filter(item => {
                        return item.id === this.dataRow.id
                    })[0];
                }
                let dataRow = temp.data;
                dataRow[index] = value;

                let keys = Object.keys(this.fields);
                let fieldName = '';
                for (let i = 0; i < keys.length; i++) {
                    if (this.fields[keys[i]].columnIndex === index) {
                        fieldName = keys[i];
                        break;
                    }
                }
                if (!fieldName) {
                    fieldName = index;
                }
                //find the fieldname end

                let params = {
                    id: this.datasetId,
                    data: {[fieldName]: value}, //replace by complete datarow
                    datarowId: dataRowID,
                    listName: this.datasetId,
                };
                this.$store.dispatch('clientUpdateDatasetRow', params).then(data => {
                  this.$emit('updateDatasetRow', data);
                });
                this.$emit('dataRowChange', dataRowID, index, value);
                this.finishEditingDataRow();
            },
        }
    }
</script>

<style lang="scss">
    //transition
    .toggle-enter-active, .toggle-leave-active {
        transition: all .3s ease-out;
        height: 80px;
        overflow:visible;
    }
    .toggle-enter, .toggle-leave-to {
        height: 0;
        overflow:hidden;
    }

    .data-row-delete {
        cursor:pointer;
        opacity:0.8;
        &:hover {
            opacity:1;
        }
    }
    .table-filler {
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
        position:relative;
        .icon {
            -webkit-transition: all 300ms ease;
            transition: all 300ms ease;
            opacity: 0;
            position:absolute;
            right: 6px;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            font-size: 0.8em;
        }
        &:hover {
            .icon {
                opacity: 1;
            }
        }
    }
    .row-lifetime {
      position:relative;
      z-index:2;
      .hover {
        position:absolute;
        opacity: 0;
        pointer-events:none;
        width:170px;
        background: $input-background-color;
      }
      &:hover {
        .hover {
          opacity: 1;
        }
      }
    }
</style>