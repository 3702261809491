<template>
  <div>
    <div class="pt-1 pb-1" :key="index" v-for="(entry, index) in data">
      <log-entry
          :billing-state-editable="$store.getters.isSuperAdmin"
          :entry="entry"
          @entryDeleted="entryDeleted"
          :key="forceReRenderKey"
      />
    </div>
  </div>
</template>

<script>
import LogEntry from "@/components/LogEntry";
export default {
  name: "LogViewer",
  components: {
    LogEntry
  },
  props: {
    data: {default: () => {}},
  },
  data() {
    return {
      forceReRenderKey: 0
    }
  },
  methods: {
    entryDeleted(id) {
      this.forceReRenderKey++;
     this.$emit('entryDeleted', id);
    }
  }
}
</script>

<style scoped>
  .border-top-dark {
    border-top: 1px solid rgba(0,0,0,0.3);
  }
</style>