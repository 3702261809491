<template>
    <div :class="['color-pickr', popupStyle ? 'popup-style' : '']">
        <div v-if="popupStyle" class="popup-close" @click="close">
            <icon type="close"/>
        </div>
    <color-picker
            ref="cp"
            theme="dark"
            :color="color"
            :sucker-hide="true"
            :sucker-canvas="suckerCanvas"
            :sucker-area="suckerArea"
            @changeColor="changeColor"
    />
        <Button
                v-if="showSaveButton"
                class="mt-3"
                @click="save"
        >
            <icon type="save"/>
            {{ $t('SAVE') }}
        </Button>
    </div>
</template>

<script>
    //https://www.npmjs.com/package/@caohenghu/vue-colorpicker
    import colorPicker from '@caohenghu/vue-colorpicker';
    import Icon from "../../Icon";
    import Button from "../../forms/Button";

    export default {
        name: "ColorPicker",
        components: {
            colorPicker,
            Icon,
            Button,
        },
        props: {
            popupStyle: {type: Boolean, default: false},
            value: {type: String, default: null},
            showSaveButton: {type: Boolean, default: true},
        },
        data() {
            return {
                color: '#59c7f9',
                suckerCanvas: null,
                suckerArea: [],
                isSucking: false,
            }
        },
        beforeMount(){
            if(this.value) {
                this.color = this.value;
            }
        },
        methods: {
            changeColor(color) {
                const { r, g, b, a } = color.rgba
                this.color = `rgba(${r}, ${g}, ${b}, ${a})`;
                this.$emit('changeColor', this.$refs.cp.modelHex);
            },
            /*openSucker(isOpen) {
                if (isOpen) {
                    // ... canvas be created
                    // this.suckerCanvas = canvas
                    // this.suckerArea = [x1, y1, x2, y2]
                } else {
                    // this.suckerCanvas && this.suckerCanvas.remove
                }
            },*/
            close() {
                this.$emit('close');
            },
            save() {
                this.$emit('save', this.$refs.cp.modelHex);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .color-pickr.popup-style {
        position: absolute;
        background-color: $input-background-color;
        padding:25px;
        z-index:4;
        left: 20px;
        top: 0;
        .hu-color-picker {
            margin-right:25px;
        }
        .popup-close {
            position:absolute;
            top:15px;
            right:15px;
            z-index:4;
        }
    }
</style>