<template>
    <div>
      <div class="d-flex mb-2 bg-dark p-2 col-12">
        <div class="mr-1 p-2 bg-highlight text-right"><div class="lighter"><icon class="mr-2" type="grip-vertical" />{{ $t('vertices') }}</div> {{ logEntry ? logEntry.vertices : '-'}}</div>
        <div class="mr-1 ml-1 p-2 bg-highlight text-right"><div class="lighter"><icon class="mr-1" type="cube" />{{ $t('geometries') }}</div> {{ logEntry ? logEntry.geometries : '-'}}</div>
        <div class="mr-1 ml-1 p-2 bg-highlight text-right"><div class="lighter"><icon class="mr-1" type="cubes" />{{ $t('groups') }}</div> {{ logEntry ? logEntry.groups : '-'}}</div>
        <div class="mr-1 ml-1 p-2 bg-darker text-right"><div class="lighter mr-1">{{ $t('TotalTime') }}:</div>{{ logEntry && logEntry.startedAt ? timeDifferenceFromTwoISOs(logEntry.stoppedAt ? logEntry.stoppedAt : new Date(), logEntry.startedAt).string : '-'}}</div>
       <div class="ml-1 p-2 bg-darker text-right"><div class="lighter">{{ $t('VMTime') }}:</div> {{ logEntry && logEntry.vmStoppedAt ? timeDifferenceFromTwoISOs(logEntry.vmStoppedAt, logEntry.vmStartedAt).string : '-'}}</div>
      </div>
      <div class="">
        source: {{ logEntry.sourceFileSizeInMB }}<br />
        converted: {{ logEntry.convertedFileSizeInMB }}
      </div>

        <div class="bg-beige mb-2" v-if="logEntry">
          <div :class="isDone(key) ? 'log-task log-task-done' : 'log-task log-task-not-done'" :key="index" v-for="(key, value, index) in PipelineSimplifiedExtractSteps">
            <icon size="0.9" :type="isDone(key) ? 'check' : (getTask(key) ? 'spinner' : 'times')" /> {{ $t(value) }} <div v-if="getTask(key)" class="ml-4 inline-block col-3 dz-progress bg-beige float-right"><div :style="{'width': getProgress(key, true) + '%'}" class="dz-upload"><p>{{ getProgress(key) * 100 }}%</p></div></div>
            <p class="smaller ml-4">{{ getDuration(key) }}<span v-if="getTask(key) && getTask(key).totalItems"> | {{ getTask(key).totalItems }} items</span></p>
          </div>
        </div>
      <!--<pre style="color: #fff">{{ logEntry.stats }}</pre>-->
      </div>
</template>

<script>
import { PipelineSimplifiedExtractSteps } from "@/enum";
import Icon from "@/components/Icon";
import DateMixin from "@/components/mixins/DateMixin.js";
export default {
  name: "LogEntryProgress",
  components: {
    Icon
  },
  mixins: [DateMixin],
  props: {
    id: {type: String, required: true},
  },
  data() {
    return {
      logEntry: {},
      PipelineSimplifiedExtractSteps: PipelineSimplifiedExtractSteps,
      killSwitch: false,
    };
  },
  beforeMount() {
    this.startUpdatingLogEntry();
  },
  beforeDestroy() {
    this.killSwitch = true;
  },
  methods: {
    getDuration(taskName) {
      const task = this.getTask(taskName);
      if(task && task.createdAt && task.updatedAt && task.createdAt !== task.updatedAt) {
        return this.timeDifferenceFromTwoISOs(task.updatedAt, task.createdAt).string;
      }
    },
    getProgress(taskName, inPercent = false) {
      const task = this.getTask(taskName);
      if(task) {
        const progress = task.progress && typeof(parseFloat(task.progress)) === 'number'? parseFloat(task.progress) : 0;
        return inPercent ? (progress * 100).toFixed(2) : progress.toFixed(1);
      } else {
        return 0;
      }
    },
    getTask(taskName) {
      if(this.logEntry.stats) {
        const item = this.logEntry.stats.filter(item=> {return item.taskName === taskName});
        if(item[0]) {
          return item[0];
        }
      }
      return null;
    },
    isDone(taskName) {
      const task = this.getTask(taskName);
      return !!task && task.progress && parseFloat(task.progress) === 1.0;
    },
    async startUpdatingLogEntry() {
      if(this.killSwitch) {
        return;
      }
      const $this = this;
      this.$store.dispatch('clientLoadVMLogs', {filter: `objectId eq ${this.$route.params.id}`, sort: '-createdAt', limit: 1})
      .then(async data => {
        console.log('loading vm log...');
        if(data && data[0]) {
          this.logEntry = data[0];
          if(this.logEntry.stoppedAt) {
            this.killSwitch = true;
          }
        }
        setTimeout(async () => {
          await $this.startUpdatingLogEntry()
        }, 5000);
      })
    },
  }
}
</script>

<style lang="scss" scoped>
  .log-task {
    background-color: lighten($input-background-color, 10%);
    padding:3px 6px;
    margin-bottom:1px;
  }
  .log-task-not-done {
    background-color: $input-background-color;
  }
  .dz-progress {
    position:relative;
    width: 440px;
    padding:0;
    height:15px;
    margin-top:2px;
    margin-bottom: -2px;
    .dz-upload {
      background-color: $highlight;
      height:15px;
      p {
        position:absolute;
        left:5px;
        top:50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
      }
    }
  }
</style>