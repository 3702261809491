<template>
    <one-column-sidebar-left
      back-button-tab-selector="formbackbutton"
      tab-selector="formtab"
      sub-menu-selector="librarypanel"
    >
    <info-panel
      slot="sidebarLeft"
      :id="$route.params.id"
      :tags="$store.getters.getFormTags($route.params.id)"
      :taggable="true"
       @removeTag="removeTag"
      @addTag="addTag"
      :limit-tags-to-organization="$store.getters.getFormOrganization($route.params.id)"
      preview-id="formEdit"
     />
      <team-attacher
          v-if="$store.getters.getFormOrganization($route.params.id)"
        class="mt-3"
        slot="sidebarLeft"
        storeName="Asset"
        :id="$route.params.id"
        :teams="teams"
        :limit-by-organization-id="$store.getters.getFormOrganization($route.params.id)"
        @added="loadAssetTeams"
        @removed="loadAssetTeams"
    />

        <div slot="mainContent">
            <content-title-block
                    v-if="$store.getters.getFormName(id)"
                    :value="$store.getters.getFormName(id)"
                    :id="$route.params.id"
                    store-name="Form"
                    :organization-id="$store.getters.getFormOrganization($route.params.id)"
            />
            <content-description-block
                    :value="$store.getters.getFormDescription(id)"
                    :id="$route.params.id"
                    store-name="Form"
            />
            <meta-panel :key="item.id" v-for="(item) in this.$store.getters.getFormMetaSets($route.params.id)"
                        :title="item.description" :fields="item.metaFields" icon-left="folder"
                        store-name="Form"
                        :organization-id="$store.getters.getFormOrganization($route.params.id)"
                        @updated="loadForm"
            />
            {{ $t('configuration') }}
            <codemirror
                    :value="$store.getters.getFormRawContent($route.params.id)"
                    @save="updateValue"
            />
</div>
    </one-column-sidebar-left>
</template>

<script>
    import InfoPanel from "../components/InfoPanel";
    import OneColumnSidebarLeft from "../layouts/OneColumnSidebarLeft";
    import MetaPanel from "../components/widgets/meta/MetaPanel";
    import ContentTitleBlock from "../components/ContentTitleBlock";
    import ContentDescriptionBlock from "../components/ContentDescriptionBlock";
    import Codemirror from "@/components/widgets/editors/Codemirror";
    import TeamAttacher from "@/components/organizations/TeamAttacher";
    export default {
        name: 'FormEdit',
        components: {
            MetaPanel,
            InfoPanel,
            OneColumnSidebarLeft,
            ContentTitleBlock,
            ContentDescriptionBlock,
            Codemirror,
            TeamAttacher
        },
        data() {
            return {
                id: '',
                teams: []
            };
        },
        beforeMount() {
           this.loadForm();
           this.loadAssetTeams();
            this.id = this.$route.params.id;
        },
        methods: {
          loadAssetTeams() {
            this.$store.dispatch('clientLoadAssetTeams', {
              id: this.$route.params.id
            }).then(data => {
              this.teams = data;
            });
          },
            loadForm() {
               this.$store.dispatch('loadForm', {
                id: this.$route.params.id,
                include: 'tags,metaSets'
            }).catch(e => {
              if(e.status === 401) {
                this.$router.push('/access-denied');
              }
            });
            },
            removeTag: function(tag) {
                this.$store.dispatch('removeFormTag', {
                    id: this.$route.params.id,
                    args: [tag.id],
                });
            },
            addTag: function(tag) {
                this.$store.dispatch('addFormTag', {
                    id: this.$route.params.id,
                    args: [tag.id],
                });
            },
            updateTags: function(tags) {
                this.$store.dispatch('updateForm', {
                    id: this.$route.params.id,
                    tags: tags,
                });
            },
            updateValue: function(content) {
                this.$store.dispatch('updateForm', {
                    id: this.$route.params.id,
                    content: content,
                });
            }
        },
    }
</script>
