page<template>
    <div :class="['info-panel', collapsed ? 'is-collapsed' : '']">
        <div class="clickable header" v-if="collapsible" @click="() => {collapsed = !collapsed}">
            <h2>{{ $t(infoPanelHeader ? infoPanelHeader : 'info') }}</h2> <icon type="angle-down" />
        </div>
        <slot name="dropzone"/>
        <upload-drop-zone
                v-if="previewUri || imageEditable "
                :asset-id="uploadType.toLowerCase() === 'asset' ? id : null"
                :project-id="uploadType.toLowerCase() === 'project' ? id : null"
                :user-id="uploadType.toLowerCase() === 'user' ? id : null"
                :team-id="uploadType.toLowerCase() === 'team' ? id : null"
                :preview-uri="previewUri"
                :image-editable="imageEditable"
                :target-type="uploadType"
                :asset-type="mediaType"
                @fileReceived="fileReceived"
                :file-name="fileName"
                :downloadable="downloadable"
                :organization-id="organization"
                :form-id="previewId"
                :removable="removable"
                :make-chunks="true"
                :use-default-key="false"
        />

        <div class="info">
            <h3 v-if="title">{{ title }}</h3>
            <div class="info-section" v-if="created">
                <span class="label">{{ $t('Created') }}:</span>
                <span class="value">{{ created }}</span>
            </div>
            <div class="info-section" v-if="lastChange">
                <span class="label">{{ $t('Last change') }}</span>
                <span class="value">{{ lastChange }}</span>
            </div>
            <b v-if="contributors && contributors.length > 0">
                <span class="label">{{ $t('Contributors') }}</span></b>
            <div v-if="contributors">
                <tag v-for="value in contributors" :key="value" :text="value" icon-left="user" />
            </div>

            <b v-if="organization">
                <span class="label">{{ $t('Organization') }}</span></b>
            <div v-if="organization">
              <a v-if="$store.getters.isSuperAdmin" @click="$router.push(`/admin/organizations/${organization}/general`)">
                <tag :is-removable="false" :tag="{name: $store.getters.getOrganizationDisplayName(organization)}" icon-left="user" />
              </a>
              <span v-else>
                <tag :is-removable="false" :tag="{name: $store.getters.getOrganizationDisplayName(organization)}" icon-left="user" />
              </span>
            </div>
            <b v-if="type">
                <span class="label">{{ $t('Type') }}</span></b>
            <div v-if="type">
                <tag :is-removable="false" :tag="{name: type}" icon-left="table" />
            </div>
            <b v-if="tags">
                <h3>{{ $t('Tags') }}</h3></b>
            <div v-if="tags">
                <tag @removeTag="removeTag" v-for="value in tags" :key="value.id" :tag="value" icon-left="user" />
                <div v-if="!tags">{{ $t('noTagsAvailable') }}</div>
            </div>
            <tag-input
                    class="mt-3"
                    v-if="taggable"
                    @addTag="addTag"
                    @removeTag="removeTag"
                    :exclude-from-tag-list="tags"
                    :limit-tags-to-organization="limitTagsToOrganization"
            />
            <slot name="info" />
        </div>
    </div>
</template>

<script>

    import UploadDropZone from "./files/UploadDropZone";
    import Tag from "./Tag";
    import TagInput from "./forms/TagInput";
    import Icon from "./Icon";

    export default {
        name: "InfoPanel",
        components: {
            UploadDropZone,
            Tag,
            TagInput,
            Icon,
        },
        props: {
            image: { type: String, default: null },
            /**
             * @title the title shown inside the box (not the header) – hidden, if empty
             * */
            title: { type: String, default: null },
            /**
             * @infoPanelHeader the title shown in the headbar when the box is collapsible
             * */
            infoPanelHeader: {type: String, default: ''},
            /**
             * @id the asset/project/andsoonId to use e.g. in the dropzone
             * */
            id: { type: String, default: null },
            /**
             * @previewId {String} just any string to uniquely identify the preview
             * (needed for more complex previews like e.g. Threejs)
             * */
            previewId: {type: String, required: true},
            /**
             * @previewUri {String} the Uri to display a preview
             * */
            previewUri: {type: String, default: null},
            /**
             * @imageEditable {Boolean} whether or not to allow to upload a new image
             * */
            imageEditable: {type: Boolean, default: false},
            /**
             * @uploadType {String} the type to use for the Dropzone – can be Asset, Project, Team, User etc.
             * see the dropzone urls config for more detail
             * */
            uploadType: {type: String, default: 'file' },
            created: {type: String, default: null },
            lastChange: {type: String, default: null },
            contributors: {type: Array, default: () => [] },
            organization: {type: String, default: ''},
            type:  {type: String, default: ''},
            fileName: {type: String, default: null },
            tags: { type: Array, default: null },
            taggable: {type: Boolean, default: false },
            limitTagsToOrganization: {type: String, default: null},
            downloadable: {type: Boolean, default: false},
            removable: {type: Boolean, default: false},
            mediaType: {type: String, default: 'media'},
            collapsible: {type: Boolean, default: false},
            initiallyCollapsed: {type: Boolean, default: false},
        },
        data() {
            return {
                imageEdit: false,
                selectedTags: [],
                collapsed: false,
            }
        },
        watch: {
            image: function () {
                this.$emit('imageReceived');
            },
            initiallyCollapsed() {
                this.collapsed = this.initiallyCollapsed;
            }
        },
        beforeMount() {
            //this.selectedTags = this.tags;
            if(this.initiallyCollapsed) {
                this.collapsed = true;
            }
        },
        methods: {
            editImage: function() {
                this.imageEdit = true;
            },
            finishEditing: function() {
                this.imageEdit = false;
            },
            fileReceived: function(assetId, fileName) {
                this.$emit('fileReceived', assetId, fileName);
            },
            updateTags: function() {
                this.$emit('updateTags', this.selectedTags);
            },
            removeTag: function(tag) {
                this.$emit('removeTag', tag);
            },
            addTag: function(tag) {
                this.$emit('addTag', tag);
            },
        },
    }
</script>
<style lang="scss" scoped>
    .info-panel {
        background-color: $info-panel-color;
        margin-bottom:25px;

        .header {
            background-color: $info-panel-header-color;
          border-top: 1px solid #282d35;
          border-bottom: 1px solid black;
            position:relative;
            padding:25px;
            padding-top:15px;
            padding-bottom:15px;
            h1,h2,h3 {
                margin-bottom:0;
                padding-bottom:0;
            }
            .icon {
                position:absolute;
                right:15px;
                top:50%;
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
                -webkit-transition: all 300ms ease;
                transition: all 300ms ease;
            }
        }
        &.is-collapsed {
            .info {
                height:0;
                padding-top:0;
                padding-bottom:0;
                overflow:hidden;
            }
            .header {
                .icon {
                    -webkit-transform: translateY(-50%) rotate(-90deg);
                    transform: translateY(-50%) rotate(-90deg);
                }
            }
        }
        .info {
            padding: 25px;
            -webkit-transition: all 300ms ease;
            transition: all 300ms ease;
        }
        .tag {
            background-color: $tag-background-dark;
            margin-top:4px;
        }
        .info-section {
            border-bottom: 1px solid darken($info-panel-color, 10%);
            padding-bottom:15px;
            margin-bottom: 15px;
            .label {
                display:inline-block;
                width: 49%;
            }
            .value {
                display:inline-block;
                width: 49%;
                text-align: right;
                //padding-bottom: 15px;
                //border-bottom: 1px solid darken($info-panel-color, 10%);
            }
        }
      &.compact-header {
        .header {
          padding-top: 8px;
          padding-bottom:8px;
        }
      }
      &.light-header {
        .header {
          background-color: darken($info-panel-color, 20%);
          border-color: transparent;
        }
      }
      &.smaller-title {
        .header {
          padding-left:8px;
          h1, h2, h3, h4 {
            font-size: 0.9rem;
          }
        }
      }
      &.darker-panel {
        background-color: darken($info-panel-color, 10%);
      }
      &.border-panel {
        border: 1px solid darken($info-panel-color, 10%);
      }
    }
    .image-edit-toggler {
        position:absolute;
        right:15px;
        bottom:15px;
        font-size: 1.3em;
        padding:4px 8px;
        background-color: $highlight;
        cursor:pointer;
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
        &:hover {
            background-color: darken($highlight, 10%);
        }

    }
    @media (max-width: 1500px) {
        .info-panel {
            .info {
                padding: 15px;
            }
            .info-section {
                .label {
                    width: 100%;
                    opacity:0.8;
                }
                .value {
                    width: 100%;
                    text-align: left;
                }
            }
        }
    }
</style>
