<template>
    <div class="view-switcher">
        <div :class="['view-option', selected === 'table' ? 'active' : '']" v-if="table" @click="setSelected('table')">
            <icon type="table" />
        </div>
        <div :class="['view-option', selected === 'thumbnails' ? 'active' : '']" v-if="thumbnails" @click="setSelected('thumbnails')">
            <icon type="th" />
        </div>
    </div>
</template>

<script>
    import Icon from "../Icon";

    export default {
        name: "ViewSwitcher",
        components: {
            Icon,
        },
        props: {
            id: {type:String, required: true},
            table: {type: Boolean},
            thumbnails: {type: Boolean},
            default: {type: String, default: 'table'},
        },
        data() {
            return {
                selected: null,
            };
        },
        watch: {
            pages() {
                if(this.$store.getters.getViewSwitcher(this.$route.name, this.id)) {
                    this.setSelected(this.$store.getters.getViewSwitcher(this.$route.name, this.id));
                }
            }
        },
        beforeMount() {
            this.$store.dispatch('checkPageViewSwitcher', {
                routeName: this.$route.name,
                id: this.id,
            });

            let selected = this.$store.getters.getViewSwitcher(this.$route.name, this.id);
            if(selected === '0' || !selected) {
                selected = 'table';
            }
            this.setSelected(selected);
        },
        methods: {
            setSelected: function(selected) {
                this.selected = selected;
                this.$store.dispatch('setViewSwitcher', {
                    routeName: this.$route.name,
                    viewType: selected,
                    id: this.id,
                });
            },
        },
    }
</script>

<style lang="scss" scoped>
    .view-switcher {
        display:inline-flex;
      height: $input-default-height;
    }
    .view-option {
        background-color: #2a2a2a;
        max-width:40px;
        padding:8px 12px;
        text-align: center;
        font-size:1em;
        border-right: 1px solid #000;
        cursor:pointer;
        &:last-child {
            border-right: 0px;
        }
        &:hover, &.active {
            background-color: $highlight;
        }
    }
</style>
