<template>
    <one-column-sidebar-left
      back-button-tab-selector="mediabackbutton"
      sub-menu-selector="librarypanel"
      tab-selector="addmediatab"
    >
        <div slot="buttonsRight">
            <Button
                    class="mb-3 mt-5"
                    :deactivated="!enableSaving"
                    @click="save">
                <icon v-if="enableSaving" type="save"/><icon v-else type="ban" /> {{ $t('SAVE') }}
            </Button>
        </div>

        <div slot="mainContent">
            <h1><icon class="mr-2" size="0.9" type="film" />{{ $t('createNewMedia') }}</h1>
           <organization-switcher
                @setOrganization="id => {organizationId = id}"
                @setTeam="id => {teamId = id}"
                @unsetTeam="teamId = ''"
                class="mb-2" />
            <input
                    type="text"
                    :class="['form-text mb-3 mt-3', $v.name.$dirty && $v.name.$error ? 'form-group--error' : '']"
                    :id="'name'"
                    v-model="name"
                    :placeholder="$t('enternamehere')"
                    @input="delayTouch($v.name)"
            >

            <div class="form-error" v-if="$v.name.$dirty && !$v.name.required && $v.name.$error">{{ $t('errors.required') }}</div>
            <div class="form-error" v-if="$v.name.$dirty && !$v.name.alphaNumSpace">{{ $t('errors.alphaNumSpaceOnly') }}</div>

            <div class="form-error" v-if="$v.name.$dirty && !$v.name.maxLength">{{ $t('errors.atMostCharacters', {num: 128}) }}</div>

            <div class="form-error" v-if="$v.name.$dirty && !$v.name.minLength">{{ $t('errors.atLeastCharacters', {num: 3}) }}</div>
            <div class="form-error" v-if="$v.name.$dirty && !$v.name.isUnique">{{ $t('errors.mediaNameAlreadyExists') }}</div>
            <div class="form-error" v-if="error">{{ $t(error) }}</div>
            <div>
                <div class="inline-edit-label">
                    <b>{{ $t('description') }}</b>
                </div>

                <textarea
                        :class="['form-text mb-3', $v.description.$dirty && $v.description.$error ? 'form-group--error' : '']"
                        :id="'description'"
                        v-model="description"
                        :placeholder="$t('enterdeschere')"
                        @input="delayTouch($v.description)"
                />
                <div class="form-error" v-if="$v.description.$dirty && !$v.description.maxLength">{{ $t('errors.atMostCharacters', {num: 128}) }}</div>
            </div>
          <div class="row">
            <div class="col-12 col-md-6 col-lg-4">
              <upload-drop-zone
                  :auto-upload="false"
                  :asset-name="name"
                  :asset-description="description"
                  :organization-id="organizationId"
                  :team-id="teamId"
                  @fileReceived="finish"
                  @queueEmpty="hasFile = false; checkSaving();"
                  @filesInQueue="hasFile = true; checkSaving();"
                  ref="uploadComponent"
                  target-type="Asset"
                  form-id="MediaUpload"
                  :accepted-file-types="getAllowedMediaFileTypes()"
                  :removable="false"
                  :make-chunks="true"
              />
            </div>
          </div>
          <Button
              class="mb-3 mt-5"
              :deactivated="!enableSaving"
              @click="save">
            <icon v-if="enableSaving" type="save"/><icon v-else type="ban" /> {{ $t('SAVE') }}
          </Button>
        </div>
    </one-column-sidebar-left>
</template>

<script>
    import Button from "../components/forms/Button";
    import OneColumnSidebarLeft from "../layouts/OneColumnSidebarLeft";
    import Icon from "../components/Icon";
    import { required, minLength, maxLength } from 'vuelidate/lib/validators';
    import {alphaNumSpace} from '../customValidators';
    import OrganizationSwitcher from "../components/organizations/OrganizationSwitcher";
    import FileTypeMixin from '@/components/files/FileTypeMixin.js';
    import UploadDropZone from "@/components/files/UploadDropZone";
    import VuelidateMixin from "@/components/mixins/VuelidateMixin.js";

    export default {
        name: 'SFXAdd',
        components: {
            OrganizationSwitcher,
            Button,
            OneColumnSidebarLeft,
            Icon,
            UploadDropZone,
        },
        mixins: [
            FileTypeMixin,
            VuelidateMixin
        ],
        data() {
            return {
                hasFile: false,
                id: '',
                enableSaving: false,
                name: '',
                description: '',
                error: null,
                organizationId: null,
                teamId: ''
            };
        },
        watch: {
            name() {
                this.error = null;
            },
            '$v.$invalid': function() {
                this.checkSaving();
            },
        },
        validations: {
            name: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(127),
                alphaNumSpace,
                async isUnique(value) {
                    // standalone validator ideally should not assume a field is required
                    if (value === '') {
                        return true;
                    }
                    let bool = true;
                    await this.$store.dispatch('checkIfMediaNameExists', {name: value, type: 'Asset', value: value, organizationId: this.organizationId,}).then(data => {
                        bool = data.length === 0;
                    });
                    return bool;
                },
            },
            description: {
                maxLength: maxLength(1024),
            }
        },
        methods: {
            updateCategory: function(args) {
                this.mediaCategory = args.value;
            },
            updateContent: function(args) {
                this[args.field] = args.value;
                this.checkSaving();
            },
            checkSaving: function() {
                this.enableSaving = this.name && this.hasFile && !this.$v.$invalid;
            },
            save: async function() {
                if (this.enableSaving) {
                  this.$refs.uploadComponent.resumeUpload();
                }
            },
            finish(assetId, push = false) {
              if(push) {
                this.$router.push(`/library/media/${assetId}/general`);
              }
            },
        },
    }
</script>
