<template>
    <div class="row p-3" :key="forceReRenderKey">
        <u-iform
                v-if="currentlyEditingPosition"
                :id="id"
                :organization-id="organizationId"
                :item="currentlyEditingType === 'item' ? getItem(currentlyEditingPosition) : getSubItem(currentlyEditingPosition)"
                @abort="stopEditing"
                @save="saveItem"
        />
        <div class="col-4 ui-left">
            <div class="row">
                <div class="col-6 ui-placeholder" :key="index" v-for="(n,index) in numberOfFieldsLeft">
                    <u-i-element
                            @drag="gotDragged(n, false)"
                            @drop="gotDropped(n, false)"
                            @setActive="setActive(n)"
                            @edit="editItem"
                            drag-contraint="ui-left"
                            :key="index"
                            :item="getItem(n)"
                            :active="activePositionLeft === n"
                            :ui-id="'left-' + n"
                    />
                </div>
            </div>
        </div>
        <div class="col-4 offset-4 ui-right">
            <div class="row">
                <div class="col-6 ui-placeholder" :key="index" v-for="(n,index) in numberOfFieldsRight">
                    <u-i-element
                            @drag="gotDragged(n, true)"
                            @drop="gotDropped(n, true)"
                            drag-contraint="ui-right"
                            @edit="editSubItem"
                            :item="getSubItem(n)"
                            :ui-id="'right-' + n"
                            :key="index"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import UIElement from "./UIElement";
    import UIform from "../../forms/UIform";

    export default {
        name: "UIGroup",
        components: { UIElement, UIform,  },
        props: {
            organizationId: {type: String, required: true},
            id: {type: String, required: true},
            value: {type: String, default: ''},
        },
        data() {
            return {
                forceReRenderKey: 0,
                numberOfFieldsLeft: 6,
                numberOfFieldsRight: 6,
                activePositionLeft: 1,
                activeIndexLeft: 0,
                dragged: null,
                dropped: null,
                currentlyEditingPosition: null,
                currentlyEditingType: null,
                elements: [
                    /*{
                        name: 'Hiphop',
                        position: 1,
                        color: '#e07fcd',
                        subItems: [
                            {
                                name: 'Lolipop',
                                position: 1,
                                color: '#212a0c',
                            },
                            {
                                name: 'POP3',
                                position: 3,
                            },
                        ],
                    },
                    {
                        name: 'Pop',
                        position: 3,
                        subItems: [
                            {
                                name: 'Popcorn',
                                position: 1,
                            },
                        ],
                    },*/
                ],
            };
        },
        beforeMount() {
            if(this.value.length > 1) {
                this.elements = JSON.parse(this.value);
            }
            else {
                this.elements = [];
            }

        },
        methods: {
            getItem(position) {
                let arr = this.elements.filter(item => {
                    return item.position === position
                });
                if (arr.length > 0) {
                    return arr[0];
                }
                return {position: position};
            },
            getSubItem(position) {
                let index = this.activeIndexLeft;
                if (this.elements[index] && this.elements[index].subItems) {
                    let arr = this.elements[index].subItems.filter(item => {
                        return item.position === position
                    });
                    if (arr.length > 0) {
                        return arr[0];
                    }
                }
                return {position: position};
            },
            getIndex(position) {
                return this.elements.findIndex(item => {
                    return item.position === position;
                });
            },
            getSubIndex(position) {
                let subItems = this.elements[this.activeIndexLeft].subItems;
                if(!subItems) {
                    return null;
                }
                return subItems.findIndex(item => {
                    return item.position === position;
                });
            },
            setActive(position) {
                this.activePositionLeft = position;
                this.activeIndexLeft = this.getIndex(position);
            },
            gotDragged(position, useSubIndex) {
                this.dragged = position;
                this.swapPosition(useSubIndex);
            },
            gotDropped(position, useSubIndex) {
                this.dropped = position;
                this.swapPosition(useSubIndex);
            },
            swapPosition(useSubIndex) {
                if(this.dragged && this.dropped) {
                    if(!useSubIndex) {
                        let draggedIndex = this.getIndex(this.dragged);
                        let droppedIndex = this.getIndex(this.dropped);
                        if(droppedIndex === -1) {
                            this.elements[droppedIndex] = {};
                        }
                        this.elements[draggedIndex].position = this.dropped;
                        this.elements[droppedIndex].position = this.dragged;
                        if(this.activePositionLeft === this.dropped) {
                            this.setActive(this.dragged);
                        }
                        else if(this.activePositionLeft === this.dragged) {
                            this.setActive(this.dropped);
                        }
                    }
                    else {
                        let draggedIndex = this.getSubIndex(this.dragged);
                        let droppedIndex = this.getSubIndex(this.dropped);
                        if(droppedIndex === -1) {
                            this.elements[this.activeIndexLeft].subItems[droppedIndex] = {};
                        }
                        this.elements[this.activeIndexLeft].subItems[draggedIndex].position = this.dropped;
                        this.elements[this.activeIndexLeft].subItems[droppedIndex].position = this.dragged;
                    }
                    this.dragged = null;
                    this.dropped = null;
                    this.$emit('save', this.elements);
                    this.forceReRenderKey++;
                }
            },
            editItem(position) {
                this.currentlyEditingPosition = position;
                this.currentlyEditingType = 'item';
            },
            editSubItem(position) {
                this.currentlyEditingPosition = position;
                this.currentlyEditingType = 'subItem';
            },
            saveItem(item) {
                if(this.currentlyEditingType === 'item') {
                    if(!this.elements[this.getIndex(item.position)]) {
                        this.elements.push(item);
                        this.activeIndexLeft = this.getIndex(item.position);
                    }
                    else {
                        this.elements[this.getIndex(item.position)] = item;
                    }
                }
                else {
                    if(!this.elements[this.getIndex(this.activePositionLeft)][this.getSubIndex(item.position)]) {
                        this.elements[this.getIndex(this.activePositionLeft)].subItems = [item];
                    }
                    else {
                        this.elements[this.getIndex(this.activePositionLeft)][this.getSubIndex(item.position)] = item;
                    }
                }
                this.$emit('save', this.elements);
                this.stopEditing();
                this.forceReRenderKey++;
            },
            stopEditing() {
                this.currentlyEditingPosition = null;
                this.currentlyEditingType = null;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .ui-placeholder {
        position:relative;
        background-color: $ui-panel-background-color;
        padding-top: 50%;
        outline: 2px solid $navbarcolor;
    }
</style>
