<template>
    <div :class="['tag mb-1', clickable ? 'clickable' : '', isRemovable ? 'removable' : '']" :style="'background-color: ' + tag.color ">
        <icon v-if="iconLeft" :type="iconLeft" size="0.9" class="mr-1" />
        <span>{{ name ? name : (tag[nameProperty] ? tag[nameProperty] : tag.name) }}
        </span>
        <p class="lighter smaller" v-if="organizationId">({{ $store.getters.getOrganizationDisplayName(organizationId) }})</p>
        <slot></slot>
        <div v-if="isRemovable" class="icon-right" @click="$emit('removeTag', tag)"><icon type="timesCircle" size="1" /></div>
    </div>
</template>

<script>
    import Icon from "./Icon"

    export default {
        name: "Tag",
        components: {
            Icon
        },
        props: {
            tag : { type: Object, required: true},
            clickable : { type: Boolean, default: true},
            isRemovable : { type: Boolean, default: true},
            iconLeft: {type: String, default: 'tag'},
            nameProperty: {type: String, default: 'name'},
            name: {type: String, default: ''},
            organizationId: {type: String, default: ''},
        },

    }
</script>
<style lang="scss" scoped>


</style>