<template>
    <main-table-layout
        sub-menu-selector="adminpanel"
    >
        <div slot="buttonsRight"/>

      <content-filter
          slot="filter"
          @filter="filtering"
          id="userOverview"
          store-name="User"
          :tag-filtering="false"
          :org-filtering="false"

          :text-filtering="$store.getters.isSuperAdmin"
      >
        <organization-switcher
            v-if="!$store.getters.isSuperAdmin"
            @setOrganization="id => organizationId = id"
            class="mt-3"
            :show-teams="false"
        />
      </content-filter>

        <view-switcher
                slot="mainContentButtons"
                :id="'mainContent'"
                :table="true"
                :thumbnails="true"
        /> <pagination
            slot="mainContentButtons"
            :limit="paginationLimit"
            :total="$store.getters.getUserListOptions(listName, 'pagination_items')"
            :id="'usertablepage'"
            @setActive="loadPaginatedData"
            ref="pagination"
    />
        <Button
                permission="cannotCreateUser"
                type="action-button" slot="mainContentButtons" link="/admin/users/add" icon="plus">
                {{ $t('createNewUser') }}
        </Button>
      <Button
           slot="mainContent"
           @click="loadPaginatedData(0); $refs.pagination.setActive(0)"
           type="reload"
           icon="redo"
           class="icon-button"
       />
            <table-widget
                    v-if="$store.getters.getViewSwitcher($route.name, 'mainContent') === 'table'"
                    slot="mainContent"
                    :id="'usersDefaultTable'"
                    :nav-by-clicking-row="true"
                    :deletable="false"
                    @deleteRow="deleteRow"
                    :show-tags="true"
                    :fields="tableFields"
                    :data="$store.getters.getUsersByListName(listName)"
                    :title-as-links="true"
                    :key="forceReRenderKey"
                    edit-permission="writeUser"
                    :loading="loading"
                     @sort="sort"
            />
            <thumbnails
                    v-if="$store.getters.getViewSwitcher($route.name, 'mainContent') === 'thumbnails'"
                    slot="mainContent"
                    :id="'usersDefaultThumbnails'"
                    :deletable="false"
                    @deleteRow="deleteRow"
                    :nav-by-clicking-row="true"
                    :fields="thumbnailFields"
                    :data="$store.getters.getUsersByListName(listName)"
                    :title-as-links="true"
                    :key="forceReRenderKey"
                    edit-permission="writeUser"
                    :loading="loading"
                     @sort="sort"
            />
    </main-table-layout>
</template>

<script>
    import Button from "../components/forms/Button";
    import TableWidget from "../components/widgets/TableWidget";
    import MainTableLayout from "../layouts/MainTableLayout";
    import ViewSwitcher from "../components/widgets/ViewSwitcher";
    import Thumbnails from "../components/widgets/ThumbnailView";
    import Pagination from "../components/Pagination";
    import ContentFilter from "@/components/widgets/filter/ContentFilter";
    import OrganizationSwitcher from "@/components/organizations/OrganizationSwitcher";

    export default {
        name: "Users",
        components: {
          Button,
          TableWidget,
          MainTableLayout,
          ViewSwitcher,
          Thumbnails,
          Pagination,
          ContentFilter,
          OrganizationSwitcher
        },
        data() {
            return {
                loading: true,
                listName: 'userOverview',
                organizationId: '',
                paginationLimit: 15,
                viewId: 'mainContent',
                forceReRenderKey: 0,
                filter: {},
                offset: 0,
                sorting: '-updatedAt',
                tableFields: [
                    {
                        name: 'image',
                        id:'image',
                        type: 'image',
                    },
                    {
                        name: 'Name',
                        id: 'name',
                        type: 'string',
                        sortable: true
                    },
                    {
                        name: 'Internal',
                        id: 'internal',
                        type: 'string',
                    },
                    {
                        name: 'Blocked',
                        id: 'blocked',
                        type: 'boolean',
                        sortable: true
                    },
                    {
                        name: 'App Panel',
                        id: 'appPanel',
                        type: 'string',
                    },
                    {
                        name: 'Last Update',
                        id: 'updatedAt',
                        type: 'date',
                        sortable: true
                    },
                    {
                        name: 'Edit',
                        type: 'editButton'
                    },
                ],
                thumbnailFields: [
                    {
                        name: '',
                        id:'',
                        type: 'image',
                    },
                    {
                        name: 'Last Update',
                        id: 'updatedAt',
                        type: 'date',
                    },
                    {
                        name: 'Name',
                        id: 'name',
                        type: 'string',
                        sortable: true
                    },
                    {
                        name: 'Blocked',
                        id: 'blocked',
                        type: 'boolean',
                    },
                    {
                        name: 'Tags',
                        id:'tags',
                        type: 'tags',
                    },
                    {
                        name: 'Edit',
                        type: 'editButton'
                    },
                ],
            };
        },
        watch: {
            organizationId() {
              this.loadPaginatedData(0);
              // setting current user org because it will be needed for access check in userEdit view
              this.$store.dispatch('setUserCurrentOrg', this.organizationId);
            }
        },
        methods: {
          loadPaginatedData(offset) {
            this.offset = offset;
            this.loadData();
          },
          loadData: function() {
            this.loading = true;
                let loadingMethod = 'loadOrganizationUsers';
                let args = {
                  listName: this.listName,
                 }
                  const paginationAddOn = {
                    paging: {
                      [this.listName]: true,
                    },
                    offset: {
                      [this.listName]: this.offset,
                    },
                    limit: {
                      [this.listName]: this.paginationLimit,
                    },
                    sort: {
                      [this.listName]: this.sorting,
                    }
                  };
                  args.add = this.filter && this.filter.add ? {...paginationAddOn, ...this.filter.add } : paginationAddOn;
                  if(this.filter && this.filter.remove) {
                    args.remove = {...this.filter.remove};
                  }
                  if(this.$store.getters.isSuperAdmin) {
                      loadingMethod = 'loadUsers';
                      if(!args.remove) {args.remove = {}}
                      args.remove.include = [this.listName]
                  }
                  else {
                    args.add.id =  {[this.listName]: this.organizationId};
                    args.add.include = {
                      [this.listName]: ['users'],
                    }
                  }
                  if(this.organizationId || this.$store.getters.isSuperAdmin) {
                    this.$store.dispatch(loadingMethod, args).then(() => {
                      this.loading = false;
                    })
                  }
            },
            deleteRow: function(id) {
                this.$store.dispatch('deleteUser', {
                    args: [id]
                }).then(() => {
                  this.loadData();
                })
            },
            filtering: function(args) {
                this.filter = args;
                this.loadData();
            },
            sort(args) {
              const {fieldname, direction} = args;
              const sortString = direction + fieldname;
              if(this.sorting !== sortString) {
                this.sorting = sortString;
              }
              this.loadData(false);
            }
        }
    }
</script>