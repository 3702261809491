import Vue from 'vue'
//import {_} from 'vue-underscore';
// eslint-disable-next-line no-unused-vars
import i18n from "../translation";

const state = {
    scenesLists: {
        sceneList: {},
    },
    scenesListOptions: {},
    defaultQueryParams: {
        limit: {
            default: '50',
        },
        filter: {
            default: 'type eq scenery'
        },
        sort: {
            default: '-updatedAt',
        }
    },
    queryParams: {
        sceneList: {
            limit: {
                default: '50',
            },
            filter: {
                default: 'type eq scenery'
            },
            sort: {
                default: '-updatedAt',
            },
        },
    },
    combinedQueryParams: {},
    temp: {}
};
const getters = {
    getDefaultScenes () {
        return state.scenesLists.sceneList;
    },
    getScenesByListName: (state) => (listName) => {
        return state.scenesLists[listName] ? state.scenesLists[listName] : {};
    },
    getSceneTitle: (state) => (id, listName = 'sceneList') => {
        if(state.scenesLists[listName][id]) {
            return state.scenesLists[listName][id].name;
        }
        else {
            return '';
        }
    },
    getSceneDescription: (state) => (id, listName = 'sceneList') => {
        if(state.scenesLists[listName][id]) {
            return state.scenesLists[listName][id].description;
        }
        else {
            return '';
        }
    },
    getSceneOriginalQueryParams: function() {
        return state.queryParams;
    },
    getSceneQueryParams: (state) => (listName) => {
        return state.combinedQueryParams[listName];
    },
    getSceneCreationDate: (state, rootGetters) => (id, listName = 'sceneList') => {
        return state.scenesLists[listName][id] ? rootGetters['convertDate'](state.scenesLists[listName][id].createdAt) : '';
    },
    getSceneChangeDate: (state, rootGetters) => (id, listName = 'sceneList') => {
        return state.scenesLists[listName][id] ? rootGetters['convertDate'](state.scenesLists[listName][id].updatedAt) : '';
    },
    getSceneOrganization: (state) => (id, listName = 'sceneList') => {
        let listContainer = 'scenesLists';
        return state[listContainer][listName][id] && state[listContainer][listName][id].organizationId ? state[listContainer][listName][id].organizationId : '';
    },
    getSceneTags: (state) => (id, listName = 'sceneList') => {
        return state.scenesLists[listName][id] && state.scenesLists[listName][id].tags ? state.scenesLists[listName][id].tags : [];
    },
    getScenePreviewUri: (state) => (id, listName = 'sceneList') => {
        return state.scenesLists[listName][id] && state.scenesLists[listName][id].previewUri ? state.scenesLists[listName][id].previewUri : '';
    },
    getSceneMetaSets: (state) => (id, listName = 'sceneList') => {
        return state.scenesLists[listName][id] && state.scenesLists[listName][id].metaSets ? state.scenesLists[listName][id].metaSets : {};
    },
    getScenesListsOptions: (state) => (listName, option = '') => {
        let optionList = 'scenesListOptions';
        if(option === 'preview') {
            return state[optionList][listName] && state[optionList][listName].previewPath ? Object.keys(state[optionList][listName].previewPath)[0] : '';
        }
        else if(option === 'fbxPreview') {
            return state[optionList][listName] && state[optionList][listName].fbxPreview ? Object.keys(state[optionList][listName].fbxPreview)[0] : '';
        }
        else if(option === 'pagination_items') {
            return state[optionList][listName] && state[optionList][listName].pagination_items ? parseInt(Object.keys(state[optionList][listName].pagination_items)[0]) : 0;
        }
        return state[optionList][listName] ? state[optionList][listName] : {};
    },
};
const mutations = {
    loadScenesMutation (state, args) {
        let listName = 'sceneList';
        if(args.listName) {
            listName = args.listName;
            delete args.listName;
        }
        const data = args.data;
        Vue.set(state.scenesLists, listName, {});
        for (var i = 0; i < data.length; i++) {
            let asset = data[i];
            Vue.set(state.scenesLists[listName], asset.id, asset);
        }
    },
    loadSceneMutation(state, data) {
        Vue.set(state.scenesLists.sceneList, data.id, data);
    },
    updateSceneMutation(state, data) {
        for (var key in data) {
            var value = data[key];
            Vue.set(state.scenesLists.sceneList[data.id], key, value);
        }
    },
    markSceneSelectedMutation(state, args){
        let listName = args.listName;
        Object.keys(args.data).forEach(key => {
            let id = args.data[key].id;
            if(state.scenesLists[listName] && state.scenesLists[listName][id]) {
                Vue.set(state.scenesLists[listName][id], 'selected', true);
            }
        });
    },
    updateScenesListOptionMutation(state, args) {
        let optionList = 'scenesListOptions';
        //reset all data:
        if (!state[optionList][args.listName]) {
            Vue.set(state[optionList], args.listName, {});
        }

        if (args.override) {
            Vue.set(state[optionList][args.listName], args.optionKey, {});
        }

        //reset all other options
        if (args.optionKey === 'selected' || args.optionKey === 'preview') {
            Vue.set(state[optionList][args.listName], args.optionKey, {});
        }

        if (!state[optionList][args.listName][args.optionKey]) {
            Vue.set(state[optionList][args.listName], args.optionKey, {});
        }
        if (state[optionList][args.listName][args.optionKey][args.value]) {
            Vue.delete(state[optionList][args.listName][args.optionKey], args.value);
        } else {
            Vue.set(state[optionList][args.listName][args.optionKey], args.value, args.value2);
        }
    },
    setScenePropertyMutation (state, args) {
        let listName = args.listName;
        let id = args.id;
        if(state.scenesLists[listName] && state.scenesLists[listName][id]) {
            Vue.set(state.scenesLists[listName][id], args.property, args.value);
        }
    },
};
const actions = {
    registerScenesQueryParams: ({commit}, args) => {
        let listName = 'sceneList';
        if(args.listName) {
            listName = args.listName;
        }
        if(Object.keys(args).length > 0) {
            commit('queryParamsSave', {storeType: 'scenes', listName: listName, params: args});
        }
    },
    loadScenes: async ({commit, dispatch, getters}, args = {}) => {
        let listName = 'sceneList';
        if(args.listName) {
            listName = args.listName;
        }

        if(Object.keys(args).length > 0) {
            commit('queryParamsSave', {storeType: 'scenes', listName: listName, params: args});
        }
        commit('combineQueryParams', {storeType: 'scenes', listName: listName});
        return dispatch('clientLoadAssets', getters.getSceneQueryParams(listName)).then(async data => {
            data.listName = listName;
            for (let i = 0; i < data.length; i++) {
                data[i]['editLink'] = '/library/scenes/' + data[i].id + '/general';
                if(args.projectInstanceId) {
                    data[i].selected = await dispatch('assetHasInstanceOfProject', {projectId: args.projectInstanceId, asset: data[i]});
                }
            }
            commit('loadScenesMutation', {data: data, listName: args.listName});
            return data;
        });

    },
    loadScene: async ({commit, dispatch}, args) => {
        dispatch('clientLoadAsset', args).then(data => {
            commit('loadSceneMutation', data);
        });
    },
    setSceneMetaValues: async ({dispatch}, args) => {
        //commit('updateProjectMutation', args);
        return dispatch('clientSaveAssetMetaSetValues', args).then(() => {
            dispatch('createNotification', {'text': 'Der Wert wurde aktualisiert.'});
        });
    },
    setScenesListSelected: ({commit}, args) => {
        args.optionKey = 'selected';
        args.value = args.id;
        commit('updateScenesListOptionMutation', args);

        return commit('updateScenesListOptionMutation', {
            id: args.id,
            listName: args.listName,
            value: args.previewUri,
            optionKey: 'previewPath',
        });
    },
    updateScene: async ({commit, dispatch}, args) => {
        commit('updateSceneMutation', args);
        dispatch('clientSaveAsset', args).then(() => {
            dispatch('createNotification', {'text': i18n.t('events.mediaUpdated')});
        });
    },
    checkIfSceneNameExists: async ({dispatch, getters}, args) => {
        let value = args.value;
        let orgId = args.organizationId ? args.organizationId : getters.getCurrentUserOrg;
        console.log('checking...');
        return await dispatch('clientLoadAssets', {
            filter: ['name eq ' + value + ', organizationId eq ' + orgId],
            fields: 'name,id',
        }).then(asset => {
            return asset;
        });
    },
    /*
    * Attention: this method is vuex-store only (no database-connection)
    * id
    * listName
    * property (string with keyname)
    * value
    * */
    setSceneProperty: ({commit}, args) => {
        commit('setScenePropertyMutation', args);
    },

    /*
    * Project-related actions
    * */
    addProjectScene: async ({dispatch}, args) => {
        return dispatch('clientLoadProjectAssets', {
            filter: 'type eq scenery',
            id: args.id,
        }).then(data => {
            if(data) {
              dispatch('clientRemoveProjectAsset', {id: args.id,
                    args: data.map(item => {return item.id})});
            }
            return dispatch('clientAddProjectAsset', args);
        });
    },
    deleteScene: async ({commit, dispatch}, args = {}) => {
        let listName = 'sceneList';
        if(args.listName) {
            listName = args.listName;
        }
        let queryArgs = args.args;
        return dispatch('clientDeleteAssets', queryArgs).then(() => {
            commit('deleteTextMutation', {listName: listName, id: queryArgs});
           return dispatch('createNotification', {'text': i18n.t('events.sceneDeleted')});
        });
    },
};
export default{
    state,getters,mutations,actions
}
