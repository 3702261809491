<template>
  <one-column-sidebar-left
      tab-selector="teamtab"
      type="team"
      back-button-tab-selector="teambackbutton"
      :id="$route.params.id"
      sub-menu-selector="adminpanel"
  >
    <info-panel slot="sidebarLeft"
                :id="$route.params.id"
                :preview-uri="$store.getters.getTeamPreviewUri($route.params.id)"
                :image-editable="true"
                upload-type="Team"
                :created="$store.getters.getTeamCreationDate($route.params.id)"
                :last-change="$store.getters.getTeamChangeDate($route.params.id)"
                :downloadable="true"
                :organization="$store.getters.getTeamOrganization($route.params.id)"
                preview-id="teamEdit"
                :removable="true"
    />

        <div slot="mainContent">
            <h1><icon class="mr-1" type="users" size="0.9" /> {{ $store.getters.getTeamDisplayName(id) }}</h1>
            <p>({{ $store.getters.getTeamName(id) }})</p>
            <p v-if="$store.getters.getTeamOwner(id)">
              {{ $t('owner') }}: {{ $store.getters.getUserById($store.getters.getTeamOwner(id)) ? $store.getters.getUserById($store.getters.getTeamOwner(id)).name : $store.getters.getTeamOwner(id) }}</p>
          <div v-if="$store.getters.isSuperAdmin">
            <div class="darker p-3 mt-2 ">
              Attention: if you set this to public, newly created users will automatically be editor member of this team
              <org-visibility-dropdown
              :initial-value="$store.getters.getTeamVisibility(id)"
              @update="update"
            />
            </div>
          </div>
            <content-field-block
                class="mb-2 mt-3"
                :value="$store.getters.getTeamDisplayName(id)"
                :id="$route.params.id"
                store-name="Team"
                field-name="displayName"
                label="displayName"
                input-type="text"
            />
            <content-description-block
                    :value="$store.getters.getTeamDescription(id)"
                    :id="$route.params.id"
                    store-name="Team"
            />
          <info-panel
              :title="$t('members')"
            :collapsible="true"
            :initially-collapsed="false"
              preview-id="noId"
          >
            <div slot="info">
              <tag
                  :name="member.user.name"
                  :is-removable="true"
                  icon-left="home"
                  @removeTag="removeUser"
                  v-for="(member, index) in members" :key="index" :tag="member">
                <role-selector
                    class="mt-2 mb-2"
                    :pre-selected-role="member.role"
                    @update="(role) => updateRole(role, member.user.id)"
                />
              </tag>
              <universal-selector
                  class="mt-4"
                  store-name="User"
                  :exclude-from-item-list="members.map(item => {return {id: item.userId}})"
                  :list-name="'teamEdit' + $route.params.id"
                  filter-attribute="name"
                  @addItem="addUser"
              />
            </div>
          </info-panel>

</div>
    </one-column-sidebar-left>
</template>

<script>
    import InfoPanel from "../components/InfoPanel";
    import OneColumnSidebarLeft from "../layouts/OneColumnSidebarLeft";
    import ContentDescriptionBlock from "../components/ContentDescriptionBlock";
    import UniversalSelector from "@/components/organizations/UniversalSelector";
    import Tag from "@/components/Tag";
    import RoleSelector from "@/components/organizations/RoleSelector";
    import ContentFieldBlock from "@/components/ContentFieldBlock";
    import OrgVisibilityDropdown from "@/components/organizations/OrgVisibilityDropdown";
    import Icon from "../components/Icon";

    export default {
      name: 'TeamEdit',
      components: {
        InfoPanel,
        OneColumnSidebarLeft,
        ContentDescriptionBlock,
        UniversalSelector,
        Tag,
        RoleSelector,
        ContentFieldBlock,
        OrgVisibilityDropdown,
        Icon
      },
      data() {
        return {
          id: '',
          members: [],
        };
      },
      beforeMount() {
        this.loadTeam();
        this.loadTeamMembers();
        this.id = this.$route.params.id;
      },
      methods: {
        update(fieldname, value) {
          this.$store.dispatch('updateTeam', {
            id: this.$route.params.id,
            visibility: value
          }).then(() => {
            this.loadTeam();
          })
        },
        loadTeam() {
          this.$store.dispatch('loadTeam', {
            id: this.$route.params.id,
          }).catch(e => {
            if(e.status === 401) {
              this.$router.push('/access-denied');
            }
          });
        },
        loadTeamMembers() {
          this.$store.dispatch('clientLoadTeamMembers', {
            id: this.$route.params.id,
            include: ['users']
          }).then(data => {
            this.members = data;
          });
        },
        addUser(user) {
          this.$store.dispatch('clientAddTeamMember', {
            id: this.$route.params.id,
            cid: user.id,
          }).then(() => {
            this.loadTeamMembers();
          });
        },
        removeUser(user) {
          this.$store.dispatch('clientRemoveTeamMember', {
            id: this.$route.params.id,
            cid: user.userId,
          }).then(() => {
            this.loadTeamMembers();
          });
        },
        updateRole(role, userId) {
          this.$store.dispatch('clientUpdateTeamMember', {
            id: this.$route.params.id,
            cid: userId,
            role: role
          }).then(() => {
            this.loadTeamMembers();
          });
        }
      },
}
</script>