<template>
    <main-table-layout
        sub-menu-selector="adminpanel"
    >
        <div slot="buttonsRight"/>
        <content-filter slot="filter"
                       @filter="filtering"
                        :id="'serviceList'"
                        store-name="Service"
        />
        <view-switcher
                slot="mainContentButtons"
                :id="'mainContent'"
                :table="true"
                :thumbnails="false"
        />
        <pagination
                slot="mainContentButtons"
                :limit="paginationLimit"
                :total="$store.getters.getServiceListOptions(listName, 'pagination_items')"
                :id="'serviceList'"
                @setActive="loadPaginatedData"
                ref="pagination"
        />
        <Button
            permission="createService"
            type="action-button" slot="mainContentButtons" link="/admin/services/add" icon="plus">
            {{ $t('createnew') }}
        </Button>
      <Button
           slot="mainContent"
           @click="loadPaginatedData(0); $refs.pagination.setActive(0)"
           type="reload"
           icon="redo"
           class="icon-button"
       />
        <table-widget
                v-if="$store.getters.getViewSwitcher($route.name, 'mainContent') === 'table'"
                slot="mainContent"
                :id="'serviceDefaultTable'"
                :nav-by-clicking-row="true"
                :deletable="true"
                @deleteRow="deleteRow"
                :show-tags="true"
                :show-organization="true"
                edit-permission="editService"
                delete-permission="deleteService"
                :fields="tableFields"
                :data="$store.getters.getServicesByListName(listName)"
                :title-as-links="true"
                :loading="loading"
        />
        <thumbnails
                v-if="$store.getters.getViewSwitcher($route.name, 'mainContent') === 'thumbnails'"
                slot="mainContent"
                :id="'sfxdataDefaultThumbnails'"
                :deletable="true"
                :nav-by-clicking-row="true"
                edit-permission="editService"
                delete-permission="deleteService"
                :fields="thumbnailFields"
                :data="$store.getters.getServicesByListName(listName)"
                :title-as-links="true"
                :loading="loading"
        />
    </main-table-layout>
</template>

<script>
    import Button from "../components/forms/Button";
    import TableWidget from "../components/widgets/TableWidget";
    import ContentFilter from "../components/widgets/filter/ContentFilter";
    import MainTableLayout from "../layouts/MainTableLayout";
    import ViewSwitcher from "../components/widgets/ViewSwitcher";
    import Thumbnails from "../components/widgets/ThumbnailView";

    import Pagination from "../components/Pagination";

    export default {
        name: "SFXData",
        components: {
            Button,
            TableWidget,
            ContentFilter,
            MainTableLayout,
            ViewSwitcher,
            Thumbnails,
            Pagination,
        },
        data() {
            return {
                loading: true,
                listName: 'ServiceList',
                paginationLimit: 4,
                offset: 0,
                list: {},
                filter: {},
                queryRelevantComponents: 2, //pagination and filter
                queryRelevantInits: 0,
                tableFields: [
                    {
                        name: 'DisplayName',
                        id:'displayName',
                        type: 'string',
                    },
                    {
                        name: 'Last Update',
                        id:'updatedAt',
                        type: 'date',
                    },
                    {
                        name: 'Edit',
                        type: 'editButton'
                    },
                ],
                thumbnailFields: [
                    {
                        name: 'Organization',
                        id:'organization',
                        type: 'organization',
                    },
                    {
                        name: 'Last Update',
                        id:'updatedAt',
                        type: 'date',
                    },
                    {
                        name: 'Name',
                        id:'name',
                        type: 'string',
                    },
                    {
                        name: 'Edit',
                        type: 'editButton'
                    },
                ],
            }
        },
        watch: {
            queryRelevantInits: function(newval) {
                if(newval === this.queryRelevantComponents) {
                    this.loadServices();
                }
            }
        },
        methods: {
          loadServices(initial = false) {
            this.loading = true;
            let args = {
              listName: this.listName
            };
            const paginationAddOn = {
              limit: {
                default: this.paginationLimit
              },
              offset: {
                default: this.offset,
              },
              paging: {
                default: true
              },
            }
            args.add = this.filter.add ? {...paginationAddOn, ...this.filter.add } : paginationAddOn;
            if(this.filter.remove) {args.remove = this.filter.remove}
            if(!initial || this.queryRelevantInits >= this.queryRelevantComponents) {
              this.$store.dispatch('loadServices', args).then(data => {
                this.list = data;
                this.loading = false;
              });
            } else {
              this.$store.dispatch('registerServiceQueryParams', {listName: this.listName, params: args});
            }
          },
          deleteRow: function (id) {
            this.$store.dispatch('deleteServices', {
              args: [id]
            }).then(() => {
              this.loadServices();
            });
          },
          filtering(val, initial) {
            this.queryRelevantInits++;
            this.filter = val;
            this.loadServices(initial);
          },
          loadPaginatedData(offset, initial) {
            this.queryRelevantInits++;
            this.offset = offset;
            this.loadServices(initial);
          },
        }
    }
</script>
