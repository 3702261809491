<template>
    <one-column-sidebar-left
        back-button-tab-selector="csvbackbutton"
        sub-menu-selector="organizingpanel"
    >
        <div slot="leftContent">
          <div class="fixed-pos p-3 darker">
            <codemirror ref="myCm"
                      :value="schemaString"
                      @input="change"
                      @change="change"
          />
            <div class="form-error" v-if="error">{{ $t('invalidJSON') }}</div>
          </div>
        </div>
        <div class="row" slot="fullContent">
        <div class="col-12">
          <h1><icon class="mr-2" type="file-csv" size="0.9" />{{ $t('importCSV') }}</h1>
           <organization-switcher
                @setOrganization="id => {schema.organizationId = id}"
                @setTeam="id => {schema.teams[0] = id}"
                @unsetTeam="schema.teams[0] = null"
                class="mb-2" />
                <div class="darker p-3">
                  ATTENTION:
                  <h2>Column names</h2>
                  If you want to use data from your csv file, you can set the
                  name by setting its value to row.myNameRow. Row-Names with spaces like "my name" are not
                  permitted, you will have to rename the column name to "myName" or something similar.<br /><br />
                </div>
                <input
                    type="text"
                    :class="['form-text mt-3 mb-3']"
                    :id="'name'"
                    v-model="schema.name"
                    :placeholder="$t('row.name')"
            >
            <div class="inline-edit-label">
                <div class="darker p-2">
                  <h2>externalId</h2>
                  Your data will need to provide an externalId – this is a unique identifier across all over vHub,
                  in case you want to update your data. If your externalId's are not unique, it might happen
                  that the csv overwrites your existing data, so don't use numbers as ID's. Better use
                  uuid's.
                </div>
            </div>
            <input
                    type="text"
                    :class="['form-text mt-3 mb-3']"
                    :id="'name'"
                    v-model="schema.externalId"
                    :placeholder="$t('row.externalId')"
            >
            <div class="inline-edit-label">
                <b>{{ $t('description') }}</b>
            </div>
            <div>
                 <textarea
                         :class="['form-text mb-3']"
                         :id="'description'"
                         v-model="schema.description"
                         :placeholder="$t('row.description')"
                 />
            </div>
            <div class="row ml-1">
                <div class="col-12 col-md-6 neutralize-margin-left">
                   {{ $t('Type') }}
                    <tile-button :rows="4"
                    :icons="true"
                    ref="tileB"
                    :tiles="Object.values(AssetTypes).map(item => {return 'TileButtons.' + item})"
                    :values="Object.values(AssetTypes)"
                    @trigger="updateType" />
                </div>
            </div>
            <div class="col-12 p-3 mt-3 darker" v-if="!saving">
              <h3>MetaValues</h3>
              <meta-set-selector
                  class="mt-1"
                  v-if="$store.getters.getSuperOrg && schema.type"
                  :organization-id="$store.getters.getSuperOrg"
                  @update="setMetaSet"
                  permission="readMetaSet"
                  :show-save-button="false"
                  :meta-set-prefix="'sfx.asset.' + schema.type"
              />
              <meta-field-selector
                v-if="schema.type"
                class="mb-2"
                :meta-set-id="metaSetId"
                @change="setMetaField"
              />
              <Button v-if="metaField" class="mb-3" @click="addMetaValue"><icon type="plus" />{{ $t('add') }}</Button>
              <p>{{ $t('selectedMetaValues') }}</p>
                <table v-if="schema.organizationId && schema.tmpMetaValues && schema.tmpMetaValues[item.id] && !saving"
                  class="mt-3"
                  :key="index"
                  v-for="(item, index) in schema.metaValues">

                  <Button class="mb-3" @click="removeMetaValue(item.id)"><icon type="trash-alt" />{{ $t('remove') }}</Button>
                  <meta-field
                    v-if="schema.tmpMetaValues[item.id]"
                    :organization-id="organizationId"
                    store-name="Asset"
                    :do-emit-only="true"
                    :label="schema.tmpMetaValues[item.id].field.description"
                    :field="schema.tmpMetaValues[item.id].field"
                    :value="schema.tmpMetaValues[item.id].field.value"
                    icon-left="folder"
                    @updated="updateMetaValue"
                />
                </table>
          </div>
        </div>

        <div class="w-100" />
           <div class="col-12 col-lg-4 mt-5">
              <upload-drop-zone
                  :auto-upload="false"
                  :asset-name="schema.name"
                  :organization-id="schema.organizationId"
                  @response="finish"
                  @queueEmpty="hasFile = false; checkSaving();"
                  @filesInQueue="hasFile = true; checkSaving();"
                  ref="uploadComponent"
                  target-type="CSVAsset"
                  form-id="CSVUpload"
                  accepted-file-types=".csv"
                  :removable="false"
                  :make-chunks="true"
                  :auto-update-preview="false"
                  :attached-data="[{key: 'schema', value: JSON.stringify(schema)}]"
              />
               <div class="form-error mt-3" :key="index" v-if="errors" v-for="(e, index) in errors">{{ e }}</div>
              <Button class="mt-3" @click="save">{{ $t('startImport') }}</Button>
            </div>
        </div>
    </one-column-sidebar-left>
</template>

<script>
    import Button from "../components/forms/Button";
    import OneColumnSidebarLeft from "../layouts/ThreeColumns";
    import Icon from "../components/Icon";
    import OrganizationSwitcher from "../components/organizations/OrganizationSwitcher";
    import TileButton from "../components/forms/TileButton";
    import VuelidateMixin from "@/components/mixins/VuelidateMixin.js";
    import { AssetTypes } from "@/enum";
    import Codemirror from "@/components/widgets/editors/Codemirror";
    import UploadDropZone from "@/components/files/UploadDropZone";
    import MetaSetSelector from "@/components/widgets/meta/MetaSetSelector";
    import MetaFieldSelector from "@/components/widgets/meta/MetaFieldSelector";
    import MetaField from "@/components/widgets/meta/MetaField";
    export default {
        name: 'CSVImport',
        components: {
            OrganizationSwitcher,
            Button,
            OneColumnSidebarLeft,
            Icon,
            TileButton,
            Codemirror,
            UploadDropZone,
            MetaSetSelector,
            MetaFieldSelector,
            MetaField
        },
        mixins: [VuelidateMixin],
        data() {
            return {
                saving: false,
                forceReRenderKey: 0,
                metaSetId: '',
                metaSetName: '',
                metaField: null,
                metaFields: [],
                errors: [],
                error: false,
                schemaString: JSON.stringify(this.schema),
                schema: {
                  name: '',
                  description: '',
                  type: '',
                  organizationId: '',
                  teams: [],
                  metaValues: [],
                  tmpMetaValues: {},
                },
                timeout: null,
                nameEdit: true,
                descEdit: true,
                id: '',
                enableSaving: false,
                AssetTypes: AssetTypes
            };
        },
        watch: {
            schema: {
              deep: true,
              handler() {
                clearTimeout(this.timeout);
                const $this = this;
                this.timeout = setTimeout(() => {
                  $this.schemaString = (JSON.stringify({...$this.schema}));
                }, 1000);
              }
            },
        },
        methods: {
          updateMetaValue(obj) {
            this.schema.tmpMetaValues[this.metaField.id].value = obj.value;
            const index = this.schema.metaValues.findIndex(item => {return item.id === obj.metaFieldId});
            this.schema.metaValues[index].value = obj.value;
          },
          removeMetaValue(id) {
             const index = this.schema.metaValues.findIndex(item => {return item.id === id});
             this.schema.metaValues.splice(index, 1);
             delete this.schema.tmpMetaValues[id];
             this.forceReRenderKey = this.forceReRenderKey + 100;
          },
          addMetaValue() {
            const obj = {
              field: this.metaField,
              key: this.metaField.name,
              id: this.metaField.id,
              value: '',
              metaFieldId: this.metaField.id
            };
            this.schema.tmpMetaValues[this.metaField.id] = obj;
            this.schema.metaValues.push(obj);
          },
          setMetaSet(name, id) {
            this.metaSetId = id;
            this.metaField = {};
          },
          setMetaField(field) {
            this.metaField = field;
          },
          change(res) {
            if(res) {
              let savable = true;
              try {
                JSON.parse(res);
              } catch {
                savable = false;
                this.error = true;
              }
              if(savable) {
                this.error = false;
                this.schema = JSON.parse(res);
              }
            }
            else {
              this.schema = {};
            }
          },
            checkSaving: function() {
                this.enableSaving = this.name && this.hasFile && !this.$v.$invalid;
            },
            updateType (args) {
                this.schema.type = args.value;
            },
            save: async function() {
                this.errors = [];

                if(!this.schema.externalId || !this.schema.externalId.includes('row.')) {
                  this.errors.push('externalId must be set to a dynamic parameter (e.g. row.myExternalId) and must be unique across all vHub');
                }

                if(!this.schema.name || !this.schema.name.includes('row.')) {
                  this.errors.push('name field must be present and must be dynamic parameter (e.g. row.myName) because duplicate names are not possible')
                }

                if(!this.schema.type) {
                  this.errors.push('type must be selected');
                }

                if(this.errors.length) {
                  return;
                }
                if(!this.errors.length) {
                    this.saving = true;
                    delete this.schema.tmpMetaValues;
                    this.$refs.uploadComponent.resumeUpload();
                }
            },
            finish(response) {
              console.log(response);
              // todo: list imported items
            },
        },
    }
</script>

<style lang="scss" scoped>
  .fixed-pos {
    position: fixed;
    top: 120px;
    width: 400px;
    background-color: $highlight;
  }
</style>