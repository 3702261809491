<template>
    <div>
        <div class="fieldset" :key="fieldsetIndex" v-for="(fieldset, fieldsetIndex) in getConfig.fieldsets">
            <h4 v-if="isXForm">{{ xformLabels[fieldsetIndex] }}</h4>
            <div class="">
                <div :key="index" v-for="(field, index) in fieldset">
                    <b>{{ $t(field) }}:</b> {{ values && values[index] ? values[index] : 0 }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {VectorConfigs} from '../../../enum';
    import {MetaFieldTypes} from '../../../enum';
    /**
     * Widget for displaying vector data
     * @displayName Vector Display
     */
    export default {
        name: "VectorDisplay",
        props: {
            /**
             * A Json or Array for coordinates – must fit the corresponding type
             * @values (e.g. [0,0] for Vector 2, [[0,0,0,0], [0,0,0,0], [0,0,0,0]] for xForm3 (loc, rot, scale)
             */
            value: {type: String, default: ''},
            /**
             * The type of the vector-data
             * @values Vector2, Vector3, Vector4, xForm2, xForm3
             */
            type: {type: String, default: ''},
        },
        data() {
            return {
                xformLabels: ['position', 'rotation', 'scale'],
                values: []
            };
        },
        computed: {
            getConfig() {
                return VectorConfigs[this.type];
            },
            isXForm: function () {
                return this.type === MetaFieldTypes.xForm2 || this.type === MetaFieldTypes.xForm3;
            },
        },
        beforeMount() {
            if(this.value) {
                let arr = this.value;
                if (arr && !Array.isArray(this.value)) {
                    arr = JSON.parse(this.value)
                }
                this.values = arr;
            }
        },
    }
</script>

<style lang="scss" scoped>
    h4 {
        margin-top:25px;
        margin-bottom:0;
    }
</style>