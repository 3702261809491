<template>
    <div>
        <div class="fieldset" :key="fieldsetIndex" v-for="(fieldset, fieldsetIndex) in getConfig.fieldsets">
            <h4 v-if="isXForm">{{ xformLabels[fieldsetIndex] }}</h4>
            <div class="vector-container">
                <div class="form-group" :key="index" v-for="(field, index) in fieldset">
                    <label>{{ $t(field) }}</label>
                    <input
                            class="form-text"
                            :id="field"
                            :ref="field"
                            @keydown.enter="save"
                            type="number"
                            v-model.number="values[index]">
                </div>
            </div>
        </div>
        <Button
                class="mt-3"
                @click="save"
        >
            <icon type="save"/>
            {{ $t('SAVE') }}
        </Button>
    </div>
</template>

<script>
    import {VectorConfigs} from '../../../enum';
    import {MetaFieldTypes} from '../../../enum';
    import Button from "../../forms/Button";
    import Icon from "../../Icon";
    /**
     * Widget for editing Vector Data
     * @displayName Vector Widget
     */
    export default {
        name: "VectorWidget",
        components: {
            Button,
            Icon
        },
        props: {
            /**
             * A Json or Array for coordinates – must fit the corresponding type
             * @values (e.g. [0,0] for Vector 2, [[0,0,0,0], [0,0,0,0], [0,0,0,0]] for xForm3 (loc, rot, scale)
             */
            value: {type: Array || String, default: null},
            /**
             * The type of the vector-data
             * @values Vector2, Vector3, Vector4, xForm2, xForm3
             */
            type: {type: String, default: 'xform2'},
        },
        data() {
            return {
                xformLabels: ['position', 'rotation', 'scale'],
                values: []
            };
        },
        computed: {
            getConfig() {
                return VectorConfigs[this.type];
            },
            isXForm: function () {
                return this.type === MetaFieldTypes.xForm2 || this.type === MetaFieldTypes.xForm3;
            },
        },
        beforeMount() {
            if(this.value) {
                let arr = this.value;
                if (arr.length > 0 && !Array.isArray(this.value)) {
                    arr = JSON.parse(this.value)
                }
                this.values = arr;
            }
            else {
                //generate an empty array with the necessary fields
                let fieldsets = VectorConfigs[this.type].fieldsets;
                for (let i = 0; i < fieldsets.length; i++) {
                    let tmpArr = [];
                    for(let j = 0; j < fieldsets[i].length; j++) {
                        tmpArr.push(0);
                    }
                    this.values.push(tmpArr);
                }
            }
        },
        methods: {
            getValue(index) {
                if (this.value.length < index - 1) {
                    return 0;
                }
                return this.value[index] ? this.value[index] : 0;
            },
            save: function () {
                /**
                 * save event
                 * @param {array}
                 */
                this.$emit('save', this.values);
            },
        },
    }
</script>

<style lang="scss" scoped>
    .form-group {
        position: relative;
    }

    label {
        margin-bottom: 0;
    }

    .fieldset {
        background-color: $panel-title-background-color;
        padding:15px;
    }

    .label-left {
        float: left;
        margin: 0;
        padding-top: ceil($text-input-padding / 2);
        margin-right: 15px;
    }
    .vector-container {
      .form-group {
        width:32%;
        margin-right:0.5%;
        display:inline-block;
        margin-bottom:0;
        min-width:100px;
        input {
          width: 100%;
        }
      }
    }
</style>