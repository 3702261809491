/**
 * Used for reading the files returned by uploadDropzone when auto-upload = false
 * @param {string} content Base64String
 * */
export const decodeArrayBuffer = (content) => {
    return new TextDecoder("utf-8").decode(content);
}

export const combineAssetAndInstanceMetaValues = (assetMetaSets, instanceMetaValues) => {
    const arr = assetMetaSets;
      const targetArr = [];
      for(let i = 0; i < arr.length; i++) {
        //current MetaSet
        let rawMetaSet = JSON.parse(JSON.stringify(arr[i]));
        rawMetaSet.metaFields = [];
        targetArr.push(rawMetaSet);
        const metaFields = arr[i].metaFields;
        for(let j = 0; j < metaFields.length; j++) {
          let rawMetaField = JSON.parse(JSON.stringify(metaFields[j]));
          rawMetaField.metaValues = [];
          targetArr[i].metaFields.push(rawMetaField);
          const item = instanceMetaValues.filter(item => {return item.metaFieldId === metaFields[j].id});
          targetArr[i].metaFields[j].metaValues.push(item[0] ? item[0] : []);
          if(item.length) {
            assetMetaSets[i].metaFields[j].overwritten = true;
          }
        }
      }
     return targetArr;
}