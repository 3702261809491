<template>
  <div :class="['loading-panel text-center p-3', colorScheme]" v-if="!processing">
    <div class="text" v-html="$t(message)">
      <div v-if="timeInSeconds" class="small">{{ $t('thisCanTakeSeveralSeconds', {num: timeInSeconds}) }}</div>
    </div>
    <div v-if="showProgressBar" class="mt-2 dz-progress"><div :style="{'width': progress + '%'}" class="dz-upload"/></div>
    <loading-spinner v-else />
  </div>
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner";
export default {
name: "LoadingPanel",
  components: {
    LoadingSpinner
  },
  props: {
    // if showProgressBar is true, the progress must be updated by parent component
    showProgressBar: {type: Boolean, default: false},
    message: {type: String, default: ''},
    timeInSeconds: {type: Number, default: 10},
    /**
     * @colorScheme String can be "bright" or "dark"
     * */
    colorScheme: {type: String, default: 'bright'},
    progress: {type: Number, default: null}
  },
  data() {
    return {
      processing: false,
    }
  }
}
</script>

<style lang="scss" scoped>
.loading-panel {
  &.bright {
    background-color: $tab-item-background;
  }
  &.dark {
    background-color: $tab-item-background-active;
  }
  &.black {
    background-color: #171615;
  }
}
.half-circle-spinner {
  margin:auto;
  margin-top:15px;
}
</style>