<template>
  <div id="app">
    <page>
        <router-view />
    </page>
  </div>
</template>

<script>
import Page from './components/Page.vue'

export default {
  name: 'App',
  components: {
    Page
  }
}
</script>
<style>
    #app {
        height:100%;
        width:100vw;
        min-height:100vh;
        overflow-x: hidden;
    }
</style>

