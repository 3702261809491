<template>
    <div class="filterSection">
        <label>{{ $t('filter.orgFilter') }}</label><br>
        <tag name-property="displayName" :is-removable="!limitToOrganizationIds" icon-left="home" @removeTag="removeOrg" v-for="(value, index) in orgs" :key="index" :tag="value" />
        <universal-selector
                :special-getter-args="visibilitySelection"
                v-if="!limitToOrganizationIds"
                @addItem="addOrg"
                :exclude-from-item-list="orgs"
                list-name="contentFilter"
                store-name="Organization"
                filter-attribute="displayName"
                special-getter="getOrganizationsByVisibility"
                tag-icon="home"
        />
    </div>
</template>

<script>
    import Tag from "../../Tag";
    import UniversalSelector from "../../organizations/UniversalSelector";
    import {mapState} from "vuex";

    export default {
        name: "OrgFilter",
        components: {
            Tag,
          UniversalSelector,
        },
        props: {
            /**
             * An id in order to have a unique identifier when saving filter contents to storage
             * */
            id: {type: String, required: true},
            /**
             * If you want to filter for an organization specifically
             * */
            limitToOrganizationIds: {type: Array, default: null},
            /**
             * If value changes, the textFilter clears its value
             * */
            clear: {type: Number, default: 0},
            /**
             * Whether or not to store filter cookie globally or just for the current route
             * */
            type: {type: String, default: 'global'},
            /**
             * an array containing selected visibilities, e.g. [0] for all private organizations
             * */
            visibilitySelection: {type: Array, default: () => {return [0, 1, 2]}},
        },
        data() {
            return {
                orgs: [],
                hiddenOrgs: []
            };
        },
        computed: {
            ...mapState({currentUserOrg: state => state.users.currentUserOrg}),
            getId: function() {
                if(this.type === 'local') {
                    return this.$route.name + this.id + 'OrgFilter';
                }
                else {
                    return this.id  + 'OrgFilter';
                }
            },
        },
        watch: {
            clear() {
                this.clearValue();
            },
            /*currentUserOrg: function() {
                if(!this.limitToOrganizationIds) {
                    let org = this.$store.getters.getOrganization(this.$store.getters.getCurrentUserOrg);
                    if(org) {
                        this.orgs = [];
                        this.orgs.push(org);
                        this.filter();
                    }
                }
            },*/
        },
        beforeMount() {
            let orgs = JSON.parse(window.localStorage.getItem(this.getId));
            if(orgs && orgs.length) {
                this.orgs = orgs;
            }
        },
        mounted() {
           if (this.limitToOrganizationIds) {
                let res = this.$store.getters.getOrganization(this.limitToOrganizationIds, 'globalOrganizations');
                this.orgs = [];
                this.orgs = res;
                this.filter(true);
            }
            else if(!this.limitToOrganizationIds && !this.$store.getters.isSuperAdmin) {
              // this.limitToOrganizationIds = this.$store.getters.getUserOrganizations(this.$store.getters.getCurrentUserId).filter();
              let orgList = this.$store.getters.getUserOrganizations()
              if(orgList && orgList.length) {
                orgList = orgList.filter(item => {return !this.$store.getters.isSuperOrg(item.id)});
                this.hiddenOrgs = orgList;
              }
              this.filter(true);
            }
            else {
                this.filter(true);
            }
            console.log(this.orgs);
        },
        methods: {
            clearValue: function() {
                this.orgs = [];
                this.filter(true);
            },
            removeOrg:  function(org){
                this.orgs = this.orgs.filter(item => {return item.id !== org.id});
                this.filter();
            },
            addOrg:  function(org) {
                this.orgs.push(org);
                this.filter();
            },
            addAllOrgs: function() {
                if(!JSON.parse(window.localStorage.getItem(this.getId))) {
                    let orgs = this.$store.getters.getVisibleUserOrganizations();
                    if(orgs) {
                        this.orgs = orgs;
                    }
                }
            },
            filter: function (initial = false, useHiddenOrgs = false) {
                var filterParamId = 'orgsFilter';
                let orgsVar = 'orgs';
                if(useHiddenOrgs || (this.orgs.length === 0 && this.hiddenOrgs.length)) {
                  orgsVar = 'hiddenOrgs';
                }
                var params = {};
                if (this[orgsVar].length > 0) {
                    let filterString = 'organizationId in ';
                    if(this[orgsVar].length === 1) {
                        filterString = 'organizationId eq '  + this[orgsVar][0].id;
                    }
                    else {
                        for (let i = 0; i < this[orgsVar].length; i++) {
                            filterString += "'" + this[orgsVar][i].id + "'";
                            if(i !== this[orgsVar].length - 1) {
                                filterString += ' ';
                            }
                        }
                    }
                    params = {
                        add: {
                            filter: {
                                [filterParamId]: filterString
                            },
                            default: {
                                limit: 300,
                            }
                        }
                    };
                } else {
                    params = {
                        remove: {
                            filter: {filterParamId}
                        },
                        add: {
                            default: {
                                limit: 300,
                            }
                        }
                    };
                }
                this.$emit('filter', params, initial, 'org');
                window.localStorage.setItem(this.getId,  JSON.stringify(this.orgs));
            },
        },
    }
</script>