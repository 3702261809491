<template>
    <one-column-sidebar-left
        back-button-tab-selector="metasetbackbutton"
        tab-selector="metasettab"
        sub-menu-selector="organizingpanel"
    >
        <div slot="buttonsRight">
            <Button
                    type="button"
                    class="mb-3 mt-5"
                    :deactivated="!enableSaving"
                    @click="save"
            >
                <icon v-if="enableSaving" type="save"/><icon v-else type="ban" /> {{ $t('SAVE') }}
            </Button>
        </div>

        <div slot="mainContent">
            <h1><icon class="mr-2" type="list-ul" size="0.9" />{{ $t('createNewMetaSet') }}</h1> {{ id }}
            <organization-switcher
              @setOrganization="id => {organizationId = id}"
                @setTeam="id => {teamId = id}"
                @unsetTeam="teamId = ''"
            />
            <input
                    type="text"
                    :class="['form-text mb-3 mt-3', $v.name.$dirty && $v.name.$error ? 'form-group--error' : '']"
                    :id="'name'"
                    v-model="name"
                    :placeholder="$t('enternamehere')"
                    @input="delayTouch($v.name)"
            >

            <div class="form-error" v-if="$v.name.$dirty && !$v.name.required && $v.name.$error">{{ $t('errors.required') }}</div>
            <div class="form-error" v-if="$v.name.$dirty && !$v.name.alphaNumDot">{{ $t('errors.alphaNumDot') }}</div>
            <div class="form-error" v-if="!$store.getters.isSuperAdmin && $v.name.$dirty && !$v.name.metaSetName">{{ $t('errors.metaSetName') }}</div>

            <div class="form-error" v-if="$v.name.$dirty && !$v.name.maxLength">{{ $t('errors.atMostCharacters', {num: 128}) }}</div>

            <div class="form-error" v-if="$v.name.$dirty && !$v.name.minLength">{{ $t('errors.atLeastCharacters', {num: 3}) }}</div>
            <div class="form-error" v-if="$v.name.$dirty && !$v.name.isUnique">{{ $t('errors.metaSetNameAlreadyExists') }}</div>

            <div class="inline-edit-label">
                <b>{{ $t('description') }}</b>
            </div>
            <div>
                <textarea
                        :class="['form-text mb-3', $v.description.$dirty && $v.description.$error ? 'form-group--error' : '']"
                        :id="'description'"
                        v-model="description"
                        :placeholder="$t('enterdeschere')"
                        @input="delayTouch($v.description)"
                />
                <div class="form-error" v-if="$v.description.$dirty && !$v.description.maxLength">{{ $t('errors.atMostCharacters', {num: 128}) }}</div>
            </div>
            <p class="darker p-3">If you are creating a metaSet for filtering/merging, the metaSet name must begin with "asset.model"</p>
        </div>
    </one-column-sidebar-left>
</template>

<script>
    import Button from "../components/forms/Button";
    import { mapState } from 'vuex';
    import OneColumnSidebarLeft from "../layouts/OneColumnSidebarLeft";
    import Icon from "../components/Icon";
    import { required, minLength, maxLength } from 'vuelidate/lib/validators';
    import {alphaNumDot, metaSetName} from '@/customValidators';
    import OrganizationSwitcher from "../components/organizations/OrganizationSwitcher";
    import VuelidateMixin from "@/components/mixins/VuelidateMixin.js";
    export default {
        name: "ProjectAdd",
        components: {
            OrganizationSwitcher,
            Button,
            OneColumnSidebarLeft,
            Icon,
        },
        mixins: [VuelidateMixin],
        data() {
            return {
                id: '',
                enableSaving: false,
                name: '',
                description: '',
            };
        },
        computed: mapState({metaSetTmp: state => state.metaset.metaSetTmp,}),
        watch: {
            '$v.$invalid': function() {
                this.enableSaving = !this.$v.$invalid;
                this.$emit('enableSaving', !this.$v.$invalid);
            },
            metaSetTmp(newValue) {
                if(newValue['last_inserted']) {
                    let id = newValue['last_inserted'];
                    this.$router.push('/organizing/metaSets/'+id+'/general');
                }
            },
        },
        validations() {
          if (this.$store.getters.isSuperAdmin) {
            console.log('is super')
              return {
                name: {
                  required,
                  minLength: minLength(3),
                  maxLength: maxLength(127),
                  alphaNumDot,
                  async isUnique(value) {
                      // standalone validator ideally should not assume a field is required
                      if (value === '') {
                          return true;
                      }
                      let bool = true;
                      await this.$store.dispatch('checkIfMetaSetNameExists', {name: value, type: 'metaSet', value: value, organizationId: this.organizationId,}).then(data => {
                          bool = data.length === 0;
                      });
                      return bool;
                  },
              },
              description: {
                  maxLength: maxLength(1024),
              }
            }
          } else {
            console.log('isnotsuper')
            return {
                name: {
                  required,
                  minLength: minLength(3),
                  maxLength: maxLength(127),
                  alphaNumDot,
                  metaSetName,
                  async isUnique(value) {
                      // standalone validator ideally should not assume a field is required
                      if (value === '') {
                          return true;
                      }
                      let bool = true;
                      await this.$store.dispatch('checkIfMetaSetNameExists', {name: value, type: 'metaSet', value: value, organizationId: this.organizationId,}).then(data => {
                          bool = data.length === 0;
                      });
                      return bool;
                  },
              },
              description: {
                  maxLength: maxLength(1024),
              }
            }
          }
        },

        methods: {
            save: async function() {
                if(this.enableSaving) {
                    let args = {
                        name: this.name,
                        description: this.description,
                        organizationId: this.organizationId,
                        teams: this.teamId ? [this.teamId] : []
                    };
                    await this.$store.dispatch('createMetaSet', args);
                }
            },
        },
    }
</script>