var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.editing)?[_vm._l((_vm.rows),function(item,index){return _c('div',{key:index,staticClass:"mat-color-row"},[_c('div',{staticClass:"mat-color"},[_c('div',{class:[
                        _vm.colorPickrId === index ? 'selected' : '',
                        'color-preview',
                        'editable',
                        _vm.colorPickrId === index ? 'hover' : '' ],style:({
                        margin: 'auto',
                        position: 'relative !important',
                        left: '-10px',
                        backgroundColor: item.color ? item.color : '#fff',
                    }),on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return (function () {
                            _vm.colorPickrId = index;
                        })($event)}}},[(_vm.colorPickrId === index)?_c('color-picker',{ref:"colorpickr",refInFor:true,attrs:{"slot":"popupMainContent","value":item.color,"popup-style":true},on:{"save":function (color) {
                                _vm.colorPickrId = null;
                                return _vm.updateColor(color, index);
                            },"close":function () {
                                _vm.colorPickrId = null;
                            }},slot:"popupMainContent"}):_vm._e()],1)]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(item.material),expression:"item.material"}],staticClass:"custom-select material-select",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(item, "material", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.materials),function(matItem,matIndex){return _c('option',{key:matIndex,staticClass:"form-text mb-2",domProps:{"value":matItem.id}},[_vm._v(" "+_vm._s(matItem.displayName)+" ")])}),0),_c('Button',{staticClass:"ml-3",attrs:{"slot":"buttonsRight"},on:{"click":function($event){return _vm.deleteRow(index)}},slot:"buttonsRight"},[_c('icon',{attrs:{"type":""}}),_vm._v(_vm._s(_vm.$t("Delete"))+" ")],1)],1)}),_c('div',{staticClass:"w-100"}),_c('Button',{staticClass:"mr-2",on:{"click":_vm.addRow}},[_vm._v(_vm._s(_vm.$t("AddEntry")))]),_c('Button',{on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t("save")))]),_c('div',[_vm._v(_vm._s(_vm.error))])]:_vm._l((_vm.rows),function(row,index){return _c('div',{key:index},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticClass:"w-50"},[_vm._v(" "+_vm._s(_vm.materials.find(function (x) { return x.id === row.material; }) ? _vm.materials.find(function (x) { return x.id === row.material; }).displayName : 'N / A')+" ")]),_c('div',{staticClass:"color-square",style:({ backgroundColor: row.color })}),_c('div',{staticClass:"pl-2"},[_vm._v(_vm._s(row.color ? row.color : 'N / A'))])])])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }