<template>
  <one-column-sidebar-left
      tab-selector="materialtab"
      type="material"
      back-button-tab-selector="materialbackbutton"
      :id="$route.params.id"
      sub-menu-selector="librarypanel"
  >
    <info-panel slot="sidebarLeft"
                :id="$route.params.id"
                :image-editable="true"
                upload-type="Asset"
                :created="$store.getters.getMaterialCreationDate($route.params.id)"
                :last-change="$store.getters.getMaterialChangeDate($route.params.id)"
                :downloadable="true"
                :organization="$store.getters.getMaterialOrganization($route.params.id)"
                preview-id="materialEdit"
                :removable="true"
    />

        <div slot="mainContent">
            <content-title-block
                    v-if="$store.getters.getMaterialName($route.params.id)"
                    :value="$store.getters.getMaterialName($route.params.id)"
                    :id="$route.params.id"
                    store-name="Material"
                    :organization-id="$store.getters.getMaterialOrganization($route.params.id)"
            />
            <content-description-block
                    :value="$store.getters.getMaterialDescription($route.params.id)"
                    :id="$route.params.id"
                    store-name="Material"
            />
          <div class="row">
            <div class="col-12 col-md-6 no-gutters">
              <info-panel
                  preview-id="metasetattacher"
                  :collapsible="true"
                  :initially-collapsed="true"
                  info-panel-header="sfx edit"
                  v-if="$store.getters.isSuperAdmin"
              >
                <meta-set-attacher
                    slot="info"
                    :target-id="$route.params.id"
                    store-name="Asset"
                    @added="loadMaterial"
                    @removed="loadMaterial"
                    :given-meta-sets="$store.getters.getMaterialMetaSets(id)"
                />
              </info-panel>
            </div>
            <meta-panel
                class="col-12 col-md-8"
                type="material"
                :key="item.id" v-for="(item) in $store.getters.getMaterialMetaSets(id)"
                :title="item.description" :fields="item.metaFields" icon-left="folder"
                store-name="Material"
                :organization-id="$store.getters.getMaterialOrganization(id)"
                @updated="loadMaterial"
            />
          </div>


</div>
    </one-column-sidebar-left>
</template>

<script>
    import InfoPanel from "../components/InfoPanel";
    import OneColumnSidebarLeft from "../layouts/OneColumnSidebarLeft";
    import ContentTitleBlock from "../components/ContentTitleBlock";
    import ContentDescriptionBlock from "../components/ContentDescriptionBlock";
    import MetaSetAttacher from "@/components/widgets/meta/MetaSetAttacher";
    import MetaPanel from "@/components/widgets/meta/MetaPanel";

    export default {
        name: 'MaterialEdit',
        components: {
            InfoPanel,
            OneColumnSidebarLeft,
            ContentTitleBlock,
            ContentDescriptionBlock,
            MetaSetAttacher,
            MetaPanel
        },
        data() {
            return {
                id: '',
            };
        },
        beforeMount() {
          this.id = this.$route.params.id;
          this.loadMaterial();
        },
        methods: {
        loadMaterial() {
              this.$store.dispatch('loadMaterial', {
                id: this.$route.params.id,
                include: 'tags,metaSets'
              }).catch(e => {
                console.log('err');
              if(e.status === 401) {
                this.$router.push('/access-denied');
              }
            });
        },
  },
}
</script>