<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-md-10 col-lg-12 mt-5 offset-md-2 offset-lg-3">
                <div v-if="localRoutes && finished" id="library-tabs" class="float-right float-lg-none d-flex">
                    <navigation-panel
                            v-for="(item, key) in localRoutes"
                            v-if="item.access"
                            :title="item.name"
                            :link="item.path"
                            :routename="item.name"
                            :pid="id"
                            :translate="true"
                            :disabled="disabled"
                            :active-class="firstActive && item.firstItem ? 'active' : ''"
                            :icon="item.meta.icon"
                            :key="key"
                            :props="item.params"
                    />
                </div>
            </div>
            <div class="col-12 sub-menu-panel-background"/>
        </div>
    </div>
</template>

<script>
    import NavigationPanel from "./NavigationPanel";
    export default {
        name: 'NavigationPanelsContainer',
        components: {
            NavigationPanel,
        },
        props: {
            id: {type: String, required: true},
            disabled: {type: Boolean, default: false},
            arrowStyle: {type: Boolean, default: false},
            firstActive: {type: Boolean, default: false},
            tabSelector: {type: String, required: true}
        },
        data() {
          return {
            localRoutes: [],
            finished: false,
          };
        },
        beforeMount() {
          if(!this.localRoutes.length) {
            this.setRoutes();
          }
        },
        methods: {
           async setRoutes() {
              this.finished = false;
              const routes = this.$router.options.routes;
              this.localRoutes = routes.filter(item => {
                  return item[this.tabSelector];
              });
              for(let i = 0; i < this.localRoutes.length; i++) {
                this.localRoutes[i].access = true;
                if(this.localRoutes[i].meta.accessPermission && this.localRoutes[i].meta.staticPermission) {
                  const hasAccess = await this.$store.dispatch('checkTeamOrgPermission', {
                    op: this.localRoutes[i].meta.accessPermission,
                    opOnly: true,
                    orgPermissionOnly: this.localRoutes[i].meta.orgPermissionOnly ? this.localRoutes[i].meta.orgPermissionOnly : false,
                    allowSFXUsers: this.localRoutes[i].meta.allowSFXUsers,
                  });
                  this.localRoutes[i].access = hasAccess;
                }
              }
              this.finished = true;
            },
        }
    }
</script>