import Vue from 'vue'
import {_} from "vue-underscore";
import i18n from "@/translation";
//import i18n from "../translation";

const state = {
    instanceLists: {
        instanceList: {},
    },
    instanceInstancesLists: {
        instanceInstances: {},
    },
    instanceListOptions: {},
    defaultQueryParams: {
        limit: {
            default: '50',
        },
        filter: {
            default: 'type eq instance',
        },
        sort: {}
    },
    queryParams: {
        instanceList: {
            limit: {
                default: '50',
            },
            filter: {
                default: 'type eq instance',
            },
            sort: {
                default: '-updatedAt',
            },
        }
    },
    combinedQueryParams: {},
    instanceTmp: {},
};

const getters = {
    getInstances() {
        return state.instanceLists.instanceList;
    },
    getInstanceByListName: (state) => (listName) => {
        return state.instanceLists[listName] ? state.instanceLists[listName] : {};
    },
    getInstancesByListName: (state) => (listName) => {
        return state.instanceLists[listName] ? state.instanceLists[listName] : {};
    },
    getInstanceById: (state) => (id, listName = 'instanceList') => {
        return state.instanceLists[listName][id];
    },
    getInstanceOrganization: (state) => (id, listName = 'instanceList') => {
        let listContainer = 'instanceLists';
        return state[listContainer][listName][id] && state[listContainer][listName][id].organizationId ? state[listContainer][listName][id].organizationId : '';
    },
    getInstanceCreationDate: (state, rootGetters) => (id, listName = 'instanceList') => {
        return state.instanceLists[listName][id] ? rootGetters['convertDate'](state.instanceLists[listName][id].createdAt) : '';
    },
    getInstanceChangeDate: (state, rootGetters) => (id, listName = 'instanceList') => {
        return state.instanceLists[listName][id] ? rootGetters['convertDate'](state.instanceLists[listName][id].updatedAt) : '';
    },
    getInstancePreviewUri: (state) => (id, listName = 'instanceList') => {
        return state.instanceLists[listName][id] && state.instanceLists[listName][id].previewUri ? state.instanceLists[listName][id].previewUri : '';
    },
    getInstanceTitle: (state) => (id, listName = 'instanceList') => {
        if (state.instanceLists[listName][id]) {
            return state.instanceLists[listName][id].name;
        } else {
            return '';
        }
    },
    getInstanceName: (state) => (id, listName = 'instanceList') => {
        if (state.instanceLists[listName][id]) {
            return state.instanceLists[listName][id].name;
        } else {
            return '';
        }
    },
    getInstanceState: (state) => (id, listName = 'instanceList') => {
        if (state.instanceLists[listName][id]) {
            return state.instanceLists[listName][id].state;
        } else {
            return '';
        }
    },
    getInstanceType: (state) => (id, listName = 'instanceList') => {
        if (state.instanceLists[listName][id]) {
            return state.instanceLists[listName][id].type;
        } else {
            return '';
        }
    },
    getInstanceDescription: (state) => (id, listName = 'instanceList') => {
        if (state.instanceLists[listName][id]) {
            return state.instanceLists[listName][id].description;
        } else {
            return '';
        }
    },
    getInstanceTags: (state) => (id, listName = 'instanceList') => {
        return state.instanceLists[listName][id] && state.instanceLists[listName][id].tags ? state.instanceLists[listName][id].tags : [];
    },

    getInstanceMetaSets: (state) => (id, listName = 'instanceList') => {
        return state.instanceLists[listName][id] && state.instanceLists[listName][id].metaSets ? state.instanceLists[listName][id].metaSets : {};
    },
    getInstanceOriginalQueryParams: function() {
        return state.queryParams;
    },
    getInstanceQueryParams: (state) => (listName) => {
        return state.combinedQueryParams[listName];
    },
    getInstanceListOptions: (state) => (listName, option = '') => {
        let optionList = 'instanceListOptions';
        if (option === 'preview') {
            return state[optionList][listName] && state[optionList][listName].preview ? Object.keys(state[optionList][listName].preview)[0] : '';
        } else if (option === 'fbxPreview') {
            return state[optionList][listName] && state[optionList][listName].fbxPreview ? Object.keys(state[optionList][listName].fbxPreview)[0] : '';
        }
        else if(option === 'pagination_items') {
            return state[optionList][listName] && state[optionList][listName].pagination_items ? parseInt(Object.keys(state[optionList][listName].pagination_items)[0]) : 0;
        }
        return state[optionList][listName] ? state[optionList][listName] : {};
    },
};

const mutations = {
    removeInstanceMutation (state, data) {
        let listName = data.listName ? data.listName : 'instanceList';
        Vue.delete(state.instanceLists[listName], data.id);
    },
    loadInstancesMutation (state, data) {
        let listName = 'instanceList';
        if(data.listName) {
            listName = data.listName;
            delete data.listName;
        }
        if(data.instanceArray) {
            let instanceArray = data.instanceArray;
            Vue.set(state.instanceLists, listName, {});
            for (var i = 0; i < instanceArray.length; i++) {
                if(instanceArray[i]) {
                    let asset = instanceArray[i].asset;
                    delete instanceArray[i].asset;
                    let instance = instanceArray[i];
                    let mixed = _.extendNonNull(asset, instance);
                    Vue.set(state.instanceLists[listName], instance.id, mixed);
                }
            }
        }
        else {
            if(!state.instanceLists[listName]) {
                Vue.set(state.instanceLists, listName, {});
            }
            if(data.asset) {
                 let asset = data.asset;
                 delete data.asset;
                 let instance = data;
                 data = _.extendNonNull(asset, instance);
            }
            
            Vue.set(state.instanceLists[listName], data.id, data);
        }
    },
};

const actions = {
    registerInstancesQueryParams: ({commit}, args) => {
        let listName = 'instanceList';
        if(args.listName) {
            listName = args.listName;
        }
        if(Object.keys(args).length > 0) {
            commit('queryParamsSave', {storeType: 'instances', listName: listName, params: args});
        }
    },
    loadInstances: async ({commit, dispatch, getters}, args = {}) => {
        let listName = args.listName ? args.listName :'instanceList';

        if(Object.keys(args).length > 0) {
            commit('queryParamsSave', {storeType: 'instances', listName: listName, params: args});
        }
        commit('combineQueryParams', {storeType: 'instances', listName: listName});
        dispatch('clientLoadProjectInstances', getters.getInstanceQueryParams(listName)).then(data => {
            let args = {
                listName: listName,
                instanceArray: data,
            };
            commit('loadInstancesMutation', args);
        });
    },
    removeInstance: ({commit}, args) => {
        commit('removeInstanceMutation', args);
    },
    loadInstance: async ({commit, dispatch}, args = {}) => {
        let listName = args.listName ? args.listName : 'instanceList';

        dispatch('clientLoadProjectInstance', args).then(data => {
            data.listName = listName;
            commit('loadInstancesMutation', data);
        });
    },
    loadInstanceById: async ({commit, dispatch}, args = {}) => {
        let listName = args.listName ? args.listName : 'instanceList';
        const {id} = args;
        dispatch('clientGetCrossProjectInstances', {filter: 'id eq ' + id}).then(data => {
            console.log(data)
            data.listName = listName;
            commit('loadInstancesMutation', data[0]);
        });
    },
    loadConnectionsWithInstances: async ({dispatch,commit}, args = {}) => {
        let listName = args.listName ? args.listName : 'instanceList';
        return dispatch('getProjectInstanceConnections', args).then(connections => {
            let instances = [];
            for (let i = 0; i < connections.length; i++) {
                let connection = connections[i];
                instances.push(connection.target);
            }
            let args = {
                listName: listName,
                instanceArray: instances,
            };
            commit('loadInstancesMutation', args);
        });
    },
    setInstanceMetaValues: async ({dispatch}, args) => {
        //commit('updateProjectMutation', args);
        return dispatch('clientSaveInstanceMetaSetValues', args).then(() => {
            dispatch('createNotification', {'text': i18n.t('events.instanceDataMetaValuesUpdated')});
        });
    },
};
export default {
    state, getters, mutations, actions
}
