<template>
    <full-width-sidebar-left
        tab-selector="datasourcetab"
        type="datasource"
        back-button-tab-selector="datasourcebackbutton"
        :id="$route.params.id"
        sub-menu-selector="librarypanel"
    >
        <info-panel
                slot="sidebarLeft"
                :created="$store.getters.getDatasourceCreationDate(this.$route.params.id)"
                :last-change="$store.getters.getDatasourceChangeDate(this.$route.params.id)"
                preview-id="datasourceEdit"
                :organization="$store.getters.getDatasourceOrganization($route.params.id)"
        />
        <team-attacher
            v-if="$store.getters.getDatasourceOrganization($route.params.id)"
              slot="sidebarLeft"
              class="mt-3"
              :id="$route.params.id"
              :teams="teams"
              store-name="Asset"
              :limit-by-organization-id="$store.getters.getDatasourceOrganization($route.params.id)"
              @added="loadAssetTeams"
              @removed="loadAssetTeams"
          />
        <info-panel
                slot="sidebarLeft"
                :id="$route.params.id"
                :tags="$store.getters.getDatasourceTags($route.params.id)"
                :taggable="true"
                preview-id="datasourceEdit"

                @removeTag="removeTag"
                @addTag="addTag"
                :key="'infopanel2'"
                :limit-tags-to-organization="$store.getters.getDatasourceOrganization($route.params.id)"
        />
        <template slot="mainContent">
            <content-title-block
                    v-if="$store.getters.getDatasourceName($route.params.id)"
                    :value="$store.getters.getDatasourceName($route.params.id)"
                    :id="$route.params.id"
                    store-name="Datasource"
                    :organization-id="$store.getters.getDatasourceOrganization($route.params.id)"
                    @updated="loadDataSource"
            />
            <content-description-block
                    :value="$store.getters.getDatasourceDescription(id)"
                    :id="$route.params.id"
                    store-name="Datasource"
                    @updated="loadDataSource"
            />
            <div class="row">
              <div v-if="$store.getters.getDatasourceType($route.params.id) === 'feed'" class="col-12">
                <Button @click="stopFeed"><loading-spinner class="float-left mr-1" v-if="stopping" />{{ $t('stopFeed') }} <icon type="stop-circle" /></Button>
                <Button class="ml-3" @click="startFeed"><loading-spinner class="float-left mr-1"  v-if="starting" />{{ $t('startFeed') }}<icon type="play-circle" /></Button>
                 <Button class="ml-3" @click="restartFeed"><loading-spinner class="float-left mr-1" v-if="restarting" />{{ $t('restartFeed') }}<icon type="play-circle" /></Button>
                <Button class="ml-3" @click="getFeedStatus">{{ $t('getFeedStatus') }}<icon type="redo" /></Button>
                <div class="bg-beige p-2 mt-3 col-12 col-md-4" v-if="feedStatus !== ''">Status: {{ feedStatus ? 'running' : 'stopped' }}</div>
              </div>
              <meta-panel
                    v-if="$store.getters.getDatasourceMetaSets($route.params.id)"
                    class="col-12 col-md-6 col-xl-11 col-xxl-8 mt-3"
                    store-name="Datasource"
                    :key="item.id"
                    v-for="(item) in $store.getters.getDatasourceMetaSets($route.params.id)"
                    :title="item.description"
                    :fields="item.metaFields"
                    icon-left="folder"
                    :organization-id="$store.getters.getDatasourceOrganization($route.params.id)"
                     @updated="loadDataSource"
                     :teams="teams"
            />
            </div>
        </template>
    </full-width-sidebar-left>
</template>

<script>
    import FullWidthSidebarLeft from "../layouts/FullWidthSidebarLeft";
    import MetaPanel from "../components/widgets/meta/MetaPanel";
    import ContentTitleBlock from "../components/ContentTitleBlock";
    import ContentDescriptionBlock from "../components/ContentDescriptionBlock";
    import InfoPanel from "../components/InfoPanel";
    import TeamAttacher from "@/components/organizations/TeamAttacher";
    import Button from "@/components/forms/Button";
    import Icon from "@/components/Icon";
    import LoadingSpinner from "@/components/LoadingSpinner";

    export default {
        name: 'DataSourceEdit',
        components: {
            MetaPanel,
            FullWidthSidebarLeft,
            ContentTitleBlock,
            ContentDescriptionBlock,
            InfoPanel,
            TeamAttacher,
            Button,
            Icon,
            LoadingSpinner
        },
        data() {
            return {
                listName: 'DatasetList',
                id: '',
                teams: [],
                feedStatus: '',
                stopping: false,
                starting: false,
                restarting: false
            };
        },
        beforeMount() {
            this.loadDataSource();
            this.loadAssetTeams();
            this.$store.dispatch('loadFeedsClient')
              .then(() => {
                this.getFeedStatus();
              });
            this.id = this.$route.params.id;
        },
        methods: {
          loadDataSource() {
            this.$store.dispatch('loadDatasource', {
                id: this.$route.params.id,
                include: 'metaSets,tags'
            }).catch(e => {
              if(e.status === 401) {
                this.$router.push('/access-denied');
              }
            });
          },
          loadAssetTeams() {
            this.$store.dispatch('clientLoadAssetTeams', {
              id: this.$route.params.id
            }).then(data => {
              this.teams = data;
            });
          },
            removeTag: function(tag) {
                this.$store.dispatch('removeDatasourceTag', {
                    id: this.$route.params.id,
                    args: [tag.id],
                });
            },
            addTag: function(tag) {
                this.$store.dispatch('addDatasourceTag', {
                    id: this.$route.params.id,
                    args: [tag.id],
                });
            },
            stopFeed: function() {
              this.stopping = true;
              this.$store.dispatch('clientStopFeed', {id: this.$route.params.id})
              .then(() => {
                const $this = this;
                setTimeout( () => {
                   $this.stopping = false;
                   $this.getFeedStatus();
                }, 3000)
              })
              // eslint-disable-next-line no-unused-vars
              .catch(e => {
                //console.log(e);
              })
            },
            startFeed: function() {
              this.starting = true;
              this.$store.dispatch('clientStartFeed', {id: this.$route.params.id})
              .then(() => {
                const $this = this;
                setTimeout( () => {
                   $this.starting = false;
                   $this.getFeedStatus();
                }, 6000)

              })
              // eslint-disable-next-line no-unused-vars
              .catch(e => {
                //console.log(e);
              })
            },
            restartFeed: function() {
              this.restarting = true;
              const $this = this;
              this.$store.dispatch('clientStopFeed', {id: this.$route.params.id})
              .then(() => {
                // leave the feed time enough to stop
                setTimeout( () => {
                  $this.$store.dispatch('clientStartFeed', {id: this.$route.params.id})
                }, 4000)
              })
              .then(() => {
                setTimeout( () => {
                   $this.restarting = false;
                   $this.getFeedStatus();
                }, 6000)

              })
              // eslint-disable-next-line no-unused-vars
              .catch(e => {
                //console.log(e);
              })
            },
            getFeedStatus: function() {
              this.$store.dispatch('clientLoadFeedStatus', {id: this.$route.params.id})
              .then(data => {
                this.feedStatus = data.running;
              })
              // eslint-disable-next-line no-unused-vars
              .catch(e => {
                //console.log(e);
              })
            }
        },
    }
</script>
