<template>
    <div class="tag-input">
        <div :class="['form-item', showTagListPermanently ? 'with-append-icon' : 'with-prepend-icon'] ">
            <text-input
                    :class="[$v.value.$error && showErrors ? 'form-group--error' : '']"
                    ref="textinput"
                    :id="'tagsinput'"
                    :placeholder="$t('typeToSelect')"
                    @typingAlert="filterAndPropose"
                    @blur="getValue"
            />
            <div v-if="showErrors">
                <div class="form-error" v-if="!$v.value.minLength && $v.value.$error ">{{ $t('errors.atLeastCharacters', {num: 3}) }}</div>
                <div class="form-error" v-if="!$v.value.alphaNumSpace && $v.value.$error ">{{ $t('errors.alphaNumSpaceOnly') }}</div>
                <div class="form-error" v-if="!$v.value.isUnique && $v.value.$error ">{{ $t('errors.objectNameAlreadyExists') }}</div>
            </div>

            <div v-if="creatingTagsAllowed" @click="createNewTag" class="input-append-icon interactive">
                <icon type="plus" />
            </div>
            <div v-if="!showTagListPermanently" @click="toggleTagList" class="input-prepend-icon interactive">
                <icon v-if="!showTagList" type="angle-down"/>
                <icon v-else type="angle-up"/>
            </div>
        </div>
        <div class="form-dropdown-box" v-if="(showTagList || showTagListPermanently) && $store.getters.getTags && !tableMode">
            <div v-if="message" class="form-small-message">{{ message }}</div>
            <div @click="() => {$emit('addTag', tag); showTagList = false }" :key="id" v-for="(tag, id) in $store.getters.getTags">
                <tag
                        v-if="!excludeFromTagList || excludeFromTagList.filter(item => {return item.id === tag.id}).length === 0"
                        class="mb-1"
                        :tag="tag"
                        :is-removable="tagsAreDeletable"
                        @removeTag="deleteTag"
                        @setColor="setColor"
                />
            </div>
        </div>
        <table-widget v-if="tableMode && $store.getters.getTags"
                      class="tag-input-table"
                      :fields="fields"
                      :data="$store.getters.getTags"
                      :id="'taginputTable'"
                      @updateColor="setColor"
                      :deletable="true"
                      @deleteRow="deleteTag"
                      edit-permission="userIsOrganizationMember"
                      delete-permission="userIsOrganizationMember"
        />
    </div>
</template>

<script>
   import Icon from "../Icon";
    import Tag from "../Tag";
   import TextInput from "./TextInput";
    import TableWidget from "../widgets/TableWidget";
    import { minLength } from 'vuelidate/lib/validators';
    import {alphaNumSpace} from '../../customValidators';

    export default {
        name: "TagInput",
        components: {
            Icon,
            Tag,
            TextInput,
            TableWidget
        },
        validations: {
            value: {
                minLength: minLength(3),
                alphaNumSpace,
                async isUnique(value) {
                    // standalone validator ideally should not assume a field is required
                    if (value === '') {
                        return true;
                    }
                    let bool = true;
                    await this.$store.dispatch('checkIfTagNameExists', {value: value}).then(data => {
                        bool = data.length === 0;
                    });
                    return bool;
                },
            },
        },
        props: {
            excludeFromTagList: {type: Array, default: null},
            creatingTagsAllowed: {type: Boolean, default:true},
            showTagListPermanently: {type: Boolean, default:false},
            tagsAreDeletable: {type: Boolean, default:false},
            canSetColor: {type: Boolean, default: false},
            tableMode: {type: Boolean, default: false},
            limitTagsToOrganization: {type: String, default: null},
            excludeVHUBOrganization: {type: Boolean, default: null}
        },
        data() {
            return {
                showTagList: false,
                showErrors: false,
                message: '',
                value: '',
                showDeletePrompt: false,
                toBeDeletedTag: null,
                tempTag: null,
                fields: [
                    {
                        name: 'Name',
                        id: 'name',
                        type: 'tag',
                        sortable: true
                    },
                    {
                        name: 'Color',
                        id: 'color',
                        type: 'color',
                        sortable: true
                    },
                ],
            }
        },
        watch: {
            limitTagsToOrganization() {
                this.filterAndPropose({value: ''}, false);
            },
        },
        beforeMount() {
            if(this.tableMode && this.limitTagsToOrganization) {
                this.filterAndPropose({value: ''});
            }
        },
        methods: {
            toggleTagList: function () {
                this.showTagList = !this.showTagList;
                if (this.showTagList) {
                    this.loadTags();
                }
            },
            loadTags: function () {
                this.showTagList = true;
                this.filterAndPropose({value: ''});
                if(this.$refs.textinput){
                    this.$refs.textinput.clearValue();
                }
            },
            createNewTag: function() {
                this.showErrors = true;
                if(this.value && !this.$v.$anyError) {
                    let args = {
                        name: this.value,
                    };
                    //usually we take the orgId from the current user
                    if(this.$store.getters.getCurrentUserOrg) {
                        args.organizationId = this.$store.getters.getCurrentUserOrg;

                        //if the content belongs to a org, we usually want to tag it with this orgId
                        if(this.limitTagsToOrganization) {
                            args.organizationId = this.limitTagsToOrganization;
                        }
                    }
                    this.$store.dispatch('createTag', args).then(newTag => {
                      this.$emit('addTag', newTag);
                      this.loadTags();
                    });

                    if(!this.showTagListPermanently) {
                        this.filterAndPropose({value: this.value});
                    }
                    else {
                        this.$refs.textinput.clearValue();
                    }
                    this.showErrors = false;
                }
                else {
                    this.message = this.$t('You need to enter a name in order to create a new tag.');
                }
            },
            deleteTag: function(id) {
                this.$store.dispatch('deleteTag', {id: id});
                this.showDeletePrompt = false;
                this.toBeDeletedTag = null;
            },
            setColor: function(tag) {
                this.$store.dispatch('updateTag', tag);
            },
            updateTagColor: function(input) {
                this.$store.dispatch('updateTag', {
                    id: this.tempTag.id,
                    color: input,
                });
                this.tempTag = null;
            },
            getValue: function(id, value) {
                this.value = value;
                this.$v.$touch();
            },
            filterAndPropose: function (input, showTags = true) {
                let params = '';
                let filterParamId = 'tags';
                this.showTagList = showTags;
                this.message = '';
                let orgFilter = '';
                this.value = input.value;
                this.$v.$touch();
                this.showErrors = false;
                if(this.limitTagsToOrganization) {
                    orgFilter = 'organizationId eq ' + this.limitTagsToOrganization;
                }
                if(this.excludeVHUBOrganization) {
                    if(orgFilter) {orgFilter += ' and '}
                    const orgs = this.$store.getters.getCurrentUserOrgs;
                    const vHUB = this.$store.getters.getSuperOrg;
                    const filtered = orgs.map(item => {return item.id}).filter(item => {return item !== vHUB})
                    if(filtered.length) {
                      if(filtered.length === 1) {
                        orgFilter = 'organizationId eq ' + filtered[0];
                      } else {
                        orgFilter += "organizationId in '" + filtered.join("' '") + "'";
                      }
                    }
                }
                if (input.value.length > 2) {
                    params = {
                        add: {
                            filter: {
                                [filterParamId]: 'name like %' + input.value + '%'
                            }
                        },
                    };
                } else if (input.value.length === 0) {
                    params = {
                        remove: {
                            filter: {filterParamId}
                        }
                    };

                } else {
                    this.message = this.$t('errors.atLeastThreeCharacters');
                }
                if(orgFilter) {
                    if(!params) {
                        params = {};
                    }
                    if(!params.add) {
                        params.add = {filter: {}};
                    }
                    params.add.filter.orgFilter = orgFilter;
                }
                else {
                    if(!params) {
                        params = {};
                    }
                    if(!params.remove) {
                        params.remove = {filter: {}};
                    }
                    params.remove.filter.orgFilter = 'orgFilter';
                }
                this.$store.dispatch('loadTags', params);
            },
        }
    }
</script>

<style lang="scss">
    .tag-input {
        .form-text {
            margin-bottom: 0;
        }
    }
    .color-preview {
        position: absolute;
        top:50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        cursor:pointer;
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
        border: 1px solid transparent;
    }
    .color-preview-container {
        min-width: 40px;
        height:100%;
        position: relative;
        display: inline-block;
    }
</style>