<template>
  <div>
    <h2>{{ $t('Versions') }}</h2>
    <div class="bg-beige p-3" v-if="original && original.type === 'assembly' && $store.getters.getAssemblyPipelineStatus($route.params.id) === 'processing'">
        {{ $t('AssemblyProcessing') }}
    </div>
    <Button v-if="!creatingVersion && (original.type !== 'assembly' || $store.getters.getAssemblyPipelineStatus($route.params.id) != 'processing')" class="mr-1" @click="createVersion">{{ $t('createNewType', {type: 'version'})}}</Button><br />
    <div class="row mt-3">
      <div class="col-8">
        <loading-panel
          v-if="creatingVersion"
          :time-in-seconds="0"
          message="pleaseWait"
        />
      </div>
    </div>
    <Button class="mt-5 highlight icon-button" @click="loadVersions(); loadOriginal();">
      <icon type="redo"/>
    </Button><br />
                  <!--<div @click="listenToNotification">listen</div>-->
    <div v-if="original" class="mb-4 p-3 bg-dark" :key="key" v-for="(item, key) in versions">
      Tag: {{ item.tag }}<br />
      Created: {{ item.createdAt }}<br />
      Updated: {{ item.updatedAt }}<br />
      User: {{ item.user }}
      <div class="row mt-2"><div class="col-5">older version</div><div class="col-5">{{ key === 0 ? 'current live' : 'newer'}} version</div></div>
      <version-object
        v-if="original"
        :original="key === 0 ? original : original"
        :copy="item"
        @restore="restoreVersion"
        @delete="deleteVersion"
        :remove-buttons="!(!creatingVersion && (original.type !== 'assembly' || $store.getters.getAssemblyPipelineStatus($route.params.id) != 'processing'))"
        :show-loading-panel="creatingVersion"
        :key="forceReRenderKey"
        />
    </div>
  </div>
</template>

<script>
import {v4 as uuidv4} from "uuid";
import VersionObject from "@/components/versions/VersionObject";
import Button from "@/components/forms/Button";
import Icon from "@/components/Icon";
import {AssetTypes} from "@/enum";
import LoadingPanel from "@/components/LoadingPanel";
export default {
  name: "VersionBrowser",
  components: {
    Button,
    Icon,
    VersionObject,
    LoadingPanel
  },
   props: {
   /**
   * @type String Asset, Project, Instance and so on
   * */
     type: {type: String, required: true},
     /**
   * @subtype String Assembly, Media and so on
   * */
     subtype: {type: String, required: true},
     id: {type: String, required: true},
   },
   data() {
    return {
      versions: [],
      original: {},
      creatingVersion: false,
      forceReRenderKey: 0
    };
   },
   beforeMount() {
    this.loadOriginal();
    this.loadVersions();
   },
   methods: {
    loadOriginal() {
      this.original = null;
      console.log('load' + this.type);
      let include = ['metaValues', 'metaSets'];
      if(this.type === 'Project') {
        include.push('assets')
      }
      let args = {
        id: this.$route.params.id,
        include: include,
        keep: {
          include: include
        },
      };
      if(this.subtype === 'assembly') {
        args.addPipelineState = true;
      }
      const storeName = this.subtype.charAt(0).toUpperCase() + this.subtype.slice(1);
      this.$store.dispatch(`load${storeName}`, args)
        .then(data => {
          this.forceReRenderKey++;
          console.log(data);
          this.original = data;
        })
    },
    async loadVersions() {
      this.$store.dispatch(`clientLoad${this.type}Versions`, {
          id: this.id,
          listName: this.id + 'versions',
          sort: '-createdAt',
          keep: {
                        sort: '-createdAt'
                    },
         })
        .then(data => {
          this.versions.map(item => {
            return item;
          })
          this.versions = this.versions.sort(function(a,b){
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.updatedAt) - new Date(a.updatedAt);
          });
          this.versions = data;
        })
    },
    async createVersion() {
      this.creatingVersion = true;
      if(this.subtype === AssetTypes.ASSEMBLY) {
            await this.$store.dispatch('clientAssetToPipeline', {
              id: this.$route.params.id,
              type: 'createVersion',
              virtualMachine: null,
            }).then(() => {
              //this.creatingVersion = false;
              location.reload();
            }).catch(() => {
              // todo: show error
            })
         } else {
       this.$store.dispatch(`clientCreate${this.type}Version`, {
            tag: uuidv4(),
            items: [this.id],
            mode: 'execute'
          })
          .then(data => {
            console.log(data);
            //this.versions.push(data[]);
            this.loadVersions();
            this.listenToNotification();
            this.creatingVersion = false;
          })}
    },
    async listenToNotification() {
        /*this.$store.dispatch('clientLoadNotifications', {cid: this.$store.getters.getCurrentUserId}).then(data => {
            const res = !data.length ? [] : data.filter(item => {return item.targetId === this.id && item.category === 'version'})
            if(!res || !res.length) {
              const $this = this;
              setTimeout(() => {$this.listenToNotification()}, 5000);
            }
            else {
               this.loadOriginal();
              this.loadVersions();
              this.$store.dispatch('createNotification', {
                'text': res[0].message
               });
               this.deleteNotification(res[0].id);
            }
        })*/
    },
    async deleteNotification(id) {
        this.$store.dispatch('clientDeleteNotifications', {cid: this.$store.getters.getCurrentUserId, id: id});
    },
    async restoreVersion(id) {
      this.creatingVersion = true;
       if(this.subtype === AssetTypes.ASSEMBLY) {
          await this.$store.dispatch('clientAssetToPipeline', {
            id: this.$route.params.id,
            type: 'restoreVersion',
            virtualMachine: null,
            config: JSON.stringify({tag: id})
          }).then(() => {
            this.creatingVersion = false;
            // this is a quickfix because the view does not update dammit
            location.reload();
          }).catch(() => {
            // todo: show error
          })
       } else {
          await this.$store.dispatch(`clientRestore${this.type}Version`, {tag: id})
          .then(() => {
            this.loadOriginal();
            this.loadVersions();
            this.listenToNotification();
            this.creatingVersion = false;
          });
       }
    },
    async deleteVersion(id) {
      this.creatingVersion = true;
      if(this.subtype === AssetTypes.ASSEMBLY) {
          await this.$store.dispatch('clientAssetToPipeline', {
            id: this.$route.params.id,
            type: 'deleteVersion',
            virtualMachine: null,
            config: JSON.stringify({tag: id})
          }).then(() => {
            this.loadOriginal();
            //this.creatingVersion = false;
            location.reload();
          }).catch(() => {
            // todo: show error
          })
       } else {
        await this.$store.dispatch(`clientDelete${this.type}Version`, {tag: id})
        .then(() => {
          this.loadOriginal();
          this.loadVersions();
          this.listenToNotification();
          this.creatingVersion = false;
        })
      }
    }
   }
}
</script>

<style scoped>

</style>
