<template>
    <div :style="'background-color: ' + itemColor + '; color: ' + getContrast(item.color)" :id="uiId" @click="$emit('setActive', item.position)" :class="['ui-element', active ? 'active' : '', dragging ? 'dragging' : '', dropping ? 'dropping' : '']">
        <div v-if="item.name" :id="uiId + 'dragHandle'" class="draghandle"><icon type="arrows-alt" /></div>
        <div class="edit-entry" @click="$emit('edit', item.position)" v-if="item.name">
            <icon type="edit" />
        </div>
        <div class="new-entry p-4" @click="$emit('edit', item.position)" v-if="!item.name">
            <icon type="plus" />
        </div>
        <div v-else class="ui-element-content" :style="'background-color: ' + itemColor + '; color: ' + getContrast(item.color)">
            {{ item.name }}
        </div>
        <preview
                :preview-uri="$store.getters.getMediaPreviewUri(item.mediaLink)"
                :preview-id="item.name"
                :removable="false"
                v-if="item.mediaLink" />
    </div>
</template>

<script>
    import Icon from "../../Icon";
    import ColorContrastMixin from "@/components/mixins/ColorContrastMixin.js";
    import DraggableMixin from "@/components/mixins/DraggableMixin.js";
    import DroppableMixin from "@/components/mixins/DroppableMixin.js";
    import Preview from "../../preview/Preview";

    export default {
        name: "UIElement",
        components: {
            Icon, Preview,
        },
        mixins: [ColorContrastMixin, DraggableMixin, DroppableMixin],
        props: {
            item: {type: Object, required: true},
            active: {type: Boolean, default: false},
            uiId: {type: String, required: true},
            dragContraint: {type: String, required: true},
        },
        data() {
            return {
                dragging: false,
                dropping: false,
            };
        },
        computed: {
            itemColor: function() {
                return this.item.color ? this.item.color : '';
            },
        },
        beforeMount() {
            if(this.item.mediaLink) {
                this.$store.dispatch('loadMedia', {
                    id: this.item.mediaLink,
                });
            }

        },
        mounted() {
            this.makeDraggable();
            this.makeDroppable();
        },
        methods: {
            afterDraggedMethod() {
                this.$emit('drag', this.item.position);
            },
            afterDroppedMethod() {
                this.$emit('drop', this.item.position);
            },
        },
    }
</script>

<style lang="scss">

    .ui-element {
        position:absolute;
        width:100%;
        height:100%;
        top:0;
        left:0;
        -webkit-transition: opacity 300ms ease;
        transition: opacity 300ms ease;
        cursor:pointer;
        opacity: 0.4;
        padding:3px;
        .preview {
            width:95%;
            margin-top: 2%;
            margin-left: 2%;
            z-index:1;
        }
        &:hover {
            .ui-element-content {
                opacity: 1;
            }
        }
        &:hover, &.active,  &.dragging {
            background-color: darken($ui-panel-background-color, 10%);
            //outline: 2px solid #929292;
            opacity: 1;
            .edit-entry, .draghandle {
                opacity: 1;
            }
        }
        &.dragging {
            touch-action: none;
            user-select: none;
            background-color: darken($ui-panel-background-color, 10%);
            -webkit-transition: all 0ms ease;
            transition: all 0ms ease;
            z-index:5;
            .ui-element-content {
                opacity: 0;
            }
        }
        &.dropping {
            background-color: $highlight;
        }
    }
    .ui-element-content {
        position:absolute;
        bottom:5px;
        left:0;
        padding:5px;
        z-index:5;
        opacity:0;
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
    }
    .edit-entry {
        opacity:0;
        position:absolute;
        top: 0px;
        left:0px;
        z-index:3;
        z-index:3;
        background-color: $navbarcolor;
        color: #c4c4c4;
        padding:3px;
    }
    .draghandle {
        opacity:0;
        position: absolute;
        top: 0px;
        right: 0px;
        z-index:6;
        background-color: $navbarcolor;
        color: #c4c4c4;
        padding:3px;
    }
    .new-entry {
        position:absolute;
        top:50%;
        left:50%;
        -webkit-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        font-size:1.2em;
        opacity:0.6;
        z-index:3;
    }

    .ui-right {
        .ui-element {
            opacity: 1;
        }
    }
</style>