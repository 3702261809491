<template>
    <one-column-sidebar-left
        back-button-tab-selector="appbackbutton"
    >
<div slot="buttonsRight">
            <Button
                    class="mb-3"
                    link="/app/add"
                    :deactivated="!enableSaving"
                    @click="save">
                <icon v-if="enableSaving" type="save"/>
                <icon v-else type="ban"/>
                {{ $t('SAVE') }}
            </Button>
        </div>

        <div slot="mainContent">
            <h1><icon class="mr-2" type="app" size="0.9" />|<icon class="mr-2 ml-2" size="0.9" type="webapp" />{{ $t('createNewApp') }}</h1>

            <organization-switcher
                @setOrganization="id => {organizationId = id}"
                @setTeam="id => {teamId = id}"
                @unsetTeam="teamId = ''"
                class="mb-2" />
            <input
                    type="text"
                    :class="['form-text mt-3 mb-3', $v.name.$dirty && $v.name.$error ? 'form-group--error' : '']"
                    :id="'name'"
                    v-model="name"
                    :placeholder="$t('enternamehere')"
                    @input="delayTouch($v.name)"
            >

            <div class="form-error" v-if="$v.name.$dirty && !$v.name.required && $v.name.$error">{{ $t('errors.required') }}</div>
            <div class="form-error" v-if="$v.name.$dirty && !$v.name.alphaNumSpace">{{ $t('errors.alphaNumSpaceOnly') }}</div>

            <div class="form-error" v-if="$v.name.$dirty && !$v.name.maxLength">{{ $t('errors.atMostCharacters', {num: 128}) }}</div>

            <div class="form-error" v-if="$v.name.$dirty && !$v.name.minLength">{{ $t('errors.atLeastCharacters', {num: 3}) }}</div>
            <div class="form-error" v-if="$v.name.$dirty && !$v.name.isUnique">{{ $t('errors.appNameAlreadyExists') }}</div>

            <div class="inline-edit-label">
                <b>{{ $t('description') }}</b>
            </div>
            <div>
                 <textarea
                         :class="['form-text mb-3', $v.description.$dirty && $v.description.$error ? 'form-group--error' : '']"
                         :id="'description'"
                         v-model="description"
                         :placeholder="$t('enterdeschere')"
                         @input="delayTouch($v.description)"
                 />
                <div class="form-error" v-if=" $v.description.$dirty && !$v.description.maxLength">{{ $t('errors.atMostCharacters', {num: 128}) }}</div>
            </div>
            <div>
                <div class="inline-edit-label mb-1">
                    <b>{{ $t('chooseAppType') }}</b>
                </div><br >
                <div class="col-12 col-md-4 neutralize-margin-left">
                    <tile-button :rows="2" :icons="true" :initial-active-tiles="['app']" :tiles="['app', 'webapp']" :values="['app', 'webapp']" @trigger="updateType" />
                </div>
            </div>
        </div>
    </one-column-sidebar-left>
</template>

<script>
    import { mapState } from 'vuex';
    import Button from "../components/forms/Button";
    import OneColumnSidebarLeft from "../layouts/OneColumnSidebarLeft";
    import Icon from "../components/Icon";
    import { required, minLength, maxLength } from 'vuelidate/lib/validators';
    import {alphaNumSpace} from '../customValidators';
    import OrganizationSwitcher from "../components/organizations/OrganizationSwitcher";
    import TileButton from "../components/forms/TileButton";
    import VuelidateMixin from "@/components/mixins/VuelidateMixin.js";

    export default {
        name: 'AppAdd',
        components: {
            OrganizationSwitcher,
            Button,
            OneColumnSidebarLeft,
            Icon,
            TileButton,
        },
        mixins: [VuelidateMixin],
        data() {
            return {
                nameEdit: true,
                descEdit: true,
                id: '',
                enableSaving: false,
                name: '',
                description: '',
                type: 'app',
                organizationId: '',
                teamId: ''
            };
        },
        validations: {
            name: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(127),
                alphaNumSpace,
                async isUnique(value) {
                    // standalone validator ideally should not assume a field is required
                    if (value === '') {
                        return true;
                    }
                    let bool = true;
                    await this.$store.dispatch('checkIfAppNameExists', {name: value, type: 'Project', value: value, organizationId: this.organizationId,}).then(data => {
                        bool = data.length === 0;
                    });
                    return bool;
                },
            },
            description: {
                maxLength: maxLength(1024),
            }
        },
        computed: mapState({appsTmp: state => state.apps.appsTmp,}),
        watch: {
            appsTmp(newValue) {
                if(newValue['last_inserted']) {
                    let id = newValue['last_inserted'];
                    if(this.type === 'app') {
                        this.$router.push('/app/'+id+'/project');
                    }
                    else {
                        this.$router.push('/app/'+id+'/linked_data');
                    }
                }
            },
            '$v.$invalid': function() {
                this.enableSaving = !this.$v.$invalid;
                this.$emit('enableSaving', !this.$v.$invalid);
            },
        },
        methods: {
            updateType (args) {
                this.type = args.value;
            },
            save: async function() {
                if(this.enableSaving) {
                    let args = {
                        name: this.name,
                        slug: this.name,
                        description: this.description,
                        type: this.type,
                        //externalId: this.projectId
                    };
                    args.organizationId = this.organizationId;
                    if(this.teamId) {
                      args.teams = [this.teamId];
                    }
                    await this.$store.dispatch('createApp', args);
                }
            },
        },
    }
</script>
