<template>
    <div :class="['filterSection', collapsible ? 'collapsible' : '', collapsed ? 'is-collapsed' : '']">
        <div class="header" @click="saveStatus">
            <slot name="name" /> <icon class="float-right" v-if="collapsible" type="angle-down" />
        </div>
        <div class="content">
            <slot name="content" />
        </div>
    </div>
</template>

<script>
    import Icon from "../../Icon";
    export default {
        name: "FilterSection",
        components: {
            Icon
        },
        props: {
            id: {type: String, required: true},
            collapsible: {type: Boolean, default: false},
            initiallyCollapsed: {type: Boolean, default: false},
        },
        data() {
            return {
                collapsed: true,
            };
        },
        computed: {
            getId: function() {
                return this.$route.name + this.id  + 'FilterSectionCollapsedStatus';
            }
        },
        beforeMount() {
            this.collapsed = window.localStorage.getItem(this.getId);
        },
        methods: {
            saveStatus() {
                this.collapsed = !this.collapsed;
                window.localStorage.setItem(this.getId, this.collapsed);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .filterSection {
        border-bottom: 1px solid $standard-border-color;
        padding-bottom:25px;
        padding-top:15px;
        &:last-child {
            border-bottom: 0;
        }
        &.collapsible {
            border-bottom: 0 transparent;
            padding-bottom: 0;
            .header {
                background-color: $filter-collapsible-background-color;
                padding: 18px;
                padding-top: 12px;
                padding-bottom: 12px;
            }
            .content {
                background-color: $filter-collapsible-content-background;
            }
        }
    }
    label {
        width: 100%;
    }
</style>