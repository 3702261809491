<template>
    <div v-if="type === 'normal'" :class="['filter mt-3 p-3', {closed: isClosed}]" :id="id">
        <div class="filter-label" @click="toggleClass">
            <svg version="1.1" class="filter-label-svg" xmlns="http://www.w3.org/2000/svg"
                 xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 133.9 38"
                 style="enable-background:new 0 0 133.9 38;" xml:space="preserve"><g id="Pfad_444_1_" transform="translate(-11)"><path class="st0" d="M11,38V0h133.9v19.2L123.6,38H11z"/></g>
                <g id="Pfad_444" transform="translate(-11)"><path class="st1" d="M12.5,36.4V0h131.1v18.5l-20.3,17.9H12.5z"/></g>
            </svg>
            <div class="text">
                FILTER
                <icon type="filter"/>
            </div>
        </div>
        <div v-if="!fieldSelectorList" class="broom float-right" @click="clearFilter">
            <div class="hover-text"><span>{{ $t('clearFilter') }}</span></div><icon type="broom" />
        </div>
        <h3>{{ $t('filtering') }}</h3>
        <text-filter
                class="mt-4"
                :clear="forceClearKey"
                v-if="textFiltering"
                @filter="doFilter"
                id="contentFilterText"
                :field-name="textFilteringFieldName"
        />
      <type-filter
          class="mt-4"
          v-if="stateFilterItems"
          :type-filter-items="stateFilterItems"
          :initial-type-filter="initialStateFilter"
          id="contentFilterState"
          @filter="doFilter"
          :collapsible="true"
          filter-type="state"
          :icons-only="false"
        />
        <org-filter
                :visibility-selection="[1, 2]"
                :type="orgFilterLocal ? 'local' : 'global'"
                :clear="forceClearKey"
                class="mt-4"
                v-if="orgFiltering"
                id="contentFilterOrgs"
                @filter="doFilter"
                :limit-to-organization-ids="limitToOrganizationIds"
        />
        <type-filter
                class="mt-4"
                :collapsible="true"
                :clear="forceClearKey"
                v-if="typeFilterItems"
                :type-filter-items="typeFilterItems"
                id="contentFilterType"
                @filter="doFilter"
                :initial-type-filter="initialTypeFilter"
        />
        <media-category-filter
                :filter-type="'mediaCategory'"
                :collapsible="true"
                :clear="forceClearKey"
                v-if="categoryFilterItems"
                :type-filter-items="categoryFilterItems"
                :type-filter-values="categoryFilterValues"
                :type-exclude-values="categoryFilterExcludeValues"
                :pre-selected-values="categoryFilterPreSelectedValues"
                :id="id + 'contentFilterTypeCat'"
                @filter="doFilter"
        />
        <tag-filter
                :collapsible="true"
                :clear="forceClearKey"
                class="mt-2"
                v-if="tagFiltering"
                id="contentFilterTags"
                @filter="doFilter"
        />
        <field-selector
            :clear="forceClearKey"
            v-if="fieldSelectorList"
            :field-list="fieldSelectorList"
            :field-push-selected-item="fieldPushSelectedItem"
            @filter="doFilter"
            @registerComponent="registerComponent"
            @unregisterComponent="unregisterComponent"
            @getMinMax="getMinMax"
            id="contentFilterFieldSelector"
            :content-id="id"
        />
      <slot/>
    </div>
    <div v-else>
       <type-filter
                :collapsible="true"
                :clear="forceClearKey"
                v-if="typeFilterItems"
                :type-filter-items="typeFilterItems"
                id="contentFilterType"
                @filter="doFilter"
                :initial-type-filter="initialTypeFilter"
                class="hidden"
        />
      <media-category-filter
                :filter-type="'mediaCategory'"
                :collapsible="true"
                :clear="forceClearKey"
                v-if="categoryFilterItems"
                :type-filter-items="categoryFilterItems"
                :type-filter-values="categoryFilterValues"
                :type-exclude-values="categoryFilterExcludeValues"
                :pre-selected-values="categoryFilterPreSelectedValues"
                :id="id + 'contentFilterTypeCat'"
                @filter="doFilter"
                class="hidden"
        />
      <org-filter
                :visibility-selection="[1, 2]"
                :type="orgFilterLocal ? 'local' : 'global'"
                :clear="forceClearKey"
                class="hidden"
                v-if="orgFiltering"
                id="contentFilterOrgs"
                @filter="doFilter"
                :limit-to-organization-ids="limitToOrganizationIds"
        />
        <text-filter
                v-if="textFiltering"
                @filter="doFilter"
                id="contentFilterText"
        />
    </div>
</template>

<script>
    /*
    * To use this component the parent needs to implement a filter-method
    * add v-on:filter="filter"
    * in the filter method dispatch the corresponding loading method and pass forward the arguments
    * */

    import Icon from "../../Icon";
    import TypeFilter from "./TypeFilter";
    import TextFilter from "./TextFilter";
    import TagFilter from "./TagFilter";
    import OrgFilter from "./OrgFilter";
    import FieldSelector from "./dataset/FieldSelector";
    import MediaCategoryFilter from "./MediaCategoryFilter";

    export default {
        name: 'ContentFilter',
        components: {
            Icon,
            TextFilter,
            TypeFilter,
            TagFilter,
            OrgFilter,
            FieldSelector,
            MediaCategoryFilter,
        },
        props: {
            id: {type: String, required: true,},
            type: {type: String, default: 'normal',},
            storeName: {type: String, required: true},
            typeFilterItems: {type: Array, default: null},
            initialTypeFilter: {type: Array, default: () => {}},
            categoryFilterValues: {type: Array, default: null},
            categoryFilterItems: {type: Array, default: null},
            /**
             * @param categoryFilterExcludeValues if you wish to exclude some items from the filtered result
             * */
            categoryFilterExcludeValues: {type: Array, default: null},
            categoryFilterPreSelectedValues: {type: Array, default: null},
            tagFiltering: {type: Boolean, default: true},
            orgFiltering: {type: Boolean, default: true},
            orgFilterLocal: {type: Boolean, default: false},
            textFiltering: {type: Boolean, default: true},
            textFilteringFieldName: {type: String, default: null},
            limitToOrganizationIds: {type: Array, default: null},
            fieldSelectorList: {type: Array, default: null},
            fieldPushSelectedItem: {type: Object, default: null},
            stateFilterItems: {type: Array, default: null},
            initialStateFilter: {type: Array, default: () => {}},
        },
        data() {
            return {
                isClosed: this.$store.getters.getFilterStatus,
                emitable: false,
                isClearing: false,
                numberOfComponents: 0,
                numberOfInitializedComponentQueries: 0,
                initialFilter: {},
                forceClearKey: 0,
            }
        },
        watch: {
            numberOfInitializedComponentQueries: function(val) {
                if(val === this.numberOfComponents) {
                    this.emitable = true;
                    this.doFilter(this.initialFilter, !this.isClearing, 'self');
                    this.isClearing = false;
                }
            }
        },
        beforeMount() {
            this.isClosed = this.$store.getters.getFilterStatus;
            if(this.type === 'normal') {
                if(this.textFiltering) {this.numberOfComponents++;}
                if(this.typeFilterItems) {this.numberOfComponents++;}
                if(this.categoryFilterItems) {this.numberOfComponents++;}
                if(this.tagFiltering) {this.numberOfComponents++;}
                if(this.orgFiltering) {this.numberOfComponents++;}
                if(this.fieldSelectorList) { this.numberOfComponents++;}
                if(this.stateFilterItems) { this.numberOfComponents++;}
            }
            else {
              this.numberOfComponents = 1;
              if(this.orgFiltering) {this.numberOfComponents++;}
              if(this.categoryFilterItems) {this.numberOfComponents++;}
              if(this.typeFilterItems) {this.numberOfComponents++;}
            }
        },
        methods: {
            reInitializeEmitFilter: function() {
                this.$emit('filter', {}, true);
            },
            clearFilter: function() {
                this.numberOfInitializedComponentQueries = 0;
                this.emitable = false;
                this.initialFilter = {};
                this.isClearing = true;
                this.forceClearKey++;
            },
            getMinMax: function(fieldName) {
                this.$emit('getMinMax', fieldName);
            },
            registerComponent(number = null) {
                this.numberOfComponents = number ? this.numberOfComponents + number : this.numberOfComponents++;
            },
            unregisterComponent() {
                this.numberOfComponents -= 1;
            },
            toggleClass() {
                this.$store.dispatch('setFilter', !this.isClosed);
                this.isClosed = !this.isClosed;
            },
            doFilter: function(params, initial = false, componentName) {
                this.$log.warn(componentName);
                if(initial) {
                    this.numberOfInitializedComponentQueries++;
                    if(params.add) {
                        let add = params.add;
                        let key = Object.keys(add)[0];
                        if(!this.initialFilter.add) {
                            this.initialFilter.add = {};
                        }
                        if(!this.initialFilter.add[key]) {
                            this.initialFilter.add[key] = {};
                        }
                        let paramKey = Object.keys(add[key])[0];
                        this.initialFilter.add[key][paramKey] = params.add[key][paramKey];
                    }
                    if(params.remove) {
                        let remove = params.remove;
                        let key = Object.keys(remove)[0];
                        if(!this.initialFilter.remove) {
                            this.initialFilter.remove = {};
                        }
                        if(!this.initialFilter.remove[key]) {
                            this.initialFilter.remove[key] = {};
                        }
                        let paramKey = Object.keys(remove[key])[0];
                        this.initialFilter.remove[key][paramKey] = params.remove[key][paramKey];
                    }
                    if(params.addObject) {
                        let addObject = params.addObject;
                        let key = Object.keys(addObject)[0];
                        if(!this.initialFilter.addObject) {
                            this.initialFilter.addObject = {};
                        }
                        if(!this.initialFilter.addObject[key]) {
                            this.initialFilter.addObject[key] = {};
                        }
                        let paramKey = Object.keys(addObject[key])[0];
                        this.initialFilter.addObject[key][paramKey] = params.addObject[key][paramKey];
                    }
                    if(this.emitable) {
                        this.$emit('filter', params, true);
                    }
                }
                else {
                    this.$emit('filter', params, false);
                }
            },
        },
    }
</script>
<style lang="scss">
    $label-width: 38px; //rotated,that's why height = width
    $filter-width: 300px;
    $filter-inner-border-color: #444;

    .filter-checkbox {
        margin-right: 5px;
    }

    .filter-label {
        display: inline-block;
        padding: 0.8em;
        position: absolute;
        z-index: 3;
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        transform-origin: top right;
        right: 0;
        top: -1px;
        height: $label-width;
        min-width: 150px;
        cursor: pointer;

        .text {
            z-index: 3;
            position: absolute;
            top: 50%;
            right: 45px;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
        }

        .filter-label-svg {
            height: 100%;
            transform-origin: center;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 0;

            .st1 {
                fill: $filter-label-background-color;
            }

            .st0 {
                fill: $standard-border-color;
            }
        }
    }

    .filter-separator {
        background-color: $filter-inner-border-color;
        margin: 20px 0px;
    }

    .filter-active {
        input {
            border: 1px solid $highlight;
        }
    }
    $broomWidth: 35px;
    .filter {
        background-color: $filter-background-color;
        position:relative;
        height: auto;
        min-height: 650px;
        border: 1px solid $standard-border-color;
        width: $filter-width;
        margin-right: $label-width + 15px;
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
        z-index:8;
        .broom {
            margin-top: -5px;
            position:relative;
            cursor:pointer;
            background-color: lighten($filter-background-color, 10%);
            -webkit-transition: all 300ms ease;
            transition: all 300ms ease;
            height:$broomWidth;
            width:$broomWidth;
            .icon {
                position:absolute;
                top:50%;
                left:50%;
                -webkit-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
            }
            .hover-text {
                position: absolute;
                background-color: $highlight;
                z-index:15;
                width:0;
                overflow:hidden;
                left: $broomWidth;
                height:$broomWidth;
                top:0px;
                -webkit-transition: all 300ms ease;
                transition: all 300ms ease;
                opacity:0;
                span {
                    display:block;
                    position:absolute;
                    padding-left:5px;
                    width:100%;
                    min-width:100px;
                    top:50%;
                    left:50%;
                    -webkit-transform: translate(-50%,-50%);
                    transform: translate(-50%,-50%);
                }
            }
            &:hover {
                background-color: darken($filter-background-color, 5%);
                .hover-text {
                    display:block;
                    height:$broomWidth;
                    width:150px;
                    opacity:1;
                }
            }
        }
        &.closed {
            margin-left: - $filter-width;
        }

        h3 {
            text-transform: uppercase;
            padding-bottom: 0.5em;
            border-bottom: 1px solid $filter-inner-border-color;
        }

        .form-text {
            width: 100%;
        }
    }

    .mini-filter {
        .form-text {
            width: 100%;
        }
    }

</style>
