<template>
  <div>
    Components<br />
    <Button v-if="!loaded" @click="loadStats(organizationId)">Load <loading-spinner class="float-left mr-1" v-if="loadingStats" /></Button>
    <div v-if="loaded">
      <tr class="d-flex">
        <th>{{ $t('Assembly') }}</th>
        <th>{{ $t('3D-Instances') }}</th>
      </tr>
      <tr :key="index" v-for="(project, key, index) in projects">
        <td><span class="lighter">{{ project.name }}:</span></td><td>{{ project.length }}</td>
      </tr>
      <span class="lighter"><icon type="cube" /> {{ $t('Total 3D-Instances') }}: </span>{{ totalProjects }}<br />
      <span class="lighter"><icon type="cube" /> {{ $t('3D-Assets') }}:</span> {{ totalAssets }}<br />
      <span class="lighter"><icon type="cube" /> {{ $t('Media-Assets') }}: </span>{{ mediaAssets }}<br />
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Icon";
import Button from "@/components/forms/Button";
import LoadingSpinner from "@/components/LoadingSpinner";
export default {
  name: "ComponentStats",
  components: {
    Icon,
    Button,
    LoadingSpinner
  },
  props: {
    organizationId: {type: String, required:true},
  },
  data() {
    return {
      projects: {},
      totalAssets: null,
      mediaAssets: null,
      totalProjects: null,
      loadingStats: false,
      showDetails: false,
      loaded: false
    };
  },
  beforeMount() {
    //this.loadStats(this.organizationId);
  },
  methods: {
    async loadStats(id) {
      this.loadingStats = true;
      await this.$store.dispatch('clientLoadProjects', {
        filter: 'organizationId eq ' + id + ',type eq assembly',
        fields: ['id', 'name']
      })
      .then(async () => {
        let total = 0;
        this.projects = {};
        /*for(let i = 0; i < projects.length; i++) {
            await this.$store.dispatch('clientLoadProjectInstances',
                {
                  id: projects[i].id,
                  fields: ['id'],
                  paging: true,
                  limit: 0
                }).then(res => {
              this.projects[projects[i].id] = {name: projects[i].name, length: res.length};
              total += res.length;
            })
          }*/
        this.totalProjects = total;
      }).then(async () => {
        await this.$store.dispatch('clientLoadAssets', {
          filter: 'type in node model helper, organizationId eq ' + id,
          fields: ['id', 'type'],
          paging: true,
          limit: 1
        }).then(assets => {
          this.totalAssets = assets.headers()['x-count'];
        })
        await this.$store.dispatch('clientLoadAssets', {
          filter: 'type eq media, organizationId eq ' + id,
          fields: ['id', 'type'],
          paging: true,
          limit: 1
        }).then(assets => {
          this.mediaAssets = assets.headers()['x-count'];
        })
        this.loadingStats = false;
        this.loaded = true;
      })
    },
  }
}
</script>

<style scoped>

</style>