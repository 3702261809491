<template>
    <tr>
        <popup
                @close="() => {showDeletePrompt = false;}"
                v-if="showDeletePrompt"
        >
            <delete-prompt slot="popupMainContent"
                           @abort="() => {showDeletePrompt = false;}"
                           @confirm="() => {showDeletePrompt = false; deleteDataRowFinally();}"
            />
        </popup>
      <popup
                @close="() => {showMetaForm = false;}"
                v-if="showMetaForm"
        >
        <div slot="popupMainContent">
          MetaForm for {{ dataRow.id }}
          <label>{{ $t('key and id') }}</label>
          <input type="text" class="form-text" v-model="row.key" />
           <label>{{ $t('task') }}</label>
          <select class="custom-select mb-2" v-model="row.data.task">
            <option :key="index" :value="value" v-for="(value, index) in Object.values(TaskManagerTaskTypes)">{{ value }}</option>
          </select>
          <label>{{ $t('targetType') }}</label>
          <input type="text" class="form-text" v-model="row.data.targetType" />
          <label>{{ $t('userId') }}</label>
          <input type="text" class="form-text" v-model="row.data.userId" />
          <label>{{ $t('prio') }}</label>
          <input type="text" class="form-text" v-model="row.data.prio" />
          <label>{{ $t('status') }}</label>
          <input type="text" class="form-text" v-model="row.data.status" />
          <vm-selector class="mb-3 col-12 col-md-6" @selected="setVM" />
          <Button class="mt-2" @click="saveTask">{{ $t('saveTask') }}</Button>
        </div>
        </popup>
      <td style="min-width: 420px">
       <div v-if="row.key" class="small lighter ml-1 mt-1">{{ row.key }}</div>
        <p class="bg-bright p-1 mb-1 ml-1 mb-3">{{ $store.getters.getOrganizationDisplayName(organizationId) }}</p>
        <Button class="float-right" @click="row.data.userId = $store.getters.getCurrentUserId; showMetaForm = true"  type="icon-button">
                    <icon type="cog"/>
                  </Button>

        <h2 class="ml-1">{{ itemName ? itemName : '&nbsp;' }}</h2>

        <div class="ml-1" v-if="row.data && row.data.status">Status: {{ row.data.status }}</div>
        <info-panel
            class="mt-4"
              :info-panel-header="$t('Dangerous buttons')"
              :collapsible="true"
              :initially-collapsed="true"
              preview-id="none"
          >
          <div slot="info">

            <Button class="mb-2 mt-3 mr-2" @click="startTask" v-if="row.key">{{ $t('start Task') }} <icon type="angle-right"/></Button>
            <Button @click="finishTask" v-if="row.key">{{ $t('start PostProcessing') }} <icon type="box"/></Button>
          </div>
        </info-panel>
        <div class="ml-1 lighter float-right" v-if="row.key">
          <router-link target="_blank" :to="'/library/3d-data/assembly/' + row.key">
           <icon type="external-link-alt" /> Go to resource
        </router-link>
        </div>
        <Button class="mt-5 highlight" @click="setData">
          {{ $t('reload status') }}
                    <icon type="redo"/>
                  </Button>
      </td>
        <td class="data-row-delete" v-if="deletable" @click="deleteDataRow">
            <Button type="icon-button" slot="buttonsRight"
            >
                <icon type="trash-alt"/>
            </Button>
        </td>
      <td class="data-row-delete" v-else>
        <!--empty placeholder because we have the selector top left-->
      </td>
        <td v-if="!hiddenFields.includes(columnIndex)" :key="property" v-for="(field, property, columnIndex) in fields">
            <transition name="toggle" mode="out-in">
                <div :key="'edit'" class=" ml-3" v-if="type === 'tbl' ? columnIndex === currentlyEditingRow.index : property === currentlyEditingRow.index">
                    <date-picker-widget
                            class="mb-2"
                            v-if="field.type === 'timestamp'"
                            :value="currentlyEditingRow.value"
                            type="datetime"
                            @save="updateDataRow"
                    />
                    <input
                            v-else
                            class="form-text mb-2"
                            type="text"
                            :id="dataRow.id"
                            v-model="currentlyEditingRow.value"
                            :ref="'entry'"
                            @init="focusDataField"
                    >
                    <div class="edit-trigger mr-2" @click="finishEditingDataRow">
                        <icon :type="'times'"/>
                    </div>
                    <div class="edit-trigger" @click="saveDataRow">
                        <icon :type="'save'"/>
                    </div>
                </div>
            </transition>
            <div :key="'content'" class="table-filler" v-if="type === 'tbl' ? columnIndex !== currentlyEditingRow.index : property !==currentlyEditingRow.index"
                 @click="editDataRow(getIndexType === 'columnIndex' ? columnIndex : property)">
                        <div class="clickable withHoverBrightBackground pt-2 pb-2 pl-3 pr-4" v-if="field.type === 'timestamp' && dataRow.data[getIndexType === 'columnIndex' ? columnIndex : property]">
                            {{ dateTimeFromISO(dataRow.data[getIndexType === 'columnIndex' ? columnIndex : property]) }} <icon :type="'edit'"/>
                        </div>
                <div class="clickable withHoverBrightBackground pt-2 pb-2 pl-3 pr-4" v-else-if="dataRow.data[getIndexType === 'columnIndex' ? columnIndex : property]">
                            {{ dataRow.data[getIndexType === 'columnIndex' ? columnIndex : property] }} <icon :type="'edit'"/>
                        </div>
                <div v-else class="clickable withHoverBrightBackground pt-2 pb-2 pl-3 pr-4">
                    <span class="lighter">{{ $t('addData') }}</span> <icon :type="'plus'"/>
                </div>
            </div>
        </td>
    </tr>
</template>

<script>
    import DatePickerWidget from "../editors/DatePickerWidget";
    import DeletePrompt from "../../forms/DeletePrompt";
    import Popup from "../../Popup";
    import Icon from "../../Icon";
    import DateMixin from '@/components/mixins/DateMixin.js';
    import Button from "../../forms/Button";
    import {SpecialUuids, TaskManagerTaskTypes} from "@/enum";
    import InfoPanel from "@/components/InfoPanel";
    import vmSelector from "@/components/vmSelector";

    export default {
        name: "DatasetRowWidget",
        components: {
            DatePickerWidget,
            DeletePrompt,
            Popup,
            Icon,
            Button,
            InfoPanel,
            vmSelector
        },
        mixins: [
            DateMixin,
        ],
        props: {
            dataRow: {type: Object || Array, default: null},
            fields: {type: Object || Array, default: null},
            hiddenFields: {type: Array, required: true},
            datasetId: {type: String, required: true,},
            type: {type: String, required: true},
            deletable: {type: Boolean, default: false},
        },
        data() {
            return {
              row: {},
              itemName: '',
              SpecialUuids: SpecialUuids,
                currentlyEditingRow: {
                    id: '',
                    index: '',
                    value: '',
                },
                currentlyDeletingRow: '',
                showDeletePrompt: false,
                showMetaForm: false,
                organizationId: '',
                TaskManagerTaskTypes: TaskManagerTaskTypes,
            };
        },
        computed: {
            getIndexType: function () {
                return this.type === 'tbl' ? 'columnIndex' : 'property';
            },
        },
        beforeMount() {
          // Special Code for Taskmanager
          this.setData();
        },
        methods: {
          setVM(id) {
            this.row.data.virtualMachine = id;
          },
          async setData() {
            const localDataRow = await this.$store.dispatch('clientLoadDatasetRows', {id: this.datasetId, filter: `id eq ${this.dataRow.id}`});
            this.row = JSON.parse(JSON.stringify(localDataRow[0]));
            Object.keys(this.fields).map(item => {
              if(this.row.data[this.fields[item].columnIndex]) {
                const data = this.row.data[this.fields[item].columnIndex];
                delete this.row.data[this.fields[item].columnIndex];
                this.row.data[item] = data;
              } else {
                this.row.data[item] = '';
              }
            });
            if(!this.row.data) {
              this.row.data = {};
            }
            if(this.row.data.id) {
              if(this.row.data.targetType === 'Asset') {
                this.$store.dispatch('clientLoadAsset', {id: this.row.data.id}).then(data => {
                  this.itemName = data.name;
                  this.organizationId = data.organizationId;
                })
              } else {
                this.$store.dispatch('clientGetCrossProjectInstances', {filter: `id eq ${this.row.data.id}`, include: ['asset', 'project']}).then(data => {
                  //console.log(data);
                  if(data[0]) {
                    this.itemName = data[0].name;
                    this.organizationId = data[0].asset.organizationId;
                  }
                })
              }
            }
          },
          startTask() {
            this.row.data.status = 'new';
            this.saveTask();
            setTimeout(() => {}, 2000);
            let dispatchMethod = 'clientAssetToPipeline'
            if(this.row.data.targetType === 'Instance') {
              dispatchMethod = 'clientInstanceToPipeline';
            }
            this.$store.dispatch(dispatchMethod, {
                id: this.row.key,
                type: this.row.data.task,
                virtualMachine: this.row.data.virtualMachine
            });
          },
          finishTask() {
            this.$store.dispatch('clientFinishPipelineTask', {
                id: this.row.key
            });
          },
          saveTask: function() {
            this.$store.dispatch('clientUpdateDatasetRow', {
              id: this.datasetId,
              datarowId: this.row.id,
              key: this.row.key,
              listName: this.datasetId,
              data: {
                id: this.row.key,
                targetType: this.row.data.targetType,
                task: this.row.data.task,
                userId: this.row.data.userId,
                prio: this.row.data.prio,
                status: this.row.data.status,
                virtualMachine: this.row.data.virtualMachine
              }
            }).then(data => {
              this.$emit('updateDatasetRow', data);
              this.showMetaForm = false;
            });
          },
            deleteDataRow: function(){
                this.showDeletePrompt = true;
            },
            deleteDataRowFinally: function() {
                this.$store.dispatch('deleteDatasetRow', {
                    id: this.datasetId,
                    args: [this.dataRow.id],
                    listName: this.datasetId,
                });
                this.$emit('updateDatasetRow', {id: this.dataRow.id, data: null})
            },
            editDataRow: function (index) {
                this.currentlyEditingRow.index = index;
                if(this.dataRow.data[index]) {
                    this.currentlyEditingRow.value = this.dataRow.data[index];
                }
            },
            focusDataField: function () {
                this.$refs.entry[0].$el.children[1].focus();
            },
            finishEditingDataRow: function () {
                this.currentlyEditingRow.index = '';
                this.currentlyEditingRow.value = '';
            },
            updateDataRow: function(val) {
                let value = val ? val : val.value;
                this.currentlyEditingRow.value = value;
            },
            saveDataRow: function () {
                let dataRowID = this.dataRow.id;
                //let value = val ? val : val.value;
                let value = this.currentlyEditingRow.value;
                let index = this.currentlyEditingRow.index;

                //make a deep copy
                let temp = JSON.parse(JSON.stringify(this.dataRow));
                if (!temp) {
                    temp = this.dataRow.filter(item => {
                        return item.id === this.dataRow.id
                    })[0];
                }
                let dataRow = temp.data;
                dataRow[index] = value;

                let keys = Object.keys(this.fields);
                let fieldName = '';
                for (let i = 0; i < keys.length; i++) {
                    if (this.fields[keys[i]].columnIndex === index) {
                        fieldName = keys[i];
                        break;
                    }
                }
                if (!fieldName) {
                    fieldName = index;
                }
                //find the fieldname end

                let params = {
                    id: this.datasetId,
                    data: {[fieldName]: value}, //replace by complete datarow
                    datarowId: dataRowID,
                    listName: this.datasetId,
                };
                this.$store.dispatch('clientUpdateDatasetRow', params).then(data => {
                  this.$emit('updateDatasetRow', data);
                });
                this.$emit('dataRowChange', dataRowID, index, value);
                this.finishEditingDataRow();
                this.setData();
            },
        }
    }
</script>

<style lang="scss">
    //transition
    .toggle-enter-active, .toggle-leave-active {
        transition: all .3s ease-out;
        height: 80px;
        overflow:visible;
    }
    .toggle-enter, .toggle-leave-to {
        height: 0;
        overflow:hidden;
    }

    .data-row-delete {
        cursor:pointer;
        opacity:0.8;
        &:hover {
            opacity:1;
        }
    }
    .table-filler {
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
        position:relative;
        .icon {
            -webkit-transition: all 300ms ease;
            transition: all 300ms ease;
            opacity: 0;
            position:absolute;
            right: 6px;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            font-size: 0.8em;
        }
        &:hover {
            .icon {
                opacity: 1;
            }
        }
    }
</style>