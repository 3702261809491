<template>
    <main-table-layout
        sub-menu-selector="librarypanel"
    >
        <content-filter
                slot="filter"
                @filter="filter"
                store-name="Dataset"
                id="datasetList"
                :type-filter-items="['tbl', 'obj']"
        />
        <view-switcher
                slot="mainContentButtons"
                id="mainContent"
                :table="true"
                :thumbnails="true"
        />
        <pagination
                slot="mainContentButtons"
                :limit="paginationLimit"
                :total="$store.getters.getDatasetListOptions(listName, 'pagination_items')"
                id="datasettablepage"
                @setActive="loadPaginatedData"
                ref="pagination"
        />
        <Button
            permission="createDataSet"
            type="action-button" slot="mainContentButtons" link="/library/dataset/add" icon="plus">
                    {{ $t('createNewDataSet') }}
        </Button>

      <Button
           slot="mainContent"
           @click="loadPaginatedData(0); $refs.pagination.setActive(0)"
           type="reload"
           icon="redo"
           class="icon-button"
       />
        <div slot="mainContent">
            <table-widget
                    v-if="$store.getters.getViewSwitcher(this.$route.name, 'mainContent') === 'table'"
                    id="appsDefaultTable"
                    :fields="fields"
                    :data="$store.getters.getDatasetsByListName(listName)"
                    :title-as-links="true"
                    @sort="sort"
                    :nav-by-clicking-row="true"
                    :deletable="true"
                    @deleteRow="deleteRow"
                    :show-organization="true"
                    edit-permission="writeDataSet"
                    delete-permission="deleteDataSet"
                    :use-team-permission="true"
                    :loading="loading"
            />
            <thumbnails
                    v-if="this.$store.getters.getViewSwitcher(this.$route.name, 'mainContent') === 'thumbnails'"
                    id="appsDefaultTable"
                    :fields="thumbnailFields"
                    :data="$store.getters.getDatasetsByListName(listName)"
                    :title-as-links="true"
                    @sort="sort"
                    :deletable="true"
                    @deleteRow="deleteRow"
                    :nav-by-clicking-row="true"
                    edit-permission="writeDataSet"
                    delete-permission="deleteDataSet"
                    :use-team-permission="true"
                    :loading="loading"
            />
        </div>
    </main-table-layout>
</template>

<script>
    import Button from "../components/forms/Button";
    import TableWidget from "../components/widgets/TableWidget";
    import ContentFilter from "../components/widgets/filter/ContentFilter";
    import MainTableLayout from "../layouts/MainTableLayout";
    import ViewSwitcher from "../components/widgets/ViewSwitcher";
    import Thumbnails from "../components/widgets/ThumbnailView";
    import Pagination from "../components/Pagination";

    export default {
        name: 'DataSets',
        components: {
            Button,
            TableWidget,
            ContentFilter,
            MainTableLayout,
            ViewSwitcher,
            Thumbnails,
            Pagination,
        },
        data() {
            return {
                listName: 'DatasetList',
                loading: true,
                paginationLimit: 15,
                queryRelevantComponents: 2, //pagination and filter
                queryRelevantInits: 0,
                sorting: '-updatedAt',
                fields: [
                    {
                        name: '',
                        id: 'icon',
                        type: 'icon',
                        virtual: true,
                        fieldId: 'type'
                    },
                    {
                        name: 'Name',
                        id: 'name',
                        type: 'string',
                        sortable: true
                    },
                    {
                      name: 'Teams',
                      id: 'teams',
                      type: 'teams',
                    },
                    {
                        name: 'Type',
                        id: 'type',
                        type: 'string',
                    },
                    {
                        name: 'Last Update',
                        id: 'updatedAt',
                        type: 'date',
                        sortable: true
                    },
                    {
                        name: 'Edit',
                        type: 'editButton'
                    },
                ],
                thumbnailFields: [
                    {
                        name: '',
                        id: 'icon',
                        type: 'icon',
                        virtual: true,
                        fieldId: 'type'
                    },
                    {
                        name: 'Organization',
                        id:'organization',
                        type: 'organization',
                        sortable: true
                    },
                    {
                        name: 'Type',
                        id: 'type',
                        type: 'string',
                    },
                    {
                        name: 'Last Update',
                        id: 'updatedAt',
                        type: 'date',
                        sortable: true
                    },
                    {
                        name: 'Name',
                        id: 'name',
                        type: 'string',
                        sortable: true
                    },
                    {
                      name: 'Teams',
                      id: 'teams',
                      type: 'teams',
                    },
                    {
                        name: 'Edit',
                        type: 'editButton'
                    },
                ]
            }
        },
        watch: {
            queryRelevantInits: function(newval) {
                if(newval === this.queryRelevantComponents) {
                    this.loadPaginatedData(0);
                }
            }
        },
        methods: {
            loadPaginatedData: function (offset, initial) {
              this.loading = true;
                let args = {
                    add: {
                        paging: {
                            datasetdefaultTable: true,
                        },
                        offset: {
                            datasetdefaultTable: offset,
                        },
                        limit: {
                            datasetdefaultTable: this.paginationLimit,
                        },
                        include: {
                          datasetdefaultTable: ['teams'],
                        },
                        sort: {
                          [this.listName]: this.sorting,
                        }
                    }
                };
                if(initial) {
                    this.queryRelevantInits++;
                    this.$store.dispatch('registerDatasetQueryParams', {params: args});
                }
                else {
                    this.$store.dispatch('loadDatasets', args).then(() => {
                    this.loading = false;
                  });
                }
            },
            deleteRow: function (id) {
                this.$store.dispatch('deleteDataset',{
                    id: id
                }).then(() => {
                  this.loadPaginatedData(0);
                })
            },
            filter: function(args, initial) {
                if(initial) {
                    this.queryRelevantInits++;
                    this.$store.dispatch('registerDatasetQueryParams', {params: args});
                }
                else {
                  this.loading = true;
                    this.$store.dispatch('loadDatasets', args).then(() => {
                    this.loading = false;
                  });
                }
            },
            sort(args) {
              const {fieldname, direction} = args;
              const sortString = direction + fieldname;
              if(this.sorting !== sortString) {
                this.sorting = sortString;
              }
              this.loadPaginatedData(0);
            }
        }
    }
</script>
